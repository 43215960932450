<template lang='pug'>

  div
    div(class="form-field-label")
      span {{label}}
      span(v-show="required" class="form-field-require-tag" style="color:red;" :title="notifies.required_field")
        |*

    div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")

      div(v-if="!filesExist(currentField)" class="file-drag-drop-area" :id="`file-field-${name}`" ref="fileInput" @click="openFileInput")
        img(class="file-field-icon" :src="require('../../../../assets/icons/form/fields/file_field_icon.svg')")
        span(class="drop-files") {{field_locales.sublabel}}
        q-file(v-show="false" filled v-model="currentField" :multiple="multiple" :label="label" :for="`file-input-${name}`")

      div(v-if="filesExist(currentField)" class="form-preview-area justify-center")
        div(v-for="(file, key) in currentField" class="preview-file")
          img(v-if="['image/jpg', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type)"
              class="preview-img"
              :ref="'preview-img'+parseInt( key )")
          inline-svg(v-else
                      class="preview-doc"
                      :src="require('../../../../assets/icons/document.svg')")
          span(class="caption") {{ cutFileName(file.name) }}


      q-icon(name="cancel" v-if="currentField" @click.stop="resetField()" class="cancel-file-field")

    span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}


    div(v-if="filesExist(value)" class="form-image-area justify-center")
      existing-files-area(:parentData="{files: value}")

</template>

<script>
  import existingFilesArea from '../../helpers/existingFilesArea'

  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        multiple: this.parentData.data[0].multiple || false,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        available_format: this.parentData.data[0].available_format,
        depend_from: this.parentData.data[0].depend_from || {},
        // required: this.parentData.data[0].require,
        req: this.parentData.data[0].require,
        valid_error_message: null,
        main_class: 'form-field form-file',

        watch: this.parentData.data[0].watch || {},
        image_url: '',

        image: false,

        dragAndDropCapable: false,
        files: [],
        uploadPercentage: 0,
      }
    },

    components: {
      existingFilesArea
    },

    props: {
      parentData: Object
    },

    computed: {

      required: {
        get() {
          return this.req
        },
        set(value) {
          this.req = value
        }
      },

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            if (form_field['field']) {
              return form_field['field'].map(field => field['label'])
            } else {
              return undefined
            }
          } else {
            return undefined
          }
        },
        set(value) {
          this.checkAvailableFormat(value)
        }
      },

      fieldDependency() {
        return this.checkDependency()
      },

      field_locales() {
        return this.locales.file_field[this.current_locale]
      },
    },

    watch: {
      fieldDependency(newVal, oldVal) {}
    },

    methods: {

      setField(val) {
        // this.value = val
      },

      setRequire(val) {
        this.required = val
        let result = {}
        if (val) {
          this.resetField()
        } else {
          let result = {}
          result['invalid'] = false
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
        }
      },

      checkAvailableFormat(value) {
        if (this.available_format && this.available_format.types && this.available_format.types.length > 0) {
          if (this.multiple) {
            value.forEach(f => {
              if (!this.available_format.types.includes(f.type)) {
                let msg = this.notifies.invalid_file_extension + this.available_format.names.join(', ')
                this.$q.notify(msg)
              }
            })
          } else {
            let val = value[0] || value
            if (!this.available_format.types.includes(val.type)) {
              let msg = this.notifies.invalid_file_extension + this.available_format.names.join(', ')
              this.$q.notify(msg)
            } else {
              this.getFile(val)
            }
          }

          if (this.multiple) {
            let val = value.filter(f => {
              return this.available_format.types.includes(f.type)
            })
            this.getFile(val)
          }
        } else {
          this.getFile(value)
        }
      },

      cutFileName(name) {
        return name.length > 16 ? name.substring(0, 14) + '...' : name
      },

      filesExist(array) {
        return array && array.filter(el => el).length > 0
      },

      openFileInput() {
        document.getElementById(`file-input-${this.name}`).click()
      },

      determineDragAndDropCapable(){
        let div = document.createElement('div');
        return ( ( 'draggable' in div )
          || ( 'ondragstart' in div && 'ondrop' in div ) )
          && 'FormData' in window
          && 'FileReader' in window;
      },

      getImagePreviews(){
        if (this.currentField && this.currentField.length > 0) {
          this.currentField.forEach((file, i) => {
            if ( /\.(jpe?g|png|gif)$/i.test( file.name ) ) {
              let reader = new FileReader();
              reader.addEventListener("load", function(){
                this.$refs['preview-img' + parseInt( i )][0].src = reader.result;
              }.bind(this), false);
              reader.readAsDataURL( file );
            // } else {
            //   this.$nextTick(function(){
            //     this.$refs['preview' + parseInt( i )][0].src = require('assets/icons/document.svg')
            //   });
            }
          })
        }
      },

      showImage(url) {
        this.image_url = url
        this.image = true
      },

      isImage(value) {
        if (value) {
          return ['image/jpeg', 'image/png'].includes(value.content_type)
        } else {
          return false
        }
      },

      getFile(value) {
        let files = []
        let result = {}
        let arr = [value].flat()

        arr.forEach(file => {

            let val = {
              label: file,
              value: {name: file.name, file: file, type: file.type},
            }

            files = files.concat([val])

            result['field'] = files
            result['invalid'] = this.invalid(files)
            this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})

        })

        setTimeout(() => {
          this.getImagePreviews();
        }, 500)
      },

      resetField() {
        let result = {}
        result['invalid'] = this.invalid()
        this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
      },

      invalid(val=undefined) {
        if (this.required) {
          if (val && val.length > 0) {
            this.valid_error_message = null
            return false
          } else {
            this.valid_error_message = this.notifies.not_empty
            return true
          }
        } else {
          this.valid_error_message = null
          return false
        }
      }
    },

    created() {
      this.resetField()
      this.watchSimpleFieldParents()
    },

    beforeMount() {
    },

    mounted(){
      let input = document.getElementById(`file-field-${this.name}`)
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if( this.dragAndDropCapable ) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          input.addEventListener(evt, function(e) {
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));
        input.addEventListener('drop', function(e) {
          let files = this.multiple ? e.dataTransfer.files : [e.dataTransfer.files[0]]
          files = Object.values(files)
          this.checkAvailableFormat(files)
        }.bind(this));
      }
    },
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/file";

</style>