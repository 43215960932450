<template lang='pug'>

  div
    q-form(@submit="submitForm")

      div(class="row" v-for="(row, i) in data.scheme" :ref="row[0]['name']")
        div(v-for="field in row" :key="field.name" :class="'col-' + field.col" style="padding: 5px;")

          component(v-show="getFieldDataByName(field)"
                    :is="getFieldComponent(getFieldDataByName(field))"
                    @show-field="showField"
                    @fields-loaded="fieldsLoaded"
                    :parentData="{method: method, method_limit: getFieldDataByName(field).method_limit, grid: grid, data: [getFieldDataByName(field)], item_id: item_id, building_id: building_id}")

      div(class="button-group row justify-center")
        q-btn(flat no-caps class="new-edit-form-submit" :label="new_edit_form_locales.submit" type="submit")

</template>

<script>
  import fields from '../../fields'

  export default {
    data: function () {
      return {
        // data: this.parentData.data,
        method: this.parentData.method,
        grid: this.parentData.grid,
        item_id: this.parentData.item_id,
        building_id: this.parentData.building_id,
      }
    },

    props: {
      parentData: Object
    },

    components: {
      fields
    },

    computed: {
      data() {
        return this.parentData.data
      }
    },

    methods: {

      showField(data) {
        this.$nextTick(() => {
          if (this.$refs[data.name]) {
            if (data.val) {
              this.$refs[data.name][0].style.display = 'flex'
            } else {
              this.$refs[data.name][0].style.display = 'none'
            }
          }
        })
      },

      fieldsLoaded(field) {
        this.$emit('fields-loaded', field)
      },

      submitForm() {
        this.$emit('submit-form')
      },

      getFieldComponent(key) {
        if (key) {
          return fields[key.type]
        }
      },

      getFieldDataByName(field) {
        return this.data.fields.find(f => f.name === field.name)
      }
    },

    created() {
    }
  }
</script>

<style lang="scss">

</style>