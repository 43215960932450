<template lang='pug'>

  div(class="equipment-tech-map")

    div(class="tech-map-card")

      div(class="main-block")

        div(v-if="data.qr" class="qr" v-html="data.qr")

        div(class="actions-block")
          div(class="tech-map-title")
            div(class="title")
              span {{data.title}}
            div(class="sub-title")
              span {{data.sub_title}}

      div(class="specification-block")
        div(class="block-title")
          span {{titles.specification}}

        div
          table(class="specification-table")
            tbody
              tr(v-for="item in getData('specification')")
                td(v-for="key in Object.keys(item)") {{item[key].length > 0 ? item[key] : '-'}}

      div(class="components-block")
        div(class="block-title")
          span {{titles.components}}

        table(class="tech-map-tab-table components-table" id="print-components-table")
          thead
            tr
              th(v-for="column in data['columns']['components']" :key="column.name")
                span(class="column-header-title") {{ column.label }}
          tbody
            tr(v-for="item in getData('components')")
              td(v-for="key in Object.keys(item)" :class="{'properties-td': key === 'properties'}" :style="{'padding': (key === 'properties' ? '0' : '10px')}")
                table(v-if="key === 'properties'" class="properties-table")
                  tr(v-for="sub_item in item[key]")
                    td(v-for="sub_key in Object.keys(sub_item)")
                      span {{sub_item[sub_key].length > 0 ? sub_item[sub_key] : '-'}}

                span(v-else) {{item[key].length > 0 ? item[key] : '-'}}

      div(class="replaced-components-block")
        div(class="block-title")
          span {{titles.replaced_components}}

        table(class="tech-map-tab-table replaced-components-table" id="print-replaced-components-table")
          thead
            tr
              th(v-for="column in data['columns']['replaced_components']" :key="column.name")
                span(class="column-header-title") {{ column.label }}
          tbody
            tr(v-for="item in getData('replaced_components')")
              td(v-for="key in Object.keys(item)" :class="{'properties-td': key === 'properties'}" :style="{'padding': (key === 'properties' ? '0' : '10px')}")
                table(v-if="key === 'properties'" class="properties-table")
                  tr(v-for="sub_item in item[key]")
                    td(v-for="sub_key in Object.keys(sub_item)")
                      span {{sub_item[sub_key].length > 0 ? sub_item[sub_key] : '-'}}

                span(v-else) {{item[key].length > 0 ? item[key] : '-'}}


      div(v-if="getData('issues').length > 0" class="issues-block")
        div(class="block-title")
          span {{titles.issues}}

        table(class="tech-map-tab-table issues-table")
          thead
            tr
              th(v-for="column in data['columns']['issues']" :key="column.name")
                span(class="column-header-title") {{ column.label }}
          tbody
            tr(v-for="issue_data in getData('issues')")
              td(v-for="td in issue_data" :class="td.key" :style="td.style")
                span {{td.value}}

</template>

<script>

  export default {
    data: function () {
      return {
        data: this.parentData.data,
        titles: this.parentData.titles,
        row: this.parentData.row
      }
    },

    props: {
      parentData: Object
    },

    methods: {

      getData(key) {
        if (key) {
          if (key === 'issues') {
            return this.row[key]
          } else {
            return this.row['technological_map'][key]
          }
        }
      },
    }
  }
</script>
