import AbstractEntity from './AbstractEntity'


class Building extends AbstractEntity {
  getType() {
    return 'building';
  }

  getTitle() {
    return this.data.title;
  }

  getAddress() {
    return this.data.address;
  }

  getDescription() {
    return this.data.description;
  }

  getActiveIssuesNumber() {
    return this.data.issue_counts.active;
  }

  getUrgentIssuesNumber() {
    return this.data.issue_counts.urgent;
  }

  hasUrgentIssues() {
    return this.getUrgentIssuesNumber() > 0;
  }

  getRooms() {
    return this.data.rooms;
  }
}

export default Building;
