import Vue from 'vue';

Vue.mixin({

  computed: {

    currentFilters() {
      return this.$store.state.grid[this.grid]['filters']
    }
  },


  methods: {

    loadSelectFilterOptions(attr, value=undefined) {
      let filter = this.data.filters.find(f => f.name === attr.filter_name)
      let current_filter = this.currentFilters[attr.filter_name]
      let val = value || current_filter

      if (val) {
        if (filter.multiple) {
          attr.params['default_value'] = val.map(v => v.value)
        } else {
          attr.params['default_value'] = val.value || val
        }
      }

      this.$backend.collection(this.$store.state.paths[filter.options_path] + '/collection', attr.params)
      .then(({ data }) => {

        let for_select = data.options.map(el => {return [(el['title'] ? el['title'] : el['full_name']), el.id]}).map(el => {
          return el.reduce((result, val, index, arr) => {
            result['label'] = arr[0];
            result['value'] = arr[1];
            return result
          }, {})
        });

        this.options_data = {
          opt_object: data,
          options: for_select
        }

        if (this.$refs['grid-filters-form']) {
          this.$refs['grid-filters-form'].setOptionsData(this.options_data, filter.name);
          this.$refs['grid-filters-form'].setLoading(false, filter.name);
          this.$refs['grid-filters-form'].filtersLoaded(['select', filter.name])

        } else if (this.$refs['project-filters-form']) {
          this.$refs['project-filters-form'].setOptionsData(this.options_data, filter.name);
          this.$refs['project-filters-form'].setLoading(false, filter.name);
          this.$refs['project-filters-form'].filtersLoaded(['select', filter.name])

        } else {
          this.setOptionsData(this.options_data, filter.name);
          this.setLoading(false, filter.name);
          this.filtersLoaded(['select', filter.name])
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) { this.reLogin(error.response.status) }
      })
      .finally(() => {
        let val = this.multiple ? [] : ''

        if (!this.currentFilters[filter.name] && val) {
          this.$store.commit('createFilter', {grid_name: this.grid, filter: filter.name})
          this.$store.commit('updateFilter', {grid_name: this.grid, filter: filter.name, value: val})
        }

        if (value) {
          if (filter.multiple) {
            if (value.length > 0) {
              val = value.filter((e) => {
                return e != null
              }).map((e) => {
                return this.options_data.options.find((opt) => {
                  return opt['value'] === e['value']
                })
              }).filter(e => e !== undefined);
            }

          } else {
            if (value !== '') {
              val = this.options_data.options.find(e => e['value'] === value);
            }
          }

          if (val) {
            this.$store.commit('updateFilter', {grid_name: this.grid, filter: filter.name, value: val})
          }
        }
      })
    },

    loadChildrenSelectFilterOptions(parent) {
      this.data.filters.forEach(child => {
        if (child.watch && child.watch['parent'] && child.watch['parent'] === parent.name) {

          let attr = Object.keys(child.options_params).reduce((result, key) => {
            result[key] = child.options_params[key]
            return result
          }, {})

          attr[child.watch['parent']] = parent.value

          this.loadSelectFilterOptions({params: attr, filter_name: child.name})
        }
      })
    }
  }
})