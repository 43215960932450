<template lang='pug'>

  q-card(id="form" class="modal-form-card")

    q-card-section
      form-header(ref="form-header" @close-form="closeForm"
                  :parentData="{grid: grid, title: header_title, title_icon: 'download_header.svg'}")

    div(v-if="!data_has_loaded && loading" class="spinner-container modal-spinner")
      q-spinner(color="primary" size="3em")

    q-card-section(v-if="data_has_loaded")
      q-checkbox(v-show="fields && fields.length > 0 && !loading" dark class="form-checkbox"
                  v-model="with_filters" :label="form.with_filters_label")

    q-card-section(v-if="data_has_loaded" v-show="with_filters && !loading")
      q-form
        component(v-for="field in fields"
                  :key="field.name"
                  :is="getFieldComponent(field.type)"
                  @fields-loaded="fieldsLoaded"
                  :parentData="{grid: grid, data: getFieldData(field)}")

    q-card-actions(v-if="data_has_loaded && !loading" class="button-group row justify-center")
      q-btn(flat no-caps
            class="modal-form-cancel"
            :label="form.form_buttons.cancel"
            @click="closeForm()")
      q-btn(flat no-caps
            class="modal-form-submit"
            :label="form.form_buttons.submit"
            @click="onExport()")

</template>

<script>
  import formHeader from '../../shared/forms/formHeader'
  import fields from './fields'

  export default {
    data: function () {
      return {
        data: {},
        path: this.parentData.path,
        report_path: this.$store.state.paths['report'],
        grid: this.parentData.grid,
        collection_type: this.parentData.export_attr.collection_type,
        sti_type: this.parentData.export_attr.sti_type,
        report_class: this.parentData.export_attr.report_class,
        report_namespace: this.parentData.export_attr.report_namespace,
        export_type: this.parentData.export_attr.export_type,
        except_filters: this.parentData.export_attr.except_filters,

        loading_fields: [],
        loading: true,
        data_has_loaded: false,

        with_filters: false,

        sheet_data: [],
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      fields
    },

    computed: {

      header_title() {
        return this.parentData.export_attr.header_title
      },

      form() {
        return this.data.form
      },

      fields() {
        return this.data.fields
      }
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    methods: {

      closeForm() {
        this.resetForm()
        this.$emit('close-export', {})
      },

      resetForm() {
        let fields = Object.keys(this.currentForm);
        this.$store.commit('resetFormAllFields', {grid_name: this.grid, fields: fields})
      },

      getFieldComponent(key) {
        return fields[key]
      },

      getFieldData(field) {
        return this.fields.filter(f => f == field)
      },

      fieldsLoaded(field=undefined) {
        if (this.loading_fields.length === 0) {
          this.loading_fields = this.fields.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (field) {
          this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString())
        }

        if (this.loading_fields.length === 0) {
          this.loading = false
        }
      },

      onExport() {
        let fn
        let attr = {}
        attr['report_params'] = {}
        attr['report_params']['filters'] = {}

        if (this.collection_type) {
          attr['report_params']['collection_type'] = this.collection_type
        }

        if (this.export_type) {
          attr['report_type'] = this.export_type
        }

        if (this.with_filters) {
          attr['report_params']['filters'] = this.generateFiltersParams();
        }

        if (this.except_filters) {
          attr['report_params']['except_filters'] = this.except_filters
        }

        if (this.sti_type) {
          attr['report_params']['filters']['type'] = this.sti_type
        }

        if (this.report_class) {
          attr['report_params']['report_class'] = this.report_class
        }

        if (this.report_namespace) {
          attr['report_params']['report_namespace'] = this.report_namespace
        }

        this.loading = true

        this.loadReport(attr)
      },

      generateFiltersParams() {
        return Object.keys(this.currentForm).reduce((result, e) => {
          let field = this.currentForm[e]['field']

          if (field) {
            if (Array.isArray(this.currentForm[e]['field'])) {
              result[e] = field.map(e => {
                if (e['value']) {
                  return e['value']
                } else {
                  return e
                }
              })
            } else if (typeof field === 'object') {
              result[e] = field['value']
            } else {
              result[e] = field
            }
          }
          return result;
        }, {})
      },

      loadReport(attr, params={}) {
        if (!this.report_id_waiting) {
          attr['report_params'] = JSON.stringify(attr['report_params'])
          params['report'] = attr

          return this.$backend.create(this.report_path, null, params)
          .then(({ data }) => {
            this.$root.$emit('init-report-intervals', data)
            this.$q.notify(this.notifies.report_in_process)
          })
          .catch((error) => {
            console.log(error);
            if (error.response) { this.reLogin(error.response.status) }
            this.loading = false
            this.$emit('close-export', {})
            this.$q.notify(this.notifies.error_ask_admin)
          })
          .finally(() => {
            this.loading = false
            this.$emit('close-export', {})
          })

        } else {
          this.loading = false
          this.$emit('close-export', {})
          this.$q.notify(this.notifies.wait_report_generated)
        }
      },

      loadFormData(params={}) {
        let path = this.report_namespace && `${this.report_namespace}_${this.report_class}` || this.report_class

        if (this.export_type) {
          params['export_type'] = this.export_type
        }

        this.$backend.index(`${this.$store.state.paths[path]}/export_excel_form_data`, { params: params })
        .then(({ data }) => {
          this.data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          setTimeout(() => {
            this.data_has_loaded = true
            this.fieldsLoaded()
          },300)
        })
      }
    },

    created() {
      this.resetForm()
      this.loadFormData()
    }
  }
</script>

<style lang='scss'>

  @import "../../../assets/styles/forms/modal-form";
  @import "../../../assets/styles/forms/fields/checkbox";

</style>
