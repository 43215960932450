<template lang='pug'>

  div
    div(class="show-percentage")
      span(class="show-percentage-label") {{data.label}}
      span(class="show-percentage-value") {{row.percentage_completion + '%'}}
    progress-bar(size="medium" bar-color="#5B7AFF" :val="row.percentage_completion")

</template>

<script>
  import ProgressBar from 'vue-simple-progress'

  export default {
    data: function () {
      return {
        // row: this.parentData.row
      }
    },

    props: {
      parentData: Object
    },

    components: {
      ProgressBar
    },

    computed: {
      row() {
        return this.parentData.row
      },

      data() {
        return this.parentData.data
      }
    },

    methods: {
      // refreshRow(row) {
      //   this.row = row
      // }
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/show/percentage";

</style>