<template lang='pug'>

  q-card(id="form" class="modal-form-card")

      q-card-section
        form-header(ref="form-header" @close-form="closeForm"
                    :parentData="{grid: grid, title: header_title, title_icon: 'project_filter.svg'}")

      div(v-if="loading" class="spinner-container modal-spinner")
        q-spinner(color="primary" size="3em")

      div(v-if="dataHasLoaded")
        q-card-section(v-show="!loading")
          q-form
            div(class="row" v-for="(row, i) in data.scheme" :ref="row[0]['name']")
              div(v-for="filter in row" :key="filter.name" :class="'col-' + filter.col" style="padding: 5px;")

                component(v-show="getFilterDataByName(filter)"
                          :ref="filter.name"
                          :is="getFilterComponent(getFilterDataByName(filter))"
                          @filters-loaded="filtersLoaded"
                          @load-options="loadOptions"
                          :parentData="{grid: grid, data: [getFilterDataByName(filter)], options_data: options_data}")

        q-card-actions(v-show="!loading" class="button-group row justify-center")
          q-btn(flat no-caps
                class="modal-form-cancel"
                :label="buttons['cancel']"
                @click="closeAndResetForm()")
          q-btn(flat no-caps
                class="modal-form-submit"
                :label="buttons['submit']"
                @click="onSubmit()")

</template>

<script>
  import formHeader from '../../../../shared/forms/formHeader'
  import filters from '../../../../shared/filters'

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        item: this.parentData.item,
        loading_filters: [],
        dataHasLoaded: false,
        loading: true
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      filters
    },

    computed: {
      data() {
        return this.parentData.data.filters_data
      },

      options_data() {
        return this.parentData.options_data
      },

      header_title() {
        return this.parentData.header_title
      },

      buttons() {
        return this.parentData.data.header.filters_form.form_buttons
      }
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    methods: {

      setOptionsData(data, filter_name) {
        this.$refs[filter_name][0].setOptionsData(data);
      },

      setLoading(val, filter_name) {
        this.$refs[filter_name][0].setLoading(val);
      },

      loadOptions(attr) {
        this.$emit('load-options', attr)
      },

      setDataHasLoaded(val) {
        this.dataHasLoaded = val
      },

      closeForm() {
        this.$emit('close-form', {})
      },

      closeAndResetForm() {
        this.$emit('filters-form-reset')
        this.$root.$emit(`reload-${this.item}-data`)
        this.$emit('close-form', {})
      },

      getFilterComponent(key) {
        if (key) {
          return filters[key.type]
        }
      },

      getFilterDataByName(filter) {
        return this.data.filters.find(f => f.name === filter.name)
      },

      filtersLoaded(filter) {
        if (this.loading_filters.length === 0) {
          this.loading_filters = this.data.filters.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        this.loading_filters = this.loading_filters.filter(f => f.sort().toString() !== filter.sort().toString())

        if (this.loading_filters.length === 0) {
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      },

      onSubmit() {
        this.$root.$emit(`reload-${this.item}-data`)
        this.$emit('close-form', {})
      }
    },

    created() {
      this.$emit('load-form-data')
    }
  }
</script>

<style lang='scss'>

  @import "../../../../../assets/styles/forms/modal-form";

</style>