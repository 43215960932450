<template lang='pug'>
    div(class="index-page")
        div(class="middle-block")
            div(class="covid-headquarters-card")
                div(class="covid-headquarters-card-label")
                    span Сообщение штаба
                div(class="covid-headquarters-text")
                    div(v-for="(text, index) in messages_array" :key="index")
                        p(v-text="text")

        div(class="covid-graphics-container")
            div(class="covid-graphics-card left")
                div(class="covid-card-label")
                    span Общая заболеваемость
                ChartContainer(style="padding: 10px"
                    :chartObj="charts.illness(illness_statistic.sum)"
                    :sizeProps="{width: '100%', height: '250px'}")
            div(class="covid-graphics-card right")
                div(class="covid-card-label")
                    span Общее количество тестов
                ChartContainer(style="padding: 10px"
                    :chartObj="charts.tests(this.illness_statistic.sum, 'Общее количество тестов')"
                    :sizeProps="{width: '100%', height: '250px'}")

        div(class="middle-block")
            div(class="covid-table-card")
                div(class="covid-card-label")
                    v-row
                        v-col(md="2")
                            span Сводная таблица
                        v-col(md="8")
                            v-row(justify="center")
                                div(class="illness-status")
                                    span Показатели самочувствия:
                                    div(class="illness-status-circle well-circle")
                                    span Удовлетворительное
                                    div(class="illness-status-circle ill-circle")
                                    span Неудовлетворительное
                                    div(class="illness-status-circle critical-ill-circle")
                                    span Кризисное
                        v-col(md="2")
                v-data-table(
                    :headers="headers"
                    :items="statistic"
                    item-key="district"
                    :options="options"
                    :expanded="expanded"
                    show-expand
                    hide-default-footer
                    class="elevation-1")
                    template(v-slot:expanded-item="{ headers, item }")
                        td(:colspan="headers.length")
                            tr(v-for="exp in filteredEmployees(item.district)" :key="exp.employee")
                                td {{exp.employee}}
                                td
                                    v-img(:src="diseaseSVG(exp)")
                                td(:class="exp.status")
                                    v-img(max-width="10px" :src="thermometrSVG()")
                                td(:class="exp.status") {{exp.temperature}}
                                td(:class="exp.status" v-if="exp.comment !==''") {{exp.comment}}


</template>

<script>
    import ChartContainer from './ChartContainer'
    import {charts} from "./charts";
    import {
        VApp,
        VContainer,
        VLayout,
        VFlex,
        VCard,
        VCardText,
        VCardTitle,
        VBtn,
        VCardActions,
        VItem,
        VList,
        VTooltip,
        VCol,
        VRow,
        VSpacer,
        VListItemActionText,
        VListItemContent,
        VListItemTitle,
        VListItem,
        VListItemIcon,
        VListItemSubtitle,
        VCardSubtitle,
        VImg,
        VIcon,
        VCalendarMonthly,
        VDatePicker,
        VDataTable,
        VDialog
    } from 'vuetify/lib'

    export default {
        name: "IllnessStatistic",
        components: {
            ChartContainer,
            VDataTable,
            VApp,
            VContainer,
            VLayout,
            VFlex,
            VCard,
            VCardText,
            VCardTitle,
            VBtn,
            VCardActions,
            VItem,
            VList,
            VCol,
            VRow,
            VTooltip,
            VListItemActionText,
            VListItemContent,
            VListItemTitle,
            VListItem,
            VListItemIcon,
            VListItemSubtitle,
            VCardSubtitle,
            VImg,
            VCalendarMonthly,
            VDatePicker,
            VIcon,
            VSpacer,
            VDialog
        },
        props: ['illness_statistic'],
        data() {
            return {
                charts,
                expanded: [],
                covid_statistic: false,
                statistic: this.illness_statistic.total,
                employees: this.illness_statistic.employees,
                headers: [
                    {text: 'Округ', value: 'district', sortable: false},
                    {text: 'Тесты', value: 'tests', sortable: false},
                    {text: 'Covid-19', value: 'covid', sortable: false},
                    {text: 'ОРВИ', value: 'vri', sortable: false},
                    {text: 'Пневмония', value: 'pneumonia', sortable: false}
                ],
                options: {
                    itemsPerPage: 12
                }
            }
        },
        computed: {
            messages_array() {
                return this.illness_statistic.message.split('\n')
            }
        },
        methods: {
            thermometrSVG() {
               return  require('../../../assets/icons/covid/thermometr-' + this.$store.state.theme.value + '.svg')
            },
            diseaseSVG(exp) {
                return  require('../../../assets/icons/covid/' + exp.disease + '-' + this.$store.state.theme.value + '.svg')
            },
            filteredEmployees(district) {
                return this.employees.filter(val => val.district === district)
            },
            getStatus(status) {
                return status
            }
        }
    }
</script>

<style scoped>

</style>