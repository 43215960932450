<template lang='pug'>
  div
    div(v-if="column.type === 'image'" @click.self="openShow()")
      img(v-if="row[column.name]" :src="row[column.name]['url']" class="grid-image")

    div(v-else-if="column.type === 'files_collection'")
      inline-svg(v-if="row[column.name].length > 0" :src="require('../../../assets/icons/grid/clip.svg')" @click="openFilesModal()" class="files-collection-icon")

    div(v-else-if="column.type === 'icon'")
        q-icon(:name="row.icon" class="td-icon")

    div(v-else @click.self="openShow()")
      inline-svg(v-if="column.with_icon" class="icon-before-td-row" :src="require(`../../../assets/icons/grid/${column.with_icon.icon}`)")
      span(:class="[addColumnClass()]")
        |{{ handleRow() }}

        div(:id="`td-title-${row.id}-${column.name}`" class="td-title")
          div(class="title-msg")
          div(class="title-arrow")

</template>


<script>

  export default {
    data: function () {
      return {
        grid_key: this.parentData.grid_key
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      column() {
        return this.parentData.column
      },

      row() {
        return this.parentData.row
      }

    },

    components: {
    },

    methods: {

      openShow() {
        this.$emit('open-show', this.row)
      },

      openFilesModal() {
        this.$emit('open-files-modal', this.row[this.column.name])
      },

      addColumnClass() {
        let column_class = ''
        if (this.column.type === 'issue_state') {
          column_class = `issue-state ${ this.row['state'] }-badge`
        }
        return column_class
      },

      handleRow() {
        let result = this.row[this.column.name]
        if (this.column.type === 'boolean') {
          result = this.row[this.column.name] ? 'Да' : 'Нет'
        } else if (this.column.type === 'description') {

          result = this.handleDescriptionTd(this.row[this.column.name])

          if (result && result.length > 30) {
            this.addTitleEvent(result)
            result = result.substring(0, 30) + '...'
          }
        } else if (['datetime', 'date'].includes(this.column.type)) {
          result = this.handleDateRow(this.row, this.column.name, this.column.format)
        }

        if (result && result.length > 30) {
          this.addTitleEvent(result)
          result = result.substring(0, 30) + '...'
        }

        return result
      },

      addTitleEvent(title) {
        this.$nextTick(() => {
          let td_el = document.getElementById(`td-${this.row.id}-${this.column.name}`)
          let title_el = document.getElementById(`td-title-${this.row.id}-${this.column.name}`)
          let grid_el = document.getElementById(`grid-${this.grid_key.toString()}`)

          td_el.addEventListener('mouseover', () => { this.showTitle(td_el, title_el, title) })
          td_el.addEventListener('mouseleave', () => { this.hideTitle(title_el) })

          if (grid_el) {
            grid_el.addEventListener('scroll', () => { this.hideTitle(title_el) })
          }
        })
      },

      showTitle(td_el, title_el, title) {
        let msg = title_el.querySelector('.title-msg')

        if (msg.children.length === 0) {
          let span = document.createElement("span")
          span.textContent = title
          msg.appendChild(span);
        }

        title_el.style.visibility = 'visible'
        title_el.style.opacity = '1'
        title_el.style.top = (td_el.getBoundingClientRect().top - title_el.clientHeight) + 'px'
        title_el.style.left = (td_el.getBoundingClientRect().left + 20) + 'px'
      },

      hideTitle(title_el) {
        title_el.style.opacity = '0'
        title_el.style.visibility = 'hidden'
        let msg = title_el.querySelector('.title-msg')
        while (msg.hasChildNodes()) {
          msg.removeChild(msg.firstChild);
        }
      }
    },

    created() {
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>

  .icon-before-td-row {
    margin-right: 10px;
    vertical-align: middle;

    path {
      fill: var(--theme-icon-fill);
    }
  }

</style>