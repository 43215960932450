import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
let index_page_prompts = {
  actions: ['dropdown'],
  buttons: ['add', 'export']
}

export const ui_prompts = {

  state: {
    dashboards: {
      navbar: ['issues', 'settings'],
      filters: ['projects']
    },
    issues: {
      actions: ['issues'],
      grid: ['checkboxes'],
      tabs: ['issues'],
      buttons: ['add'],
    },
    add_issue: {
      forms: ['new']
    },
    add_service: {
      forms: ['new']
    },
    edit_user: {
      forms: ['edit']
    },
    ppr_calendar: {
      filters: ['all']
    },
    ppr_group_systems: Object.assign(index_page_prompts),
    ppr_systems: Object.assign(index_page_prompts),
    ppr_works: Object.assign(index_page_prompts),
    ppr_equipments: Object.assign(index_page_prompts),
    facilities: Object.assign(index_page_prompts),
    companies: Object.assign(index_page_prompts),
    buildings: Object.assign(index_page_prompts),
    floors: Object.assign(index_page_prompts),
    room_types: Object.assign(index_page_prompts),
    rooms: Object.assign(index_page_prompts),
    services: Object.assign(index_page_prompts),
    work_categories: Object.assign(index_page_prompts),
    users: Object.assign(index_page_prompts, {actions: ['dropdown'], buttons: ['add', 'export', 'import']}),

    show_ppr_equipment: {
      forms: ['show']
    },
  }
}