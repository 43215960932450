class TooltipEntityView {
  constructor(entity, locales) {
    this.entity = entity;
    this.map_locales = locales
  }

  toHtmlCode() {
    const labelValuePairs = entityLabelValuePairs(this.entity, this.map_locales);
    const labelHtml = s => `<div class='map-tooltip__label'>${s}</div>`;
    const valueHtml = s => `<div class='map-tooltip__value'>${s}</div>`;

    return labelValuePairs.map(([l, v]) => labelHtml(l) + valueHtml(v)).join('');
  }
}

function entityLabelValuePairs(entity, map_locales) {
  switch(entity.getType()) {
    case 'worker': return workerLabelValuePairs(entity, map_locales);
    case 'building': return buildingLabelValuePairs(entity, map_locales);
    case 'vehicle': return vehicleLabelValuePairs(entity, map_locales);
    default:
      console.error(`TooltipEntityData: not implemented for type ${entity.getType()}`);
      return [];
  }
}

function buildingLabelValuePairs(building, map_locales) {
  return [
    [map_locales['building_title'], building.getTitle()],
    [map_locales['building_address'], building.getAddress()],
    [map_locales['building_active_issues'], building.getActiveIssuesNumber()],
  ];
}

function workerLabelValuePairs(worker, map_locales) {
  return [
    [map_locales['full_name'], worker.getFullName()],
    [map_locales['phone'], worker.getPhone()],
    [map_locales['email'], worker.getEmail()],
  ];
}

function vehicleLabelValuePairs(vehicle, map_locales) {
  return [
    [map_locales['vehicle_model'], vehicle.getModel()],
    [map_locales['vehicle_number'], vehicle.getPlateNumber()],
  ];
}

export default TooltipEntityView;
