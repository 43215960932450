import Vue from 'vue';
import showComponents from '../../components/shared/show_card_components/index'

Vue.mixin({

  computed: {
  },

  components: {
    showComponents
  },

  methods: {

    getShowComponent(key) {
      return showComponents[key]
    }
  }
})