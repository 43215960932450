<template lang='pug'>

  div(class="col-12 col-lg-6")

    div(class="form-field-label")
      span {{field.title}}
      span(v-show="field.required" class="form-field-require-tag" style="color:red;" :title="field.title")
        |*

    div(class="form-field form-date" :id="field.id || field.uuid")
      datetime(:ref="'elem'+(field.id || field.uuid)" type="datetime"
                v-model="value"
                :phrases="phrases"
                value-zone="Europe/Moscow"
                :disabled="readonly"
                @input="editField"
                :input-id="field.id ? field.id.toString() : field.uuid.toString()")

        template(slot="before")
          inline-svg(class="datetime-icon" :src="require(`../../../assets/icons/calendar.svg`)")

      q-icon(name="cancel" v-if="value && !readonly" @click.stop="resetField()" class="cancel-datetime-field")



</template>

<script>
  import { Datetime } from 'vue-datetime';

  export default {
    data() {
        return {
            value: this.$props.initialValue[0].value,
            phrases: {},
            name: this.field.id || this.field.uuid

        }
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean || undefined,
            required: false,
            default: false
        },
        initialValue: {
            type: Array,
            required: false,
            default: () => ([{value: ''}])
        }
    },
    components: {
        Datetime
    },
    methods: {
        resetField() {
            this.value = ''
            this.$emit('reset-field', {id: this.field.id})
        },
        editField(val) {
            if (!this.readonly) this.$emit('edit-field', {id: this.field.id, value: [{value: val, title: ''}]})
        },
    },
    created() {
        if (this.readonly) {
            
            this.value = this.field._data.value && this.field._data.value.length > 0 ? this.field._data.value[0].value : ''
        }
    },


    beforeMount() {
      this.setDateFieldLocale()
      this.setDateFieldPhrases()
    },
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/date";

  .form-date {
      position: relative;
  }

</style>