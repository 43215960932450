<template lang='pug'>

  div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
    div(class="text-h5 rating-label")
      |{{label}}
    div(class="rating-field")
      q-rating(v-model="currentField"
                size="3em"
                color="#f09b12"
                icon="star_border"
                icon-selected="star")

    span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}

</template>

<script>
  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        depend_from: this.parentData.data[0].depend_from || {},
        required: this.parentData.data[0].require,
        valid_error_message: null,
        main_class: 'form-rating'
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            return form_field['field']
          } else {
            return 0
          }
        },
        set(value) {
          let result = {}
          if (value) {
            result['field'] = value
            result['invalid'] = this.invalid(value)
          } else {
            let val = 0
            result['field'] = val
            result['invalid'] = this.invalid(val)
          }
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {grid_name: this.grid, field: 'invalid_fields', value: this.name})
          }
        }
      }
    },

    methods: {

      setField(val) {
        this.currentField = val
      },

      resetField() {
        this.currentField = ''
      },

      invalid(val=undefined) {
        if (this.required) {
          if (val && val === 0) {
            this.valid_error_message = null
            return false
          } else {
            this.valid_error_message = 'Необходимо проставить рейтинг'
            return true
          }
        } else {
          this.valid_error_message = null
          return false
        }
      }
    },

    created() {
    },

    beforeMount() {
      this.$store.commit('createFormField', {grid_name: this.grid, field: this.name})
      this.setField(this.value)
    }
  }
</script>

<style lang="scss">

  .form-rating {
    .rating-label {
      margin-top: 10px;
      margin-left: 10px;
    }

    .rating-field {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

</style>