<template lang='pug'>

  div(class="chart-wrapper")
    highcharts(ref="high-chart"
                :options="options({data: data, theme: getTheme(), options: chart_options})")

    div(v-if="categories && !chart.scrollable" class="chart-paging")
      inline-svg(class="chart-paging-icon left noselect" @click="chartNavigation('back')" :src="require(`../../../../assets/icons/highcharts/arrow-left.svg`)")
      inline-svg(class="chart-paging-icon right noselect" @click="chartNavigation('next')" :src="require(`../../../../assets/icons/highcharts/arrow-right.svg`)")
    div(v-if="chart.zoom" class="chart-zoom")
      div(class="zoom-wrapper" v-for="zoom in zooms")
        inline-svg(:class="`chart-paging-icon ${zoom}`" @click="chartNavigation(zoom)" :src="require(`../../../../assets/icons/highcharts/${zoom}.svg`)")


</template>

<script>
  import { options } from './highchart/options'
  import themes from './themes'

  export default {
    data: function() {
      return {
        grab_position: '',
        zooms: ['minus', 'plus', 'reset'],
        options
      }
    },

    computed: {
      data() {
        return {
          series: this.series,
          categories: this.categories
        }
      },

      step() {
        return this.step_width
      },

      chart_options() {
        return {
          chart: this.chart,
          begin_extr: this.begin_extr,
          xaxis: this.xaxis,
          yaxis: this.yaxis,
          legend: this.legend,
          tooltip: this.tooltip,
          plot_options: this.plot_options,
          chart_events: this.chart_events,
          type: this.chart_type && this.chart_type.type || ''
        }
      }
    },

    watch: {
      begin_extr(newVal, oldVal) {
        let chart = this.$refs['high-chart'].chart
        // console.log(newVal.start, newVal.end)
        chart.xAxis[0].setExtremes(newVal.start, newVal.end)
      }
    },

    props: {
      grid: String,
      grid_key: String,
      categories: Array,
      series: Array,
      chart: Object,
      step_width: Number,
      begin_extr: Object,
      xaxis: Object,
      yaxis: Object,
      legend: Object,
      tooltip: Object,
      plot_options: Object,
      chart_type: Object
    },

    components: {
      options,
      themes
    },

    methods: {
      chartNavigation(event, grab=false) {
        let chart = this.$refs['high-chart'].chart

        if (chart) {
          this.$emit('set-chart-area', {
            chart: chart,
            event: event,
            grab: grab
          })
        }
      },

      getTheme() {
        return themes[this.theme]
      }
    },

    created() {
    },

    mounted() {
      let chart = this.$refs['high-chart'].chart

      if (this.chart.grab) {
        chart.container.addEventListener("mousedown", (e) => {
          this.grab_position = e.x
          chart.container.style.cursor = 'grab'
        })

        chart.container.addEventListener("mouseup", (e) => {
          if (this.grab_position < e.x) {
            this.chartNavigation('back', true)
          } else if (this.grab_position > e.x) {
            this.chartNavigation('next', true)
          }
          this.grab_position = ''
          // chart.container.style.cursor = 'initial'
        })
      }
    },

    beforeDestroy() {
    }
  }
</script>

<style lang='scss'>

  .highcharts-scrolling {
    margin-left: 10px;
    margin-right: 10px;
  }

  .highcharts-credits {
    display: none;
  }

  .chart-paging {
    position: relative;
  }

  .chart-paging-icon {
    cursor: pointer;
    position: absolute;
    height: 15px;
    width: 15px;
    margin: initial !important;

    path {
      fill: #d3d3d3 !important;
    }

    g:nth-child(1) > g > path {
      fill: #d3d3d3 !important;
    }
  }

  .chart-paging-icon.left {
    left: 20px;
    margin-top: 5px;
  }
  .chart-paging-icon.right {
    right: 20px;
    margin-top: 5px;
  }
  .chart-paging-icon:hover {
    path {
      fill: #5B7AFF !important;
    }
    g:nth-child(1) > g > path {
      fill: #5B7AFF !important;
    }
  }

  .tooltip-header {
    color: #fff !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }

  .tooltip-point-name {
    /*color: #fff !important;*/
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;

    padding: 5px;
    white-space: normal;
    width: 80%
  }

  .tooltip-point-value {
    color: #fff !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    padding: 5px;
    width: 20%
  }

  .tooltip-chart {
    display: flex;
    width: 100%;
    height: fit-content;
  }

  .chart-zoom {
    /*position: relative;*/
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: center;


    .zoom-wrapper {
      position: relative;
      width: 15px;
      height: 15px;
      margin: 5px;
    }

    .minus {
      position: absolute;
      width: 15px;
      height: 15px;
      left: 0;
    }

    .plus {
      position: absolute;
      width: inherit;
      height: inherit;
      left: 0;
    }

    .reset {
      position: absolute;
      width: inherit;
      height: inherit;
      left: 0;
    }
  }


</style>
