function addStyle (win, css) {
  let head = win.document.head || win.document.getElementsByTagName('head')[0],
      style = document.createElement('style');

  head.appendChild(style);

  style.type = 'text/css';

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(win.document.createTextNode(css));
  }
}

const VuePrintPlugin = {
  install (Vue, options = {}) {
    Vue.prototype.$PrintPlugin = (el, css, title) => {
      let name = '_blank',
        specs = ['titlebar=yes', 'fullscreen=yes', 'scrollbars=yes'].join(','),
          replace = true

      // specs = specs.length > 0 ? specs.join(',') : '';

      const element = window.document.getElementById(el);

      if (!element) {
        console.log(`Не найдено элемента с id #${el}!`);
        return
      }

      const url = '';
      const print_window = window.open(url, name, specs, replace);

      print_window.document.write(`
        <html>
          <head>
            <title>${title || window.document.title}</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);

      if (css) {
        addStyle(print_window, css);
      }

      setTimeout(() => {
        print_window.document.close();
        print_window.focus();
        print_window.print();
        // print_window.close();
      }, 500);

      return true;
    };
  }
}

export default VuePrintPlugin;