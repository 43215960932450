<template lang='pug'>

  div
    div(v-if="data" id="edit-locale" class="edit-locale")
      div(class="edit-locale-btn" @click="showLocalesMenu()")
        span(class="noselect") {{locale_label}}
        div
          inline-svg(class="arrow-down-locale-icon" :src="require(`../../../assets/icons/dropdown.svg`)")

      div(class="edit-locale-menu")
        div(class="edit-locale-link" v-for="locale in locales_collection")
          span(@click="updateLocale(locale.value)") {{locale.label}}

</template>

<script>

  export default {
    data: function () {
      return {
        path: this.$store.state.paths['user']
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      data() {
        if (this.locales['locales_data']) {
          return this.locales['locales_data'][this.current_locale]
        } else {
          return undefined
        }
      },

      locales_data_arr() {
        return Object.keys(this.data).map(key => key.split('.'))
      },

      locale_label() {
        let key = this.locales_data_arr.find(el => el[0] === this.current_locale).join('.')
        return this.data[key]
      },

      locales_collection() {
        let result = this.locales_data_arr.reduce((r, el) => {

          r[el[0]] = this.locales_data_arr.filter(l => l[0] === el[0]).reduce((res, ell) => {
            res[ell[1]] = this.data[ell.join('.')]
            return res
          }, {})

          return r
        }, {})

        return Object.keys(result).filter(key => key !== this.current_locale).map(key => result[key])
      }
    },

    methods: {

      showLocalesMenu() {
        let main = document.getElementById('main')
        let el = document.getElementById('edit-locale')
        let btn = el.querySelector('.edit-locale-btn')
        let link = el.querySelector('.edit-locale-link')
        let menu = el.querySelector('.edit-locale-menu')

        if (main) {
          main.addEventListener("scroll", () => {
            this.hideLocalesMenu()
          })
        }

        document.addEventListener('click', (e) => {
          if (!btn.contains(e.target) && !link.contains(e.target) && menu.style.display === 'block') {
            this.hideLocalesMenu()
          }
        })

        menu.style.display = 'block'
        menu.style.top = (btn.getBoundingClientRect().bottom + 5) + 'px'
      },

      hideLocalesMenu() {
        let el = document.getElementById('edit-locale')
        let menu = el.querySelector('.edit-locale-menu')
        menu.style.display = 'none'
      },

      updateLocale(value, params={user:{}}) {
        params['user']['locale'] = value

        this.$backend.update(this.path, this.current_user.id, params)
        .then(({data}) => {
          // console.log(data)
          this.hideLocalesMenu()
          this.$store.commit('updateCurrentUser', data)
          this.$emit('reload-data')
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }

          let base_errors = error.response.data.errors.base
          if (base_errors) {
            base_errors.forEach(e => {
              this.$q.notify(e.message)
            })
          } else {
            this.$q.notify(this.notifies.error_ask_admin)
          }

          console.log(error)
        })
      }
    }
  }
</script>

<style lang='scss'>
</style>