<template lang='pug'>

  div(class="show-tabs")
    q-tabs(v-model="tab.name" align="left")
      q-tab(v-for="tab in data"
            @click="addCurrentTab(tab)"
            :key="tab.name"
            :name="tab.name"
            :label-slot="false"
            :disable="data.length <= 1"
            :style="data.length <= 1 ? {'border-bottom': 'initial'} : {}")

        template(v-if="tab.icon" slot="default")
          inline-svg(class="show-tab-icon" :src="require(`../../../assets/icons/show/tabs/${tab.icon}`)")

        template(v-if="tab.label" slot="default")
          span() {{tab.label}}

    //grid-hidden-msg(v-show="hide_table" ref="grid-hidden-msg" :parentData="{hide_table: hide_table}")

    //q-tab-panels(:class="[hide_table ? 'show-tabs-panels' + ' hide-table' : 'show-tabs-panels']" v-model="tab.name")
    q-tab-panels(class="show-tabs-panels" v-model="tab.name")
      q-tab-panel(v-for="tab in data" :key="tab.name" :name="tab.name")
        div(class="grid")
          grid(v-if="tab.type === 'table'" :ref="'grid-' + tab.name" @get-collection="getCollection" :parentData="{grid: tab.name, grid_key: grid_key, default_class: 'simple-table'}")
        div(v-if="tab.type === ''" style="height: 100px")

</template>

<script>
  import gridHiddenMsg from '../grid/gridHiddenMsg'

  export default {
    data: function () {
      return {
        grid: '',
        grid_key: 'show_grid_data',
        tab: '',
        // hide_table: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      Grid: () => import('../../shared/grid/Grid'),
      gridHiddenMsg
    },

    computed: {
        data() {
            return this.parentData.data;
        }
    },

    methods: {

      // hideTable(val) {
      //   this.hide_table = val
      //   this.$refs['grid-hidden-msg'].hideTable(val)
      // },

      refreshGrid(grid_name) {
        let grid = this.$store.state.grid[grid_name][this.grid_key]
        if (this.$refs['grid-' + grid_name][0]) {
          this.$refs['grid-' + grid_name][0].onRequest({ pagination: grid.pagination })
        }
      },

      addCurrentTab(tab) {
        // this.hide_table = false
        this.grid = tab.name
        this.tab = tab
        this.initialTabGrid()
      },

      getCollection(params) {
        params['filters'] = Object.assign(params['filters'], this.tab.conditions)

        this.gridCollection(this.$store.state.paths[this.tab.path], this.tab.name, this.grid_key, params)
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
          this.$refs['grid-' + this.tab.name][0].setError(false);
        })
        .finally(() => {
          this.$refs['grid-' + this.tab.name][0].setLoading(false);
        })
      },

      initialTabGrid(attr={}) {
        if (this.tab.type === 'table' && !this.$store.state.grid[this.tab.name]) {
          this.$store.commit('initialGridsState', {grid: this.tab.name, attr: attr})
        }
      }
    },

    created() {
      if (this.data && this.data.length > 0) {
        this.tab = this.data[0]
      }
      this.initialTabGrid()
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/show/tabs";

</style>