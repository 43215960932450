<template lang='pug'>

  div
    div(class="form-field-label")
      span {{label}}
      span(v-show="required" class="form-field-require-tag" style="color:red;" :title="notifies.required_field")
        |*

    div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
      q-input(filled no-error-icon
              class="float-field"
              v-model="currentField"
              @input="val => onInput(val)"
              type="text"
              :min="min_val"
              :max="max_val"
              :step="step_val"
              :label-slot="false"
              :readonly="readonly"
              :for="name")
        q-icon(name="cancel" v-if="currentField && !readonly" @click.stop="resetField()" class="cancel-string-field")

    span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}

</template>

<script>
  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        depend_from: this.parentData.data[0].depend_from || {},
        required: this.parentData.data[0].require,
        watch: this.parentData.data[0].watch || {},
        valid_error_message: null,
        main_class: 'form-field form-input',

        min_val: this.parentData.data[0].min_val || 0,
        max_val: this.parentData.data[0].max_val || null,
        step_val: this.parentData.data[0].step_val || null,

        readonly: this.parentData.data[0].readonly || false
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            return form_field['field']
          } else {
            return undefined
          }
        },
        set(value) {
          let result = {}
          if (!['', undefined, null].includes(value)) {
            result['field'] = value
            result['invalid'] = this.invalid(value)
          } else {
            result['invalid'] = this.invalid()
          }
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {grid_name: this.grid, field: 'invalid_fields', value: this.name})
          }
        }
      },

      fieldDependency() {
        return this.checkDependency()
      }
    },

    watch: {
      fieldDependency(newVal, oldVal) {},
    },

    methods: {

      setField(val) {
        this.currentField = val ? val.toString().replace(/[,]/g, ".") : val
      },

      onInput(val) {
        val = val.replace(/[,]/g, ".")
        let match = /(\d+[,.]\d+)|(\d+[,.])|(\d)+/.exec(val)
        this.$nextTick(() => {
          this.currentField = match && match.length ? match[0] : ''
        })
        
      },

      resetField() {
        this.currentField = ''
      },

      invalid(val=undefined) {
        let result

        if (!['', undefined, null].includes(val)) {
            this.valid_error_message = null
            result = false
          }

        if (this.required) {
          if (!['', undefined, null].includes(val)) {
            this.valid_error_message = null
            result = false
          } else {
            this.valid_error_message = this.notifies.not_empty
            result = true
          }

        // } else if (this.max_val && this.max_val < val) {
        //   this.valid_error_message = this.notifies.value_greater_than_allowed
        //   return true

        } else {
          this.valid_error_message = null
          result = false
        }

        if (this.watch && this.watch['conditions'] && this.watch['conditions'].length > 0) {
          this.watch['conditions'].forEach(c => {
            if (!['', undefined, null].includes(val)) {
              result = this.checkFloatCondition(c, val, result)
            }
          })
        }

        return result
      }
    },

    created() {
      this.watchSimpleFieldParents()
    },

    beforeMount() {
      this.$store.commit('createFormField', {grid_name: this.grid, field: this.name})
      this.setField(this.value)
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/string";
  @import "../../../../assets/styles/forms/fields/float";

</style>