<template lang='pug'>

  div(class="chart-wrapper")
    //span {{data.title}}
    apexchart(v-if="data && chart_height" ref="apex-chart" type="area"  :height="chart_height" :options="apex.options(data, locale, getTheme())" :series="apex.series(data, label)")


</template>

<script>
  import { apex } from './apex/index'
  import themes from './themes'

  export default {
    data: function() {
      return {
        apex,
        windowWidth: '',
        chart_height: null
      }
    },

    computed: {
      data() {
        return this.parentData.chart_data.data;
      },

      locale() {
        return this.parentData.chart_data.locale;
      },

      label() {
        return this.parentData.chart_data.label
      }
    },

    watch: {
      windowWidth(newWidth, oldWidth) {

        let height = newWidth < 568 ? 160 : 260

        if (this.chart_height !== height) {
          setTimeout(() => {
            this.chart_height = height
          },200)
        }
      }
    },

    props: {
      parentData: Object
    },

    components: {
      apex,
      themes
    },

    methods: {

      getTheme() {
        return themes[this.theme]
      }
    },

    created() {
      this.$nextTick(() => {
        this.addWindowWidth()

        window.addEventListener('resize', () => {
          this.addWindowWidth()
        });

        let past =  new Date().setDate(new Date().getDate() - 25);
        let future =  new Date().setDate(new Date().getDate());
        if (this.$refs['apex-chart']) {
          this.$refs['apex-chart'].zoomX(past, future)
        }
      })
    },

    beforeDestroy() {
      window.removeEventListener("resize", () => {
        this.addWindowWidth()
      });
    }
  }
</script>

<style>

  @import '../../../../assets/styles/dashboard/charts/apex_chart.scss';

</style>
