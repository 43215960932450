<template lang='pug'>
  div(class="index-page measuring-statistic dashboard")

    //div(class="trouble-shooting-container")
      div(class="trouble-shooting-message" style="padding-left: 10px; padding-right: 10px;")
        span {{trouble_shooting_message}}

    div(v-if="!loading && loading_data" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")


    div(v-if="!loading")
      common-header(v-show="!loading_data"
                  @reload-data="reloadData"
                  :parentData="{data: dashboard_data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: header_actions}")

    div(v-if="!loading" class="meter-type-charts-carousel-block")
      div(v-show="!loading_data")
        meter-type-charts-carousel(ref="meter-type-charts-carousel" :parentData="{grid: grid, grid_key: grid_key, meter_types_by_months: dashboard_data.boards.meter_types_statistic}")

    div(v-if="!loading" class="middle-block")
      div(v-if="!loading_data" class="middle-left-block")
        div(class="dashboard-card meter-readings-chart-card")
          div(class="dashboard-card-label noselect")
            span {{dashboard_data.boards.common_spending_statistic.title}}
          meter-readings-chart(ref="meter-readings-chart" :parentData="{grid: grid, grid_key: grid_key, data: dashboard_data.boards.common_spending_statistic}")

      div(v-show="!loading_data" class="middle-right-block")
        div(class="dashboard-card measuring-soon-readings")
          div(class="dashboard-card-label noselect")
            span {{dashboard_data.boards.soon_verifyings.title}}
          div(class="grid")
            grid(ref="measuring_soon_verifyings" @get-collection="getCollection" :parentData="{grid: 'measuring_soon_verifyings', grid_key: grid_key, path: this.$store.state.paths['measuring_meter'], report_class: 'meter', report_namespace: 'measuring', transition_key: 'measuring_meter', sort_by: 'verifying_date', descending: false, reload_after_update: true}")


    div(v-if="!loading" class="footer-block")
      div(v-show="!loading_data" class="dashboard-card measuring-last-readings")
        div(class="dashboard-card-label noselect")
          span {{dashboard_data.boards.last_readings.title}}
        div(class="grid")
          grid(ref="measuring_last_readings" @get-collection="getCollection" :parentData="{grid: 'measuring_last_readings', grid_key: grid_key, sort_by: 'created_at'}")

</template>

<script>
  import commonHeader from '../../../shared/helpers/Header'
  import MeterReadingsChart from './meter_readings_statistic/MeterReadingsChart'
  import MeterTypeChartsCarousel from './meter_type_statistic/MeterTypeChartsCarousel'

  export default {
    data: function () {
      return {
        grid: 'measuring_statistics',
        grid_key: 'statistic_grid_data',
        item: 'measuring_statistic',
        path: this.$store.state.paths,
        dashboard_data: {},
        loading: true,
        loading_data: true,
        header_actions: [
          'project_filter_form'
        ],
        lists: [
          'measuring_soon_verifyings',
          'measuring_last_readings'
        ],

        trouble_shooting_message: "Статистика временно отключена, приносим свои извинения."
      }
    },

    components: {
      commonHeader,
      MeterReadingsChart,
      MeterTypeChartsCarousel,
      Grid: () => import('../../../shared/grid/Grid')
    },

    watch: {
      loading(newVal, oldVal) {
        this.$emit('set-loading', this.loading)

        setTimeout(() => {
          this.loading_data = newVal
        }, 500)
      }
    },

    methods: {

      getCollection(params) {
        params.filters = this.filters && this.generateFiltersParams() || {}

        if (this.$route.query.building_id) {
          if (!params.filters['building_id']) {
            params.filters['building_id'] = this.$route.query.building_id
          }

          history.replaceState(
            {},
            null,
            `${this.$route.path}?building_id=${params.filters['building_id']}`
          )
        }

        this.gridCollection(this.$store.state.paths[params.grid], params.grid, this.grid_key, params)
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
          this.$refs[params.grid].setError(false);
        })
        .finally(() => {
          this.$refs[params.grid].setLoading(false);
        })
      },

      reloadData() {
        this.loadDashboardData()
        setTimeout(() => {
          if (this.$refs['meter-type-charts-carousel']) { this.$refs['meter-type-charts-carousel'].reloadData() }
          if (this.$refs['meter-readings-chart']) { this.$refs['meter-readings-chart'].loadChartData() }
          if (this.$refs.measuring_soon_verifyings) { this.$refs.measuring_soon_verifyings.reloadData() }
          if (this.$refs.measuring_last_readings) { this.$refs.measuring_last_readings.reloadData() }
        },300)
      },

      loadDashboardData(params={}) {
        params.filters = this.filters && this.generateFiltersParams() || {}

        if (this.$route.query.building_id) {
          if (!params.filters['building_id']) {
            params.filters['building_id'] = this.$route.query.building_id
          }

          history.replaceState(
            {},
            null,
            `${this.$route.path}?building_id=${params.filters['building_id']}`
          )
        }

        this.$backend.index(`${this.path.measuring_statistic}/data`, { params: params })
        .then(({ data }) => {
          this.dashboard_data = data
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }
          console.log(error)
        })
        .finally(() => {
          // setTimeout(() => {
            this.loading = false
          // }, 800)
        })
      }
    },

    created() {
      this.lists.forEach(list => {
        this.$store.commit('initialGridsState', {grid: list, attr: {}})
      })

      this.$root.$on('reload-measuring-last-readings', () => {
        if (this.$refs['measuring_last_readings']) {
          this.$refs['measuring_last_readings'].reloadData()
        }
      })
    },

    mounted() {
      this.$root.$on('reload-measuring_statistic-data', () => {
        this.reloadData()
      })
    },

    beforeMount() {
      this.$emit('expand-class', {val: true, class: 'dashboard-background'})
      this.loadDashboardData()
    }
  }
</script>

<style lang='scss'>

  @import "../../../../assets/styles/measuring/statistic/main";

</style>