<template lang='pug'>
  div
    div(v-if="data && building" class="object-pass-blocks")
      div(class="top")
        div(class="card common-info")
          div(class="card-image noselect")
            img(:src="require(`../../../../assets/icons/object_pass/object.svg`)")
          div(class="card-separator")
          div(class="card-info")
            div(class="card-info-rows")
              div(v-for="row in data.common_info.info" class="card-info-row")
                span(class="title") {{row.label}}
                span(class="sub-title") {{building[row.name]}}
            //div(class="card-button")
              q-btn(flat no-caps)
                template(slot="default")
                  span {{data.common_info.buttons[0].label}}
        div(class="card-separator")
        div(class="card passport_fields")
          div(class="passport_fields-item" v-for="item in passport_fields" @mouseover="openParagraphs(item)" @mouseleave="closeForm")
            div
              span {{item.heading_title}}
              div(v-if="canEdit" class="passport_fields-item-button" @click="openCreateParagraph(item)") +
              create-form(:parentData="{data: item, building_id: building.id}" @close="closeForm" @reload="updateDashboard")
              div(class="passport_fields-item-paragraphs" v-if="item.paragraphs && item.active")
                div(class="card")
                  div(class="passport_fields-item-paragraphs-title") {{item.heading_title}}
                  div(class="passport_fields-item-paragraphs-grid" v-for="paragraph in item.paragraphs")
                    div(class="passport_fields-item-paragraphs-grid-number") {{paragraph.code}}
                    div(class="passport_fields-item-paragraphs-grid-name") {{paragraph.paragraph_title}}
                    div(class="passport_fields-item-paragraphs-grid-value") {{paragraph.value}}
                    q-icon(v-if="canEdit" name="edit" class="passport_fields-item-paragraphs-grid-icon" @click="openEditParagraph(paragraph)")
                    q-icon(v-if="canEdit" name="delete" class="passport_fields-item-paragraphs-grid-icon" @click="removeParagraph(paragraph)")
              edit-form(
                v-if="editForm && item.active" 
                :parentData="{data: item, building_id: building.id, paragraph: currentParagraph}" 
                @close="closeForm" 
                @change="changeParagraph"
                )

      div(class="bottom")
        div(class="card expenses")
          div(class="card-label noselect")
            span {{data.expenses.title}}
          p {{ locales['ppr_object_passport'][current_locale]['plug'] }}
          //expenses-chart(:parentData="{path: path}")

        div(class="measuring-meters")
          div(class="card")
            div(class="card-label noselect")
              span {{data.measuring_meters.title}}
            div(class="card-image noselect")
              img(:src="require(`../../../../assets/icons/object_pass/measuring_meters_${theme}.svg`)")
            div(class="card-button")
              //q-btn(flat no-caps @click="openMeasuringStatistics()")
                template(slot="default")
                  span {{data.measuring_meters.buttons[0].label}}

        div(class="telemetry")
          div(class="card")
            div(class="card-label noselect")
              span {{data.telemetry.title}}
            div(class="card-image noselect")
              img(:src="require(`../../../../assets/icons/object_pass/telemetry_${theme}.svg`)")
            div(class="card-button")
              q-btn(flat no-caps @click="openTelemetry()")
                template(slot="default")
                  span {{data.telemetry.buttons[0].label}}

    grid-hidden-msg(v-show="!building" ref="object-pass-hidden-msg" :parentData="{hide_table: hide_table}")


</template>

<script>
  import expensesChart from '../expenses/expensesChart'
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'
  import createForm from '../passport_fields/form'
  import editForm from '../passport_fields/editForm'

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        building: this.parentData.building,
        lists: this.parentData.lists,
        passport_fields: this.parentData.passport_fields,
        grid_key: 'data',
        editForm: false,
        currentParagraph: {},
        canEdit: false,
        hide_table: false
      }
    },

    watch: {
      parentData(newVal, oldVal) {
        this.passport_fields = newVal.passport_fields
      }
    },

    computed: {
      data() {
        return this.parentData.data
      }
    },

    props: {
      parentData: Object
    },

    components: {
      expensesChart,
      gridHiddenMsg,
      Grid: () => import('../../../shared/grid/Grid'),
      createForm,
      editForm
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['object-pass-hidden-msg']) {
          this.$refs['object-pass-hidden-msg'].hideTable(val)
        }
      },

      reloadData() {
        this.lists.forEach(list => {
          if (this.$refs[list]) { this.$refs[list].reloadData() }
        })
      },

      getCollection(params) {
        // if (this.filters) { params.filters = this.generateFiltersParams() }

        if (this.building.id) {
          params.filters.building_id = this.building.id
        }

        this.gridCollection(`${this.path}/${params.grid}`, params.grid, this.grid_key, params)
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
          this.$refs[params.grid].setError(false);
        })
        .finally(() => {
          this.$refs[params.grid].setLoading(false);
        })
      },

      openMeasuringStatistics() {
        if (this.building.id) {
          this.$router.push({
            name: 'measuring_statistics',
            query: { building_id: this.building.id }
          })
        }
      },

      openTelemetry() {
        if (this.building.id) {
          this.$router.push({
            name: 'telemetry',
            query: { building_id: this.building.id }
          })
        }
      },

      openIssuesByBuilding(params={}) {
        if (this.building.id) {
          params['attr'] = {
            filters: {
              building_id: [
                {
                  label: `${this.building.title}, ${this.building.address}`,
                  value: this.building.id
                }
              ]
            }
          }

          if (this.current_user.additional_services_ids.length > 0) {
            params['service_id'] = this.current_user.additional_services_ids[0]
          }

          this.$router.push({
            name: 'issues',
            params: params
          })
        }
      },

      openCreateParagraph(item) {
        this.passport_fields = this.passport_fields.map((field) => {
          if(field == item){
            field.create = true
            return field
          }
          field.create = false
          return field
        })
      },

      openParagraphs(item) {
        this.passport_fields = this.passport_fields.map((field) => {
          if(field == item){
            field.active = true
            return field
          }else{
            field.active = false
            return field
          }
        })
      },

      openEditParagraph(paragraph) {
        this.editForm = true
        this.currentParagraph = paragraph
      },

      closeForm() {
        this.passport_fields = this.passport_fields.map((item) => {
          item.create = false
          item.active = false
          this.currentParagraph = {}
          this.editForm = false
          return item
        })
      },

      changeParagraph(params) {
        this.passport_fields = this.passport_fields.map((title) => {
          if (title.pg_passport_heading_id == params.title_id){
            title.paragraphs = title.paragraphs.map((paragraph) => {
              if (paragraph.pg_passport_paragraph_id == params.paragraph_id){
                paragraph.value = params.value
                paragraph.paragraph_title = params.name
                return paragraph
              }
              return paragraph
            })
            return title
          }
          return title
        })
      },

      removeParagraph(paragraph) {
        this.$backend.destroy('/api/v3/building_passport_paragraphs', paragraph.pg_passport_paragraph_id)
        .then((res) => {
          this.passport_fields = this.passport_fields.map((title) => {
            if (title.pg_passport_heading_id == paragraph.pg_passport_heading_id){
              title.paragraphs = title.paragraphs.filter((item) => {
                return item.pg_passport_paragraph_id != paragraph.pg_passport_paragraph_id
              })
              title.paragraphs = title.paragraphs.map((paragr, index) => {
                paragr.code = index + 1
                return paragr
              })
              if (title.paragraphs.length <= 0){
                title.paragraphs = null
              }
              return title
            }
            return title
          })
          this.$emit('close')
        })
      },

      updateDashboard(){
        this.$emit('reload')
      },

      checkUserRoles() {
        if(
          this.current_user.role == 'superadmin'
          ||
          this.current_user.role == 'admin'
          ||
          this.current_user.role == 'chief_engineer'
          ){
          this.canEdit = true
        }
      }
    },

    mounted() {
      if (!this.building) {
        this.hideTable(true)
      }
      this.checkUserRoles()
    }
  }
</script>

<style lang='scss'>

  .object-pass {

    .card {
      background: var(--dashboard-card-background);
      border-radius: 20px;
      position: relative;
    }

    .card-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: var(--dashboard-label-color);
    }

    .object-pass-blocks {
      display: flex;
      overflow: auto;
      margin-top: 30px;
      flex-flow: row wrap;
      justify-content: space-between;

      .top{
        background: var(--dashboard-card-background);
        border-radius: 20px;
        width: 100%;
        display: flex;
        height: 388.27px;

        .card-separator {
          border: 1px dashed #E8E9EB;
        }

        .common-info {
          min-width: 570px;
          padding: 20px;
          height: 388.27px;

          display: flex;

          .card-button {
            margin-top: 10px;
          }

          .card-image {
            width: 35%;
            margin-right: 10px;
          }

          .card-info {
            width: 65%;
            padding-left: 20px;
            display: grid;

            .card-info-rows {
              overflow: auto;
              height: 312px;
            }

            .card-info-row {
              display: grid;
              padding-bottom: 25px;
            }

            .title {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              padding-bottom: 5px;

              color: #5B7AFF;
            }

            .sub-title {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;

              color: var(--dashboard-label-color);
            }
          }
        }

        .passport_fields{
          display: grid;
          grid-template-columns: 50% 50%;
          grid-auto-rows: fit-content(100%);
          grid-gap: 15px;
          width: 100%;
          height: 388.27px;
          max-height: 388.27px;
          flex-flow: row wrap;
          padding: 30px 23px;
          overflow-y: scroll;
          overflow-x: hidden;

          @media (max-width: 1300px) {
            grid-template-columns: 100%;
          }

          &-item{
            width: 100%;
            display: flex;
            position: relative;
            height: fit-content;
            padding-bottom: 5px;
            color: var(--dashboard-label-color);

            span{
              display: block;
              margin-right: 35px;
              font-weight: bold;
              cursor: pointer;
            }

            &-button{
              width: 24px;
              height: 24px;
              margin-left: 5px;
              line-height: 24px;
              font-size: 24px;
              position: absolute;
              right: 0;
              top: 0;
              color: #5B7AFF;
              cursor: pointer;
            }

            &-paragraphs{
              position: absolute;
              display: block;
              top: 100%;
              left: 0;
              width: 100%;
              max-height: 350px;
              overflow-y: scroll;

              &-title{
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 12px;
              }

              &-grid{
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                font-size: 14px;
                color: var(--dashboard-label-color);

                &-number{
                  width: 3%;
                }

                &-name{
                  width: 60%;
                }

                &-value{
                  width: 25%;
                }

                &-icon{
                  width: 5%;
                  cursor: pointer;
                }

                &:not(:last-child){
                  margin-bottom: 15px;
                }
              }

              .card{
                filter: drop-shadow(0px 8px 24px rgba(44, 63, 88, 0.1));
                border: 1px solid #F8F8F8;
                padding: 15px 15px 30px 15px;
                z-index: 1;
              }
            }
          }

          &-paragraph-form{
            position: absolute;
          }
        }
      }

      .bottom{
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .measuring-meters {
          width: 23%;
          .card {
            padding: 20px;
            height: 250px !important;
            margin-bottom: 30px;

            .card-image {
              margin-top: 15px;
              height: 85px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .telemetry {
          width: 23%;
          .card {
            padding: 20px;
            height: 250px !important;
            margin-bottom: 30px;

            .card-image {
              margin-top: 15px;
              height: 85px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .expenses {
          padding: 20px;
          height: 250px;
          width: 50%;
          position: relative;
          
          p{
            text-align: center;
            display: block;
            font-size: 14px;
            line-height: 18px;
            position: absolute;
            height: fit-content;
            width: 80%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            color: var(--dashboard-label-color);
          }
        }
      }
    }

    .object-pass-left-block {
      width: 520px;
      margin-bottom: 20px;


      .object-pass-left-block.footer {
        display: flex;
      }


      .seasonal_works {
        padding: 20px;
        height: 250px;
        width: 200px;
        margin-left: 30px;

        .card-image {
          margin-top: 15px;
          margin-bottom: 20px;
          height: 120px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .card-image {
      text-align: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-button {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto;
      /*width: 100%;*/
      text-align: center;

      button {
        height: 34px;
        width: 100%;
        max-width: 150px;
        border-radius: 8px;
        background-color: #5b7aff10;
        color: #5B7AFF;
        padding-left: 40px;
        padding-right: 40px;

        .q-btn__wrapper {
          min-height: initial !important;
        }

        .q-btn__content {

          span {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;

          }
        }
      }
    }
  }

</style>