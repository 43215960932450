<template lang='pug'>

  high-chart(@load-chart-data="loadChartData"
            @set-chart-area="setChartArea"
            :grid="grid"
            :grid_key="grid_key"
            :chart="{height: chart_height, margin_top: 20, zoom: true, grab: true, scrollable: false, xaxis_plotbands: this.getDeviations()}"
            :series="series"
            :categories="categories"
            :step_width="step"
            :xaxis="xAxis"
            :yaxis="yAxis"
            :legend="{enabled: false}"
            :tooltip="tooltip"
            :begin_extr="{start: begin_extr_start, end: begin_extr_end}"
            :plot_options="plot_options"
            :chart_type="{type: 'areaspline'}")

</template>

<script>
  import highChart from '../../../shared/helpers/charts/highChart'

  export default {
    data: function () {
      return {
        step: 11,
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key,
        chart_height: this.parentData.chart_height || 310,
        tooltip: {
          headerFormat:   '',
          pointFormat:    this.pointFormat(),
          footerFormat:   '',
          shared: false,
          useHTML: true,
          className: 'tooltip-chart',
          backgroundColor: '#5B7AFF',
          borderWidth: 0,
          borderRadius: 12,
          padding: 15,
          hideDelay: 2500,
          followTouchMove: false,
          followPointer: true,
          style: {
            pointerEvents: 'auto'
          }
        },
        xAxis: {
          reversed: false,
        },
        yAxis: {
          min: null,
          grid_line_color: 'transparent'
        },
        plot_options: {
          fill_opacity: 0.4,
          sticky_tracking: false,
          areaspline: {
            marker: {
              enabled: false
            }
          },
          point: {
            events: {
              mouseOver: function (event) {
                if (this.series.chart.tooltip.label) {
                  this.series.chart.tooltip.label.show()
                }
              }
            }
          }
        },
        extr_start: this.parentData.extr_start || null,
        extr_end: this.parentData.extr_end || null,
        is_modal: this.parentData.is_modal
      }
    },

    computed: {
      data() {
        return this.currentGrid.data
      },

      series() {
        return this.data.map (v => {
          return {
            name: v.name,
            data: v.data.map(val => {
              return {
                y: val.value,
                color: v.color,
                date: val.date,
                time: val.time,
                unit: v.measure_unit,
                issue_id: val.issue_id,
                telemetry_id: val.telemetry_id,
                min: val.min_value,
                max: val.max_value,
                path: v.path || process.env.VUE_APP_TMP_URL
              }
            }),
            color: v.color
          }
        })
      },

      categories() {
        return this.data.map(val => val.data.map(val => val.time))[0]
      },

      begin_extr_start() {
        return this.extr_start
      },

      begin_extr_end() {
        return this.extr_end
      }
    },

    watch: {
      categories(n, o) {
        if (n && n.length >= this.begin_extr_end) {
          if (this.currentGrid.pagination.per_page) {
            let val = (this.currentGrid.area.data_max || this.currentGrid.pagination.per_page) - this.begin_extr_end

            // console.log('extr_start', n.length - (val + (this.begin_extr_end - this.begin_extr_start)))
            this.extr_start = n.length - (val + (this.begin_extr_end - this.begin_extr_start))


            // console.log('extr_end', n.length > val ? n.length - val : val)
            this.extr_end = n.length > val ? n.length - val : val
          }
        }
        // console.log(n.length, this.begin_extr_start, this.begin_extr_end)
        this.currentGrid.area.data_max = n.length
      }
    },

    props: {
      parentData: Object
    },

    components: {
      highChart
    },

    methods: {

      getExtrStart() {
        return this.begin_extr_start
      },

      getExtrEnd() {
        return this.begin_extr_end
      },

      setChartArea(attr) {
        let extr = attr.chart.xAxis[0].getExtremes()
        let step2 = this.step * 2
        let step = attr.grab ? step2 : this.step

        if (attr.event === 'reset') {

          this.currentGrid.pagination = {}
          this.currentGrid.data = []
          this.currentGrid.area.data_max = null
          this.$emit('load-chart-data', {event: 'reset'})
          this.initialChart()

        } else if (attr.event === 'minus') {

          if (extr.min - step2 > 0) {
            this.extr_start = extr.min - step2
            this.extr_end = extr.max
            // console.log(attr.event, this.begin_extr_start, this.begin_extr_end)
          } else {
            this.loadChartData(attr.chart)
          }

        } else if (attr.event === 'plus') {

          if (extr.max - this.step/2 > extr.min) {
            this.extr_start = extr.min + step2
            this.extr_end = extr.max
            // console.log(attr.event, this.begin_extr_start, this.begin_extr_end)
          }

        } else if (attr.event === 'back') {

          if (extr.min - step > 0) {
            this.extr_start = extr.min - step
            this.extr_end = extr.max - step
            // console.log(attr.event, this.begin_extr_start, this.begin_extr_end)
          } else {
            this.loadChartData(attr.chart)
          }


        } else if (attr.event === 'next') {


          if (extr.max < extr.dataMax) {
            this.extr_start = extr.min + step
            this.extr_end = extr.max + step
            // console.log(attr.event, this.begin_extr_start, this.begin_extr_end)
          }
        }

      },

      loadChartData(chart) {
        if (this.currentGrid) {
          let pagination = this.currentGrid.pagination
          if (pagination && pagination.count > pagination.page * pagination.per_page) {
            this.currentGrid.pagination.page += 1
            this.$emit('load-chart-data')
          }
        }
      },

      pointFormat() {
        return '<ul>' +
          '<li class="indication-chart-tooltip-row date">{point.date}</li>' +
          '<li class="indication-chart-tooltip-row time">{point.time}</li>' +
          '<li class="indication-chart-tooltip-row value" style="color:{series.color};">{series.name}: {point.y} {point.unit}</li>' +
          '<li class="indication-chart-tooltip-row issue">Заявка: <a href="{point.path}issues?show=true&id={point.issue_id}" target="_blank">{point.issue_id}</a></li>' +
          '<li class="indication-chart-tooltip-row permissible-title">Допустимое значение:</li>' +
          '<li class="indication-chart-tooltip-row permissible-value">min {point.min} {point.unit}</li>' +
          '<li class="indication-chart-tooltip-row permissible-value">max {point.max} {point.unit}</li>' +
          '</ul>'
      },

      getDeviations(result=[]) {
        if (this.currentGrid.deviations.length > 0) {
          this.currentGrid.deviations.map((d, i) => {
            result.push({
              label: 'Отклонения',
              color: '#ff828333',
              from: d.from,
              to: d.to,
              id: `plotband-${i}`,
              className: 'plotband-deviations',
              borderWidth: 0.1,
              borderColor: '#FA7167'
            })
          })
        }
        return result
      },

      initialChart() {
        // console.log('initialChart', this.begin_extr_start, this.begin_extr_end)
        this.extr_start = this.categories && this.categories.length >= this.step ? this.categories.length - this.step : 0
        this.extr_end = this.categories && this.categories.length >= 1 ? this.categories.length : this.step
      }
    },

    created() {
      if (!this.begin_extr_start && !this.begin_extr_end) {
        this.initialChart()
      }
    },

    beforeDestroy() {
      if (!this.is_modal && this.currentGrid) {
        this.currentGrid.data = []
      }
    }
  }
</script>

<style lang='scss'>

  .plotband-deviations {
    border: 2px solid #FA7167;
  }

  .indication-chart-tooltip-row.date {
    font-weight: 600 !important;
    font-size: 13px !important;
  }

  .indication-chart-tooltip-row.time {
    margin-bottom: 10px;
  }

  .indication-chart-tooltip-row.value {
    font-weight: 600 !important;
  }

  .indication-chart-tooltip-row.permissible-title {
    margin-top: 10px;
  }

  .indication-chart-tooltip-row.permissible-title, .indication-chart-tooltip-row.permissible-value {
    font-size: 11px !important;
  }

  .indication-chart-tooltip-row {
    /*padding: 0 10px 0 0;*/
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FCFCFC;

    list-style-type: none;

    a {
      font-weight: 600;
      color: #FCFCFC !important;
      text-decoration: initial;
    }
  }

</style>