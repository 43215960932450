<template lang='pug'>

  div(v-if="visible" class="passport_fields-paragraph-form")
    div(class="card")
      div(class="passport_fields-paragraph-form-title") {{title}}
      form(@submit="submitForm")
        input(outlined v-model="paragraph" placeholder="Параграф")
        input(outlined v-model="value" placeholder="Значение")
        button(type="submit")
          div +
          p Добавить пункт

      q-icon(name="close" class="passport_fields-paragraph-form-close" @click="closeForm")

</template>

<script>

  export default {
    data: function () {
      return {
        paragraph: '',
        value: '',
        title: this.parentData.data.heading_title,
        visible: this.parentData.data.create,
        building_passport_heading_id: this.parentData.data.pg_passport_heading_id,
        building_id: this.parentData.building_id
      }
    },

    watch: {
      parentData(newVal, oldVal) {
        this.visible = newVal.data.create
      }
    },

    props: {
      parentData: Object
    },

    methods: {
      closeForm(){
        this.$emit('close')
      },

      submitForm(e){
        e.preventDefault()
        const params = {
          title: this.paragraph,
          value: this.value,
          building_passport_heading_id: this.building_passport_heading_id,
          building_id: this.building_id
        }

        this.$backend.collection('/api/v3/building_passport_paragraphs', params)
        .then((res) => {
          this.$emit('close')
          this.paragraph = ''
          this.value = ''
        })
        .finally(() => {
          setTimeout(() => {
            this.$emit('reload')
          }, 800);
        })
      }
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>

.card-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: var(--dashboard-label-color);
}

.passport_fields-paragraph-form{
  position: absolute;
  left: 0;
  top: 110%;
  z-index: 2;
  width: 100%;
  height: fit-content;

  .card{
    padding: 18px 16px;
    border: 1px solid #F8F8F8;
    filter: drop-shadow(0px 8px 24px rgba(44, 63, 88, 0.1));
  }

  &-title{
    font-size: 12px;
    font-weight: 600;
    color: var(--dashboard-label-color);
    margin-right: 30px;
  }

  form{
    input{
      background: var(--dashboard-card-background);
      color: var(--dashboard-label-color);
      border: 1px solid #D2D2D2;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      height: 33px;
      line-height: 33px;
      padding: 0 16px;
      margin-top: 12px;
      outline: none;
    }
  }

  &-close{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 20px;
    cursor: pointer;
  }

  button{
    color: #5B7AFF;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 10px 0 0;

    div{
      font-size: 22px;
      display: inline-block;
      margin-right: 7px;
      font-weight: normal;
    }
    
    p{
      display: inline-block;
      font-size: 14px;
      margin: 0;
    }
  }
}

</style>