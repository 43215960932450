<template lang='pug'>

  div
    table(class="tech-map-tab-table issues-table")
      thead
        tr
          th(v-for='column in tab_data.columns' :key="column.name")
            span(class="column-header-title") {{ column.label }}
      tbody
        tr(v-for="issue_data in data" @click="showPprIssue(issue_data['id'])")
          td(v-for="td in issue_data.rows" :class="td.key" :style="td.style")
            span {{td.value}}

</template>

<script>

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        tab_data: this.parentData.tab_data,
        row_id: this.parentData.row_id
      }
    },

    computed: {
      data() {
        return this.parentData.data
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    methods: {

      showPprIssue(id) {
        this.$router.push({
          name: 'ppr_issues',
          query: {
            show: true,
            id: id
          }
        })
      }
    },

    created(){
    },
  }
</script>

<style lang='scss'>
</style>