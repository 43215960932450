<template lang='pug'>

  div(class="indications-by-hours")
    div(class="card-label noselect" @click="openModal")
      span {{data.title}}
    div(class="card")

      div(v-if="loading" class="spinner-container grid-spinner")
        q-spinner(color="primary" size="3em")

      div(v-if="!loading && chart_is_not_empty")
        indications-by-hours-chart(ref="indications-by-hours-chart" @load-chart-data="loadChartData" :parentData="{grid: grid, grid_key: grid_key}")

      grid-hidden-msg(v-show="!loading && !chart_is_not_empty" ref="indication-chart-hidden-msg" :parentData="{hide_table: hide_table}")

</template>

<script>
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'
  import indicationsByHoursChart from './indications_by_hours_chart'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        grid_key: 'data',
        sensor_id: '',
        deviations: [],
        area: {
          data_max: null,
          area_max: null
        },
        pagination: {},
        modal: false,
        loading: true,
        hide_table: false
      }
    },

    computed: {
      data() {
        return this.parentData.data
      },

      sensor() {
        return this.sensor_id
      },

      chart_is_not_empty() {
        return this.sensor && this.currentGrid.data[0] && this.currentGrid.data[0].data.length > 0
      }
    },

    props: {
      parentData: Object
    },

    components: {
      indicationsByHoursChart,
      gridHiddenMsg
    },

    methods: {

      openModal() {
        let chart_component = this.$refs['indications-by-hours-chart']

        if (this.chart_is_not_empty) {
          this.$emit('open-modal', {
            grid: this.grid,
            grid_key: this.grid_key,
            component: 'indications_by_hours_chart',
            chart_height: '44%',
            extr_start: chart_component && chart_component.getExtrStart(),
            extr_end: chart_component && chart_component.getExtrEnd(),
            is_modal: true
          })
        }
      },

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['indication-chart-hidden-msg']) {
          this.$refs['indication-chart-hidden-msg'].hideTable(val)
        }
      },

      initialGrid(id='') {
        this.$store.commit('initialGrid', {grid_name: this.grid, grid_key: this.grid_key})
        this.currentGrid.indication_id = id
        this.currentGrid.pagination = this.pagination
        this.currentGrid.deviations = this.deviations
        this.currentGrid.area = this.area
        this.currentGrid.area.area_max = null
        this.currentGrid.area.data_max = null
      },

      reloadChart(params={}) {
        this.loading = true
        this.loadChartData(params)
      },

      loadChartData(attr={}) {
        let params = {}

        if (attr.event === 'reset') {
          this.loading = true
        }

        if (attr['sensor_id'] !== undefined) {
          this.sensor_id = attr['sensor_id'] || ''
        }

        if (attr['id']) {
          params['indication_id'] = attr['id']

          if (this.currentGrid.indication_id !== attr['id']) {
            this.initialGrid(attr['id'])
          }

        } else if (!params['id'] && this.currentGrid && this.currentGrid.indication_id) {
          params['indication_id'] = this.currentGrid.indication_id
        }

        let table = {
          pagination: this.currentGrid.pagination
        }

        if (attr['interval_reload']) {
          table['interval_reload'] = attr['interval_reload']
        }

        params = Object.assign(params, {json: {table: table}})

        if (Object.keys(params).length > 0) {

          this.$backend.collection(`${this.$store.state.paths.telemetry}/indications_by_hours`, params)
          .then(({ data }) => {

            if (data) {
              let props

              if (data) { props = data.data }

              if (props && props[0]) {
                this.currentGrid.pagination = data.pagination
                this.currentGrid.deviations = props[0].deviations

                if (this.currentGrid.data.length > 0) {

                  if (attr['interval_reload'] === true) {
                    let d = props[0].data[props[0].data.length - 1]
                    console.log('interval_reload:', attr['interval_reload'], d && d.date || 'no date', d && d.time || 'no time', d && d.value || 'no value')
                    this.currentGrid.data = props

                  } else if (attr['interval_reload'] === false) {
                    this.currentGrid.data = props

                  } else {
                    this.currentGrid.data[0].data = props[0].data.concat(this.currentGrid.data[0].data)
                  }

                } else {
                  this.currentGrid.data = props
                }

              } else {
                console.log('indications_by_hours no data')
              }
            }

          })
          .catch((error) => {
            console.log(error);
            if (error.response) { this.reLogin(error.response.status) }
          })
          .finally(() => {
            this.loading = false

            if (this.chart_is_not_empty) {
              this.hideTable(false)
            } else {
              this.hideTable(true)
            }
          })


        } else {
          this.loading = false

          this.currentGrid.indication_id = ''

          this.sensor_id = ''
          this.currentGrid.data = []
          this.hideTable(true)
        }
      }
    },

    created() {
      this.initialGrid()
    }
  }
</script>

<style lang='scss'>

  .indications-by-hours {
    height: 100%;

    .card {
      padding: 20px;
      height: 375px;
      width: 100%;

      .hidden-tabs-message {
        height: 100%;

        span {
          padding-top: initial;
        }
      }

      .spinner-container.grid-spinner {
        height: 100%;
      }

      .chart-wrapper {
        margin-top: 0 !important;
      }
    }

    .card-label {
      padding-bottom: 10px !important;

      span {
        cursor: pointer;
      }
    }
  }

</style>