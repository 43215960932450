<template lang='pug'>

  index-page(@set-loading="setLoading"
              :parentData="{grid: grid, path: path, collection_path: collection_path, report_class: report_class, grid_key: grid_key, transition_key: transition_key, collection_type: collection_type, sti_type: sti_type, except_filters: except_filters}")

</template>

<script>
  import indexPage from '../../shared/indexPage'

  export default {
    data: function () {
      return {
        grid: 'archive',
        path: this.$store.state.paths['issue'],
        collection_path: this.$store.state.paths['archive'],
        collection_type: 'archive',
        transition_key: 'issue',
        report_class: 'issue',
        sti_type: 'MaintenanceIssue',
        grid_key: 'data',
        except_filters: {
          state: this.$store.state.issues_active_states
        }
      }
    },
    components: {
      indexPage,
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>
