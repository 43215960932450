<template lang='pug'>

  div(class="meter-type-chart-card")
    div(class="dashboard-card-label")
      span {{meter_type.title}}

    div(v-if="loading" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")

    high-chart(v-if="!loading && chart.length > 0"
               ref="high-chart"
               @set-chart-area="setChartArea"
               :chart="{height: 270, margin_top: 50}"
               :series="series"
               :categories="categories"
               :step_width="step_width"
               :yaxis="{min: 0, soft_max: 120, tick_interval: 40}"
               :legend="{enabled: false}"
               :tooltip="tooltip()"
               :begin_extr="{start: begin_extr_start, end: begin_extr_end}"
               :plot_options="{column_point_width: 10}"
               :chart_type="{type: 'column'}")

    grid-hidden-msg(:ref="`${chart_name}-hidden-msg`" :parentData="{hide_table: hide_table}")

</template>

<script>
  import highChart from '../../../../shared/helpers/charts/highChart'
  import themes from '../../../../shared/helpers/charts/themes'
  import gridHiddenMsg from '../../../../shared/grid/gridHiddenMsg'

  export default {

    data() {
      return {
        path: this.$store.state.paths.measuring_statistic,
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key,
        chart_name: 'meter_types_statistic',
        data: {},
        loading: true,
        hide_table: false,

        tooltip_options: {
          positioner: function () {
            return { x: this.chart.plotRight + 15, y: this.chart.plotTop - 55 };
          },
          shadow: false,
          headerFormat: '<small>{point.key}: </small>',
          pointFormat: '<small>{point.y}</small>',
          borderWidth: 0
        }
      }
    },

    props: {
      parentData: Object
    },

    components: {
      highChart,
      gridHiddenMsg
    },

    computed: {
      chart() {
        return this.data && this.data.data || []
      },

      meter_type() {
        return this.parentData.meter_type;
      },

      series() {
        return this.data.data && this.data.data.map (v => {
          return {
            name: v.name,
            data: v.data.map(val => {
              return {y: val.value, color: v.color}
            }),
            color: v.color
          }
        }) || []
      },

      categories() {
        return this.data.data && this.data.data.map(val => val.data.map(val => val.month))[0] || []
      },

      step_width() {
        return this.categories && this.categories.length < this.data.step_width ? this.categories.length : this.data.step_width
      },

      begin_extr_start() {
        return !this.categories || this.categories.length <= this.data.step_width ? this.step_width : this.categories.length - this.step_width
      },

      begin_extr_end() {
        return !this.categories || this.categories.length <= 1 ? 0 : this.categories.length
      }
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs[`${this.chart_name}-hidden-msg`]) {
          this.$refs[`${this.chart_name}-hidden-msg`].hideTable(val)
        }
      },

      setChartArea(attr) {
        let extr = attr.chart.xAxis[0].getExtremes()

        if (attr.event === 'back') {
          if (extr.min > extr.dataMin) {
            attr.chart.xAxis[0].setExtremes(extr.min - this.step_width, extr.max - this.step_width)
          }

        } else if (attr.event === 'next') {
          if (extr.max < extr.dataMax) {
            attr.chart.xAxis[0].setExtremes(extr.min + this.step_width, extr.max + this.step_width)
          }
        }
      },

      tooltip() {
        this.tooltip_options['backgroundColor'] = themes[this.theme].tooltip_background
        let style = {
          color: themes[this.theme].tooltip_color,
          fontWeight: 'bold'
        }
        this.tooltip_options['style'] = style
        console.log()
        return this.tooltip_options
      },

      loadChartData(params={}) {
        params.filters = this.filters && this.generateFiltersParams() || {}

        if (this.$route.query.building_id) {
          if (!params.filters['building_id']) {
            params.filters['building_id'] = this.$route.query.building_id
          }

          history.replaceState(
            {},
            null,
            `${this.$route.path}?building_id=${params.filters['building_id']}`
          )
        }

        params['measuring_meter_type_id'] = this.meter_type.id
        params['chart_name'] = this.chart_name

        this.$backend.collection(`${this.path}/${this.chart_name}`, params)
        .then(({ data }) => {
          this.data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.loading = false

          if (this.chart.length > 0) {
            this.hideTable(false)
          } else {
            this.hideTable(true)
          }
        })
      }
    },

    created() {
      this.loadChartData()
    }
  }
</script>

<style lang="scss">

  .meter-type-chart-card {
    .hidden-tabs-message {
      height: 310px !important;

      span {
        padding-top: initial;
      }
    }

    .spinner-container.grid-spinner {
      height: 310px !important;
    }
  }

</style>

