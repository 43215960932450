export const options = (theme) => ({
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [{
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      gridLines: {
        display: false
      }
    }]
  },
  tick: {
    display: false
  }
})