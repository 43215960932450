<script>
  import { Line } from 'vue-chartjs'
  import themes from './themes'
  import { chartjs } from './chartjs/index'

  export default {
    extends: Line,
    props: ["data", "options"],

    components: {
      chartjs,
      themes
    },

    methods: {
      getTheme() {
        return themes[this.theme]
      }
    },

    mounted () {

      let data = {
        labels: this.data.map(val => val.created_at),
        datasets: [
          {
            label: 'Удовлетворенность',
            borderColor: '#5B7AFF',
            backgroundColor: '#5B7AFF25',

            borderWidth: 1,
            data: this.data.map(val => val.value)
          }
        ]
      }

      this.renderChart(data, chartjs.options(this.getTheme()))
    }
  }
</script>
