<template lang='pug'>

  q-card(id="form" class="checklist-form__wrapper")

      q-card-section(class="checklist-form__card-section" style="background: var(--simple-table-background) !important;")
        form-header(ref="form-header" @close-form="closeForm"
                    :parentData="{grid: grid, title: title, title_icon: 'checklist.svg'}")

        q-form(class="checklist-element-form fill-height")
          div(class="q-px-md q-stepper-fill fill-height")
            q-stepper(header-class="checklist-form__stepper-header" 
                    :animated="false"
                    v-model="checklistStep" 
                    ref="stepper" 
                    color="primary" 
                    done-color="indigo-12" 
                    active-color="indigo-12" 
                    active-icon="circle"
                    style="display: flex; flex-direction: column; height: 100%"
            )
              q-step(v-for="(page, index) in data.checklist_data.pages"
                    :key="page.id"
                    :name="index" 
                    :title="page.title" 
                    icon="edit"
                    :done="checklistStep > index" 
              )
                div(class="row")
                  component(:ref="field.name"
                            v-for="field in page.elements"
                            :key="field.id"
                            :is="getFieldComponent(field.type)"
                            :field="field"
                            :readonly="true"
                            @edit-field="editField($event)"
                            @reset-field="resetField($event)"
                            )
                
        div(class="row justify-center btn-position-absolute")
          q-stepper-navigation
            q-btn(outline color="indigo-12" 
                  @click="checklistStep--" 
                  :disable="checklistStep === 0"
            ) {{ checklist_locales.previous }}
            q-btn(color="indigo-12 q-ml-sm" 
                  @click="checklistStep++"
                  :disable="checklistStep > parentData.data.checklist_data.pages.length - 2"
            ) {{ checklist_locales.next }}
</template>

<script>
  import formHeader from '../../shared/forms/formHeader'
  import fill_string_field from './fill_string_field'
  import fill_number_field from './fill_number_field'
  import fill_select_field from './fill_select_field'
  import fill_checkbox_field from './fill_checkbox_field'
  import fill_radio_field from './fill_radio_field'
  import fill_file_field from './fill_file_field'
  import fill_date_field from './fill_date_field'
  import fill_datetime_field from './fill_datetime_field'
  import {v4} from 'uuid'

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,

        form_valid: false,
        checklistStep: 0,
        disabled: null,
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      fill_string_field,
      fill_number_field,
      fill_select_field,
      fill_checkbox_field,
      fill_radio_field,
      fill_file_field,
      fill_date_field,
      fill_datetime_field
    },

    computed: {

      checklist_locales() {
        return this.locales.checklist[this.current_locale]
      },

      row() {
        return this.parentData.row
      },

      data() {
        return this.parentData.data
      },

      title() {
        console.log(this.data)
        return this.current_locale === 'en' ? 'Checklist for issue № ' + this.data.checklist_data.issue_id : 'Чеклист по заявке № ' + this.data.checklist_data.issue_id
      },

      header_title() {
        return this.parentData.header_title
      }

      // buttons() {
      //   return this.parentData.data.header.filters_form.form_buttons
      // }
    },

    methods: {
      setFieldVal(attr) {
        this.$nextTick(() => {
          if (this.$refs[attr.field]) {
            this.$refs[attr.field][0].setField(attr.val)
          }
        })
      },

      closeForm() {
        this.$emit('close-form')
      },

      onSubmit(params={}) {
        let form = this.currentForm

        this.checkFormValidation(form)

        if (this.form_valid) {
          let result = Object.keys(form).reduce((result, e) => {
            if (Array.isArray(form[e]['field']) && form[e]['selected_items']) {
              result[e] = form[e]['selected_items']
            } else if (Array.isArray(form[e]['field'])) {
              result[e] = form[e]['field'].map(e => e['value'])
            } else if (typeof form[e]['field'] === 'object') {
              result[e] = form[e]['field']['value']
            } else {
              result[e] = form[e]['field']
            }
            return result;
          }, {})

          params = {
            issue_id: this.row.id,
            checklist_name: this.row.checklist_name,
            checklist_el: this.data.name,
            form: result
          };

          this.$backend.create(this.path + '/form_submit', null, params)
          .then(({ data }) => {

            this.$root.$emit('load-show-data', this.row)
            let next_el = data.next_el
            if (next_el) {
              this.resetForm()
              this.$emit('reload-form-data', next_el)
            } else {
              this.$emit('close-form')
            }

          })
          .catch((error) => {
            if (error.response) { this.reLogin(error.response.status) }
           
            this.$q.notify(this.notifies.error_ask_admin)
          })
        } else {
          this.$q.notify(this.notifies.fix_invalid_fields)
        }
      },

      getFieldComponent(key) {
        if (key === 'text') return fill_string_field
        if (key === 'select') return fill_select_field
        if (key === 'number') return fill_number_field
        if (key === 'date') return fill_date_field
        if (key === 'datetime') return fill_datetime_field
        if (key === 'checkbox') return fill_checkbox_field
        if (key === 'radio') return fill_radio_field
        if (key === 'file') return fill_file_field
      },

      getChecklistFieldsFromPages(parentData) {
        let fieldsArray = []
        parentData.data.checklist.pages.forEach(page => page.elements.forEach(element => fieldsArray.push({id: element.id, required: element.required})))
        return fieldsArray
      },

      editField(event) {
        const index = this.checklist.findIndex(el => el.id === event.id)
        this.checklist[index].value = event.value
        this.isButtonDisabled()
      },

      resetField(event) {
        const index = this.checklist.findIndex(el => el.id === event.id)
        delete this.checklist[index].value
        this.isButtonDisabled()
      },

      isButtonDisabled() {
        if(this.checklist) {
          for (let elem of this.checklist) {
            if (elem.required && !elem.value) {
              this.disabled = true
              return
            }
          }
          this.disabled = false
        } else {
          this.disabled = true
        }
        
      },
      
      completeChecklist() {
        // {
        //   "checklist_data": {
        //     "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "es_checklist_uuid": 0,
        //     "checklist_pages": [
        //       {
        //         "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //         "title": "string",
        //         "parent_uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //         "finish": true,
        //         "checklist_elements": [
        //           {
        //             "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //             "type": "string",
        //             "title": "string",
        //             "order": 0,
        //             "_data": {
        //               "type": "string",
        //               "value": "string",
        //               "values": [
        //                 "string"
        //               ]
        //             },
        //             "checklist_element_options": [
        //               {
        //                 "type": "string",
        //                 "values": [
        //                   {
        //                     "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //                     "title": "string",
        //                     "order": 0,
        //                     "_data": {
        //                       "type": "string",
        //                       "value": "string"
        //                     }
        //                   }
        //                 ]
        //               }
        //             ]
        //           }
        //         ]
        //       }
        //     ]
        //   }
        // }

        if (!this.isButtonDisabled()) {
          let checklist_data_id = v4()
          let checklist_data = {
            checklist_data: {
              uuid: checklist_data_id,
              issue_id: this.parentData.row.id,
              checklist_pages: this.parentData.data.checklist.pages.map(page => 
              {
                return {
                  uuid: v4(),
                  title: page.title,
                  parent_uuid: checklist_data_id,
                  checklist_elements: page.elements.map(element => {
                    let checklist_element_index = this.checklist.findIndex(elem => elem.id === element.id)
                    const res = {
                      uuid: v4(),
                      type: element.checklist_element_type_code,
                      title: element.title,
                      order: element.order,
                      "_data": {
                        type: this.checklist[checklist_element_index].value ? this.checklist[checklist_element_index].value.split && this.checklist[checklist_element_index].value.split(',').length > 1? 'array' : typeof this.checklist[checklist_element_index].value : null,
                        value: this.checklist[checklist_element_index].value || null,
                        values: this.checklist[checklist_element_index].value ? this.checklist[checklist_element_index].value.split ? this.checklist[checklist_element_index].value.split(',').length : [] : null, 
                      },
                      checklist_element_options: element.checklist_element_type_code === 'checkbox' || element.checklist_element_type_id === 'radio' ? element.options.map(option => {
                          return {
                            type: parseInt(option.title) == option.title ? 'number' : 'string',
                            values: [
                              {
                                uuid: v4(),
                                title: option.title,
                                order: option.order,
                                "_data": [
                                  {
                                    type: parseInt(option.title) == option.title ? 'number' : 'string',
                                    value: option.title
                                  }
                                ]
                              }
                            ]
                          }
                        }
                      ) : null
                    }
                    element.checklist_element_type_code === 'checkbox' || element.checklist_element_type_code === 'radio' ? true : delete res.checklist_element_options
                    return res
                  })
                }
              })
            }
          }
          console.log(checklist_data)
          this.$backend.create(`/api/v3/checklists/${this.parentData.data.checklist.id}/data`, null, checklist_data).then((res) =>  
            // this.closeForm({method: 'create', item: 'checklist', form_title: '', title_icon_path: this.title_icon_path, path: this.path['checklist'], grid: 'checklists', data: res.data.checklist})
            console.log(res)
          )
        }
          
          

          
      },
    },

    created() {
    },

    mounted() {
      this.isButtonDisabled()
    },
    updated() {
      this.isButtonDisabled()
    }
  }
</script>

<style lang='scss'>

  @import "../../../assets/styles/forms/modal-form";
  @import "../../../assets/styles/checklists/form";

  .q-stepper-fill {
    display: flex;
    flex-direction: column;
    background: var(--simple-table-background) !important;
    .q-checkbox {
      color: var(--modal-form-card-color);
    }
    .q-checkbox__inner {
      color: var(--modal-form-card-color);
    }
    .form-field-label {
      white-space: normal;
      position: relative;
    }
    .q-stepper__content {
      flex-grow: 10000;
      background: var(--simple-table-background) !important;
    }
    .q-stepper__header {
      background: var(--simple-table-background) !important;
      border-radius: 0;

    }
    .q-stepper__header--standard-labels .q-stepper__tab {
      justify-content: center !important
    }
    .q-panel.scroll {
      overflow-y: unset;
    }
  }

</style>