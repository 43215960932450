<template lang='pug'>
  div(class="index-page dashboard")

    div(class="trouble-shooting-container" v-if="current_branch")
      div(class="trouble-shooting-message" style="padding-left: 10px; padding-right: 10px;")
        span {{current_branch}}

    //div(class="trouble-shooting-container")
        div(class="trouble-shooting-message" style="padding-left: 10px; padding-right: 10px;")
          span {{trouble_shooting_message}}

    div(v-if="!loading && loading_data" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")


    div(v-if="!loading")
      common-header(v-show="!loading_data"
                  @reload-data="reloadData"
                  :parentData="{data: dashboard_data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: header_actions}")

    div(v-if="!loading" class="quantities-carousel-block")
      div(v-show="!loading_data")
        quantities-carousel(ref="quantities-carousel" :parentData="{issues_total_count: dashboard_data.boards.issues_count, issues_count_by_status: dashboard_data.boards.issues_count_by_status}")

    div(v-if="!loading" class="middle-block")
      div(v-if="!loading_data" class="middle-left-block")
        div(class="dashboard-card customer-satisfaction")
          div(class="dashboard-card-label")
            span {{dashboard_data.boards.rates_average_by_day.title}}
          apex-chart(ref="apex-chart" :parentData="{chart_data: dashboard_data.boards.rates_average_by_day}")

      div(v-show="!loading_data" class="middle-right-block")
        dashboard-links(:parentData="{data: dashboard_data.boards.dashboard_links}")


    div(v-if="!loading" class="footer-block")

      div(v-if="!loading_data" class="footer-donuts-block")
        div(class="dashboard-card chart-card")
          donut-chart(:parentData="{data: dashboard_data.boards.obligations_execution, key: 'obligations_execution'}")
        div(class="dashboard-card chart-card")
          donut-chart(:parentData="{data: dashboard_data.boards.work_efficiency, key: 'work_efficiency'}")
        div(class="dashboard-card chart-card")
          donut-chart(:parentData="{data: dashboard_data.boards.rejected_percentage, key: 'rejected_percentage'}")
        div(class="dashboard-card chart-card")
          donut-chart(:parentData="{data: dashboard_data.boards.reworked_percentage, key: 'reworked_percentage'}")

      div(v-show="!loading_data" class="footer-posts-block")
        div(class="dashboard-card posts-card")
          div(class="dashboard-card-label")
            span {{dashboard_data.boards.posts.title}}
          dashboard-posts(:parentData="{posts: dashboard_data.boards.posts.data}")


    ui-prompts(v-if="!loading_data" :parentData="{areas_key: grid}")

</template>

<script>
  import commonHeader from '../../shared/helpers/Header'
  import quantitiesCarousel from './quantities/quantitiesCarousel'
  import dashboardLinks from './links/dashboardLinks'
  import dashboardPosts from './posts/dashboardPosts'
  import donutChart from './charts/donutChart'
  import apexChart from './charts/apexChart'
  import chartJs from './charts/chartjsChart'
  import uiPrompts from '../../shared/ui_prompts/index.vue'

  export default {
    data: function () {
      return {
        grid: 'dashboards',
        item: 'dashboard',
        path: this.$store.state.paths,
        dashboard_data: {},
        loading: true,
        loading_data: true,
        header_actions: [
          'project_filter_form'
        ],

        trouble_shooting_message: "Статистика временно отключена, приносим свои извинения.",
        current_branch: '',
      }
    },

    components: {
      commonHeader,
      quantitiesCarousel,
      dashboardLinks,
      dashboardPosts,
      donutChart,
      apexChart,
      chartJs,
      uiPrompts
    },

    watch: {
      loading(newVal, oldVal) {
        this.$emit('set-loading', this.loading)

        setTimeout(() => {
          this.loading_data = newVal
        }, 400)
      }
    },

    methods: {

      reloadData() {
        this.loadDashboardData()
      },

      loadDashboardData(params={}) {

        if (this.filters) { params.filters = this.generateFiltersParams() }

        this.$backend.index(this.path.dashboard_data, { params: params })
        .then(({ data }) => {
          this.dashboard_data = data
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
      },

      loadCurrentBranch() {
        this.$backend.index(this.path.current_branch)
            .then(({ data }) => {
              this.current_branch = data["current_branch"];
            })
            .catch((error) => {
              console.log(error);
            });
      },
    },

    created() {
    },

    mounted() {
      this.$root.$on('reload-dashboard-data', () => {
        this.loadDashboardData()
      })
    },

    beforeMount() {
      this.$emit('expand-class', {val: true, class: 'dashboard-background'});
      this.loadCurrentBranch();
      this.loadDashboardData()
    }
  }
</script>

<style lang='scss'>
</style>
