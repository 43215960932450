<template lang='pug'>

  div
    div(class="form-field-label")
      span {{label}}
      span(v-show="required" class="form-field-require-tag" style="color:red;" :title="notifies.required_field")
        |*

    div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]" :id="name")
      datetime(:ref="name" :type="type"
                v-model="currentField"
                :phrases="phrases"
                value-zone="Europe/Moscow"
                :min-datetime="minDate"
                @input="onInput"
                @close="onClose"
                :input-id="name")

        template(slot="before")
          inline-svg(class="datetime-icon" :src="require(`../../../../assets/icons/calendar.svg`)")

      q-icon(name="cancel" v-if="currentField" @click.stop="resetField()" class="cancel-datetime-field")

    span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}


</template>

<script>
  import { Datetime } from 'vue-datetime';

  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        type: this.parentData.data[0].type,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        depend_from: this.parentData.data[0].depend_from || {},
        conditions: this.parentData.data[0].conditions || [],
        required: this.parentData.data[0].require,
        watch: this.parentData.data[0].watch || {},
        minDate: this.parentData.data[0].min_date,
        valid_error_message: null,
        main_class: 'form-field form-date',
        phrases: {}
      }
    },

    components: {
      Datetime
    },

    props: {
      parentData: Object
    },

    computed: {

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            return form_field['field']
          } else {
            return undefined
          }
        },
        set(value) {
          let result = {}

          if (value && value.length > 0) {
            result['field'] = value
            result['invalid'] = this.invalid(value)
          } else {
            result['invalid'] = this.invalid()
          }
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})

          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {
              grid_name: this.grid,
              field: 'invalid_fields',
              value: this.name
            })
          }
        }
      },

      fieldDependency() {
        return this.checkDependency()
      }
    },

    watch: {
      fieldDependency(newVal, oldVal) {},
    },

    methods: {

      onInput(val) {
        if (this.watch && this.watch['conditions'] && this.watch['conditions'].length > 0) {
          this.watch['conditions'].forEach(condition => {

            if (!(this.value && this.value === this.currentField)) {

              if (val && val.length > 0) {
                if (!this.checkCondition(condition, val)) {
                  this.$nextTick(() => {
                    this.resetField()
                  })
                }
              }
            }
          })
        }
      },

      onClose() {
        this.temporaryFixSafariPopupBug('close', 'form')
      },

      setField(val) {
        this.currentField = val
      },

      resetField() {
        this.currentField = ''
      },

      stackLabelCheck() {
        return this.currentField !== null && this.currentField !== undefined && this.currentField !== ''
      },

      loadValue(params, parent_params) {

        if (Object.keys(parent_params).length > 0) {
          this.$backend.index(this.$store.state.paths[parent_params['path']] + parent_params['action'], { params: params })
          .then(({ data }) => {
            this.currentField = data[parent_params['result_key']]
          })
          .catch((error) => {
            console.log(error);
            if (error.response) { this.reLogin(error.response.status) }
            this.error = true
          })
          // .finally(() => {
          // })
        }
      },

      checkCondition(condition, current_value) {
        if (Object.keys(condition).length === 0) {
          return true

        } else {
          let result = false
          let operator = condition['expect_result']
          let message = condition['message']
          let condition_value = this.getConditionValueByField(condition['condition_field'])

          if (operator && condition_value) {

            if (operator === '<') {
              result = Date.parse(current_value) < condition_value
              if (!result) {
                this.$q.notify(message)
              }
              return result

            } else if (operator === '>') {
              result = Date.parse(current_value) > condition_value
              if (!result) {
                this.$q.notify(message)
              }
              return result

            } else if (operator === '<=') {
              result = Date.parse(current_value) <= condition_value
              if (!result) {
                this.$q.notify(message)
              }
              return result

            } else if (operator === '>=') {
              result = Date.parse(current_value) >= condition_value
              if (!result) {
                this.$q.notify(message)
              }
              return result

            } else if (operator === '===') {
              result = Date.parse(current_value) === condition_value
              if (!result) {
                this.$q.notify(message)
              }
              return result

            } else {
              return result
            }
          } else {
            return true
          }
        }
      },

      getConditionValueByField(val) {
        if (val === 'current_datetime') {
          let now = new Date();
          return now.setMinutes(now.getMinutes() - 1)

        } else {
          let field = this.currentForm[val]
          if (field) {
            return Date.parse(field['field'])
          }
        }
      },

      invalid(val=undefined) {
        if (this.required) {
          if (val && val.length > 0) {
            this.valid_error_message = null
            return false
          } else {
            this.valid_error_message = this.notifies.not_empty
            return true
          }
        } else {
          this.valid_error_message = null
          return false
        }
      },
    },

    created() {
      this.watchSimpleFieldParents()
    },

    beforeMount() {
      this.$store.commit('createFormField', {grid_name: this.grid, field: this.name})
      this.setField(this.value)
      this.setDateFieldLocale()
      this.setDateFieldPhrases()
    },

    mounted() {
      this.temporaryFixSafariPopupBug('open', 'form')
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/date";

</style>