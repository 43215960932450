<template lang="pug">
  table(class="tech-map-tab-table")
    thead
      tr
        th
          span(class="column-header-title") Обозн.
        th
          span(class="column-header-title") Наим.
        th
          span(class="column-header-title") Техн. Хар-ки
        th
          span(class="column-header-title") Комментарий
    tbody
      tr(v-for="item in components")
        td {{item.code || "–"}}
        td {{item.name || "–"}}
        td(class="properties-td")
          table(class="properties-table")
            tbody
              tr(v-for="prop in item.properties")
                td {{prop.name || "–"}}
                td {{prop.value || "–"}}
        td {{item.comment || "–"}}
</template>

<script>

export default({
  data(){
    return{
      components: this.parentData.tabs
    }
  },

  props: {
    parentData: Object
  },

  mounted(){
  }
})
</script>
