<template lang='pug'>

    div(v-show="hide_table" class="hidden-tabs-message noselect")
      span {{message}}

</template>

<script>

  export default {
    data: function () {
      return {
        hide_table: this.parentData.hide_table,
        message: ''
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    computed: {
    },

    methods: {

      loadHiddenMsg(params={}) {
        this.$backend.index(this.$store.state.paths['hidden_message'], { params: params })
        .then(({ data }) => {
          this.message = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
      },

      hideTable(val) {
        this.hide_table = val
        if (this.hide_table) {
          this.loadHiddenMsg()
        }
      },
    },

    created() {
      this.$root.$on('reload-hidden-message', () => {
        if (this.hide_table) {
          this.loadHiddenMsg()
        }
      })
    }
  }
</script>

<style lang="scss">

</style>