<template lang='pug'>

  div
    q-btn(flat no-caps
          :class="[active_button ? btn_class + ' active-btn' : btn_class]"
          @click="onClickFn(el, item)")
      template(slot="default")
        inline-svg(:class="[btn_class + '-icon']"
                    :src="require(`../../../../../assets/icons/selected-item-form/${active_button ? 'up_arrow.svg' : 'down_arrow.svg'}`)")


</template>

<script>
  export default {
    data: function () {
      return {
        btn_class: 'selected-item-button',
        active_button: false,
        el: this.parentData.el,
        item: this.parentData.item
      }
    },

    props: {
      parentData: Object
    },

    computed: {
    },

    methods: {

      onClickFn(el, item) {
        this.active_button = !this.active_button
        this.$emit('on-click', {el: el, item: item})
      }
    },

    created() {
    },

    beforeMount() {
    }
  }
</script>

<style lang="scss">
</style>