<template lang='pug'>
  div(class='entity-view-tabs')
    div(v-for='itm in items' :class='itemClass(itm)' @click='$emit("click", itm.key)') {{ itm.title }}

</template>

<script>
  export default {
    props: {
      items: Array, // { key: 123, title: '456' }
      active: [String, Number], // key of the active item
    },

    methods: {
      itemClass(item) {
        const mainClass = 'entity-view-tabs__tab';
        const activeClass = 'entity-view-tabs__tab--active';
        return item.key === this.active ? `${mainClass} ${activeClass}` : mainClass;
      }
    },
  }
</script>

<style lang="scss">
.entity-view-tabs {
  display: flex;

  &__tab {
    margin-top: 20px;
    margin-bottom: 20px;

    font-size: 15px;
    line-height: 22px;
    flex: 1;
    color: var(--tab-text-color);
    text-align: center;
    border-bottom: 2px solid var(--tabs-border-bottom-color);
    padding-bottom: 7px;
    cursor: pointer;

    &--active {
      cursor: auto;
      border-bottom: 3px solid #5B7AFF;
    }
  }
}
</style>
