<template lang='pug'>

  div(class="sensors-block")

    div(v-if="loading" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")

    swiper(v-show="!loading && sensors.length > 0" ref="sensorsSwiper" :options="swiperOption" class="sensors-wrapper main-swiper")
      div(:class="`card sensor-badge swiper-slide ${i === 0 ? 'first-badge' : ''}`"
          v-for="(sensor, i) in sensors" :key="sensor.id"
          :id="`sensor-badge-${sensor.id}`"
          @click="onClickSensor(sensor)")
        div(class="card-wrapper")
          div(class="icon" :style="{'background-color': sensor.color}")
            inline-svg(:src="sensor.icon.url")
          div(class="title")
            span {{sensor.title}}
          div(class="charge")
            i(class="fas fa-battery-three-quarters")
            span {{sensor.battery_charge + '%'}}
          div(v-if="sensor.link_quality" class="deviation")
            inline-svg(:src="require(`../../../../assets/icons/telemetry/deviation.svg`)")
            p 
              | Отклонение
              br
              span {{`${sensor.link_quality}`}}

      div(v-if="empty_sensors.length > 0" class="card empty-sensor-badge swiper-slide" v-for="empty_sensor in empty_sensors")
        div(class="card-wrapper")
          //div(class="empty-sensor-icon")
            inline-svg(:src="require(`../../../../assets/icons/telemetry/empty_sensor.svg`)")
          //div(class="empty-sensor-title")
            span нет данных

      div(v-show="sensors.length > 2" class="swiper-pagination" slot="pagination")

    grid-hidden-msg(v-show="!loading && !sensors.length > 0" ref="sensors-hidden-msg" :parentData="{hide_table: hide_table}")

</template>

<script>
  import { Swiper } from 'vue-awesome-swiper';
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'

  export default {
    data: function () {
      return {
        sensors: [],
        empty_sensors: [],
        sensor: {},
        options: {
          spaceBetween: 30,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          slidesPerColumn: 2,
          grabCursor: true,
          loop: false,
          freeMode: true,
          mousewheel: {
            forceToAxis: true
          },
          autoplay: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        loading: true,

        hide_table: false
      }
    },

    computed: {
      swiperOption: {
        get() {
          return this.options
        },

        set(attr) {
          this.options[attr['key']] = attr['value']
        }
      }
    },

    watch: {
      sensor(newVal, oldVal) {
        this.$emit('load-indications', {sensor_id: newVal && newVal.id || ''})
      },

      // sensors(newWidth, oldWidth) {
      //   if (newWidth && newWidth.length < 2) {
      //     this.swiperOption['slidesPerColumn'] = 1
      //   } else {
      //     this.swiperOption['slidesPerColumn'] = 2
      //   }
      // }
    },

    props: {
      parentData: Object
    },

    components: {
      Swiper,
      gridHiddenMsg
    },

    methods: {

      onClickSensor(sensor) {
        if (sensor) {
          let attr = {
            item: 'sensor',
            new_item: sensor
          }
          this.activeBadge(attr)
          this.sensor = sensor
        }
      },

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['sensors-hidden-msg']) {
          this.$refs['sensors-hidden-msg'].hideTable(val)
        }
      },

      loadSensors(params={}) {
        this.loading = true

        this.$backend.collection(`${this.$store.state.paths.telemetry}/sensors`, params)
        .then(({ data }) => {
          this.empty_sensors = []
          this.sensors = data
          this.sensor = this.sensors[0]

          if (data && data.length < 2) {
            this.swiperOption['slidesPerColumn'] = 1
          } else {
            this.swiperOption['slidesPerColumn'] = 2
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.loading = false

          setTimeout(() => {
            if (this.$refs.sensorsSwiper) { this.$refs.sensorsSwiper.$swiper.slideTo(0, 600, false) }
          },300)

          if (this.sensors.length > 0) {
            this.hideTable(false)
            let attr = {
              item: 'sensor',
              new_item: this.sensors[0]
            }
            this.activeBadge(attr)
            this.addEmptySensors()

          } else {
            this.hideTable(true)
            this.$emit('load-indications', {sensor_id: ''})
          }
        })
      },

      addEmptySensors() {
        if (this.sensors.length < 2) {
          let count = 2 - this.sensors.length
          this.empty_sensors = [...Array(count)].map(() => {return {}})
        }
      }
    }
  }
</script>

<style lang='scss'>

  .sensors-block {
    display: flex;
    height: 390px;

    .hidden-tabs-message {
      height: inherit;

      span {
        padding-top: initial;
      }
    }

    .swiper-wrapper {
      width: inherit;
    }

    .main-swiper {
      width: 100%;

      .swiper-pagination-bullet {
        background: #007AFF;
      }
    }

    .empty-sensor-badge {
      background: transparent !important;
      /*border: 1px solid #E4E4E4;*/
      /*box-sizing: border-box;*/

      .card-wrapper {
        /*padding: 15px;*/
        /*text-align: center;*/

        /*.empty-sensor-icon {*/
          /*margin-top: 5px;*/

          /*svg {*/

            /*path {*/
              /*fill: #E4E4E4;*/
            /*}*/
          /*}*/
        /*}*/

        /*.empty-sensor-title {*/
          /*padding: 10px 0 10px 0;*/

          /*span {*/
            /*font-style: normal;*/
            /*font-weight: 600;*/
            /*font-size: 12px;*/
            /*line-height: 18px;*/
            /*color: #E4E4E4;*/
          /*}*/
        /*}*/
      }
    }

    .sensor-badge {
      cursor: pointer;
    }

    .first-badge {
      margin-top: 0 !important;
    }

    .active-badge {
      background: linear-gradient(180deg, #A9B4EC 0%, #738DE1 100%) !important;

      .title {
        span {
          color: #ffffff !important;
        }
      }
      .charge{
        color: #ffffff !important;
      }
    }

    .card {
      height: 148px;
      width: 152px;

      .card-wrapper {
        padding: 15px;
      }

      .icon {
        position: relative;
        width: 40px;
        height: 40px;
        /*background-color: #3580FF;*/
        border-radius: 50%;

        svg {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .title {
        padding: 10px 0 10px 0;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 21px;
          color: var(--dashboard-label-color);
        }
      }

      .charge{
        position: absolute;
        right: 10px;
        top: 15px;
        color: var(--dashboard-label-color);

        span{
          margin-left: 5px;
        }
      }
                      
      .deviation {
        position: absolute;
        right: 5px;
        top: 35px;
        z-index: 1;

        >p {
          display: none;
          position: absolute;
          width: fit-content;
          text-align: center;
          transform: translateX(-50%);
          left: 50%;
          transform: translateX(-50%);
          background-color: #343442;
          color: #E8E8E8;
          z-index: 1;
          box-shadow: 0px 1px 2px rgba(89, 89, 89, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 1px 8px rgba(73, 73, 73, 0.1);;
          border-radius: 5px;
          padding: 2px 8px;
          margin-top: 5px;

          span{
            white-space: nowrap;
          }

          &::before{
            content: '';
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #343442;
            left: 50%;
            transform: translateX(-50%);
            top: -8px;
            position: absolute;
          }
        }

        &:hover {
          >p {
            display: block;
          }
        }
      }
    }
  }

</style>