<template lang='pug'>

  div(class="indications-block")
    div(class="card")
      div(class="card-label")
        span {{data.title}}

      div(v-if="loading" class="spinner-container grid-spinner")
        q-spinner(color="primary" size="3em")

      swiper(v-show="!loading && indications.length > 0" ref="indicationsSwiper" :options="options" class="indications-wrapper main-swiper")
        div(:class="`indication-card indication-badge swiper-slide ${i === 0 ? 'first-badge' : ''}`"
            v-for="(indication, i) in indications" :key="indication.id"
            :id="`indication-badge-${indication.id}`"
            :style="{'background': `linear-gradient(180deg, ${indication.color}40 0%, rgba(246, 247, 251, 0) 70%)`}"
            @click="onClickIndication(indication)")
          div(class="card-wrapper")
            div(class="card-top")
              div(class="icon" :style="{'background-color': indication.color}")
                inline-svg(:src="indication.icon.url")
              div(class="values")
                //span(class="value") {{`${indication.value || '144'} ${indication.measure_unit || ''}`}}
                div(class="value-data")
                  span(class="value") {{!indication.value && indication.value !== 0 ? '' : indication.value}}
                  span(class="unit") {{indication.measure_unit || ''}}
                span(class="current-time") {{indication.current_time}}
              div(v-if="indication.deviation" class="deviation")
                inline-svg(:src="require(`../../../../assets/icons/telemetry/deviation.svg`)")
                p 
                  | Отклонение
                  br
                  span {{`min ${indication.min_value} ${indication.measure_unit} max ${indication.max_value} ${indication.measure_unit}`}}

            div(class="title")
              span {{indication.title}}
              span(class="no-data") {{!indication.value && indication.value !== 0 ? 'нет показаний' : ''}}

            div(class="icon bottom-icon")
              inline-svg(:id="`title-icon-${indication.id}`" :src="require(`../../../../assets/icons/telemetry/${!indication.value && indication.value !== 0 ? 'straight' : 'curve'}.svg`)")


        div(v-if="empty_indications.length > 0" class="empty-indication-card swiper-slide" v-for="empty_indication in empty_indications")
          div(class="card-wrapper")
            //div(class="icon")
              inline-svg(:src="require(`../../../../assets/icons/telemetry/empty_sensor.svg`)")
            //div(class="title")
              span нет данных

        div(class="swiper-pagination" slot="pagination")

      grid-hidden-msg(v-show="!loading && !indications.length > 0" ref="indications-hidden-msg" :parentData="{hide_table: hide_table}")



</template>

<script>
  import { Swiper } from 'vue-awesome-swiper';
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'

  export default {
    data: function () {
      return {
        intervals: [],
        indications: [],
        item: {},
        sensor_id: '',
        empty_indications: [],
        options: {
          spaceBetween: 30,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          slidesPerColumn: 2,
          grabCursor: true,
          loop: false,
          freeMode: true,
          mousewheel: {
            forceToAxis: true
          },
          autoplay: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        loading: true,

        hide_table: false
      }
    },

    computed: {
      data() {
        return this.parentData.data
      },

      indication: {
        get() {
          return this.item
        },
        set(value) {
          this.item = value
        }
      },

      sensor() {
        return this.sensor_id
      },
    },

    watch: {
      sensor(n, o) {

        // console.log('value:', n, 'sensor_id:', this.sensor, 'intervals_length:', this.intervals.length)

        if (n && !this.intervals.length > 0) {
          this.intervals.push(this.indicationsInterval())
        }
      }
    },

    components: {
      Swiper,
      gridHiddenMsg
    },

    props: {
      parentData: Object
    },

    methods: {

      onClickIndication(indication) {
        if (indication) {
          let attr = {
            item: 'indication',
            new_item: indication
          }
          this.activeBadge(attr)
          this.indication = indication
          this.$emit('load-indications-by-hours', {sensor_id: this.sensor, id: this.indication.id})
        }
      },

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['indications-hidden-msg']) {
          this.$refs['indications-hidden-msg'].hideTable(val)
        }
      },

      loadIndications(params={}) {
        if (!params['interval_reload']) {
          this.loading = true
        }

        if (params['sensor_id']) {

          this.sensor_id = params['sensor_id']

          this.$backend.collection(`${this.$store.state.paths.telemetry}/indications`, params)
          .then(({ data }) => {
            this.empty_indications = []
            this.indications = data

            if (!params['interval_reload']) {
              this.indication = this.indications[0]
            }

          })
          .catch((error) => {
            console.log(error);
            if (error.response) { this.reLogin(error.response.status) }
          })
          .finally(() => {

            this.loading = false

            setTimeout(() => {
              if (this.$refs.indicationsSwiper) { this.$refs.indicationsSwiper.$swiper.slideTo(0, 600, false) }
            },300)

            if (this.indications.length > 0) {
              this.hideTable(false)
              let attr = {
                item: 'indication',
                new_item: this.indications[0]
              }

              if (!params['interval_reload']) {
                this.activeBadge(attr)
                this.fillTitleIcons()
              }
              this.addEmptyIndications()

              let child_params = {sensor_id: this.sensor, id: this.indication.id}
              child_params['interval_reload'] = params['interval_reload'] || false
              this.$emit('load-indications-by-hours', child_params)

            } else {
              this.hideTable(true)
            }
          })
        } else {
          this.loading = false
          this.empty_indications = []
          this.indications = []
          this.indication = {}
          this.sensor_id = ''
          this.hideTable(true)

          this.destroyIntervals()

          this.$emit('load-indications-by-hours', {sensor_id: this.sensor, id: ''})
        }
      },

      fillTitleIcons() {
        setTimeout(() => {
          this.indications.forEach(i => {
            let el = document.getElementById(`title-icon-${i.id}`)
            if (el) {
              let path = el.getElementsByTagName('path')[0]
              if (path) {
                path.style.fill = i.color
              }
            }
          })
        }, 500)
      },

      addEmptyIndications() {
        if (this.indications.length < 2) {
          let count = 2 - this.indications.length
          this.empty_indications = [...Array(count)].map(() => {return {}})
        }
      },

      indicationsInterval() {
        return setInterval(() => {
          // console.log('interval')
          if (this.sensor) {
            // console.log('interval;   sensor:', this.sensor, 'indication:', this.indication.id, 'intervals_length:', this.intervals.length)


            let params = {
              sensor_id: this.sensor, id: this.indication.id, interval_reload: true
            }
            this.loadIndications(params)
            // this.$emit('load-indications-by-hours', {sensor_id: this.sensor, id: this.indication.id, interval_reload: true})
          }
        }, 15000);
      }
    },

    mounted() {
      this.intervals.push(this.indicationsInterval())
    },

    beforeDestroy() {
      this.destroyIntervals()
    }
  }
</script>

<style lang='scss'>

  .indications-block {
    height: 100%;

    .card-label {
      padding-bottom: 30px !important;
    }

    .card {
      padding: 20px;
      height: inherit;

      .hidden-tabs-message {
        height: 90%;

        span {
          padding-top: initial;
        }
      }

      .spinner-container.grid-spinner {
        height: auto;
      }

      .main-swiper {
        height: 365px;

        .swiper-pagination-bullet {
          background: #007AFF;
        }
      }

      .empty-indication-card {
        width: 130px;
        height: 130px;
        background: transparent;
        /*border: 1px solid #E4E4E4;*/
        /*box-sizing: border-box;*/
        /*border-radius: 20px;*/

        .card-wrapper {
          /*padding: 15px;*/
          /*text-align: center;*/

          /*.icon {*/
            /*margin-top: 5px;*/

            /*svg {*/

              /*path {*/
                /*fill: #E4E4E4;*/
              /*}*/
            /*}*/
          /*}*/

          /*.title {*/
            /*padding: 10px 0 10px 0;*/

            /*span {*/
              /*font-style: normal;*/
              /*font-weight: 600;*/
              /*font-size: 12px;*/
              /*line-height: 18px;*/
              /*color: #E4E4E4;*/
            /*}*/
          /*}*/
        }
      }

      .active-badge {
        /*border: 1px solid #454545 !important;*/
        background: linear-gradient(205.31deg, #FFECB5 -23.7%, #A8B3EC -23.69%, #6644FF 83.95%) !important;

        .current-time {
          color: #ffffff !important;
        }

        .value-data {
          .value {
            color: #ffffff !important;
          }

          .unit {
            color: #ffffff !important;
          }
        }

        .title {

          span {
            color: #ffffff !important;
          }

          .no-data {
            color: #ffffff !important;
          }
        }
      }

      .first-badge {
        margin-top: 0 !important;
      }

      .indication-card {
        min-height: 130px;
        max-height: 140px;
        width: 130px;
        /*background: var(--indication-card-background);*/
        border: var(--indication-card-border);
        box-sizing: border-box;
        border-radius: 20px;
        cursor: pointer;

        .card-wrapper {
          padding: 15px;

          .card-top {
            display: flex;

            .values {
              padding-left: 10px;
              display: grid;

              .value-data {
                .value {
                  white-space: nowrap;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  color: var(--indication-value-data);
                }

                .unit {
                  white-space: nowrap;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 9px;
                  line-height: 18px;
                  color: var(--indication-value-data);
                  padding-left: 5px;
                }
              }

              .current-time {
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 16px;
                color: var(--indication-current-time);
              }
            }
          }

          .icon {
            position: relative;
            width: 28px;
            height: 28px;
            border-radius: 8px;

            svg {
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }
          }

          .bottom-icon {
            position: absolute;
            bottom: 5px;
          }
          
          .deviation {
            position: absolute;
            right: 5px;

            >p {
              display: none;
              position: absolute;
              width: fit-content;
              text-align: center;
              transform: translateX(-50%);
              left: 50%;
              transform: translateX(-50%);
              background-color: #343442;
              color: #E8E8E8;
              z-index: 1;
              box-shadow: 0px 1px 2px rgba(89, 89, 89, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 1px 8px rgba(73, 73, 73, 0.1);;
              border-radius: 5px;
              padding: 2px 8px;
              margin-top: 5px;

              span{
                white-space: nowrap;
              }

              &::before{
                content: '';
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid #343442;
                left: 50%;
                transform: translateX(-50%);
                top: -8px;
                position: absolute;
              }
            }

            &:hover {
              >p {
                display: block;
              }
            }
          }

          .title {
            padding: 10px 0 10px 0;
            display: grid;

            span {
              white-space: wrap;
              font-style: normal;
              font-weight: 600;
              font-size: 11px;
              line-height: 18px;
              color: var(--indication-title);
            }

            .no-data {
              white-space: nowrap;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              color: var(--indication-no-data);
            }
          }
        }
      }
    }
  }

</style>