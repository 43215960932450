import Vue from 'vue';

Vue.mixin({

  computed: {

    map_locales() {
      return this.locales.map[this.current_locale]
    }
  },

  methods: {
  }
})