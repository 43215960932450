<template lang='pug'>

  div(v-if="title" class="theme-icon" :theme-icon-title="title['icon_title_' + theme]")
    inline-svg(:src="require(`../../../assets/icons/header/theme/${theme}.svg`)" @click="updateTheme()")

</template>

<script>

  export default {
    data: function () {
      return {
        path: this.$store.state.paths['user'],
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      title() {
        if (this.locales['theme']) {
          return this.locales['theme'][this.current_locale]
        } else {
          return undefined
        }
      }
    },

    methods: {

      updateTheme(params={user:{}}) {
        params['user']['theme'] = this.theme === 'light'

        this.$backend.update(this.path, this.current_user.id, params)
        .then(({data}) => {
          if (data.theme && data.theme !== this.theme) {
            this.setTheme(data.theme)
          }
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }

          let base_errors = error.response.data.errors.base
          if (base_errors) {
            base_errors.forEach(e => {
              this.$q.notify(e.message)
            })
          } else {
            this.$q.notify(this.notifies.error_ask_admin)
          }

          console.log(error)
        })
      },
    }
  }
</script>

<style lang='scss'>
</style>