<template lang='pug'>

  div(class="equipment-tech-map")

    div(v-if="loading" class="spinner-container form-spinner")
      q-spinner(color="primary" size="3em")

    div(v-if="!loading" class="tech-map-card")

      div(class="main-block")

        div(v-if="data.qr" class="qr" v-html="data.qr")

        div(class="actions-block")
          div(class="tech-map-title")
            div(class="title")
              span {{data.title}}
            div(class="sub-title")
              span {{data.sub_title}}

          div(class="spacer")

          div(class="show-card-actions")
            div(v-for="button in buttons" :class="'button-wrapper ' + button.name" :style="button.style.wrapper")
              q-btn(flat no-caps
                    class="show-card-action"
                    @click="onClickFn(button)"
                    :style="button.style.button")
                template(v-if="button.icon && button.icon.length > 0" slot="default")
                  inline-svg(:class="[button.name + '-icon']" :style="button.style.icon" :src="require(`../../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`)")
                template(v-if="button.label && button.label.length > 0" slot="default")
                  span(class="action-label") {{button.label}}


      div(class="techmap-info-blocks")
        div(v-if="loading_blocks" class="spinner-container form-spinner")
          q-spinner(color="primary" size="3em")

        div(v-if="!loading_blocks" class="specification-block")
          div(class="specification-title")
            span {{data.specification}}
          specification(:parentData="{buttons: data['edit_tech_map_buttons'], data: row['technological_map']['specification'], editable: editable, grid: grid, row_id: row_id}")

        div(v-if="!loading_blocks" class="equipment-show-tabs tabs-block")
          q-tabs(v-model="tab.name" align="left")
            q-tab(v-for="tab in data.tabs" :key="tab.name"
                  v-if="tabCondition(tab.name)"
                  @click="addCurrentTab(tab)"
                  :name="tab.name"
                  :label-slot="false")
              template(v-if="tab.label" slot="default")
                span() {{tab.label}}

          q-tab-panels(v-model="tab.name" class="equipment-show-tabs-panels")
            q-tab-panel(v-for="tab in data.tabs"
                        v-if="tabCondition(tab.name)"
                        :key="tab.name"
                        :name="tab.name")
              component(:is="getTabPanel(tab.name)"
                        :parentData="{buttons: data['edit_tech_map_buttons'], data: getData(tab.name), tab_data: tab.data, editable: editable, grid: grid, row_id: row_id}")

      div(v-if="!loading" v-show="false" id="print-equipment-tech-map")
        component(:is="getShowComponent('print_equipment_tech_map')"
                  :parentData="{titles: data.titles, data: data.print_equipment_tech_map, row: row}")

      div(v-if="!loading" v-show="false" id="print-qr")
        component(:is="getShowComponent('print_qr')"
                  :parentData="{data: data.print_qr}")

      div(v-if="editable" class="button-group row justify-center")
        q-btn(flat no-caps class="tech-map-form-submit" :label="data['edit_tech_map_buttons']['submit']['label']" @click="onSubmit()")

</template>

<script>
  import specification from './blocks/specification'
  import tabPanels from './blocks/tabs/tabs_panels'

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        item: 'ppr_equipment',
        row_id: this.parentData.row_id,
        tab_name: this.parentData.tab_name,
        row: {},
        data: [],
        editable: false,
        loading: true,
        loading_blocks: false,
        tab: ''
      }
    },

    computed: {
      buttons() {
        return this.data.actions
      },

      tech_map_data() {
        let grid = this.$store.state.grid[this.grid]
        if (grid) {
          return grid['tech_map']
        } else {
          return undefined
        }
      }
    },

    watch: {
      tech_map_data(newVal, oldVal) {}
    },

    props: {
      parentData: Object
    },

    components: {
      specification,
      tabPanels
    },

    methods: {

      tabCondition(tab_name) {
        return tab_name !== 'issues' || (tab_name === 'issues' && this.getData(tab_name).length > 0)
      },

      getData(tab_name) {
        if (tab_name) {
          if (tab_name === 'issues') {
            return this.row[tab_name]
          } else {
            return this.row['technological_map'][tab_name]
          }
        }
      },

      onSubmit(params={}) {
        let attr = {
          technological_map: JSON.stringify(this.tech_map_data)
        }
        params[this.item] = attr

        this.loading = true

        this.$backend.update(this.path, this.row_id, params)
        .then(({data}) => {
          this.row = data
          this.editable = false
          this.loading = false

          this.loadData()

          this.$q.notify(this.notifies.success_updated_entry)
        })
        .catch((error) => {
          console.log(error)
          if (error.response) { this.reLogin(error.response.status) }
          this.loading = false
          this.$q.notify(this.notifies.error_ask_admin)
        })
      },

      getTabPanel(key) {
        if (key) {
          return tabPanels[key]
        }
      },

      addCurrentTab(tab) {
        this.tab = tab
      },

      onClickFn(button) {
        if (button.onclick) {
          if (button.onclick.child) {
            this.buttons.find(b => b.name == button.name).style.wrapper.display = 'none'
            this.buttons.find(b => b.name == button.onclick.child).style.wrapper.display = 'initial'
          } else if (button.onclick.parent) {
            this.buttons.find(b => b.name == button.name).style.wrapper.display = 'none'
            this.buttons.find(b => b.name == button.onclick.parent).style.wrapper.display = 'initial'
          }

          if (button.onclick.fn_name) {
            this[button.onclick.fn_name](button.onclick.attr)
          }
        }
      },

      setEditable(attr) {
        this.editable = attr.value
        if (!attr.value) {
          this.loading_blocks = true
          this.loadData()
        }
      },

      loadData(params={}) {
        this.$backend.show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.row = data['common']['data']
          this.data = data['common']['tabs'].find(tab => tab.name === this.tab_name)['data']
          this.tab = this.data.tabs[0]

          this.$nextTick(() => {
            let keys = Object.keys(this.row.technological_map)
            if (keys.length > 0) {
              keys.forEach(key => {


                // >> Temporary solution, shall to remove it later, the reason is old type of tech_map hash
                if (key === 'specification') {
                  if (this.row['technological_map'][key].map(s => s.required).length > 0) {
                    this.row['technological_map'][key].map(s => delete s.required)
                  }
                  if (this.row['technological_map'][key].filter(el => el.name).length > 0) {
                    this.row['technological_map'][key] = this.rewriteKeysTempLogic(this.row['technological_map'][key])
                  }

                } else {
                  this.row['technological_map'][key].forEach(key_el => {
                    if (key_el['properties'].filter(el => el.name).length > 0) {
                      key_el['properties'] = this.rewriteKeysTempLogic(key_el['properties'])
                    }
                  })
                }
                // <<


                this.$store.commit('addEquipmentTechMap', {grid_name: this.grid, tech_map_key: key, attr: this.row['technological_map'][key]})
              })
            }
          })
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.loading = false
          this.loading_blocks = false
        })
      },

      rewriteKeysTempLogic(obj) {
        return obj.map(el => {
          return Object.keys(el).reduce((r, key) => {
            if (key === 'name') {
              r['key'] = el[key]
            } else {
              r[key] = el[key]
            }
            return r
          }, {})
        })
      }
    },

    created() {
      this.$store.commit('initialEquipmentTechMap', {grid_name: this.grid})
      this.loadData()
    }
  }
</script>

<style lang='scss'>
</style>