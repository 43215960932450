<template lang='pug'>
  div(class="form-icon-area")
    div(class="form-field-label")
      span {{label}}
      span(v-show="required" class="form-field-require-tag" style="color:red;" :title="notifies.required_field")
        |*

    q-btn(flat class="btn-icon" @click="onClick()")
      img(v-if="!useIconPicker && currentIconPath" class="selected-icon" :src="currentIconPath")
      q-icon(v-if="useIconPicker" :name="currentIcon")

    q-dialog(v-model="modal")
      q-banner(class="form-icon-area-banner" id="banner-dialog")
        template(slot="avatar")

          div(v-if="!useIconPicker" class="form-icon-area selectable-icon-area")
            span(class="selectable-icon-wrapper" v-for="icon in fieldOptions" :key="icon.id" :title="icon.id")
              img(class="selectable-icon"
                   :src="icon.path"
                   @click="selectIcon(icon)")

          q-icon-picker(v-if="useIconPicker"
                        v-model="currentField"
                        class="form-icon-area"
                        icon-set="fontawesome-v5"
                        :pagination.sync="pagination"
                        style="height: 300px; width: 300px;background-color: transparent;")

</template>

<script>
  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        depend_from: this.parentData.data[0].depend_from || {},
        watch: this.parentData.data[0].watch || {},
        required: this.parentData.data[0].require,
        valid_error_message: null,
        main_class: 'form-field form-text',
        showIconPicker: false,
        pagination: {
            itemsPerPage: 60,
            page: 0
        },

        modal: false,

        options_path: this.parentData.data[0].options_path,
        optObject: {},
        options: [],
        fieldOptions: [],
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      useIconPicker() {
        return this.fieldOptions.length === 0
      },

      options_params() {
        return this.parentData.data[0].options_params || {}
      },

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
              return form_field['field']
          } else {
            return undefined
          }
        },
        set(value) {
          let result = {}
          if (value && value.length > 0) {
            result['field'] = value
            result['invalid'] = this.invalid(value)
          } else {
            result['invalid'] = this.invalid()
          }
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {grid_name: this.grid, field: 'invalid_fields', value: this.name})
          }
        }
      },

      fieldDependency() {
        return this.checkDependency()
      },

      currentIconPath() {
        let icon = this.fieldOptions.find(o => o.value === this.currentField)
        if (icon) {
          return icon['path']
        } else {
          return undefined
        }
      },

      currentIcon() {
        return this.currentField
      }
    },

    watch: {
      fieldDependency(newVal, oldVal) {},

      value () {
        this.showIconPicker = false
      },

      options_params(newVal, oldVal) {
        if (newVal['options_type'] && newVal['options_type'] !== oldVal['options_type']) {
          this.options = []
          this.loadOptions()
        }
      }
    },

    methods: {

      selectIcon(icon) {
        this.setField(icon.value)
        this.modal = false
      },

      onClick() {
        this.modal = true
        setTimeout(() => {
          let banner = document.getElementById('banner-dialog')
          if (this.useIconPicker) {
            banner.style.maxWidth = "355px"
          } else {
            banner.style.maxWidth = "500px"
          }

          banner.style.visibility = "visible"
        }, 100)
      },

      setField(val) {
        this.currentField = val
      },

      resetField() {
        this.currentField = ''
      },

      invalid(val=undefined) {
        if (this.required) {
          if (val && val.length > 0) {
              this.valid_error_message = null
              return false
          } else {
              this.valid_error_message = this.notifies.not_empty
              return true
          }

        } else {
          this.valid_error_message = null
          return false
        }
      },

      loadOptions() {
        this.$backend.collection(this.$store.state.paths[this.options_path] + '/collection', this.options_params)
        .then(({ data }) => {

          this.optObject = data
          let for_select = this.optObject.options.map(el => {

            return Object.keys(el).reduce((result, key) => {
              if (key === 'id') {
                result['value'] = el[key];
              } else if (key === 'title') {
                result['label'] = el[key];
              } else if (key === 'path') {
                result['path'] = el[key];
              }
              return result
            }, {})
          });

          this.options = for_select
          this.fieldOptions = this.options;

          let result = {}

          if (![undefined, null, ''].includes(this.value)) {
            let value = this.fieldOptions.find(e => e['value'] === this.value);
            result['field'] = value['value']
            result['invalid'] = this.invalid(value['value'])

          } else {
            result['invalid'] = this.invalid()
          }

          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
          this.error = true
        })
      }
    },

    created() {
      this.watchSimpleFieldParents()

      if (Object.keys(this.options_params).length > 0) {
        this.loadOptions()
      }
    },

    beforeMount() {
      this.$store.commit('createFormField', {grid_name: this.grid, field: this.name})
      this.setField(this.value)
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/icon";

</style>