<template lang='pug'>

  div(class="dashboard-posts")
    div(class="posts-area")
      div(v-for="post in posts" :key="post.id")
        div(class="post" @click="openModal(post)")
          div(class="post-img")
            img(:src="post && post.image_url ? post.image_url : require('../../../../assets/icons/dashboard/posts/missing_post.svg')")
          div(class="post-info")
            span(class="post-title") {{post.title}}
            span(class="post-content") {{handleDescriptionTd(post.short_content)}}
          div(class="post-date")
            span {{post.created_at}}

        div(class="posts-spacer")

    q-dialog(v-model="modal")
      post-modal(@close-modal="closeModal" :post="post")

</template>

<script>
  import postModal from './postModal'

  export default {
    data: function () {
      return {
        // posts: this.parentData.posts,
        post: {},
        modal: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      postModal
    },

    computed: {
      posts() {
        return this.parentData.posts;
      },
    },

    methods: {
      openModal(post) {
        this.post = post
        this.modal = true
      },

      closeModal() {
        this.modal = false
        this.post = {}
      }
    }
  }
</script>

<style lang="scss">
</style>