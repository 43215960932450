<template lang='pug'>

  div(class="navbar" id="navbar")

    div(class="navbar-header")
      inline-svg(class="navbar-logo" :src="require('../../assets/icons/navbar/nav-logo.svg')" @click="goToDashboard")

    div(class="navbar-body")

      ul(class="nav-list")
        div(v-for="(item, i) in commonLinks"
            class="nav-link"
            :id="`nav-${item.anchor}`")

          li(:ref="'nav-' + item.anchor" :class="{active: i === selected}" @click="clickNavLink(item, i, $event)")
            inline-svg(:src="require('../../assets/icons/navbar/nav-'+item.anchor+'.svg')" v-if="item.anchor!=='covid'")
            inline-svg(:src="covidSVGLink()" :class="covidSVGClass()" v-if="item.anchor==='covid'")
            span {{item.title}}

            div(:ref="item.anchor + '-sublinks-wrapper'" class="sublinks-wrapper" :id="`sublinks-wrapper-${i}`")
              div(class="sublinks-menu-arrow")

              div(v-if="canSearch(item, i)" class="form-field form-input sublinks-menu-search"
                  :id="`nav-link-search-${i}`")
                q-input(filled no-error-icon
                        autocomplete="off"
                        v-model="searchMsg"
                        :placeholder="navbar_locale.search"
                        :label-slot="false" :for="`nav-link-search-input-${i}`")
                  q-icon(name="cancel" v-if="searchMsg"
                          @click.stop="resetSearchMsg()"
                          class="cancel-string-field")

              div(class="sublinks-menu" :id="`sublinks-menu-${i}`")
                ul(v-if="sublinkListSearch && sublinkListSearch.length > 0" class="sublinks-list")
                  div(class="sublink" v-for="(sublink, index) in sublinkListSearch" :key="`${sublink.anchor}-${index}`")
                    li(@click.stop="clickSubLink(sublink, i)")
                      q-icon(v-if="sublink.icon_class" :name="sublink.icon_class" class="sublink-icon")
                      inline-svg(v-if="sublink.icon" :src="require('../../assets/icons/navbar/sublinks/' + sublink.icon)")
                      span {{sublink.title}}

    div(class="navbar-footer")
      div(class="signout-link")
        inline-svg(:src="require('../../assets/icons/navbar/nav-signout.svg')" @click="signOut")


    q-dialog(v-model="confirm_modal")
      confirm-modal(@confirm="confirmResult" :parentData="{message: confirm_message}")

</template>

<script>

  export default {
    data: function () {
      return {
        commonLinks: [],
        sublinkList: [],
        sublinkListSearch: [],

        selected: undefined,
        dataLoaded: false,

        sublinks_ref_key: '',
        confirm_modal: false,
        confirm_message: '',
        search: '',
        nav_link_exist: undefined
      }
    },

    components: {
      confirmModal: () => import('./helpers/confirmModal')
    },

    watch: {
      dataLoaded(newVal, oldVal) {
        if (newVal) {
          this.$emit('set-loading', false)
        }
      }
    },

    computed: {
      navbar_locale() {
        return this.locales.navbar[this.current_locale]
      },

      searchMsg: {
        get() {
          return this.search
        },
        set(value) {
          this.search = value

          if (this.search !== '') {
            this.sublinkListSearch = this.sublinkList.filter(link => {
              return link.title.toLowerCase().includes(this.search.toLowerCase())
            })

          } else {
            this.sublinkListSearch = this.sublinkList
          }
        }
      }
    },

    methods: {

      resetSearchMsg(item) {
        this.searchMsg = ""
      },

      canSearch(item, i) {
        let result = this.sublinkList.length > 15

        if (result) {
          setTimeout(() => { document.getElementById(`sublinks-menu-${i}`).style.marginTop = "68px"; }, 10)
        } else {
          setTimeout(() => { document.getElementById(`sublinks-menu-${i}`).style.marginTop = "0px"; }, 10)
        }
        return result
      },

      defineSublinks(sublinks) {
        this.sublinkList = sublinks
        this.sublinkListSearch = this.sublinkList
      },

      covidSVGLink() {
          return require('../../assets/icons/navbar/nav-covid-' + this.$store.state.theme.value + '.svg')
      },
      covidSVGClass() {
          return 'covid-svg-' + this.$store.state.theme.value
      },
      loadLinks() {
        return this.$backend.index(this.$store.state.paths.navbar, {params: {}})
        .then(({data}) => {
          this.commonLinks = data
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }
          console.log(error);
        })
      },

      clickNavLink(nav_link, i, e) {
        if (nav_link.sublinks.length > 0) {
          if (this.selected !== undefined) {

            if (this.selected === i) {
              let wrapper = document.getElementById(`sublinks-wrapper-${i}`)
              let input = document.getElementById(`nav-link-search-input-${i}`)

              if (![wrapper, input].includes(e.srcElement)) {
                this.closeSublinksMenu()
                this.hideBg()
              }

            } else {
              this.closeSublinksMenu()
              this.openSublinksMenu(nav_link, i)
            }

          } else {
            this.openSublinksMenu(nav_link, i)
          }

        } else {
          this.closeSublinksMenu()
          this.hideBg()
          this.$router.push({ name: nav_link.path.name, params: nav_link.path.query }).catch(err => {})
        }
      },

      clickSubLink(sublink, i) {
        this.closeSublinksMenu()
        this.hideBg()
        this.$router.push({ name: sublink.path.name, params: sublink.path.query }).catch(err => {})
      },

      hideBg() {
        let modal_bg = document.getElementById('modal-background')
        modal_bg.classList.remove('disable-background')
        modal_bg.removeEventListener('click', () => { this.closeSublinksMenu() })

        document.getElementById('main-container').classList.remove('disable-container')
        document.getElementsByTagName('html')[0].style.overflow = 'auto'
      },

      closeSublinksMenu() {
        if (this.sublinks_ref_key) {
          this.selected = undefined
          this.$refs[this.sublinks_ref_key][0].style.display = 'none'
          this.sublinks_ref_key = undefined
        }
        this.sublinkList = []
        this.resetSearchMsg()
      },

      openSublinksMenu(nav_link, i) {
        this.defineSublinks(nav_link.sublinks)
        this.selected = i
        let navbar = document.getElementById('navbar')
        let parent = this.$refs['nav-' + nav_link.anchor][0]
        this.sublinks_ref_key = nav_link.anchor + '-sublinks-wrapper'
        let child = this.$refs[this.sublinks_ref_key][0]

        child.style.top = parent.getBoundingClientRect().top + 'px'
        child.style.display = 'block'

        let modal_bg = document.getElementById('modal-background')
        modal_bg.classList.add('disable-background')
        modal_bg.style.zIndex = '1000'

        modal_bg.addEventListener('click', () => {
          this.closeSublinksMenu()
          this.hideBg()
        })

        navbar.addEventListener("scroll", () => {
          this.closeSublinksMenu()
          this.hideBg()
        })

        document.getElementById('main-container').classList.add('disable-container')
        document.getElementsByTagName('html')[0].style.overflow = 'hidden'
      },

      signOut() {
        this.confirm_message = this.notifies.confirm_signout
        this.confirm_modal = true
        this.closeSublinksMenu()
        this.hideBg()
      },

      confirmResult(val) {
        if (val) {
          this.confirm_modal = false
          this.$backend.sign_out(this.$store.state.paths['sign_out'])
              .then(() => {
                this.$store.commit('logOut')
                this.$router.push({name: 'Login'})
              })
              .catch((error) => {
                this.$q.notify(error.response.data.message)
              })
        } else {
          this.confirm_modal = false
        }
      },

      goToDashboard() {
        this.closeSublinksMenu()
        this.hideBg()
        this.$router.push({ name: 'Dashboard' }).catch(err => {})
      },

    },

    beforeMount() {
      this.$emit('set-loading', true)
      this.loadLinks().finally(() => {
        this.dataLoaded = true
      })

      this.$root.$on('reload-navbar', () => {
        this.loadLinks()
      })
    }
  }
</script>

<style lang='scss'>

  @import '../../assets/styles/navbar.scss';

</style>