<template lang='pug'>

  div
    div(:class="main_class")
      q-checkbox(dark v-model="currentFilter" :disable="disabled" :label="label")

</template>

<script>
  export default {
    data: function () {
      return {
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        grid: this.parentData.grid,
        path: this.parentData.path,
        disabled: this.parentData.data[0].disabled,
        watch: this.parentData.data[0].watch || {},
        main_class: 'form-checkbox'
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      currentFilter: {
        get() {
          return this.currentFilters[this.name] || false
        },
        set(value) {
          this.$store.commit('updateFilter', {grid_name: this.grid, filter: this.name, value: value})
        }
      },
    },

    methods: {

      setField(val) {
        this.currentFilter = val
      },
    },

    created() {

    },

    beforeMount() {
      // this.setField(false)
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/checkbox";

</style>