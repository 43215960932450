<template lang='pug'>

  prompt(v-if="prompt" :ref="prompt.class"
          @customize-prompt="customizePrompt"
          @close-modal="closeModal" :parentData="{prompt: prompt}")


</template>

<script>
  import Prompt from '../../../prompt'

  export default {

    props: {
      parentData: Object
    },

    components: {
      Prompt
    },

    computed: {
      prompt() {
        return this.parentData.prompt
      }
    },

    methods: {
      customizePrompt(parent_el, arrow, card) {
        card.style.top = `${parent_el.offsetHeight / 2 - card.offsetHeight / 2}px`
        card.style.left = `${parent_el.offsetLeft - card.offsetWidth - 40}px`
        arrow.style.top = `${parent_el.offsetHeight / 2 - arrow.offsetHeight / 2}px`
        arrow.style.left = `${card.offsetLeft + card.offsetWidth - 20}px`

        let modal_bg = document.getElementById('modal-background')
        if (modal_bg) { modal_bg.classList.remove('disable-background') }

        modal_bg = document.getElementById('modal-background-form')
        if (modal_bg) {
          modal_bg.classList.add('disable-background')
          modal_bg.style.top = '0px'
          modal_bg.style.left = '0px'
          modal_bg.style.zIndex = '5000'
        }

        this.$refs[this.prompt.class].showPrompt()
      },

      closeModal() {
        this.$emit('close-modal')
      }
    }
  }
</script>

<style lang='scss'>

  .modal-form-card.ui-prompt-card.add-service-forms-new {
    width: 262px !important;
  }


</style>