import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { account } from './modules/account'
import { theme } from './modules/theme'
import { issues_tabs } from './modules/issues_tabs'
import { dashboard } from './modules/dashboard'
import { grid } from './modules/grid'
import { locales } from './modules/locales'
import { ui_prompts } from './modules/ui_prompts'

Vue.use(Vuex)


const persistedStates = createPersistedState({
  paths: ['account', 'theme', 'issues_tabs']
})


export default new Vuex.Store({
  state: {
    paths: {
      sign_in: '/api/v3/auth/login',
      default_locale: '/api/v3/auth/default_locale',
      login_locales: '/api/v3/auth/login_locales',
      default_locales: '/api/v3/auth/default_locales',
      reset_password: '/api/v3/reset_password',
      issue: '/api/v3/issues',
      archive: '/api/v3/issues/archive',
      post: '/api/v3/posts',
      facility: '/api/v3/facilities',
      service: '/api/v3/services',
      work_category: '/api/v3/work_categories',
      company: '/api/v3/companies',
      building: '/api/v3/buildings',
      floor: '/api/v3/floors',
      room: '/api/v3/rooms',
      room_type: '/api/v3/room_types',
      sign_out: '/api/v3/auth/login',
      dashboard_data: '/api/v3/dashboards/data',
      dashboard: '/api/v3/dashboards',
      user: '/api/v3/users',
      work_group: '/api/v3/work_groups',
      work: '/api/v3/works',
      measure_unit: '/api/v3/measure_units',
      document: '/api/v3/documents',
      material: '/api/v3/materials',
      navbar: '/api/v3/navbar',
      notification_rule: '/api/v3/notification_rules',
      fullcalendar: '/api/v3/fullcalendar/fullcalendar_data',
      comment: '/api/v3/comments',
      consumption: '/api/v3/consumptions',
      completed_work: '/api/v3/completed_works',
      issue_children: '/api/v3/issue_children',
      contract: '/api/v3/contracts',
      product: '/api/v3/products',
      purchase: '/api/v3/purchases',
      ppr_equipment: '/api/v3/ppr/equipments',
      ppr_calendar: '/api/v3/ppr/calendar',
      ppr_issue: '/api/v3/ppr/issues',
      ppr_archive: '/api/v3/ppr/issues/archive',
      ppr_group_system: '/api/v3/ppr/group_systems',
      ppr_system: '/api/v3/ppr/systems',
      ppr_work: '/api/v3/ppr/works',
      ppr_frequency_work: '/api/v3/ppr/frequency_works',
      ppr_work_equipment: 'api/v3/ppr/work_equipments',
      ppr_work_group: '/api/v3/ppr/work_groups',
      employee: '/api/v3/employees',
      illness_status: '/api/v3/illness_statuses',
      employee_status: '/api/v3/employee_statuses',
      covid_test: '/api/v3/covid_tests',
      report: '/api/v3/reports',
      hidden_message: '/api/v3/app_settings/grid_hidden_message',
      checklist: '/api/v3/checklists',
      sales_form: '/api/v3/auth/sales_form',
      ui_prompt: '/api/v3/ui_prompts',
      current_branch: '/api/v3/current_branch',
      measuring_meter: '/api/v3/measuring/meters',
      measuring_meter_type: '/api/v3/measuring/meter_types',
      measuring_meter_reading: '/api/v3/measuring/meter_readings',
      measuring_statistic: '/api/v3/measuring/statistics',
      measuring_soon_verifyings: '/api/v3/measuring/statistics/soon_verifyings',
      measuring_last_readings: '/api/v3/measuring/statistics/last_readings',
      measuring_meter_verifying: '/api/v3/measuring/meter_verifyings',
      object_pass: '/api/v3/building_passports',
      telemetry: '/api/v3/telemetry'
    },

    not_auth_paths: [
      '/sales-form'
    ],

    grids: [
      'issues',
      'archive',
      'posts',
      'facilities',
      'buildings',
      'floors',
      'rooms',
      'room_types',
      'users',
      'work_groups',
      'works',
      'measure_units',
      'companies',
      'documents',
      'services',
      'work_categories',
      'materials',
      'notification_rules',
      'comments',
      'instructions',
      'covid',
      'contracts',
      'employees',
      'illness_statuses',
      'employee_statuses',
      'covid_tests',
      'object_pass',
      'telemetry',
      'checklists'
    ],

    additional_grids: [
      'archive',
      'comments',
      'dashboards',
      'indications_by_hours'
    ],

    fields_with_options: [
      'select',
      'select_multiple',
      'radio_collection'
    ],

    handle_by_available_transitions_grids: [
      'issues',
      'archive',
      'ppr_issues',
      'ppr_archive'
    ],

    // initial_grid_fields: {
    //   form: {},
    //   query: '',
    //   filters: {},
    //   except_filters: {}
    // },

    issues_active_states: [
      "initial", "accepted", "reworked", "processing", "checking_on", "paused", "done"
    ],

    issues_archive_states: [
      "rejected", "closed", "pp_revoked"
    ],

    ppr: {
      grids: [
        'calendar',
        'issues',
        'archive',
        'group_systems',
        'systems',
        'equipments',
        'works',
        'frequency_works',
        'work_groups'
      ]
    },

    measuring: {
      grids: [
        'meters',
        'meter_types',
        'meter_readings',
        'statistics'
      ]
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    locales,
    theme,
    issues_tabs,
    dashboard,
    grid,
    ui_prompts
  },
  plugins: [
    persistedStates
  ]
})
