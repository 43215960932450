<template lang='pug'>

  div
    div(class="form-card-title")
      inline-svg(v-if="title_icon" class="form-card-title-icon" :src="require(`../../../assets/icons/form/header/${title_icon}`)")
      span {{title}}
      inline-svg(v-if="!loading" class="form-card-close-icon" :src="require('../../../assets/icons/form/header/close.svg')" @click="closeForm()")

    div(class="form-card-title-spacer")

</template>

<script>

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        title: this.parentData.title,
        title_icon: this.parentData.title_icon,
        loading: true
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    computed: {
    },

    methods: {
      closeForm() {
        if (this.grid) {
          this.resetForm()
        }
        this.$emit('close-form', {})
      },

      setLoading(val) {
        this.loading = val
      }
    }

  }
</script>

<style lang="scss">



</style>