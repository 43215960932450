<template lang='pug'>

  div(class="notification-rule-grid-filters row")
    div(v-show="!loading" class="grid-filters col-6")
      component(v-for="filter in data.filters" :key="filter.name"
                :ref="filter.name"
                :is="getFilterComponent(getFilterDataByName(filter.name))"
                @filters-loaded="filtersLoaded"
                @load-options="loadSelectFilterOptions"
                :parentData="{grid: grid, data: [getFilterDataByName(filter.name)], options_data: options_data}")
</template>


<script>
  import filters from '../../shared/filters'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        path: this.parentData.path,
        loading_filters: [],
        loading: true,
        options_data: {},
      }
    },
    props: {
      parentData: Object
    },

    computed: {
      data() {
        return this.parentData.data.filters_data
      }
    },

    components: {
      filters
    },

    methods: {

      getFilterComponent(key) {
        if (key) {
          return filters[key.type]
        }
      },

      getFilterDataByName(filter_name) {
        return this.data.filters.find(f => f.name === filter_name)
      },

      setOptionsData(data, filter_name) {
        this.$refs[filter_name][0].setOptionsData(data);
      },

      setLoading(val, filter_name) {
        this.$refs[filter_name][0].setLoading(val);
      },

      filtersLoaded(filter) {
        if (this.loading_filters.length === 0) {
          this.loading_filters = this.data.filters.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }
        this.loading_filters = this.loading_filters.filter(f => f.sort().toString() !== filter.sort().toString())

        if (this.loading_filters.length === 0) {
          this.loading = false
        }
      }
    },

    created() {
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>
</style>
