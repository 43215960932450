import Vue from 'vue';
import './form/watchSimpleFieldParents'
import './form/float_field'
import { Settings } from 'luxon'

Vue.mixin({

  computed: {

    currentForm() {
      return this.$store.state.grid[this.grid]['form']
    },

    currentFieldIsInvalid() {
      if (this.currentForm && this.currentForm['invalid_fields']) {
        return this.currentForm['invalid_fields'][this.name]
      } else {
        return undefined
      }
    },

    datetime_modal_locales() {
      return this.locales.date_field[this.current_locale]
    },

    new_edit_form_locales() {
      return this.locales.new_edit_form[this.current_locale]
    },

    transition_form_locales() {
      return this.locales.transition_form[this.current_locale]
    },

    work_equipment_locales() {
      return this.locales.ppr_work_equipment[this.current_locale]
    }
  },


  methods: {

    checkFieldUniqueness(val) {
      if (val && val.length > 0 && (this.method === 'create' || val !== this.value)) {
        this.$backend.index(`${this.$store.state.paths[this.uniqueness.path]}/${this.uniqueness.action}`, {params: {field: val}})
        .then(({data}) => {
          if (data) {
            let result = {}
            result['field'] = val
            result['invalid'] = true
            this.valid_error_message = data
            this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status)
          }
        })
      }
    },

    checkFormValidation(form) {
      let invalid_form = Object.keys(form).reduce((result, e) => {
        if (form[e]['invalid'] === true) result[e] = form[e]
        return result;
      }, {})

      let invalid_fields = Object.keys(invalid_form)

      if (invalid_fields.length > 0) {
        this.form_valid = false

        this.$store.commit('createFormField', {grid_name: this.grid, field: 'invalid_fields'})

        let val = {}
        invalid_fields.forEach(f => val[f] = true)
        this.$store.commit('updateFormField', {grid_name: this.grid, field: 'invalid_fields', value: val})

      } else {
        this.form_valid = true
        this.$store.commit('resetFormField', {grid_name: this.grid, field: 'invalid_fields'})
      }
    },

    setDateFieldLocale() {
      if (Settings.defaultLocale !== this.current_locale) {
        Settings.defaultLocale = this.current_locale           // localization for vue-datetime (date_field, date_filter)
      }
    },

    setDateFieldPhrases() {
      this.phrases = {
        ok: this.datetime_modal_locales.submit,
        cancel: this.datetime_modal_locales.cancel
      }
    },

    valueIsObjectOrNot(val) {
      return (val && typeof val === 'object') ? val.value || val.map(el => el.value) : val
    },

    resetForm() {
      let fields = Object.keys(this.currentForm);
      this.$store.commit('resetFormAllFields', {grid_name: this.grid, fields: fields})
    },

    multiselectHandler() {
      let field = this.$el
      let select = field.querySelector('.multiselect')
      let input = field.querySelector('.multiselect__input')
      let wrapper = field.querySelector('.multiselect__content-wrapper')
      let forms = ['form', 'show-form']

      wrapper.style.width = field.clientWidth + 'px'

      if (!this.open_direction || this.open_direction === 'bottom') {
        wrapper.style.position = 'fixed'
        wrapper.style.top = (input.getBoundingClientRect().bottom + 5) + 'px'
      }

      setTimeout(() => {

        if (!this.open_direction || this.open_direction === 'bottom') {
          wrapper.style.position = 'fixed'
          wrapper.style.top = (input.getBoundingClientRect().bottom + 5) + 'px'
        }

        field.addEventListener("DOMSubtreeModified", (e) => {
          if (!this.open_direction || this.open_direction === 'bottom') {
            wrapper.style.position = 'fixed'
            wrapper.style.top = (input.getBoundingClientRect().bottom + 5) + 'px'
          }
        }, false);

        wrapper.scrollTop = 0

        forms.forEach((form) => {
          if (document.getElementById(form)) {
            document.getElementById(form).addEventListener("scroll", () => {
              // select.classList.remove('multiselect--active')
              // wrapper.style.display = 'none'
              // input.style.width = '0px'
              // input.style.position = 'absolute'
              // input.style.padding = '0px'

              wrapper.style.top = (input.getBoundingClientRect().bottom + 5) + 'px'
            })
          }
        })
      }, 5)
    },

    checkDependency() {
      if (!this.method_limit || this.method_limit === this.method) {

        if (Object.keys(this.depend_from).length === 0) {
          return true

        } else if (this.depend_from.request && Object.keys(this.depend_from.request).length > 0) {
          let depended_field = this.currentForm[this.depend_from['field_name']]
          let params = {}

          if (depended_field && depended_field['field'] && depended_field['field']['value']) {
            params[this.depend_from['field_name']] = depended_field['field']['value']
            this.$backend.index(this.$store.state.paths[this.depend_from.request.path] + '/' + this.depend_from.request.action, {params: params})
            .then(({data}) => {
              this.$emit('show-field', {name: this.name, val: data.value})
            })
            .catch((error) => {
              console.log(error);
              if (error.response) { this.reLogin(error.response.status) }
              this.$emit('show-field', {name: this.name, val: false})
            })

          } else {
            this.$emit('show-field', {name: this.name, val: false})
          }

        } else {
          let depended_field = this.currentForm[this.depend_from['field_name']]
          if ((depended_field && depended_field['field'])
                && (((depended_field['field']['value'] && (this.depend_from['value'] && depended_field['field']['value'] === this.depend_from['value']))
                  || (depended_field['field']
                    && depended_field['field'] !== (this.multiple ? [] : ''))))) {

            this.$emit('show-field', {name: this.name, val: true})
          } else {
            this.resetField()
            this.$emit('show-field', {name: this.name, val: false})
          }
        }
      } else {
        this.$emit('show-field', {name: this.name, val: false})
      }
    },
  }
})