<template lang='pug'>

  q-card(id="form" class="modal-form-card")

    q-card-section
      form-header(ref="form-header" @close-form="cancelTransition"
                  :parentData="{grid: grid, title: action.name, title_icon: action.title_icon}")

    div(v-if="loading" class="spinner-container modal-spinner")
      q-spinner(color="primary" size="3em")

    q-card-section
      q-form(v-show="!loading")
        component(v-if="dataHasLoaded" ref="transition_component"
                  @loading-form="loadingForm"
                  :is="transitionForm(action.key)"
                  :parentData="{grid: grid, path: path, action: action, row: row, data: transition_form_data}")

    q-card-actions(v-show="!loading" class="button-group row justify-center")
      q-btn(flat no-caps
            class="modal-form-cancel"
            :label="transition_form_locales.cancel"
            @click="cancelTransition()")
      q-btn(flat no-caps
            class="modal-form-submit"
            :label="action.name"
            @click="sendTransition()")


</template>

<script>
  // import Pluralize from 'pluralize';
  import formHeader from '../../shared/forms/formHeader'
  import transitions from '../transitions'


  export default {
    data: function () {
      return {
        action: this.parentData.action,
        row: this.parentData.row,
        path: this.parentData.path,
        grid: this.parentData.grid,
        transition_key: this.parentData.transition_key,

        transition_form_data: [],
        dataHasLoaded: false,
        form_valid: false,

        loading: true
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      transitions
    },

    computed: {
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    methods: {

      loadingForm(loading) {
        this.loading = loading
      },

      loadTransitionFormData(params = {}) {
        let form_data_path = this.path + '/transition_form'
        params['id'] = this.row.id
        params['action_key'] = this.action.key

        this.$backend.index(form_data_path, { params: params })
        .then(({ data }) => {
          this.transition_form_data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          setTimeout(() => {
            this.dataHasLoaded = true
          }, 500)
        })
      },

      fieldsLoaded(field=undefined) {

        if (this.loading_fields.length === 0) {
          this.loading_fields = this.data.fields.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (field) {
          this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString())
        }

        if (this.loading_fields.length === 0) {
          this.loading = false
        }
      },

      transitionForm(key) {
        return transitions[key]
      },

      cancelTransition() {
        this.resetForm()
        this.$emit('cancel-transition', {})
        this.$emit('row-is-active', false)
      },

      sendTransition(params = {}) {
        let form = this.currentForm

        this.checkFormValidation(form)

        if (this.form_valid) {

          let row_name = this.transition_key

          let result = Object.keys(form).reduce((result, e) => {
            if (Array.isArray(form[e]['field']) && form[e]['selected_items']) {
              result[e] = form[e]['selected_items']
            } else if (Array.isArray(form[e]['field'])) {
              result[e] = form[e]['field'].map(e => {
                if (e['value']) {
                  return e['value']
                } else {
                  return e
                }
              })
            } else if (typeof form[e]['field'] === 'object') {
              result[e] = form[e]['field']['value']
            } else {
              result[e] = form[e]['field']
            }
            return result;
          }, {})

          result['transition'] = this.action.key
          params['id'] = this.row.id
          params[row_name] = result;

          this.$emit('send-transition', params)
          this.resetForm()

        } else {
          this.$q.notify(this.notifies.fix_invalid_fields)
        }
      },


      // checkFormValidation(form) {
      //   let invalid_form = Object.keys(form).reduce((result, e) => {
      //     if (form[e]['invalid'] === true) result[e] = form[e]
      //     return result;
      //   }, {})
      //
      //   let invalid_fields = Object.keys(invalid_form)
      //
      //   if (invalid_fields.length > 0) {
      //     this.form_valid = false
      //
      //     this.$store.commit('createFormField', {grid_name: this.grid, field: 'invalid_fields'})
      //
      //     let val = {}
      //     invalid_fields.forEach(f => val[f] = true)
      //     this.$store.commit('updateFormField', {grid_name: this.grid, field: 'invalid_fields', value: val})
      //
      //   } else {
      //     this.form_valid = true
      //     this.$store.commit('resetFormField', {grid_name: this.grid, field: 'invalid_fields'})
      //   }
      // },

      resetForm() {
        let fields = Object.keys(this.currentForm);
        this.$store.commit('resetFormAllFields', {grid_name: this.grid, fields: fields})
      },
    },

    created() {
      this.resetForm()
      this.loadTransitionFormData()
      this.$emit('row-is-active', true)
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/modal-form";

</style>