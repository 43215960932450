<template lang='pug'>

  q-menu(ref="grid-button-menu" class="grid-button-menu" id="grid-button-menu")
    q-list
      q-item(v-for="link in links" @click="getHandler(link)" v-close-popup='1' clickable)
        q-item-section
          q-item-label {{link.label}}

</template>

<script>

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    computed: {
      links() {
        return this.parentData.links
      }
    },

    methods: {

      getHandler(link) {
        this.$emit('get-handler', link)
      },

      closeMenu() {
        if (this.$refs['grid-button-menu']) {
          this.$refs['grid-button-menu'].hide()
        }
      }
    },

    created () {
      setTimeout(() => {
        let obj = document.getElementById("main")
        if (obj) {
          obj.addEventListener("scroll", this.closeMenu, false)
        }
      },500)
    },

    destroyed () {
      setTimeout(() => {
        let obj = document.getElementById("main")
        if (obj) {
          obj.removeEventListener('scroll', this.closeMenu, false)
        }
      },500)
    },
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/menu/grid_actions_menu";

</style>