// TODO: receive from env during compilation
const API_KEY = '4bfc81af-43ea-4b0e-820e-a055a923257d';

/*
 * Wrapper around 2gis Map object
 */
class Map {
  constructor(api, centerCoordinates) {
    this.map = new api.Map('map', {
      center: centerCoordinates,
      zoom: 14,
      zoomControl: false,
      key: API_KEY,
      pitch: 45,
    });

    window.addEventListener('resize', () => this.map.invalidateSize());

    /*
     * HACK: at this point the page may not be rendered yet
     *       so the map may appear broken.
     *       Let's assume everything is loaded after 3 seconds.
     *       Ugly hack. Dunno if there's a better way
     */
    setTimeout(() => this.map.invalidateSize(), 3000);
  }

  getWrapee() {
    return this.map;
  }
}

export default Map

