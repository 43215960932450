import { render, staticRenderFns } from "./expensesChart.vue?vue&type=template&id=31e5b369&lang=pug&"
import script from "./expensesChart.vue?vue&type=script&lang=js&"
export * from "./expensesChart.vue?vue&type=script&lang=js&"
import style0 from "./expensesChart.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports