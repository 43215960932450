<template lang='pug'>

  div(class="building-block")
    div(class="building-info")
      span(class="title") {{building && building.title || ''}}
      span(class="address") {{building && building.address || ''}}

    //div(class="sensors-info")
      div(class="sensors-info-badge humidity")
        inline-svg(:src="require(`../../../../assets/icons/map/telemetry/humidity.svg`)")
        span 33%
      div(class="sensors-info-badge co2")
        inline-svg(:src="require(`../../../../assets/icons/map/telemetry/co2.svg`)")
        span 0,3%
      div(class="sensors-info-badge temperature")
        inline-svg(:src="require(`../../../../assets/icons/map/telemetry/temperature.svg`)")
        span 20%

</template>

<script>

  export default {
    data: function () {
      return {
        building: this.parentData.building
      }
    },

    props: {
      parentData: Object
    }
  }
</script>

<style lang='scss'>

  .building-block {
    display: flex;
    height: 50px !important;
    /*width: 636px !important;*/
    margin-bottom: 30px;

    .building-info {
      display: grid;
      width: 100%;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: var(--telemetry-building-title);
      }

      .address {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--telemetry-building-address);
      }
    }

    .sensors-info {
      display: flex;

      .sensors-info-badge {
        padding: 0px 12px;

        svg {
          margin: 3px;

          path {
            fill: #868686;
          }
        }

        span {
          padding-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;

          color: #868686;
        }
      }

      .humidity {
        display: flex;
      }
      .co2 {
        display: flex;
      }
      .temperature {
        display: flex;
      }
    }
  }

</style>