import echarts from 'echarts/dist/echarts'
export const illness = (propsData) => ({
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    legend: {
        data: ['Covid-19', 'ОРВИ', 'Пневмония'],
        right: '10%',
        icon: "circle"
    },
    grid: {
        show: false
    },
    xAxis: [{
        boundaryGap: ['20%', '20%'],
        showGrid: false,
        data: propsData.map(data => data.date),
        nameTextStyle: {
            color: 'white'
        },
        axisLabel: {
            margin: 18
        },
        axisLine: {
            lineStyle: {
                color: 'white'
            }
        },
        splitLine: {
            show: false
        }
    }],
    yAxis: [{
        type: 'value',
        splitNumber: 4,
        showGrid: false,
        nameTextStyle: {
            color: 'white'
        },
        axisLine: {
            lineStyle: {
                color: 'white'
            }
        },
        axisLabel: {
            margin: 18
        },
        splitLine: {
            show: true
        }
    }],
    series: [
        {
            type: 'line',
            name: 'Covid-19',
            color: '#E05959',
            symbolSize: 5,
            data: propsData.map(data => data.covid),
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#D94B4B2B'
                    },
                    {
                        offset: 1,
                        color: '#ffffff2B'
                    }])
            }
        },
        {
            type: 'line',
            name: 'ОРВИ',
            color: '#EEE07D',
            symbolSize: 5,
            data: propsData.map(data => data.vri),
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FFC3002B'
                },
                    {
                        offset: 1,
                        color: '#ffffff2B'
                    }])
            }
        },
        {
            type: 'line',
            name: 'Пневмония',
            color: '#F6840C',
            symbolSize: 5,
            data: propsData.map(data => data.pneumonia),
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#F6840C2B'
                },
                    {
                        offset: 1,
                        color: '#ffffff2B'
                    }])
            }
        }
    ]
})
