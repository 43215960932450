<template lang="pug">

  div(
    :class="`floor-plan-dot`"
    v-bind:style="{ left: dot.x + '%', top: dot.y + '%' }"
    @mouseover="showPrompt"
    @mouseleave="hidePrompt"
    @mousedown="dragStart"
    @mouseup="dragEnd"
    @click="showDetail"
  )
    inline-svg(v-if="dot.icon_url" class="floor-plan-dot-icon" :src="dot.icon_url" alt="icon") 
    q-icon(class="floor-plan-dot-icon-remove" name="cancel" v-if="editable && !this.dot.create" @click.stop="removeDot()")

    pointPrompt(
      v-if="prompt"
      :promptData="{dot: dot, floor_plan_id: floor_plan_id}"
      v-bind:style="{ left: promptX + 'px', top: promptY + 'px' }"
      :class="`floor-plan-dot-prompt ${promptAdditional}`"
    )
    pointCreate(
      v-if="dot.create && editable"
      :parentData="{dot: dot, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id}"
      @setDot="setDot"
      @hide="hideCreateDot"
    )
    pointDetail(
      v-if="active"
      :detailData="{dot: dot, active: active}"
      @hide="hideDetail"
    )
    
</template>

<script>
  import pointPrompt from './point/prompt.vue'
  import pointDetail from './point/detail.vue'
  import pointCreate from './point/create.vue'

  export default {
    
    data: function(){
      return{
        dot: this.dotData.dot,
        floor_plan_id: this.dotData.floor_plan_id,
        floor_id: this.dotData.floor_id,
        building_id: this.dotData.building_id,
        types: this.dotData.types,
        dotInfo: {},
        active: false,
        editable: this.dotData.editable,
        prompt: false,
        promptX: 0,
        promptY: 0,
        promptAdditional: '',
        dragging: false,
        img: {},
        dotEl: Object,
        prevX: Number,
        prevY: Number
      }
    },
    
    components: {
      pointPrompt,
      pointDetail,
      pointCreate
    },

    props: {
      dotData: Object
    },

    watch: {
      dotData: function (newVal, oldVal) {
        this.dot = newVal.dot
      },
    },

    methods: {
      showPrompt(e){
        if(!this.dragging && !this.dot.create){
          this.prompt = true
          setTimeout(() => {
            const prompt = document.querySelector('.floor-plan-dot-prompt')
            if (this.promptX === 0 && this.promptY === 0){
              const dot = e.target.closest('.floor-plan-dot')
              this.promptX = dot.getBoundingClientRect().left
              this.promptY = dot.getBoundingClientRect().top
              if (dot.getBoundingClientRect().top - prompt.getBoundingClientRect().height - 30 <= 0){
                this.promptAdditional = 'reverse'
              }
            }
          }, 0);
        }
      },

      setDot(data){
        this.$emit('setDot', data)
      },

      hidePrompt(){
        this.prompt = false
        this.promptX = 0
        this.promptY = 0
      },

      showDetail(){
        if(!this.dragging && !this.dot.create && this.dot.type !== 'sensors'){
          this.active = true
        }
      },

      setType(type){
        this.dot.type = type
      },

      hideDetail(){
        this.active = false
      },

      round (number){
        return Math.round(parseFloat(number) * 1000) / 10
      },

      hideCreateDot(){
        this.$emit('hideCreate')
      },

      removeDot(){
        this.$emit('remove')
      },

      updateDot(){
        const plan_points = {
          x: this.dot.x,
          y: this.dot.y
        }
        this.$backend.update(`api/v3/plan_points`,this.dot.id , {plan_point: plan_points})
        .then((res) => {
          this.dragging = false
        })
      },

      dragStart(e){
        if(this.editable  && !this.dot.create && !e.target.classList.contains('floor-plan-dot-icon-remove')){
          e.preventDefault()
          this.prompt = false
          this.dragging = true
          const dot = e.target.parentElement.parentElement
          const img = e.target.parentElement.parentElement.offsetParent
          this.img['width'] = img.getBoundingClientRect().width
          this.img['height'] = img.getBoundingClientRect().height
          this.img['top'] = img.getBoundingClientRect().top
          this.img['left'] = img.getBoundingClientRect().left
          this.dotEl = dot
          this.prevX = this.dot.x
          this.prevY = this.dot.y
          document.addEventListener('mousemove', this.drag)
        }
      },

      drag(e){
        if(this.dragging){
          this.prompt = false
          const currentPositionY = e.y - this.img.top - this.dotEl.getBoundingClientRect().height/2
          const currentPositionX = e.x - this.img.left - this.dotEl.getBoundingClientRect().width/2
          const y = this.round(currentPositionY/this.img.height)
          const x = this.round(currentPositionX/this.img.width)
          this.dot.y = y
          this.dot.x = x
          if (
            (window.innerWidth - 900)/2 - e.x > 0
            ||
            (window.innerWidth - 900)/2 + 900 < e.x
            ||
            (window.innerHeight - 500)/2 - e.y - 30 > 0
            ||
            (window.innerHeight - this.img.height)/2 - e.y - 30 > 0
            ||
            (window.innerHeight - 500)/2 + 500 - 30 < e.y
            ||
            (window.innerHeight - this.img.height)/2 + this.img.height - 30 < e.y
          ){
            document.removeEventListener('mousemove', this.drag)
            this.dot.x = this.prevX
            this.dot.y = this.prevY
            return
          }
        }
      },

      dragEnd(e){
        if(this.dragging){
          e.preventDefault()
          if(this.prevX !== this.dot.x || this.prevY !== this.dot.y){
            this.updateDot()
          }else{
            this.dragging = false
          }
          document.removeEventListener('mousemove', this.drag)
        }
      },
    },

    beforeMount() {
    },
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/floor-plan/main";

</style>
