<template lang='pug'>
  div(class="index-page")
    common-header(ref="header"
                  v-if="!filter_data_loading"
                  @reload-data="reloadData"
                  :parentData="{path: path, grid: grid, grid_key: grid_key, data: forms['filter_data']}")


    div(v-if="!filter_data_loading" :id="'grid-' + grid_key" :class="custom_grid ? 'grid ' + custom_grid : 'grid'")
      //grid-hidden-msg(v-show="hide_table" ref="grid-hidden-msg" :parentData="{hide_table: hide_table}")

      div(v-if="custom_grid")
        component(ref="grid"
                  :is="getCustomGrid(custom_grid)"
                  @get-collection="getCollection"
                  :parentData="{filters_data: forms['filter_data'], grid: grid, grid_key: grid_key, path: path}")

      div(v-else)
        grid(ref="grid" @get-collection="getCollection" :parentData="{filters_data: forms['filter_data'], grid: grid, channel: channel, path: path, grid_key: grid_key, transition_key: transition_key}")

</template>

<script>
  import commonHeader from './helpers/Header'
  import Grid from './grid/Grid'
  import gridHiddenMsg from './grid/gridHiddenMsg'
  import customGrids from './custom_grids'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        path: this.parentData.path,
        collection_path: this.parentData.collection_path,
        transition_key: this.parentData.transition_key,
        collection_type: this.parentData.collection_type,
        report_class: this.parentData.report_class,
        report_namespace: this.parentData.report_namespace,
        sti_type: this.parentData.sti_type,
        except_filters: this.parentData.except_filters,
        custom_grid: this.parentData.custom_grid,
        forms: this.parentData.forms || {
          filter_data: []
        },
        forms_without_data: {
            import_excel_form_data  : [],
            export_excel_form_data  : []
        },

        // hide_table: false,

        channel: '',
        grid_key: this.parentData.grid_key,
        row: null,

        filter_data_loading: true,
      }
    },

    props: {
      parentData: Object
    },

    components: {
      commonHeader,
      Grid,
      gridHiddenMsg,
      customGrids
    },

    computed: {
    },

    watch: {
      allDataLoaded(newVal, oldVal) {
        if (newVal) {
          this.$emit('set-loading', false)
        }
      }
    },

    methods: {

      getCustomGrid(custom_grid) {
        return customGrids[custom_grid]
      },

      openForm() {
        if (this.row) {
          let attr = {
            form_type: 'edit',
            row: this.row,
            callback_params: {}
          }

          let grid = this.$refs['grid']
          if (grid) {
            grid.openForm(attr)
          }
        }
      },

      openShow(tab_name=undefined) {
        if (this.row) {
          if (tab_name) {
            this.row['show_tab'] = tab_name
          }
          let grid = this.$refs['grid']
          if (grid) {
            grid.openShow(this.row)
          }
        }
      },

      // hideTable(val) {
      //   this.hide_table = val
      //   this.$refs['grid-hidden-msg'].hideTable(val)
      // },

      filtersFormAccepted(val) {
        let grid = this.$refs['grid']
        if (grid) {
          grid.filtersFormAccepted(val);
        }
      },

      resetLastFilter(filter_name) {
        this.$store.commit('resetFilter', {grid_name: this.grid, filter: filter_name})
        let grid = this.$refs['grid']
        if (grid) {
          grid.filtersFormAccepted(true);
        }
      },

      filtersFormReset() {
        this.searchAllFilterReset()

        let filters = Object.keys(this.filters)
        this.$store.commit('resetAllFilters', {grid_name: this.grid, filters: filters})
        let grid = this.$refs['grid']
        if (grid) {
          grid.filtersFormAccepted(true);
        }
      },

      searchAllFilterReset() {
        this.$store.commit('resetQuery', {grid_name: this.grid, value: this.searchAllFilter})
      },

      openExportForm(item) {
        let attr = {
          data: this.forms.export_excel_form_data,
          header_title: item.label,
          sti_type: this.sti_type,
          report_class: this.report_class,
          report_namespace: this.report_namespace,
          except_filters: this.except_filters,
          collection_type: this.collection_type,
          export_type: item.export_type
        }

        this.$nextTick(()=>{
          let grid = this.$refs['grid']
          if (grid) {
            grid.openExportForm(attr);
          }
        })
      },

      openImportForm(item) {
        let attr = {
            data: this.forms_without_data.import_excel_form_data,
            href: item.href
        }

        if (this.collection_type) {
            attr['collection_type'] = this.collection_type
        }

        this.$nextTick(()=>{
          let grid = this.$refs['grid']
          if (grid) {
            grid.openImportForm(attr);
          }
        })
      },

      getCollection(params) {
        let path = this.collection_path || this.path

        if (this.sti_type) {
          params['filters']['type'] = this.sti_type
        }

        this.gridCollection(path, this.grid, this.grid_key, params)
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }

          this.$refs.grid.setError(false);
        })
        .finally(() => {
          this.$refs.grid.setLoading(false);
        })
      },

      reloadData() {
        Object.keys(this.forms).forEach(data_key => {
          this.loadFormData(data_key)
        })
        let grid = this.$refs['grid']
        if (grid) {
          grid.reloadData();
        }
      },

      loadFormData(key, attr={}) {
        let path = this.path + '/' + key;
        let params = {}

        if (this.collection_type) {
          params[this.collection_type] = true
        }

        if (attr.export_type) {
          params['export_type'] = attr.export_type
        }

        this.$backend.index(path, { params: params })
        .then(({ data }) => {
          this.forms[key] = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this[key + '_loading'] = false
        })
      },

      addNewItem(params={}) {
        let attr = {
          form_type: 'add',
          callback_params: params
        }
        this.$nextTick(()=> {
          let grid = this.$refs['grid']
          if (grid) {
            grid.openForm(attr);
          }
        })
      },

      addSearchAllFromParams(val) {
        if (val) {
          let result = {
            name: 'query',
            type: 'search_all',
            value: val.toString()
          }

          if (this.$refs.header) {
            this.$refs.header.updateFilter(result)
          }
        }
      },

      addFiltersFromParams(filters) {
        if (filters) {
          let filters_data = this.forms.filter_data.filters_data.filters
          Object.keys(filters).forEach(key => {

            let filter = filters_data.find(f => f.name === key)

            if (this.$refs.header) {
              this.$refs.header.updateFilter(Object.assign(filter, {value: filters[key]}))
            }
          })
        }
      }
    },

    created(attr={}){
      if (this.except_filters) {
        attr['except_filters'] = this.except_filters
      }
      this.$store.commit('initialGridsState', {grid: this.grid, attr: attr})

      Object.keys(this.forms).forEach(data_key => {
        this.loadFormData(data_key)
      })

      setTimeout(() => {
        if (this.$route.query.open_form) {
          let id = this.$route.query.id
          this.addSearchAllFromParams(id)
          this.loadRow(id, this.path)
          setTimeout(() => {
            this.openForm()
          }, 1500)
        } else if (this.$route.query.show) {
          let id = this.$route.query.id
          this.addSearchAllFromParams(id)
          this.loadRow(id, this.path)
          setTimeout(() => {
            this.openShow(this.$route.query.tab)
          }, 1500)
        }

        if (this.$route.params.attr && this.$route.params.attr.filters) {
          this.addFiltersFromParams(this.$route.params.attr.filters)
        }
      }, 500)
    },

    mounted() {
      this.$root.$on('open-export-form', (item) => {
        this.openExportForm(item)
      })

      this.$root.$on('open-import-form', (item) => {
        this.openImportForm(item)
      })

      this.$root.$on('add-new-item', () => {
        this.addNewItem()
      })

      this.$root.$on('filters-form-accepted', (val) => {
        this.filtersFormAccepted(val)
      })

      this.$root.$on('filters-form-reset', () => {
        this.filtersFormReset()
      })

      this.$root.$on('search-all-filter-reset', () => {
        this.searchAllFilterReset()
      })

      this.$root.$on('reset-last-filter', (filter_name) => {
        this.resetLastFilter(filter_name)
      })

      this.$root.$on('add-filters-from-params', (filters) => {
        this.addFiltersFromParams(filters)
      })
    }
  }
</script>

<style lang='scss'>
</style>