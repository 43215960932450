<template lang='pug'>

  div(id='covid-page' class='index-page')
    Header(:parentData="{ data: { title: 'Статистика Covid-19' }, hide_filters: true }")
    div(v-if="!loading" id="covid-container")
      CovidDashboard(:illness_statistic="dashboard_data.boards.illness_statistic")

</template>

<script>

    import Header from '../../shared/helpers/Header'
    import CovidDashboard from "./CovidDashboard";

    export default {
        data: function () {
            return {
                dashboard_data: {},
                loading: true,
            }
        },
        components: {
            Header,
            CovidDashboard
        },

        watch: {
            loading(newVal, oldVal) {
                this.$emit('set-loading', this.loading)
            }
        },

        methods: {

            loadDashboardData() {
                this.$backend.index(this.$store.state.paths.dashboard_data, { params: {'boards': ['illness_statistic']} })
                    .then((response) => {
                        // console.log(response.data)
                        this.dashboard_data = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                      if (error.response) { this.reLogin(error.response.status) }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 800)
                    })
            }

        },

        beforeMount() {
            this.$emit('expand-class', {val: true, class: 'dashboard-background'})
            this.loadDashboardData()
        }
    }
</script>

<style lang='scss'>
</style>
