class Tooltip {
  constructor(parentElement) {
    const container = document.createElement('div');
    container.className = 'map-tooltip';
    parentElement.appendChild(container);

    this.container = container;
  }

  show(event, tooltipView, onClick) {
    this.container.classList.add('map-tooltip--visible');
    this.container.innerHTML = tooltipView.toHtmlCode();
    this.container.style.top = `${event.y}px`;
    this.container.style.left = `${event.x}px`;
    this.container.onclick = onClick;
  }

  hide() {
    this.container.classList.remove('map-tooltip--visible');
  }

  destroy() {
    this.container.remove();
  }
}

export default Tooltip;
