<template lang='pug'>
  div
    div(v-if="loading" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")

    grid-hidden-msg(v-show="hide_table && !loading" :ref="`grid-hidden-msg-${grid}`" :parentData="{hide_table: hide_table}")

    table(v-show="!hide_table && !loading" :class="default_class ? table_class + ' ' + default_class : table_class" style='width:100%', border='0')
      div(
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="limit"
        infinite-scroll-immediate-check="false"
      )
        thead
          tr
            th(class="grid-column-header" v-for='column in currentGrid.columns' :key="column.name" :style="column.column_style")
              div(class="column-header")
                span(class="column-header-sorting" v-if="column['sortable']" @click="sortBy(column.name)")
                  inline-svg(
                    class="column-sorting-icon"
                    v-if="!currentGrid.pagination.descending"
                    :src="require('../../../assets/icons/grid/up_arrow.svg')"
                  )
                  inline-svg(
                    class="column-sorting-icon"
                    v-if="currentGrid.pagination.descending"
                    :src="require('../../../assets/icons/grid/down_arrow.svg')"
                  )
                span(class="column-header-title noselect")
                  | {{ column.label }}
        tbody
          tr(v-for='(row, index) in currentGrid.data' :key="row.id" :class="{'active-row': (active_row && (callback_row.id && row.id === callback_row.id)), 'overdue-row': row.overdue, 'pre-overdue-row': (!row.overdue && row.pre_overdue), 'ml-processed': row.ml_processed}")
            td(:id="'td-' + row.id + '-' + column.name" v-for='column in currentGrid.columns' :key="column.name" @click.self="openShow(row)" :style="column.row_style")

              grid-actions(v-if="column.name == 'actions'"
                            @set-callback-row="setCallbackRow"
                            @row-is-active="changeRowActive"
                            @refresh-table="refreshTableRows"
                            @open-form="openForm"
                            @open-show="openShow"
                            :parentData="{index: index, actions: currentGrid.actions, row: row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, default_class: 'grid-actions', actions_icon_path: 'grid/actions.svg'}")

              grid-actions-by-checkbox(v-if="column.name === 'select'"
                    :key="actionByCheckboxKey"
                    :parentData="{checkbox_select: false, row: row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, default_class: 'grid-select'}")

              grid-row(@open-show="openShow" @open-files-modal="openFilesModal" :parentData="{row: row, column: column, grid_key: grid_key}")

    actions-menu(v-if="actions_menu_visible()"
        @refresh-table="refreshTableRows"
        @remove-checkboxes="removeCheckboxes"
        :parentData="{path: path, grid: grid, transition_key: transition_key}")
    grid-modals(ref="grid-modals"
                @set-callback-row="setCallbackRow"
                @row-is-active="changeRowActive"
                @refresh-table="refreshTableRows"
                @reload-data="reloadData"
                :parentData="{row: callback_row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key}")

</template>


<script>
  import gridActions from './gridActions'
  import gridRow from './gridRow'
  import gridModals from './gridModals'
  import gridActionsByCheckbox from "./gridActionsByCheckbox";
  import actionsMenu from '../actionsMenu'
  import gridHiddenMsg from '../grid/gridHiddenMsg'

  export default {
    name: "Grid",
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        transition_key: this.parentData.transition_key,
        grid_key: this.parentData.grid_key,
        actions: this.parentData.actions,
        channel: this.parentData.channel,
        no_show_link: this.parentData.no_show_link,
        // filters_data: this.parentData.filters_data,
        default_class: this.parentData.default_class,

        reload_after_update: this.parentData.reload_after_update,

        sort_by: this.parentData.sort_by,
        descending: this.parentData.descending,

        table_class: 'common-table',
        active_row: false,

        loading: true,

        loadingMore: false,
        limit: 1500,
        error: false,
        pageSize: 20,
        nextPage: 2,

        callback_row: {},
        actionByCheckboxKey: 0,

        filters_form_accepted: false,

        hide_table: false
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      filters_data() {
        return this.parentData.filters_data
      },

      filtersExists() {
        return Object.keys(this.filters).filter(key => ![undefined, null, ''].includes(this.filters[key])).length > 0
      },
    },

    watch: {
      searchAllFilter(newValue, oldValue) {
        this.currentGrid.pagination.page = 1
        this.nextPage = 2

        this.loading = true

        if (newValue && (newValue !== oldValue) || (!newValue && this.filtersExists)) {
          this.currentGrid.pagination.refresh_tabs = true
          this.$emit('reload-tabs', true)
        } else if (!newValue && !this.filtersExists) {
          this.$emit('reload-tabs', true)
        }

        setTimeout(() => {
          this.onRequest({
            pagination: this.currentGrid.pagination
          })
        },1000)
      },

      filters_form_accepted(newValue, oldValue) {
        if (newValue) {
          this.currentGrid.pagination.page = 1
          this.nextPage = 2

          if (this.filtersExists) {
            this.currentGrid.pagination.refresh_tabs = true
            this.$emit('reload-tabs', true)
          } else {
            this.$emit('reload-tabs', true)
          }

          this.loading = true

          this.onRequest({
            pagination: this.currentGrid.pagination
          })

          this.filters_form_accepted = false
        }
      }
    },

    components: {
      gridActionsByCheckbox,
      actionsMenu,
      gridActions,
      gridRow,
      gridModals,
      gridHiddenMsg
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs[`grid-hidden-msg-${this.grid}`]) {
          this.$refs[`grid-hidden-msg-${this.grid}`].hideTable(val)
        }
      },

      setRefreshTabs() {
        this.currentGrid.pagination.refresh_tabs = true
        this.$emit('reload-tabs', true)
      },

      filtersFormAccepted(val) {
        this.filters_form_accepted = val
      },

      setLoading(val) {
        this.loading = val
        this.loadingMore = val

        if (this.currentGrid.count === 0) {
          this.hideTable(true)
          // this.$emit('hide-table', true)
        } else {
          this.hideTable(false)
          // this.$emit('hide-table', false)
        }
      },

      setError(val) {
        this.error = val
      },

      setCallbackRow(row) {
        this.callback_row = row
      },

      changeRowActive(val) {
        this.active_row = val
      },

      openFilesModal(files) {
        this.$refs['grid-modals'].openFilesModal(files)
      },

      openExportForm(attr) {
        this.$refs['grid-modals'].openExportForm(attr)
      },

      openImportForm(attr) {
            this.$refs['grid-modals'].openImportForm(attr)
      },

      openShow(row) {
        if (!this.no_show_link) {
          this.$refs['grid-modals'].openShow(row)
        }
      },

      openForm(data) {
        this.$refs['grid-modals'].openForm(data)
      },

      sortBy(column) {
        let pagination = {
          sortBy: column,
          descending: !this.currentGrid.pagination.descending,
          page: 1,
          rowsPerPage: this.currentGrid.pagination.rowsPerPage,
          rowsNumber: this.currentGrid.pagination.rowsNumber
        };

        this.nextPage = 2

        this.onRequest({
          pagination: pagination
        })
      },

      loadMore () {
        let lastPage = this.currentGrid.count / this.pageSize

        if (!this.error && !this.loadingMore && (this.nextPage - 1) < lastPage) {
          this.loadingMore = true;

          this.$nextTick(() => {
            let pagination = {
              sortBy: this.currentGrid.pagination.sortBy,
              descending: this.currentGrid.pagination.descending,
              page: this.nextPage,
              rowsPerPage: this.currentGrid.pagination.rowsPerPage,
              rowsNumber: this.currentGrid.pagination.rowsNumber
            };

            this.nextPage++

            this.onRequest({
              pagination: pagination
            })
          })
        }
      },

      refreshTableRows(row) {

        let item = row.attr.data || row.attr

        if (row.action === 'create') {
          if (this.grid !== 'issues' || (this.grid === 'issues' && item.service_id.toString() === this.grid_key.toString())) {

            this.currentGrid.data.unshift(item)

            if (this.currentGrid.data.length === 0) {
              this.hideTable(true)
              // this.$emit('hide-table', true)
            } else {
              this.hideTable(false)
              // this.$emit('hide-table', false)
            }
          }

        } else if (row.action === 'update') {

          if (this.reload_after_update) {
            this.reloadData()

          } else if (item.state) {
            if ((this.grid === 'issues' && item.service_id.toString() !== this.grid_key.toString())
                  || (this.grid === 'issues' && ['closed', 'rejected'].includes(item.state))
                  || (this.grid === 'archive' && !['closed', 'rejected'].includes(item.state))) {
              this.currentGrid.data = this.currentGrid.data.filter(el => el.id !== item.id)

            } else {
              let current_row = this.currentGrid.data.find(el => el.id === item.id)
              Object.assign(current_row, item)
            }

          } else {
            let current_row = this.currentGrid.data.find(el => el.id === item.id)
            Object.assign(current_row, item)
          }

        } else if (row.action === 'destroy') {

          this.currentGrid.data = this.currentGrid.data.filter(el => el.id !== item.id)

          if (this.currentGrid.data.length === 0) {
            this.hideTable(true)
            // this.$emit('hide-table', true)
          } else {
            this.hideTable(false)
            // this.$emit('hide-table', false)
          }
        }
      },

      reloadData() {
        this.onRequest({
          pagination: this.currentGrid.pagination
        })
      },

      onRequest (props) {
        let params = props.pagination;

        params.query = this.searchAllFilter
        params.filters = this.generateFiltersParams()
        params.except_filters = this.$store.state.grid[this.grid]['except_filters']
        params.grid = this.grid

        this.$emit('get-collection', params)
      },

      actions_menu_visible() {
        return Array.isArray(this.actionsByCheckBox) &&
                this.actionsByCheckBox.length > 0 &&
                Array.isArray(this.actionsByCheckBoxRows) &&
                this.actionsByCheckBoxRowsCount > 0
      },

      removeCheckboxes() {
        this.$store.commit('updateActionsByCheckbox', { grid_name: this.grid })
        this.actionByCheckboxKey += 1
      }
    },

    channels: {
      IssuesChannel: {
        connected() {
          // console.log('I am connected to "IssuesChannel".');
        },
        received(data) {
          let current_user = this.$store.state.account.current_user
          let available_roles = ["dispatcher/executor", "dispatcher", "service_admin"]

          if (data['sender_id'] !== current_user['id'] && available_roles.includes(current_user['role'])) {
            this.refreshTableRows(data)
          }
        }
      },

      // AnyOtherChannel: {
      //   connected() {
      //     console.log('I am connected to "AnyOtherChannel".');
      //   },
      //   received(data) {
      //     console.log(data)
      //   }
      // }
    },

    created(attr={}) {
      attr = {
        grid_name: this.grid,
        grid_key: this.grid_key,
        sort_by: this.sort_by
      }
      attr['descending'] = this.descending !== undefined ? this.descending : true
      this.$store.commit('initialGrid', attr)

      setTimeout(() => {

        this.onRequest({
          pagination: this.currentGrid.pagination
        })
      }, 1000)
    },

    beforeCreate() {
      // this.$cable.subscribe({ channel: this.channel });
    }
  }
</script>

<style lang='scss'>
</style>
