<template lang='pug'>
  div
    div(class="entity-view__label") {{map_locales['building_title']}}:
    div(class="entity-view__value") {{ entity.getTitle() }}

    div(class="entity-view__label") {{map_locales['building_address']}}:
    div(class="entity-view__value") {{ entity.getAddress() }}

    div()
      Tabs(@click='activeTelemetryTab = $event' :active='activeTelemetryTab' :items='telemetryTabs')
      telemetry(v-if='activeTelemetryTab == "telemetry"' :building="entity")

    div(style='display: flex; margin-top: 20px; justify-content: space-between;')
      QuantityCard(:title="map_locales['issues_count']" :value='entity.getActiveIssuesNumber()' background='total')
      QuantityCard(:title="map_locales['emergencies']" :value='entity.getUrgentIssuesNumber()' background='urgent')

    Tabs(@click='activeChartTab = $event' :active='activeChartTab' :items='chartTabs')
    apex-chart(v-if='activeChartTab == "rlsa"' :parentData='{chart_data: ratesAverageByDay}')

    Tabs(@click='activeTableTab=$event' :active='activeTableTab' :items='tableTabs')
    PillList(v-if='activeTableTab == "rooms"' :items='roomItems')
    IssuesTable(v-if='activeTableTab == "issues"' :issues='issues')
</template>

<script>
  import IssuesTable from '../EntityView/IssuesTable'
  import PillList from '../EntityView/PillList'
  import Tabs from '../EntityView/Tabs'
  import apexChart from '../../dashboard/charts/apexChart'
  import QuantityCard from '../EntityView/QuantityCard'
  import Telemetry from '../EntityView/telemetry'

  export default {
    data() {
      return {
        issues: [],
        rooms: [],
        ratesAverageByDay: [],
        activeTableTab: 'issues',
        // tableTabs: [
        //   { key: 'issues', title: this.map_locales['tab_title_issues'] },
        //   { key: 'rooms', title: this.map_locales['tab_title_rooms'] },
        // ],

        activeChartTab: 'rlsa',
        // chartTabs: [
        //   { key: 'rlsa', title: this.map_locales['rlsa_title'] },
        //   // { key: 'temperature', title: this.map_locales['temperature_title'] },
        // ],
        activeTelemetryTab: 'telemetry'
      };
    },
    props: {
      entity: Object,
    },
    computed: {
      roomItems() {
        return this.rooms.map(r => ({ key: r.id, title: r.title }));
      },

      tableTabs() {
        return [
          { key: 'issues', title: this.map_locales['tab_title_issues'] },
          { key: 'rooms', title: this.map_locales['tab_title_rooms'] },
        ]
      },

      chartTabs() {
        return [
          { key: 'rlsa', title: this.map_locales['rlsa_title'] },
          // { key: 'temperature', title: this.map_locales['temperature_title'] },
        ]
      },

      telemetryTabs() {
        return [
          { key: 'telemetry', title: this.map_locales['tab_title_telemetry'] },
          { key: 'all_sensors', title: this.map_locales['tab_title_all_sensors'] }
        ]
      }
    },
    components: {
      IssuesTable,
      PillList,
      Tabs,
      apexChart,
      QuantityCard,
      Telemetry
    },
    mounted() {
      this.loadDetails().then(response => {
        this.issues = response.data.issues;
        this.rooms = response.data.rooms;
        this.ratesAverageByDay = response.data.rates_average_by_day;
      });
    },

    methods: {
      loadDetails() {
        return this.$backend.index(`/api/v3/map/buildings/${this.entity.getId()}`);
      },
    },
  }
</script>

<style lang="scss">
</style>
