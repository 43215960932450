import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../components/view/dashboard/index'
import Login from '../components/shared/Login'
import gridRoutes from './modules/grids'
import Main from '../components/shared/Main'
import store from '../store/index'
import Map from '../components/view/map/index'
import SalesForm from '../components/view/sales/form'

Vue.use(VueRouter)


let baseRoutes = [
  {
    name: 'Main',
    path: '/',
    component: Main
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard
  },
  {
    name: 'map',
    path: '/map',
    component: Map,
  },
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    name: 'SalesForm',
    path: '/sales-form',
    component: SalesForm
  }
];

let routes = baseRoutes.concat(gridRoutes);

export const router = new VueRouter({
  mode: 'history',
  hashbang: false,
  routes
});

router.beforeResolve((to, from, next) => {
  const publicPages = ['Login', 'SalesForm'];
  const authRequired = !publicPages.includes(to.name);
  const isAuthenticated = store.state.account.auth_token && store.state.account.current_user;

  if (authRequired && !isAuthenticated) {
    next({ name: 'Login' })
  } else if (isAuthenticated && ['/', '/login'].includes(to.path)) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
})
