<template lang='pug'>

  div(class="qr-print")
    div(class="facility-title")
      span {{data.facility_title}}
    div(class="logo")
      img(:src="require(`../../../../../../../assets/images/amelia_logo.jpg`)")
    div(class="serial_number")
      span {{"Инв. № " + data.description}}
    div(v-if="data.qr" class="qr" v-html="data.qr")


</template>

<script>

  export default {
    data: function () {
      return {
        data: this.parentData.data,
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    computed: {
    },

    methods: {
    }
  }
</script>

