import echarts from 'echarts/dist/echarts'
export const tests = (propsData, title) => ({
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    grid: {
        show: false
    },
    legend: {
        data: [title],
        right: '10%',
        icon: "circle"
    },
    xAxis: [{
        boundaryGap: ['20%', '20%'],
        data: propsData.map(data => data.date),
        nameTextStyle: {
            color: 'white'
        },
        axisLabel: {
            margin: 18
        },
        showGrid: false,
        splitLine: {
            show: false
        },
        axisLine: {
            lineStyle: {
                color: 'white'
            }
        }
    }],
    yAxis: [{
        type: 'value',
        nameTextStyle: {
            color: 'white'
        },
        axisLabel: {
            margin: 18
        },
        axisLine: {
            lineStyle: {
                color: 'white'
            }
        },
        showGrid: false,
        splitLine: {
            show: true
        }
    }],
    series: [
        {
            type: 'bar',
            name: title,
            color: '#59D4B0',
            data: propsData.map(data => data.tests),
            itemStyle: {
                barBorderRadius: [10, 10, 10, 10],
                color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [

                        {offset: 0.2, color: '#5675F6'},
                        {offset: 0.3, color: '#5775F7'},
                        {offset: 0.4, color: '#7086FA'},
                        {offset: 0.5, color: '#7087FA'},
                        {offset: 0.6, color: '#536FFF'},
                        {offset: 0.7, color: '#1859CF'},
                        {offset: 0.8, color: '#4951EC'}
                    ]
                )
            },
            barMaxWidth: 20
        }
    ]
})
