import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex)

export const dashboard = {
  
  state: {
  },

  
  
  mutations: {
  },
  
  
  actions: {
  }

}