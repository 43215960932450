<template lang='pug'>

  div(style="box-shadow: initial;")
    div(class="files-area-body")
      div(class="existing-file" v-for="file in files")
        div(v-if="isImage(file)" class="form-image")
          a(v-if="file" class="file-link" :href="file.url" target="_blank" :link_title="file.file_name")
            img(class="file-image" :src="file.url")
            span(class="caption") {{ cutFileName(file.file_name) }}

        div(v-else class="form-image")
          a(v-if="file" class="file-link" :href="file.url" target="_blank" :link_title="file.file_name")
            inline-svg(class="file-document" :src="require('../../../assets/icons/document.svg')")
            span(class="caption") {{ cutFileName(file.file_name) }}

</template>

<script>

  export default {
    data: function () {
      return {
        files: this.parentData.files
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    computed: {
    },

    methods: {
      closeFilesModal() {
        this.$emit('close-files-modal')
      },

      isImage(value) {
        if (value) {
          return ['image/jpeg', 'image/png'].includes(value.content_type)
        } else {
          return false
        }
      },

      cutFileName(name) {
        return name.length > 16 ? name.substring(0, 14) + '...' : name
      },
    }

  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/file";

</style>