<template lang='pug'>

  div(class="login-component")

    video(autoplay muted loop)
      source(type="video/mp4" :src="require('../../assets/videos/amelia-background-video.mp4')")

    div(class="login-background")

    div(v-if="loading || loading_form" class="spinner-container form-spinner")
      q-spinner(color="primary" size="3em")

    div(v-if="!loading && !login_locales_loading" class="q-pa-md row items-start justify-center")
      div(v-show="!loading_form" id="login-card")

        div(class="login-logo row justify-center")
          inline-svg(:src="require('../../assets/icons/login/login-logo.svg')")

        login-form(v-if="!password_recovery && !reset_password_form"
                            @password-recovery="onPasswordRecovery"
                            @set-loading="setLoading")

        password-recovery-form(v-if="password_recovery"
                            @password-recovery="onPasswordRecovery"
                            @set-loading="setLoading")

        password-reset-form(v-if="reset_password_form"
                            :reset_password_token="reset_password_token"
                            @password-reset="onPasswordReset"
                            @set-loading="setLoading")

</template>

<script>
  import LoginForm from '../view/login/LoginForm'
  import PasswordRecoveryForm from '../view/login/PasswordRecoveryForm'
  import PasswordResetForm from '../view/login/PasswordResetForm'

  export default {
    data: function () {
      return {
        password_recovery: false,
        reset_password_form: false,
        reset_password_token: '',
        loading_form: true,
        loading: true,
        login_locales_loading: true
      }
    },

    components: {
      LoginForm,
      PasswordRecoveryForm,
      PasswordResetForm
    },

    methods: {

      onPasswordRecovery(val) {
        this.password_recovery = val
      },

      onPasswordReset(val) {
        if (!val) {
          this.reset_password_token = ''
        }
        this.reset_password_form = val
      },

      setLoading(val) {
        this.$emit('set-loading', val)
        this.loading_form = val
      },
    },

    created(){
      this.loadDefaultLocale()
      this.loadDefaultLocalesData()
      .finally(() => {
        this.login_locales_loading = false
      })
    },

    beforeMount() {
      if (this.$route.query['reset_password_token']) {
        this.reset_password_token = this.$route.query['reset_password_token']
        this.reset_password_form = true
      }
    },

    mounted() {
      setTimeout(() => { this.loading = false },1500)
    }
  }
</script>

<style lang='scss'>

  @import '../../assets/styles/login.scss';

</style>