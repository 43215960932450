<template lang='pug'>

  div
    div(v-if="loading" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")

    high-chart(v-if="!loading && chart.length > 0"
                ref="high-chart"
                :chart="{height: 153, margin_top: 20}"
                :series="series"
                :categories="categories"
                :step_width="step_width"
                :yaxis="{min: 0, soft_max: 120, tick_interval: 40}"
                :legend="{enabled: false}"
                :tooltip="tooltip"
                :begin_extr="{start: begin_extr_start, end: begin_extr_end}"
                :plot_options="{}"
                :chart_type="{type: 'column'}")

    grid-hidden-msg(ref="expenses-chart-hidden-msg" :parentData="{hide_table: hide_table}")

</template>

<script>
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'
  import highChart from '../../../shared/helpers/charts/highChart'

  export default {
    data() {
      return {
        data: {},
        path: this.parentData.path,
        loading: true,
        hide_table: false,

        tooltip: {
          headerFormat:   '<span class="tooltip-header">{point.key}</span><table class="tooltip-table">',
          pointFormat:    '<tr><td class="tooltip-point-name" style="color:{series.color};">{series.name}: </td>' +
            '<td class="tooltip-point-value"><b>{point.y}</b></td></tr>',
          footerFormat:   '</table>',
          shared: true,
          useHTML: true,
          className: 'tooltip-chart',
          backgroundColor: '#5B7AFF',
          borderWidth: 0,
          borderRadius: 12,
          padding: 15
        }
      }
    },

    props: {
      parentData: Object
    },

    components: {
      highChart,
      gridHiddenMsg
    },

    computed: {
      chart() {
        return this.data && this.data.data || []
      },

      series() {
        return this.data.data.map (v => {
          return {
            name: v.name,
            data: v.data.map(val => {
              return {y: val.value, color: v.color}
            }),
            color: v.color
          }
        })
      },

      categories() {
        return this.data.data.map(val => val.data.map(val => val.month))[0]
      },

      step_width() {
        return this.categories && this.categories.length < this.data.step_width ? this.categories.length : 4
      },

      begin_extr_start() {
        return !this.categories || this.categories.length <= this.data.step_width ? this.step_width : this.categories.length - this.step_width
      },

      begin_extr_end() {
        return !this.categories || this.categories.length <= 1 ? 0 : this.categories.length
      }
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['expenses-chart-hidden-msg']) {
          this.$refs['expenses-chart-hidden-msg'].hideTable(val)
        }
      },

      loadChartData(params={}) {
        this.$backend.collection(`${this.path}/expenses_chart`, params)
        .then(({ data }) => {
          this.data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.loading = false

          if (this.chart.length > 0) {
            this.hideTable(false)
          } else {
            this.hideTable(true)
          }
        })
      }

    },

    created() {
      this.loadChartData()
    }
  }
</script>

<style lang="scss">

  .card.expenses {

    .hidden-tabs-message {
      height: 150px !important;

      span {
        padding-top: initial;
      }
    }

    .spinner-container.grid-spinner {
      height: 150px !important;
    }
  }

</style>
