import emailField from './email_field'
import floatField from './float_field'
import phoneField from './phone_field'
import selectField from './select_field'
import stringField from './string_field'
import editorField from './editor_field'
import textField from './text_field'
import fileField from './file_field'
import checkboxField from './checkbox_field'
import passwordField from './password_field'
import radioCollectionField from './radio_collection_field'
import dateField from './date_field'
import ratingField from './rating_field'
import infoField from './info_field'
import iconField from './icon_field'
import colorpickerField from './colorpicker_field'
import planField from './plan_field'

export default {
  email:              emailField,
  float:              floatField,
  phone:              phoneField,
  select:             selectField,
  string:             stringField,
  editor:             editorField,
  text:               textField,
  file:               fileField,
  checkbox:           checkboxField,
  password:           passwordField,
  radio_collection:   radioCollectionField,
  datetime:           dateField,
  date:               dateField,
  rating:             ratingField,
  info:               infoField,
  icon:               iconField,
  colorpicker:        colorpickerField,
  plan:               planField
}