<template lang='pug'>
  div
    div(class="entity-view__label") {{map_locales['vehicle_model']}}:
    div(class="entity-view__value") {{ entity.getModel() }}

    div(class="entity-view__label") {{map_locales['vehicle_number']}}:
    div(class="entity-view__value") {{ entity.getPlateNumber() }}

    div(class="entity-view__label") {{map_locales['vehicle_type']}}:
    div(class="entity-view__value") {{ entity.getVehicleType() }}

    div(class="entity-view__label") {{map_locales['driver']}}:
    div(class="entity-view__value") {{ entity.getDriverName() }}

    div(class="entity-view__label") {{map_locales['phone']}}:
    div(class="entity-view__value") {{ entity.getDriverPhoneNumber() }}
</template>

<script>

export default {
  props: {
    entity: Object,
  }
}
</script>

<style lang="scss">
</style>
