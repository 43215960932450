<template lang='pug'>

  div(class="header")
    div(class="header-title-block row")
      div(class="col-6 header-title") {{data.header.title}}
      div(class="col-6 header-actions")

        component(v-if="parent_actions.length > 0"
                  v-for="action in parent_actions"
                  :is="getActionComponent(action)"
                  :parentData="{grid: grid, item: item}")

        theme-handler
        locale-handler(@reload-data="reloadData")
        profile(@reload-data="reloadData")

    div(class="header-title-spacer")

    div(v-if="!hide_filters && !hide_buttons" class="header-filters-block row")

      grid-filters(ref="grid-filters" v-if="!hide_filters"
                    class="col-6"
                    :parentData="{path: path, grid: grid, data: data.filters_data, header: data.header}")

      grid-button-group(v-if="!hide_buttons"
                        class="col-6"
                        :parentData="{grid: grid, grid_key: grid_key, buttons: data.buttons}")

</template>


<script>
  import gridButtonGroup from '../grid/gridButtonGroup'
  import gridFilters from '../grid/gridFilters'
  import Profile from '../../view/profile'
  import ThemeHandler from '../../view/theme/edit'
  import LocaleHandler from '../../view/locale/edit'
  import headerActions from './header/actions'


  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        item: this.parentData.item,
        grid_key: this.parentData.grid_key,
        hide_filters: this.parentData.hide_filters,
        hide_buttons: this.parentData.hide_buttons,
      }
    },
    props: {
      parentData: Object
    },

    computed: {
      data() {
        return this.parentData.data
      },

      parent_actions() {
        return this.parentData.actions || []
      }
    },

    components: {
      Profile,
      ThemeHandler,
      LocaleHandler,
      gridButtonGroup,
      gridFilters,
      headerActions
    },

    methods: {

      getActionComponent(action) {
        return headerActions[action]
      },

      updateFilter(attr) {
        this.$refs['grid-filters'].updateFilter(attr)
      },

      reloadData() {
        this.$root.$emit('reload-navbar')
        this.$emit('reload-data')
        this.$root.$emit('reload-hidden-message')
      }
    },

    created() {
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>
</style>