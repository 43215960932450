<template lang='pug'>

  prompt(v-if="prompt" :ref="prompt.class"
  @customize-prompt="customizePrompt"
  @close-modal="closeModal" :parentData="{prompt: prompt}")


</template>

<script>
  import Prompt from '../../../prompt'

  export default {

    props: {
      parentData: Object
    },

    components: {
      Prompt
    },

    computed: {
      prompt() {
        return this.parentData.prompt
      }
    },

    methods: {

      customizePrompt(parent_el, arrow, card) {
        let header = document.getElementsByClassName('header')[0].offsetHeight
        let tabs = document.getElementsByClassName('q-tabs')[0].offsetHeight

        arrow.style.top = `${header + tabs + parent_el.offsetTop + parent_el.offsetHeight + 49}px`
        card.style.top = `${header + tabs + parent_el.offsetTop + parent_el.offsetHeight}px`
        card.style.left = `${parent_el.offsetLeft + parent_el.offsetWidth + 120}px`
        arrow.style.left = `${parent_el.offsetLeft + parent_el.offsetWidth + 115}px`

        this.$refs[this.prompt.class].showPrompt()
      },

      closeModal() {
        this.$emit('close-modal')
      }
    }
  }
</script>

<style lang='scss'>

  .modal-form-card.ui-prompt-card.issues-grid-checkboxes {
    width: 300px !important;
  }


</style>