<template lang='pug'>
    div(:class="default_class")
        q-checkbox(v-model="checkbox_select"
        :id="`${grid}-grid-checkbox`"
        @input="inputCheckbox" )
</template>

<script>

    export default {
        data: function () {
            return {

                default_class: this.parentData.default_class || '',
                row: this.parentData.row,
                path: this.parentData.path,
                grid: this.parentData.grid,
                transition_key: this.parentData.transition_key,
                grid_key: this.parentData.grid_key,
                current_action: {},
                checkbox_select: this.parentData.checkbox_select,

                modal: {
                    transition_form: false
                },

                confirm_modal: false,
                confirm_message: '',
                destroy_data: undefined
            }
        },

        props: {
            parentData: Object
        },
        methods: {
            inputCheckbox() {
                let checkBoxData = {
                    row: this.row, actions_by_checkbox: this.row.actions_by_checkbox
                }
                this.$store.commit('updateActionsByCheckbox', { grid_name: this.grid, value: checkBoxData })
            }
        },

        components: {

        },

    }
</script>

<style lang="scss">
</style>