<template lang='pug'>

  div(class="floors")
    div(class="card-label")
      span {{data.title}}
    div(class="floors-wrapper")
      div(v-show="floors.length > 0" class="telemetry-floors-swiper-area")
        swiper(ref="floorsSwiper" :options="options" class="main-swiper")
          div(class="floor-badge swiper-slide" :id="`floor-badge-${f.id}`" v-for="f in floors" @click="onClickFloor(f)")
            span {{f.short_title}}
          div(class="swiper-pagination" slot="pagination")

      grid-hidden-msg(v-show="!floors.length > 0" ref="floors-hidden-msg" :parentData="{hide_table: hide_table}")

</template>

<script>
  import { Swiper } from 'vue-awesome-swiper';
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'

  export default {
    data: function () {
      return {
        building: this.parentData.building,
        options: {
          spaceBetween: 10,
          slidesPerView: 'auto',
          slidesPerGroup: 3,
          grabCursor: true,
          loop: false,
          freeMode: true,
          mousewheel: {
            forceToAxis: true
          },
          autoplay: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },

        hide_table: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      Swiper,
      gridHiddenMsg
    },

    computed: {
      data() {
        return this.parentData.data
      },

      building_id() {
        return this.building && this.building.id || ''
      },

      floors() {
        return this.parentData.floors
      },

      floor() {
        return this.parentData.floor
      }
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['floors-hidden-msg']) {
          this.$refs['floors-hidden-msg'].hideTable(val)
        }
      },

      onClickFloor(floor) {
        if (floor) {
          let attr = {
            item: 'floor',
            new_item: floor
          }
          this.activeBadge(attr)
          this.$emit('set-floor', floor)
          this.$emit('load-rooms', {floor_id: floor.id})
          this.$emit('load-sensors', {floor_id: floor.id})
        }
      }
    }
  }
</script>

<style lang='scss'>

  .floors {
    height: fit-content;
    border-bottom: 1px dashed #8d8d8d30;

    .floors-wrapper {
      min-height: 90px;
      height: fit-content;

      .hidden-tabs-message {
        height: 75px;

        span {
          padding-top: initial;
        }
      }

      .main-swiper {
        height: 70px;

        .swiper-pagination-bullet {
          background: #007AFF;
        }
      }
    }

    .telemetry-floors-swiper-area {
      width: 100%;
      padding: 20px 5px 0px 5px;
    }

    .swiper-slide {
      width: fit-content !important;
    }

    .floor-badge {
      height: fit-content;
      padding: 5px;
      border-radius: 15px;
      background: var(--telemetry-floor-badge-background);
      padding-left: 30px;
      padding-right: 30px;
      cursor: pointer;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;

        white-space: nowrap;

        color: var(--dashboard-label-color);
      }
    }

    .active-badge {
      background: #5B7AFF !important;

      span {
        color: #FFFFFF !important;
      }
    }
  }

</style>