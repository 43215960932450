<template lang='pug'>
  div
    q-card(v-show="dataLoaded" class="notification-rules-panel no-shadow")
      q-card-section
        div(class="row")
          div(class="col-12 col-md-2 rules-panel-header")
            span {{notification_rule_locales.role}}
          div(class="col-12 col-md-1 rules-panel-header" v-for='label in data.labels')
            span {{label}}

        div(class="row" v-for='role in data.roles')
          div(class="rules-panel-role-column col-12 col-md-2")
            span {{role[0]}}

          div(class="rules-panel-checkboxes col-12 col-md-1" v-for='event in data.events')
            div(class="notification-checkbox" v-for='type in data.types')
              check-box(@update-rule="updateNotificationRule" :parentData="{grid: grid, label: type, name: event + '-' + role[1] + '-' + type}")

</template>


<script>
  import checkBox from './checkBox'

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        data: [],

        dataLoaded: false
      }
    },
    props: {
      parentData: Object
    },

    computed: {
      facility_id() {
        return this.currentFilters['facility_id']
      },
      service_id() {
        return this.currentFilters['service_id']
      },

      notification_rule_locales() {
        return this.locales.notification_rule[this.current_locale]
      }
    },

    watch: {
      facility_id(newValue, oldValue) {
        if (newValue) {
          let params = {
            facility_id: newValue.value,
            service_id: null
          }
          this.fillGridData(params)

        } else if (oldValue) {
          let attr = {
            grid_name: this.grid,
            facility_id: oldValue.value
          }
          this.$store.commit('resetNotificationRule', attr)
        }
      },

      service_id(newValue, oldValue) {
        if (this.facility_id && (newValue || newValue == null)) {
          let params = {
            facility_id: this.facility_id.value,
            service_id: newValue ? newValue.value : newValue
          }
          setTimeout(() => {
            this.fillGridData(params)
          }, 300)
        }
      }
    },

    components: {
      checkBox
    },

    methods: {

      reloadData() {
        this.dataLoaded = false
        this.loadGridData()
      },

      loadGridData() {
        this.$backend.index(this.path, { params: {} })
        .then(({ data }) => {
          this.data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.dataLoaded = true
        })
      },

      fillGridData(params) {
        this.$backend.index(this.path+'/data', { params: params })
        .then(({ data }) => {
          // console.log(data)

          let attr = {
            grid_name: this.grid,
            facility_id: data.facility_id,
            service_id: (data.service_id === '' ? null : data.service_id),
            grid_data: data.data
          }

          this.$store.commit('initialNotificationRule', attr)
          this.$store.commit('fillNotificationRule', attr)
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
      },

      updateNotificationRule(rule) {
        let name = rule.name.split('-')

        let params = {
          facility_id: rule.facility_id,
          service_id: rule.service_id,
          notification: name[0],
          role_title: name[1],
          notify_type: name[2],
          notify_value: rule.value
        }

        this.$backend.update_or_create(this.path, params)
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          rule['grid_name'] = this.grid
          this.$store.commit('updateNotificationRule', rule)
        })
      }
    },

    channels: {
    },

    created() {
      this.loadGridData()
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>
</style>