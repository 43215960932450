<template lang='pug'>

  div(class="col-12 col-lg-6 fill-multiselect")
    div(class="form-field-label")
      span {{field.title}}
      span(v-show="field.required" class="form-field-require-tag" style="color:red;" :title="field.title")
        |*

    div(class="form-field form-multiselect checklist-multiselect")
      multiselect(v-model="value"
                :options="options",
                :searchable="false",
                track-by="id"
                :close-on-select="true"
                :show-labels="false"
                :placeholder="readonly ? checklist_locales.value_not_selected : checklist_locales.select_value"
                @select="editField"
                :disabled="readonly"
                open-direction="bottom"
                :hideSelected="false"
                @remove="resetField")
        template(slot="singleLabel", slot-scope="props")
            div.option__desc
                div.option__title {{ props.option.title }}
        template(slot="option", slot-scope="props")
            div.option__title {{ props.option.title }}


      q-icon(name="cancel" v-if="value && !readonly" @click.stop="resetField()" class="cancel-select-field")

</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    components: {
        Multiselect,
    },

    data() {
        return {
            value: null,
            options: this.field.options
        }
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean || undefined,
            required: false,
            default: false
        },
      initialValue: {
          type: Array,
          required: false,
          default: () => ([{value: null}])
      }
    },
    computed: {
      checklist_locales() {
        return this.locales.checklist[this.current_locale]
      }
    },
    methods: {
        resetField() {
            this.value = ''
            this.$emit('reset-field', {id: this.field.id})
        },
        editField(val) {            
            if (!this.readonly) this.$emit('edit-field', {id: this.field.id, value: [{value: val.id, title: val.title}]})
        },
    },
    created() {

        if (this.readonly) {
            this.options = this.field._data.value && this.field._data.value.length > 0 ? [{title: this.field._data.value[0].title, id: this.field._data.value[0].value} ]: []
            this.value = this.field._data.value && this.field._data.value.length > 0 ? [{title: this.field._data.value[0].title, id: this.field._data.value[0].value} ]: []
        }
        else if (this.initialValue[0].value) this.value = this.options.find(elem => elem.id === this.initialValue[0].value)
    },
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/select";
  .fill-multiselect {
    .multiselect--disabled {
      background: inherit;
      .multiselect__select {
        background: inherit;
      }
    }
  }
  .checklist-multiselect {
    border: var(--field-border) !important;
    .multiselect--active{
      .multiselect__content-wrapper{
        box-shadow: none;
        border: var(--field-border) !important;
      }
    }
  }

</style>