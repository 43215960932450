<template lang='pug'>
  div(class="index-page telemetry dashboard")

    div(v-if="!loading && loading_data" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")


    div(v-if="!loading")
      common-header(v-show="!loading_data"
                  @reload-data="reloadData"
                  :parentData="{data: data, grid: grid, item: item, hide_filters: true, hide_buttons: true, actions: header_actions}")


    div(v-if="!loading && !loading_data" class="telemetry-blocks")
      div(class="telemetry-blocks-floor")
        div(class="card")
          floors(ref="floors" @set-floor="setFloor" @load-rooms="loadRooms" @load-sensors="loadSensors" :parentData="{data: data.boards.floors, building: building, floors: floors, floor: floor}")
          rooms(ref="rooms" @set-room="setRoom" @load-sensors="loadSensors" :parentData="{data: data.boards.rooms, building: build, room: room}")
      div(class="telemetry-blocks-sensors")
        building-info(:parentData="{building: building}")
        sensors(ref="sensors" @load-indications="loadIndications" :parentData="{data: []}")
      div(class="telemetry-blocks-indications")
        indications(ref="indications" @load-indications-by-hours="loadIndicationsByHours" :parentData="{data: data.boards.indications}")
      div(class="telemetry-blocks-floor-plan")
        floor-plan(ref="floor-plan" :parentData="{building: building, floors: floors, floor: floor, room: room}")
      div(class="telemetry-blocks-indications-hours")
        indications-by-hours(@open-modal="openModal" ref="indications-by-hours" :parentData="{grid: lists[0], data: data.boards.indications_by_hours}")


    q-dialog(v-model="modal.state")
      telemetry-modal(@modal-callback="modalCallback" :parentData="modal.data")

</template>

<script>
  import commonHeader from '../../shared/helpers/Header'
  import floors from './boards/floors'
  import rooms from './boards/rooms'
  import camera from './boards/camera'
  import buildingInfo from './boards/building_info'
  import sensors from './boards/sensors'
  import floorPlan from './boards/floor_plan'
  import indications from './boards/indications'
  import indicationsByHours from './boards/indications_by_hours'
  import telemetryModal from './modal'

  export default {
    data: function () {
      return {
        grid: 'telemetry',
        item: 'telemetry',
        modal: {
          state: false,
          data: {},
        },
        lists: [
          'indications_by_hours'
        ],
        path: this.$store.state.paths.telemetry,
        data: {},
        loading: true,
        loading_data: true,
        building: {},
        header_actions: [
          'project_filter_form'
        ],
        current_floors: [],
        current_floor: {},
        current_room: {}
      }
    },

    components: {
      commonHeader,
      floors,
      rooms,
      camera,
      buildingInfo,
      sensors,
      floorPlan,
      indications,
      indicationsByHours,
      telemetryModal
    },

    computed: {
      build() {
        return this.building
      },

      building_id() {
        return this.building && this.building.id || ''
      },

      floors() {
        return this.current_floors
      },

      floor() {
        return this.current_floor
      },

      room() {
        return this.current_room
      }
    },

    watch: {
      loading(newVal, oldVal) {
        this.$emit('set-loading', this.loading)

        // setTimeout(() => {
          this.loading_data = newVal
        // }, 2500)
      }
    },

    methods: {

      setFloor(floor) {
        this.current_floor = floor
      },

      setRoom(room) {
        this.current_room = room
      },

      openModal(data) {
        this.modal.state = true
        this.modal.data = data
      },

      reloadData() {
        this.loadDashboardData()
      },

      loadDashboardData(params={}) {
        if (this.$route.query.building_id) {
          params['building_id'] = this.$route.query.building_id
        }

        if (this.filters) { params.filters = this.generateFiltersParams() }

        this.$backend.index(`${this.path}/data`, { params: params })
        .then(({ data }) => {
          this.data = data
          this.building = data.building
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }
          console.log(error)
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.loadFloors()
          }, 500)
        })
      },

      loadFloors(params={}) {
        params['building_id'] = this.building_id

        if (params['building_id']) {
          this.$backend.collection(`${this.$store.state.paths.floor}/collection`, params)
          .then(({ data }) => {
            this.current_floors  = data.options
          })
          .catch((error) => {
            console.log(error);
            if (error.response) { this.reLogin(error.response.status) }
          })
          .finally(() => {
            this.loadRooms()
            if (this.floors.length > 0) {
              if (this.$refs.floors) { this.$refs.floors.hideTable(false) }
            } else {
              if (this.$refs.floors) { this.$refs.floors.hideTable(true) }
            }
          })
        } else {
          if (this.$refs.floors) { this.$refs.floors.hideTable(true) }
          this.loadRooms({floor_id: ''})
        }
      },


      loadRooms(params={}) {
        setTimeout(() => {
          if (this.$refs.rooms) {
            this.$refs.rooms.loadRooms(params)
          }
        },300)
      },

      loadSensors(params={}) {
        if (this.$refs.sensors) {
          this.$refs.sensors.loadSensors(params)
        }
      },

      loadIndications(params={}) {
        if (this.$refs.indications) {
          if (params['interval_reload']) {
            // this.$refs.indications.reloadIndications(params)
          } else {
            this.$refs.indications.loadIndications(params)
          }
        }
      },

      loadIndicationsByHours(params={}) {
        if (this.$refs['indications-by-hours']) {

          if (params['interval_reload']) {
            this.$refs['indications-by-hours'].loadChartData(params)
          } else {
            this.$refs['indications-by-hours'].reloadChart(params)
          }
        }
      },

      modalCallback(data) {
        if (this.$refs[data.ref]) {
          this.$refs[data.ref][data.fn](data.params)
        }
      }
    },

    created() {
      this.lists.forEach(list => {
        this.$store.commit('initialGridsState', {grid: list, attr: {}})
      })
    },

    beforeMount() {
      this.$emit('expand-class', {val: true, class: 'dashboard-background'})
      this.loadDashboardData()
    }
  }
</script>

<style lang='scss'>

  .dashboard-background {
    background-color: var(--dashboard-background-color) !important;
  }

  .index-page.object-pass.dashboard {
    min-height: 100vh;

    .spinner-container.grid-spinner {
      height: 100%;
    }
  }

  .index-page.telemetry.dashboard {

    .card {
      background: var(--dashboard-card-background);
      border-radius: 20px;
    }

    .card-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: var(--dashboard-label-color);
    }

    .telemetry-blocks {
      display: flex;
      overflow: auto;
      margin-top: 30px;
      width: 100%;
      justify-content: space-between;
      flex-flow: row wrap;

      &-floor{
        width: 27%;
        filter: drop-shadow(-4px 8px 24px rgba(44, 63, 88, 0.02));
        margin-bottom: 25px;
        height: 475px;
        overflow: scroll;

        .card{
          padding: 22px 28px;
        }

        @media (max-width: 1310px){
          width: 49%;
          order: 1;
          
          @media (max-width: 900px){
            width: 100%;
          }
        }
      }

      &-sensors{
        width: 30%;
        height: 475px;
        overflow: scroll;

        @media (max-width: 1310px){
          width: 49%;
          order: 2;

          @media (max-width: 900px){
            width: 100%;
          }
        }
      }

      &-indications{
        width: 39%;
        min-width: 475px;
        height: 475px;
        overflow: scroll;

        @media (max-width: 1310px){
          width: 49%;
          order: 3;
          min-width: initial;

          @media (max-width: 900px){
            width: 100%;
          }
        }
      }

      &-floor-plan{
        width: 49%;

        @media(max-width: 1310px){
          width: 100%;
          order: 5
        }
      }

      &-indications-hours{
        width: 49%;

        @media (max-width: 1310px){
          order: 4;

          @media (max-width: 900px){
            width: 100%;
          }
        }
      }
    }

    .card-image {
      text-align: center;
    }

    .card-button {
      top: 105px;
      width: 100%;
      text-align: center;

      button {
        height: 34px;
        border-radius: 8px;
        background-color: #5b7aff10;
        color: #5B7AFF;
        padding-left: 40px;
        padding-right: 40px;

        .q-btn__wrapper {
          min-height: initial !important;
        }

        .q-btn__content {

          span {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;

          }
        }
      }
    }
  }

</style>
