<template lang="pug">
#main(:class="[expand_class ? self_class + ' ' + expanded_class : self_class]")
  .spinner-container.form-spinner(v-if="check_auth_loading || !allDataLoaded")
    q-spinner(color="primary", size="3em")

  #modal-background

  .main-component(v-show="(!check_auth_loading && allDataLoaded) || not_auth_container")
    .navbar-container(v-if="!check_auth_loading && isAuthenticated() && !not_auth_container")
      navbar(@set-loading="setNavbarLoading", @set-main-loading="setMainLoading", @expand-class="expandClass")

    #main-container.main-container(v-if="!check_auth_loading && isAuthenticated()")
      .service-moved.service-moved--main
        .service-moved__wrapper
          p Уважаемые коллеги!

          p Информируем вас, что сервис Амелия обновляется до новой версии. Данный сайт больше не будет доступен с 
            b  20 сентября 2024 г.

          p Обновлённая версия системы доступна по ссылке 
            a(href="https://amelia-sm.ru/") https://amelia-sm.ru

          p Вы можете работать в новой системе с тем же логином. Войдите через кнопку 
            b  "Забыли пароль" 
            span  (если логин - ваша действующая почта). 

          div В ином случае для получения доступа обратитесь к руководителю своего подразделения.
      router-view(@set-loading="setMainLoading", @expand-class="expandClass", :key="$route.fullPath")

      q-dialog(v-model="report_modal", persistent)
        report-link-area(:parentData="{ report: report }")

    .login-container(v-if="!isAuthenticated()")
      .service-moved
        .service-moved__wrapper
          p Уважаемые коллеги!

          p Информируем вас, что сервис Амелия обновляется до новой версии. Данный сайт больше не будет доступен с 
            b  20 сентября 2024 г.

          p Обновлённая версия системы доступна по ссылке 
            a(href="https://amelia-sm.ru") https://amelia-sm.ru

          p Вы можете работать в новой системе с тем же логином. Войдите через кнопку 
            b  "Забыли пароль" 
            span  (если логин - ваша действующая почта). 

          div В ином случае для получения доступа обратитесь к руководителю своего подразделения.

      router-view(@set-loading="setMainLoading", :key="$route.fullPath")
</template>

<script>
import Navbar from "./Navbar";
import reportLinkArea from "./helpers/reportLinkArea";

export default {
  data: function () {
    return {
      navbar_loading: true,
      main_loading: true,
      self_class: "main",

      expand_class: false,
      expanded_class: "",

      intervals: [],
      report: {},
      report_modal: false,

      check_auth_loading: true,

      trouble_shooting_message:
        "Уважаемые пользователи, в настоящий момент имеются проблемы на стороне провайдера, что приводит к сбоям в работе программы. Приносим свои извинения. Прикладываем все усилия для устранения проблемы.",
    };
  },

  components: {
    Navbar,
    reportLinkArea,
  },

  computed: {
    allDataLoaded() {
      let main_loading = this.main_loading;
      return (
        (!this.isAuthenticated() && !main_loading) ||
        (this.isAuthenticated() && this.theme && !this.navbar_loading && !main_loading)
      );
    },

    not_auth_container() {
      return this.$store.state.not_auth_paths.includes(this.$route.path);
    },
  },

  watch: {
    current_user(newVal, oldVal) {
      // console.log('main current_user newVal', newVal)
      if (newVal) {
        this.setTheme(newVal.theme);
      }
    },

    check_auth_loading(newVal, oldVal) {
      if (newVal === false) {
        if (this.current_user) {
          this.setTheme();
        }
        this.reportLogic();
      }
    },
  },

  methods: {
    check_auth() {
      if (!this.not_auth_container) {
        this.$backend
          .index(this.$store.state.paths.user + "/current_theme", { params: {} })
          .then(({ data }) => {
            this.check_auth_loading = false;
          })
          .catch(error => {
            this.check_auth_loading = true;

            if (error.response) {
              this.reLogin(error.response.status);

              setTimeout(() => {
                this.check_auth_loading = false;
              }, 1500);
            }
          });
      } else {
        setTimeout(() => {
          this.check_auth_loading = false;
        }, 500);
      }
    },

    expandClass(data) {
      if (data) {
        this.expand_class = data.val;
        this.expanded_class = data.class;
      } else {
        this.expand_class = false;
        this.expanded_class = "";
      }
    },

    setNavbarLoading(val) {
      // setTimeout(() => {
      this.navbar_loading = val;
      // }, 1500)
    },

    setMainLoading(val) {
      // setTimeout(() => {
      this.main_loading = val;
      // }, 1000)
    },

    isAuthenticated() {
      return this.current_user && this.auth_token;
    },

    reportLogic() {
      this.$root.$on("init-report-intervals", data => {
        localStorage.setItem("report_id_waiting", data.id);
        this.initReportIntervals(data.id);
      });

      this.$root.$on("show-report-modal", data => {
        this.report = data.report;
        this.report_modal = data.value;
      });

      if (this.report_id_waiting) {
        this.initReportIntervals(this.report_id_waiting);
      }
    },

    getLocation() {
      // console.log(this.$router)
    },

    initReportIntervals(id) {
      this.destroyIntervals();
      this.intervals = [
        setInterval(() => {
          this.checkReportState(id);
        }, 20000),
      ];
    },

    checkReportState(id) {
      this.$backend
        .show(this.$store.state.paths["report"], id, { params: {} })
        .then(({ data }) => {
          if (data["expired"] && data["link"]) {
            this.destroyIntervals();
            localStorage.removeItem("report_id_waiting");
            this.report = data;
            this.report_modal = true;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.destroyIntervals();
          localStorage.removeItem("report_id_waiting");
          this.$q.notify(this.notifies.report_error);
        });
    },
  },

  created() {
    this.loadDefaultLocale();
    this.loadDefaultLocalesData();
    setTimeout(() => {
      this.check_auth();
    }, 300);
  },

  beforeDestroy() {
    this.destroyIntervals();
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/main.scss";
.service-moved {
  background-color: #d53032;
  color: white;
  padding: 25px;
  position: relative;
  z-index: 10;
  font-size: 20px;
  font-weight: 500;

  ul {
    margin-left: 15px;
    margin-bottom: 16px;
  }

  &__wrapper {
    width: 1050px;
    margin: auto;
  }

  &--main {
    border-radius: 16px;
    margin-top: 20px;

    ul {
      margin: 0 0 16px 15px;
    }
  }

  &__main-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: bold;
  }

  &__additional-info {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    margin-top: 10px;

    &__block {
      background: #ffffff26;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
}
</style>
  