<template lang='pug'>
  span(:class='className') {{ translation }}
</template>

<script>
export default {
  props: {
    state: String,
    translation: String,
  },

  computed: {
    className() {
      return `issue-state ${this.state}-badge`;
    }
  }
}
</script>

<style lang="scss">
  // @import('../../../../assets/styles/state_badges.scss')
</style>
