<template lang='pug'>

  div(v-if="meter_types.length > 0" class="meter-type-chart-swiper-area")
    swiper(ref="MeterTypeChartSwiper" :options="swiperOption")
      meter-type-chart(:ref="`meter-type-chart-${meter_type && meter_type.id || ''}`" class="swiper-slide" v-for="(meter_type, i) in meter_types"
                        :key="i"
                        :parentData="{grid: grid, grid_key: grid_key, meter_type: meter_type, theme: theme}")

    //div(v-if="meter_types.length > 3" class="swiper-button-prev" slot="button-prev")
    //div(v-if="meter_types.length > 3" class="swiper-button-next" slot="button-next")

</template>

<script>
  import MeterTypeChart from './MeterTypeChart'
  import { Swiper } from 'vue-awesome-swiper';

  export default {
    data() {
      return {
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key,
        windowWidth: '',
        options: {
          spaceBetween: 30,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          grabCursor: true,
          loop: false,
          freeMode: true,
          // speed: 10000,
          mousewheel: {
            forceToAxis: true
          },
          autoplay: false,
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // }
        }
      }
    },

    props: {
      parentData: Object
    },

    components: {
      Swiper,
      MeterTypeChart
    },

    computed: {
      swiperOption: {
        get() {
          return this.options
        },

        set(attr) {
          this.options[attr['key']] = attr['value']
        }
      },

      meter_types() {
        return this.parentData.meter_types_by_months
      }
    },

    methods: {
      reloadData() {
        this.meter_types.forEach (type => {
          let ref = this.$refs[`meter-type-chart-${type.id}`]
          if (ref) {
            ref[0].loadChartData()
          }
        })
      }
    },

    created() {
    },

    beforeDestroy() {
    }
  }
</script>

<style lang="scss">
</style>