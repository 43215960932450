<template lang='pug'>

  div(v-if="dataHasLoaded" class="work-equipment-form justify-center")
    div(v-if="loading" class="spinner-container form-spinner show-form")
      q-spinner(color="primary" size="3em")

    q-card-section(v-show="!loading && collection.length > 0")
      work-equipments-list(@load-collection="loadCollection" :collection="collection" :path="path")

    q-card-section(v-show="!loading")
      q-form(@submit="submitForm")

        div(class="row" v-for="(row, i) in data.scheme" :ref="row[0]['name']")
          div(v-for="field in row" :key="field.name" :class="'col-' + field.col" style="padding: 5px;")

            component(v-show="getFieldDataByName(field)"
                      :is="getFieldComponent(getFieldDataByName(field))"
                      @fields-loaded="fieldsLoaded"
                      :parentData="{grid: grid, data: [getFieldDataByName(field)]}")

        div(class="button-group row justify-center")
          q-btn(flat no-caps class="work-equipment-form-submit" :label="work_equipment_locales.submit" type="submit")

</template>

<script>
  import fields from '../../../shared/forms/fields'
  import workEquipmentsList from './list'

  export default {
    data: function () {
      return {
        grid: 'ppr_work_equipments' + this.parentData.equipment.id,
        item: 'ppr_work_equipment',
        path: this.$store.state.paths['ppr_work_equipment'],
        equipment: this.parentData.equipment,
        attr: this.parentData.attr,
        data: [],
        collection: [],

        loading_fields: [],
        dataHasLoaded: false,
        loading: true,

        form_valid: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      fields,
      workEquipmentsList
    },

    methods: {

      submitForm() {
        let params = {};
        let form = this.currentForm;

        this.checkFormValidation(form)

        if (this.form_valid) {

          let result = Object.keys(form).reduce((result, e) => {
            if (e === 'weeks') {
              result[e] =  form[e]['field'].replace(/\s/g,'').split(",")
            } else if (Array.isArray(form[e]['field'])) {
              result[e] = form[e]['field'].map(e => e['value'])
            } else if (typeof form[e]['field'] === 'object') {
              result[e] = form[e]['field']['value']
            } else {
              result[e] = form[e]['field']
            }
            return result;
          }, {})

          result['equipment_id'] = this.equipment.id
          params[this.item] = result;

          this.$backend.create(this.path, null, params)
          .then(res => {
            // console.log(res.data)

            if (res.data) {
              this.$nextTick(() => {
                this.loadFormData({}, true)
                this.loadCollection()
                this.$q.notify(this.notifies.success_added_ppr_work)
              })
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response) { this.reLogin(error.response.status) }
            this.$nextTick(() => {
              this.loadFormData({}, true)
              this.$q.notify(this.notifies.error_ask_admin)
            })
          })
        } else {
          this.$q.notify(this.notifies.fix_invalid_fields)
        }
      },

      getFieldComponent(key) {
        if (key) {
          return fields[key.type]
        }
      },

      getFieldDataByName(field) {
        return this.data.fields.find(f => f.name === field.name)
      },

      fieldsLoaded(field=undefined) {

        if (this.loading_fields.length === 0) {
          this.loading_fields = this.data.fields.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (field) {
          this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString())
        }

        if (this.loading_fields.length === 0) {
          this.loading = false
        }
      },

      loadCollection(params={}) {
        params['equipment_id'] = this.equipment.id;

        this.$backend.collection(this.path + '/collection', params)
        .then(({ data }) => {
          this.collection = data.options
          this.$nextTick(() => {
            let attr = {item: this.equipment, field_key: 'work_count', value: data.count}
            this.$emit('update-field', attr)
          })
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response) { this.reLogin(error.response.status) }
          }
        })
      },

      loadFormData(params={}, reload=false) {
        let form_data_path = this.path + '/form_data'
        params['id'] = this.equipment.ppr_system_id;

        this.$backend.index(form_data_path, { params: params })
        .then(({ data }) => {
          // console.log(data)
          this.data = data

          if (reload) {
            this.reloadFormFields(data && data.fields || [])
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response) { this.reLogin(error.response.status) }
          }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      },

      reloadFormFields(fields) {
        fields.forEach (f => {
          if (f && f.name) {
            let result = {}
            result['field'] = f.value
            result['invalid'] = false
            this.$store.commit('updateFormField', {grid_name: this.grid, field: f.name, value: result})
          }
        })
      }
    },

    created(attr={}) {
      this.$store.commit('initialGridsState', {grid: this.grid, attr: attr})
      this.loadCollection()
      this.loadFormData()
    },

    beforeMount() {

    }
  }
</script>

<style lang="scss">

  .work-equipment-form {
    position: relative;
    min-height: 250px;
    height: auto;

    .spinner-container.form-spinner.show-form {
      height: initial !important;
    }

    .button-group {
      margin-top: 20px;
      margin-bottom: 30px;

      .work-equipment-form-submit {
        border-radius: 8px;
        background-color: #5B7AFF;
        color: #fff;
        padding-left: 70px;
        padding-right: 70px;

        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

</style>