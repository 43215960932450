<template lang='pug'>

  div
    div(class="form-field-label")
      span {{label}}
      span(v-show="required" class="form-field-require-tag" style="color:red;" :title="notifies.required_field")
        |*

    div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
      q-input(filled no-error-icon
              v-model='currentField'
              :label-slot="false"
              autocomplete="off"
              :for="name")

        template(v-slot:before='')
          div(name="colorize" class="color-preview" :style="{background: currentField || color_preview}")
            q-popup-proxy(ref='qProxyColorPicker' transition-show='scale' transition-hide='scale')
              q-card
                q-card-section
                  q-color(no-header no-footer v-model='currentField')

        q-icon(name="cancel" v-if="currentField" @click.stop="resetField()" class="cancel-string-field")

    span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}

</template>

<script>
  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        depend_from: this.parentData.data[0].depend_from || {},
        watch: this.parentData.data[0].watch || {},
        required: this.parentData.data[0].require,
        rules: this.parentData.data[0].rules,
        valid_error_message: null,
        color_preview: '#d3d3d3',
        main_class: 'form-field form-input form-iconpicker'
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            return form_field['field']
          } else {
            return undefined
          }
        },
        set(value) {
          let result = {}
          if (value && value.length > 0) {
            result['field'] = value
            result['invalid'] = this.invalid(value)
          } else {
            result['invalid'] = this.invalid()
          }
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {grid_name: this.grid, field: 'invalid_fields', value: this.name})
          }
        }
      },

      fieldDependency() {
        return this.checkDependency()
      }
    },

    watch: {
      fieldDependency(newVal, oldVal) {},
    },

    methods: {

      setField(val) {
        this.currentField = val
      },

      resetField() {
        this.currentField = ''
      },

      invalid(val=undefined) {
        let result

        if (this.required) {
          if (val && val.length > 0) {
            this.valid_error_message = null
            result = false
          } else {
            this.valid_error_message = this.notifies.not_empty
            result = true
          }
        } else {
          this.valid_error_message = null
          result = false
        }

        if (this.rules && this.rules.length > 0) {
          if (val && val.length > 0) {
            let messages = this.rules.map(rule => {
              let regex = new RegExp(rule['regex'])
              if (!regex.test(val)) {
                return rule['error']
              }
            }).filter(msg => ![undefined, null, ""].includes(msg))
            if (messages.length > 0) {
              this.valid_error_message = messages[messages.length - 1]
              result = true
            }
          }
        }

        return result
      }
    },

    created() {
      this.watchSimpleFieldParents()
    },

    beforeMount() {
      this.$store.commit('createFormField', {grid_name: this.grid, field: this.name})
      this.setField(this.value)
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/string";

  .form-iconpicker {
    .q-field__before {
      height: inherit !important;
      flex: 0;
      padding: 10px;
    }

    .color-preview {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      border: solid 0.5px var(--datetime-icon-fill);
      cursor: pointer;
    }
  }

  .q-color-picker {
    border-radius: unset;
    box-shadow: none;
  }

</style>