<template lang='pug'>

  div(class="quantity-card" @click="openIssuesByState()")
    quantity-bg(:code="quantity.code")
    span(class="quantity_title") {{quantity.title}}
    span(class="quantity_value") {{quantity.value}}


</template>

<script>
  import quantityBg from './quantityBg'

  export default {
    name: 'IssueQuantity',

    props: {
      quantity: Object,
      disable: Boolean
    },

    components: {
      quantityBg
    },

    methods: {

      openIssuesByState(params = {}) {

        if (!this.disable) {
          params['attr'] = {
            filters: {
              state: [
                {
                  label: this.quantity.title,
                  value: this.quantity.code
                }
              ]
            }
          }

          if (this.current_user.additional_services_ids.length > 0) {
            params['service_id'] = this.current_user.additional_services_ids[0]
          }

          let route = this.$store.state.issues_archive_states.includes(this.quantity.code) ? 'archive' : 'issues'

          this.$router.push({
            name: route,
            params: params
          })
        }
      }
    },

    created() {
    }
  }
</script>

<style lang="scss">

  /*@import "../../../../assets/styles/dashboard/quantity.scss";*/

</style>

<!--<template lang='pug'>-->
  <!--div(:class="`quantity-card ${backgroundClass}`" @click="openIssuesByState()")-->
    <!--div(class="quantity-card__title") {{quantity.title}}-->
    <!--div(class="quantity-card__value") {{quantity.value}}-->


<!--</template>-->

<!--<script>-->
  <!--// import t from '../../../../assets/icons/dashboard/quantities/initial_light.svg'-->

  <!--export default {-->
    <!--name: 'IssueQuantity',-->

    <!--computed: {-->
      <!--backgroundClass() {-->
        <!--const code = this.quantity.code;-->
        <!--return `quantity-card&#45;&#45;bg-${code}_${this.theme}`;-->
      <!--},-->
    <!--},-->

    <!--props: {-->
      <!--quantity: Object,-->
      <!--disable: Boolean-->
    <!--},-->

    <!--methods: {-->

      <!--openIssuesByState(params = {}) {-->

        <!--if (!this.disable) {-->
          <!--params['attr'] = {-->
            <!--filters: {-->
              <!--state: [-->
                <!--{-->
                  <!--label: this.quantity.title,-->
                  <!--value: this.quantity.code-->
                <!--}-->
              <!--]-->
            <!--}-->
          <!--}-->

          <!--if (this.current_user.additional_services_ids.length > 0) {-->
            <!--params['service_id'] = this.current_user.additional_services_ids[0]-->
          <!--}-->

          <!--let route = this.$store.state.issues_archive_states.includes(this.quantity.code) ? 'archive' : 'issues'-->

          <!--this.$router.push({-->
            <!--name: route,-->
            <!--params: params-->
          <!--})-->
        <!--}-->
      <!--}-->
    <!--},-->

    <!--created() {-->
    <!--}-->
  <!--}-->
<!--</script>-->

<!--<style lang="scss">-->

  <!--@import "../../../../assets/styles/dashboard/quantity.scss";-->

<!--</style>-->


