<template lang='pug'>

  q-checkbox(dark :disable="!facility_id" v-model="currentField" :label="label")

</template>

<script>
  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        name: this.parentData.name,
        label: this.parentData.label
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      facility_id() {
        return this.currentFilters['facility_id']
      },

      service_id() {
        return this.currentFilters['service_id']
      },

      currentField: {
        get() {
          if (this.facility_id) {
            let service_id = this.service_id ? this.service_id.value : null
            let facility = this.$store.state.grid[this.grid][this.facility_id.value]

            if (facility) {
              let service_val = this.$store.state.grid[this.grid][this.facility_id.value][service_id]

              if (service_val) {
                let val = service_val[this.name]

                if (val) {
                  return val

                } else {
                  return false
                }
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return false
          }
        },

        set(value) {
          if (this.facility_id) {
            let service_id = this.service_id ? this.service_id.value : null

            let data = {
              facility_id: this.facility_id.value,
              service_id: service_id,
              name: this.name,
              value: value
            };

            this.$emit('update-rule', data)
          }
        }
      }
    },

    methods: {
    },

    created() {
    },

    beforeMount() {
    }
  }
</script>

<style lang="scss">


</style>