<template lang='pug'>

  div(class="col-12 col-lg-6")

    div(class="form-field-label")
      span {{field.title}}
      span(v-show="field.required" class="form-field-require-tag" style="color:red;" :title="field.title")
        |*

    div(class="form-field form-file")

      div(v-if="!filesExist(value) && !readonly" class="file-drag-drop-area" :id="getFieldId('field')" ref="fileInput" @click="openFileInput")
        img(class="file-field-icon" :src="require('../../../assets/icons/form/fields/file_field_icon.svg')")
        span(class="drop-files") {{field_locales.sublabel}}
        q-file(v-show="false" filled :value="value" @input="val => value = [val]" :multiple="false" :label="field.title" :for="getFieldId('input')")

      div(v-if="filesExist(value) && !readonly" class="form-preview-area justify-center")
        div(v-for="(file, key) in value" class="preview-file")
          img(v-if="['image/jpg', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type)"
              class="preview-img"
              :ref="'preview-img'+parseInt( key )")
          inline-svg(v-else
                      class="preview-doc"
                      :src="require('../../../assets/icons/document.svg')")
          span(class="caption") {{ cutFileName(file.name) }}


      q-icon(name="cancel" v-if="value && !readonly" @click.stop="resetField()" class="cancel-file-field")

      div(v-if="readonly" class="form-preview-area justify-center")
        div(class="preview-file")
          a(v-if="field._data.value[0].value" :href="field._data.value[0].value" target="_blank")
            img(v-if="['jpg', 'png', 'jpeg', 'gif'].includes(field._data.value[0].value.split('.')[field._data.value[0].value.split('.').length-1].split('?')[0])"
              class="preview-img"
              :src="field._data.value[0].value"
              @click="goToFile"
              )
            inline-svg(v-else
                        class="preview-doc"
                        :src="require('../../../assets/icons/document.svg')"
                        @click="goToFile")
          div(v-else)
            img(v-if="['jpg', 'png', 'jpeg', 'gif'].includes(field._data.value[0].value.split('.')[field._data.value[0].value.split('.').length-1].split('?')[0])"
              class="preview-img"
              :src="field._data.value[0].value"
            )
            inline-svg(v-else
              class="preview-doc"
                :src="require('../../../assets/icons/document.svg')")
          span(class="caption") {{ cutFileName(field._data.value[0].title) }}

</template>

<script>
  import existingFilesArea from '../../shared/helpers/existingFilesArea'

  export default {
    data: function () {
      return {
        multiple: false,
        value: this.$props.initialValue[0].value,
        dragAndDropCapable: false,
        uploadPercentage: 0,
        image_url: '',
        image: false,
      }
    },

    components: {
      existingFilesArea
    },

    props: {
      field: {
          type: Object,
          required: true
      },
      readonly: {
          type: Boolean || undefined,
          required: false,
          default: false
      },
      initialValue: {
          type: Array,
          required: false,
          default: () => ([{value: null}])
      }
    },

    computed: {
      field_locales() {
        return this.locales.file_field[this.current_locale]
      },
    },

    methods: {
      cutFileName(name) {
        return name.length && name.length > 16 ? name.substring(0, 14) + '...' : name
      },

      filesExist(array) {
        return !!array
      },

      openFileInput() {
        this.field.id ? document.getElementById(`file-input-${this.field.id}`).click() : document.getElementById(`file-input-${this.field.uuid}`).click()
      },

      determineDragAndDropCapable(){
        let div = document.createElement('div');
        return ( ( 'draggable' in div )
          || ( 'ondragstart' in div && 'ondrop' in div ) )
          && 'FormData' in window
          && 'FileReader' in window;
      },

      getImagePreviews(){
        if (this.value && this.value.length > 0) {
          this.value.forEach((file, i) => {
            if ( /\.(jpe?g|png|gif)$/i.test( file.name ) ) {
              let reader = new FileReader();
              reader.addEventListener("load", function(){
                  this.$refs['preview-img' + parseInt( i )][0].src = reader.result;
              }.bind(this), false);
              reader.readAsDataURL( file );
            // } else {
            //   this.$nextTick(function(){
            //     this.$refs['preview' + parseInt( i )][0].src = require('assets/icons/document.svg')
            //   });
            }
          })
        }
      },

      showImage(url) {
        this.image_url = url
        this.image = true
      },

      isImage(value) {
        if (value) {
          return ['image/jpeg', 'image/png'].includes(value.content_type)
        } else {
          return false
        }
      },

      resetField() {
        if (!this.readonly) {
          this.value = null
          this.$emit('reset-field', {id: this.field.id || this.field.uuid})
        }
            
      },
      editField(val) {
        console.log(val)
        if (!this.readonly && val) this.$emit('edit-field', {id: this.field.id || this.field.uuid, value: [{value: val, title: val[0].name}]})
        if (!this.readonly && !val) this.$emit('edit-field', {id: this.field.id || this.field.uuid, value: []})
      },
      getFieldId(str) {
        return this.field.id ? `file-${str}-${this.field.id}` : `file-${str}-${this.field.uuid}`
      },
      goToFile() {
        console.log(this.field._data.value[0].value)
        window.location = this.field._data.value[0].value
      }
},

    created() {
      this.resetField()
    },

    beforeMount() {
    },

    mounted(){
      this.value = this.initialValue[0].value
      if (this.readonly) {
        return true
      }
      else {
        let input = this.field.id ? document.getElementById(`file-field-${this.field.id}`) : document.getElementById(`file-field-${this.field.uuid}`)
        this.dragAndDropCapable = this.determineDragAndDropCapable();
        if( this.dragAndDropCapable ) {
          ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
            input.addEventListener(evt, function(e) {
              console.log('as')
              e.preventDefault();
              e.stopPropagation();
            }.bind(this), false);
          }.bind(this));
          input.addEventListener('drop', function(e) {
            console.log('drop')
            console.log(e.dataTransfer.files)
            let files = this.multiple ? e.dataTransfer.files : [e.dataTransfer.files[0]]
            this.value = Object.values(files)
            console.log(Object.values(files))
          }.bind(this));
        }
      }
    },
    watch: {
        value: {
          deep: true,
          handler() {
            if (!this.readonly) {
              this.editField(this.value)
              this.getImagePreviews();
            }
          }
        }
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/file";

</style>