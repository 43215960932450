export const options = (attr) => ({
  chart: {
    backgroundColor: attr.theme.grid_background_color,
    height: attr.options.chart.height,
    marginTop: attr.options.chart.margin_top,
    type: attr.options.type,
    scrollablePlotArea: attr.options.chart.scrollable || false,
    events: {
      load: function() {
        this.xAxis[0].setExtremes(attr.options.begin_extr.start, attr.options.begin_extr.end);
      }
    }
  },
  title: false,
  tooltip: attr.options.tooltip,
  plotOptions: {

    series: {
      fillOpacity: attr.options.plot_options.fill_opacity,
      animation: false,
      stickyTracking: attr.options.plot_options.sticky_tracking,
      turboThreshold: 0,

      tooltip: {

      },

      point: {
        events: attr.options.plot_options.point && attr.options.plot_options.point.events || {
          mouseOver: function (event) {
            if (this.series.chart.tooltip.label) {
              this.series.chart.tooltip.label.show()
            }
          },
          mouseOut: function (event) {
            if (this.series.chart.tooltip.label) {
              this.series.chart.tooltip.label.hide()
            }
          }
        }
      }
    },
    column: {
      pointPadding: 0.1,
      borderWidth: 0,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      pointWidth: attr.options.plot_options.column_point_width
    },
    areaspline: attr.options.plot_options.areaspline
  },
  xAxis: {
    categories: attr.data.categories,
    lineColor: attr.theme.grid_border_color,
    labels: {
      enabled: attr.options.chart.labels_enabled !== undefined ? attr.options.chart.labels_enabled : true,
      style: {
        color: attr.theme.fore_color
      },
      formatter: function() {
        if (this.chart.series[0].xAxis.categories.indexOf(this.value) === -1) {
          return null;
        } else {
          return this.value
        }
      }
    },
    plotBands: attr.options.chart.xaxis_plotbands

  },
  yAxis: {
    min: attr.options.yaxis.min,
    max: attr.options.yaxis.max,
    softMax: attr.options.yaxis.soft_max,
    tickInterval: attr.options.yaxis.tick_interval,
    title: false,
    gridLineColor: attr.options.yaxis.grid_line_color || attr.theme.grid_border_color,
    labels: {
      style: {
        color: attr.theme.fore_color
      }
    }
  },
  series: attr.data.series,
  legend: {
    itemStyle: {
      color: attr.theme.fore_color
    },
    itemHoverStyle: {
      color: attr.theme.fore_color
    },
    itemHiddenStyle: {
      color: attr.theme.legend_hidden_color
    },
    enabled: attr.options.legend.enabled
  }
})