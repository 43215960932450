<template lang='pug'>

  div(class="object_pass-ppr")
    span {{ locales['ppr_object_passport'][current_locale]['plug'] }}

</template>

<script>

  export default {
    computed: {
      data() {
        return []
      }
    }
  }
</script>

<style lang='scss'>
.object_pass{
  &-ppr{
    span{
      color: var(--dashboard-label-color);
    }
  }
}
</style>