<template lang='pug'>

  index-page(@set-loading="setLoading"
               :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class}")

</template>

<script>
  import indexPage from '../../shared/indexPage'

  export default {
    data: function () {
      return {
        grid: 'covid_tests',
        report_class: 'covid_test',
        path: this.$store.state.paths['covid_test'],
        grid_key: 'data',
      }
    },
    components: {
      indexPage,
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      // this.$emit('set-loading', false)
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>