<template lang="pug">
  div(class="index-page")

    common-header(ref="header"
                v-if="!filter_data_loading"
                @reload-data="reloadData"
                :parentData="{path: path['issue'], grid: 'issues', grid_key: grid_key, data: forms['filter_data']}")

    q-tabs(v-if="!services_loading" v-model="tab")
      div(class="swiper-area")
        swiper(ref="tabSwiper" :options="swiperOption" class="main-swiper" @slideChange="onSlide")
          //div(v-swiper:issueServiceTabSwiper="swiperOption" class="main-swiper" @slideChange="onSlide" ref="tabSwiper")
            div(class="swiper-wrapper")
          q-tab(class="swiper-slide" v-for="(tab, i) in searchTabs" :key="tab.id" @click="addCurrentService(tab.id)" :name="String(tab.id)")
            template(slot="default")
              q-icon(:name="tab.icon" class="tab-icon")
            template(slot="default")
              span(class="tab-label") {{tab.title}}
            template(slot="default")
              div(class="issues-count-area")
                span(class="issues-count") {{tab.issues_count}}
        div(class="swiper-button-prev" slot="button-prev")
        div(class="swiper-button-next" slot="button-next")

    //grid-hidden-msg(v-show="hide_table" ref="grid-hidden-msg" :parentData="{hide_table: hide_table}")
    //q-tab-panels(:class="[hide_table ? 'index-tabs-panels' + ' hide-table' : 'index-tabs-panels']" v-model="tab")
    q-tab-panels(class="index-tabs-panels" v-model="tab")
      q-tab-panel(v-for="tab in searchTabs" :key="tab.id" :name="String(tab.id)")
        div(v-if="!filter_data_loading" :id="'grid-' + grid_key" class="grid")
          grid(:ref="'grid' + parseInt(tab.id)" @get-collection="getCollection" @reload-tabs="reloadTabs" :parentData="{filters_data: forms['filter_data'], grid: grid, mutation: 'updateGridData', channel: 'IssuesChannel', path: path['issue'], grid_key: grid_key, param_key: 'service_id', transition_key: 'issue'}")


    ui-prompts(v-if="!grid_data_loading && !child_route_component" :parentData="{areas_key: grid}")

</template>

<script>
import { Swiper } from "vue-awesome-swiper";
import commonHeader from "../../shared/helpers/Header";
import Grid from "../../shared/grid/Grid";
import gridHiddenMsg from "../../shared/grid/gridHiddenMsg";
import qs from "qs";
import assert from "assert";
import uiPrompts from "../../shared/ui_prompts/index.vue";

export default {
  data: function () {
    return {
      grid: "issues",
      path: this.$store.state.paths,
      grid_key: "",
      services: [],
      tabs: [],
      searchTabs: [],
      tab: "",
      slide_first_tab: false,

      param_key: "service_id",
      sti_type: "MaintenanceIssue",

      forms: {
        filter_data: [],
      },

      filter_data_loading: true,
      services_loading: true,

      // hide_table: false,

      reload_tabs: false,
      refresh_tabs: false,

      except_filters: {
        state: this.$store.state.issues_archive_states,
      },

      row: null,

      grid_data_loading: true,
      child_route_component: false,

      swiperOption: {
        spaceBetween: 0,
        slidesPerView: "auto",
        slidesPerGroup: 1,
        mousewheel: {
          forceToAxis: true,
          sensitivity: 3,
        },
        grabCursor: true,
        // followFinger: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    Swiper,
    Grid,
    commonHeader,
    gridHiddenMsg,
    uiPrompts,
  },

  computed: {
    allDataLoaded() {
      return !this.filter_data_loading && !this.services_loading;
    },

    filtersExists() {
      return (
        Object.keys(this.filters).filter(
          (key) => ![undefined, null, ""].includes(this.filters[key])
        ).length > 0
      );
    },

    swiper() {
      if (this.$refs.tabSwiper) {
        return this.$refs.tabSwiper.$swiper;
      } else {
        return undefined;
      }
    },

    tabs_order() {
      return this.$store.state.issues_tabs.tabs_order;
    },
  },

  watch: {
    allDataLoaded(newVal, oldVal) {
      if (newVal) {
        this.$emit("set-loading", false);
      }
    },

    searchAllFilter(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        localStorage.setItem("query", JSON.stringify(newVal));
        this.slide_first_tab = true;
      }
    },

    filter_data_loading(newVal, oldVal) {
      if (newVal === false) {
        let query_attr = qs.parse(this.$route.query.attr);

        if (query_attr && query_attr.filters) {
          this.$nextTick(() => {
            this.loadFiltersFromStorage(query_attr.filters);
          });
        } else {
          this.$nextTick(() => {
            this.loadFiltersFromStorage();
          });
        }
      }
    },
  },

  methods: {
    reloadTabs(val) {
      this.reload_tabs = val;
    },

    // hideTable(val) {
    //   this.hide_table = val
    //   this.$refs['grid-hidden-msg'].hideTable(val)
    // },

    filtersFormAccepted(val) {
      if (val) {
        let filters_data = this.forms.filter_data.filters_data;
        if (filters_data) {
          let refresh_filters = filters_data.filters
            .filter((f) => {
              return (
                !Object.keys(this.filters).includes(f.name) &&
                f.name !== "query"
              );
            })
            .map((f) => f.name);

          refresh_filters.forEach((filter_name) => {
            if (localStorage.getItem(filter_name)) {
              localStorage.removeItem(filter_name);
            }
          });
        }

        Object.keys(this.filters).forEach((key) => {
          localStorage.setItem(key, JSON.stringify(this.filters[key]));
        });

        this.slide_first_tab = true;
      }
      let grid = this.$refs["grid" + this.grid_key];
      if (grid && grid.length > 0) {
        grid[0].filtersFormAccepted(val);
      }
    },

    resetLastFilter(filter_name) {
      localStorage.removeItem(filter_name);
      this.$store.commit("resetFilter", {
        grid_name: this.grid,
        filter: filter_name,
      });
      let grid = this.$refs["grid" + this.grid_key];
      if (grid && grid.length > 0) {
        grid[0].filtersFormAccepted(true);
      }
    },

    filtersFormReset() {
      this.searchAllFilterReset();

      let filters = Object.keys(this.filters);
      filters.forEach((key) => {
        localStorage.removeItem(key);
      });
      this.$store.commit("resetAllFilters", {
        grid_name: this.grid,
        filters: filters,
      });
      let grid = this.$refs["grid" + this.grid_key];
      if (grid && grid.length > 0) {
        grid[0].filtersFormAccepted(true);
      }
    },

    searchAllFilterReset() {
      localStorage.removeItem("query");
      this.$store.commit("resetQuery", {
        grid_name: this.grid,
        value: this.searchAllFilter,
      });
    },

    openExportForm(item) {
      let attr = {
        data: this.forms.export_excel_form_data,
        report_class: "issue",
        header_title: item.label,
        sti_type: this.sti_type,
        except_filters: this.except_filters,
        export_type: item.export_type,
      };

      this.$nextTick(() => {
        let grid = this.$refs["grid" + this.grid_key];
        if (grid && grid.length > 0) {
          grid[0].openExportForm(attr);
        }
      });
    },

    onSlide() {
      // console.log(this.swiper.activeIndex)
    },

    getCollection(params) {
      if (this.sti_type) {
        params["filters"]["type"] = this.sti_type;
      }

      if (this.param_key) {
        params["filters"][this.param_key] = this.grid_key;
      }

      let attr = { table: params };
      let additional_attr = {};
      let table_params = Object.assign(attr, additional_attr);

      // if (this.reload_tabs) {
      this.loadTabs(table_params);
      // }

      this.$backend
        .index(this.path["issue"], { params: { json: table_params } })
        .then(({ data }) => {
          // console.log(data)
          this.slideToActiveTab();
          this.$store.commit("updateGridData", {
            grid_data: data,
            grid_name: this.grid,
            grid_key: this.grid_key,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }

          this.$refs["grid" + this.grid_key][0].setError(false);
        })
        .finally(() => {
          this.grid_data_loading = false;
          this.$refs["grid" + this.grid_key][0].setLoading(false);
        });
    },

    addCurrentService(service_id) {
      this.reload_tabs = true;
      if (this.grid_key.toString() !== service_id.toString()) {
        // this.hide_table = false
        this.grid_key = service_id;
        this.$store.commit("updateActionsByCheckbox", { grid_name: this.grid });

        // this.slideToActiveTab()

        history.replaceState(
          {},
          null,
          this.$route.path + "?" + "service_id=" + this.grid_key
        );
      }
    },

    slideToActiveTab() {
      let index = this.searchTabs.findIndex(
        (s) => s["id"] === parseInt(this.grid_key)
      );
      this.$nextTick(() => {
        if (this.swiper) {
          this.swiper.slideTo(index - 1, 1000, false);
        }
      });
    },

    loadFormData(key, attr = {}) {
      let path = this.path["issue"] + "/" + key;
      let params = {};

      if (attr.export_type) {
        params["export_type"] = attr.export_type;
      }

      this.$backend
        .index(path, { params: params })
        .then(({ data }) => {
          this.forms[key] = data;
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.reload_tabs = false;
          this[key + "_loading"] = false;
        });
    },

    loadTabs(params = {}) {
      if (params["table"] && params["table"]["refresh_tabs"]) {
        this.refresh_tabs = true;
      }

      this.$backend
        .index(this.path["issue"] + "/tabs_collection", {
          params: { json: params },
        })
        .then(({ data }) => {
          // console.log(data)
          this.tabs = data;

          if (this.refresh_tabs) {
            this.refresh_tabs = false;
            let tabs_order = this.tabs.map((el) => el["id"]);
            this.$store.commit("updateIssuesTabsOrder", tabs_order);
          }

          if (this.searchAllFilter || this.filtersExists) {
            if (this.tabs_order) {
              this.tabs = this.mapOrder(this.tabs, this.tabs_order, "id");
            }
          } else {
            if (this.tabs_order) {
              this.$store.commit("resetIssuesTabsOrder");
            }
          }
          this.searchTabs = this.tabs;
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          if (Object.keys(params).length !== 0) this.services_loading = false;

          if (this.slide_first_tab) {
            if (this.searchTabs[0] && this.searchTabs[0]["id"]) {
              this.grid_key = this.searchTabs[0]["id"].toString();
              this.tab = this.grid_key;

              history.replaceState(
                {},
                null,
                this.$route.path + "?" + "service_id=" + this.grid_key
              );
            }
            this.slide_first_tab = !this.slide_first_tab;
          }
        });
    },

    addNewItem(params = {}) {
      if (!params["service_id"]) {
        params["service_id"] = parseInt(this.grid_key);
      }

      let attr = {
        form_type: "add",
        callback_params: params,
      };

      this.$nextTick(() => {
        let grid = this.$refs["grid" + params["service_id"]];
        if (grid && grid.length > 0) {
          grid[0].openForm(attr);
        }
      });
    },

    openShow(tab_name = undefined) {
      if (this.row) {
        if (tab_name) {
          this.row["show_tab"] = tab_name;
        }
        let grid = this.$refs["grid" + this.grid_key];
        if (grid && grid.length > 0) {
          grid[0].openShow(this.row);
        }
      }
    },

    addSearchAllFromParams(val) {
      if (val) {
        let result = {
          name: "query",
          type: "search_all",
          value: val.toString(),
        };

        if (this.$refs.header) {
          this.$refs.header.updateFilter(result);
        }
      }
    },

    reloadData() {
      Object.keys(this.forms).forEach((data_key) => {
        this.loadFormData(data_key);
      });
      this.loadTabs();
      if (this.$refs["grid" + this.grid_key]) {
        this.$refs["grid" + this.grid_key][0].reloadData();
      }
      this.loadFiltersFromStorage();
    },

    loadFiltersFromStorage(filters = undefined) {
      let filters_data, filter, value;
      filters_data = this.forms.filter_data.filters_data.filters;

      if (filters) {
        Object.keys(filters).forEach((key) => {
          localStorage.removeItem(key);

          Array.isArray(filters[key]) &&
            filters[key].map((v) => {
              return (v["value"] = Number.isInteger(parseInt(v["value"]))
                ? parseInt(v["value"])
                : v["value"]);
            });

          localStorage.setItem(key, JSON.stringify(filters[key]));
          value = JSON.parse(localStorage.getItem(key));
          filter = filters_data.find((f) => f.name === key);

          if (this.$refs.header) {
            this.$refs.header.updateFilter(
              Object.assign(filter, { value: value })
            );
          }
        });
      } else {
        filters_data.forEach((filter) => {
          value = JSON.parse(localStorage.getItem(filter.name));
          if (value) {
            if (this.$refs.header) {
              this.$refs.header.updateFilter(
                Object.assign(filter, { value: value })
              );
            }
          }
        });
      }

      setTimeout(() => {
        if (this.filtersExists) {
          let grid = this.$refs["grid" + this.grid_key];

          if (grid && grid.length > 0) {
            grid[0].setRefreshTabs();
            grid[0].filtersFormAccepted(true);
          }
        }
      }, 500);
    },

    replaceRoute() {
      let service_id, query;
      let params = this.$route.params;

      if (params.service_id) {
        service_id = params.service_id;
      } else {
        service_id = "";
      }

      let filtered_params = Object.keys(params)
        .filter((key) => key !== "service_id")
        .reduce((r, key) => {
          if (typeof params[key] === "object") {
            r[key] = qs.stringify(params[key]);
          } else {
            r[key] = params[key];
          }
          return r;
        }, {});

      query = Object.assign({ service_id: service_id }, filtered_params);

      this.$router.replace({
        path: this.$route.path,
        query: query,
      });
    },
  },

  created(attr = {}) {
    if (Object.keys(this.$route.query).length === 0) {
      this.replaceRoute();
    } else {
      attr["except_filters"] = this.except_filters;
      this.$store.commit("initialGridsState", { grid: this.grid, attr: attr });

      Object.keys(this.forms).forEach((data_key) => {
        this.loadFormData(data_key);
      });
      this.loadTabs();

      setTimeout(() => {
        if (this.$route.query.service_id) {
          this.grid_key = this.$route.query.service_id;
          this.tab = this.grid_key.toString();
        } else {
          if (this.searchTabs[0] && this.searchTabs[0]["id"]) {
            this.grid_key = this.searchTabs[0]["id"].toString();
            this.tab = this.grid_key;

            history.replaceState(
              {},
              null,
              this.$route.path + "?" + "service_id=" + this.grid_key
            );
          }
        }

        let query_attr = qs.parse(this.$route.query.attr);

        // if (query_attr && query_attr.filters) {
        //   this.$nextTick(() => {
        //     this.loadFiltersFromStorage(query_attr.filters)
        //   })
        // } else {
        //   this.$nextTick(() => {
        //     this.loadFiltersFromStorage()
        //   })
        // }

        if (this.$route.query.add_issue) {
          this.child_route_component = true;
          this.addNewItem(query_attr);
        }

        if (this.$route.query.show) {
          this.child_route_component = true;
          let id = this.$route.query.id;
          this.addSearchAllFromParams(id);
          this.loadRow(id, this.path["issue"]);
          setTimeout(() => {
            this.openShow(this.$route.query.tab);
          }, 1500);
        }
      }, 1600);
    }
  },

  beforeMount() {
    this.$emit("expand-class", { val: false, class: "" });
  },

  mounted() {
    this.$root.$on("open-export-form", (item) => {
      this.openExportForm(item);
    });

    this.$root.$on("add-new-item", () => {
      this.addNewItem();
    });

    this.$root.$on("filters-form-accepted", (val) => {
      this.filtersFormAccepted(val);
    });

    this.$root.$on("filters-form-reset", () => {
      this.filtersFormReset();
    });

    this.$root.$on("search-all-filter-reset", () => {
      this.searchAllFilterReset();
    });

    this.$root.$on("reset-last-filter", (filter_name) => {
      this.resetLastFilter(filter_name);
    });
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/index-page/issue/tabs";
</style>
