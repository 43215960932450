<template lang='pug'>

  div(class="form-field form-input")
    q-input(filled no-error-icon
            class="float-field"
            :value="value"
            type="number"
            min="0"
            step="any"
            :label-slot="false"
            @input="onChange(item, $event)"
            @clear="onChange(item, $event)")

</template>

<script>
  export default {
    data: function () {
      return {
        item: this.parentData.item,
        value_key: this.parentData.value_key,
        value: this.parentData.value,
        selected_items: this.parentData.selected_items,
      }
    },

    props: {
      parentData: Object
    },

    computed: {
    },

    methods: {

      onChange(item, e) {
        let val = 0

        if (e && e !== '') {
          val = e
        }

        this.value = val

        let data = {
          id: item.id,
          value_key: this.value_key,
          value: val
        }

        // this.selected_items.find(el => el.id === item.id)[this.value_key] = val
        this.$emit('set-selected-items', data)
      }
    },

    created() {
    },

    beforeMount() {
    }
  }
</script>

<style lang="scss">
</style>