import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';


// import { VApp, VContainer, VLayout, VFlex,VCard, VCardText, VCardTitle, VItem,VList,VCol, VRow } from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: colors.blue.lighten3,
        background: "#0D0D17",
        card: "#1E2135",
        button: "#51CDDD"
      }
    }
  }
};

export default new Vuetify(opts)
