<template lang='pug'>
  div
    div(v-if="dataHasLoaded" class="user-edit-form justify-center")
      handle-form(@close-form="closeForm" :parentData="{method: 'update', item: 'user', item_id: row.id, title_icon_path: title_icon_path, form_title: form_data.form_header.title.edit, path: path['user'], grid: grid, data: form_data}")

</template>

<script>
  import handleForm from '../../shared/forms/Form'

  export default {
    data: function () {
      return {
        grid: 'profile',
        path: this.$store.state.paths,
        title_icon_path: 'edit_header.svg',
        row: this.parentData.row,
        callback_params: this.parentData.callback_params,
        form_data: [],
        dataHasLoaded: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      handleForm,
    },

    methods: {

      closeForm(data) {
        this.$emit('close-form', data)
      },

      loadFormData() {
        let form_data_path = this.path['user'] + '/form_data';

        let params = {};
        params['class_name'] = 'profile'

        if (this.row) {
          params['id'] = this.row.id;
        }

        this.$backend.index(form_data_path, { params: params })
        .then(({ data }) => {
          this.form_data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      },
    },

    created(){
      this.loadFormData()
      this.$emit('row-is-active', true)
    },

    mounted() {
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>
</style>