<template lang='pug'>

  div(class="show-card-info more-details-btn")
    div(class="row-info-field" @click="changeShowDetails()")
      div(class="row-more-details")
        inline-svg(class="more-details-icon"
                    v-if="show_details"
                    :src="require('../../../assets/icons/show/arrow_up_blue.svg')")
        inline-svg(class="more-details-icon"
                    v-if="!show_details"
                    :src="require('../../../assets/icons/show/arrow_down_blue.svg')")
        span(class="more-details-label") {{label}}


</template>

<script>

  export default {
    data: function () {
      return {
        block: this.parentData.block,
        show_details: this.parentData.show_details
      }
    },

    props: {
      parentData: Object
    },

    components: {
    },

    computed: {
      label() {
        return this.parentData.label
      }
    },

    methods: {

      changeShowDetails() {
        this.show_details = !this.show_details
        this.$emit('change-show-details', this.show_details)
      }
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/show/details-btn";

</style>