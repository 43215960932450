<template lang="pug">
  div
    div(v-if="loading" class="spinner-container floor-loader")
      q-spinner(color="primary" size="3em")
    div(v-show="!loading && image_url")
      div(class="floor-plan-thumb")
        div(
          class="floor-plan-thumb-wrapper" 
          )
          img(
            class="floor-plan-thumb-img" :src="image_url"
            )
          div(
            v-for="item in dots"
            :key="item.id"
          )
            floorPoint(
              :dotData="{dot: item, editable: false, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id}"
            )
        div(class="floor-plan-thumb-buttons")
          q-btn(v-if="editable" dense flat icon="edit" @click="openEditor")
          q-btn(v-if="editable" dense flat icon="close" @click="clearPlan")
          q-btn(v-if="!editable" dense flat icon="fas fa-arrows-alt" @click="openFullMap")

    div(v-if="!loading && !image_url")
      div(class="floor-plan-plug")
        p Выберите этаж

    q-dialog(ref="editor" v-model="editor" transition-show="slide-left" transition-hide="slide-right" @before-hide="hideCreateDot")
      q-card(class="floor-map-dialog-card")
        q-card-section
          div(:class="`floor-plan-editor ${mapHeight}`")
            q-btn(dense v-bind:class="changePosition ? 'floor-plan-editor-move active' : 'floor-plan-editor-move'" flat icon="fas fa-arrows-alt" @click="setChangePosition")
            div(class="floor-plan-editor-buttons")
              q-btn(dense v-bind:disable="plusActive ? false : true " label="+" flat @click="zoomPlus")
              q-btn(dense v-bind:disable="minusActive ? false : true " label="-" flat @click="zoomMinus")
            div(
              class="floor-plan-editor-wrapper"
              v-bind:style="{ left: '-' + wrapperPosition.x + 'px', top: '-' + wrapperPosition.y + 'px' }"
              )
              img(
                v-bind:style="{ width: imageProps.width + 'px', height: imageProps.height + 'px' }"
                v-bind:class="changePosition ? 'floor-plan-editor-img move' : 'floor-plan-editor-img'"
                :src="image_url"
                @mousedown="!changePosition ? createDot() : startChangePosition()"
              )
              div(
                v-for="item in dots"
                :key="item.id"
              )
                floorPoint(
                  @remove="removeDot(item)"
                  @hideCreate="hideCreateDot"
                  @setDot="setDot"
                  :dotData="{dot: item, editable: true, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id}"
                )
          legendComponent(:legendData="{editable: editable, types: types, icons: icons}")

    q-dialog(ref="fullMap" class="floor-map-modal" v-model="fullMap" transition-show="slide-left" transition-hide="slide-right")
      q-card(class="floor-map-dialog-card")
        q-card-section
          div(:class="`floor-plan-editor ${mapHeight}`")
            div(class="floor-plan-editor-buttons")
              q-btn(dense label="+" flat @click="zoomPlus")
              q-btn(dense label="-" flat @click="zoomMinus")
            div(
              class="floor-plan-editor-wrapper"
              v-bind:style="{ left: '-' + wrapperPosition.x + 'px', top: '-' + wrapperPosition.y + 'px' }"
              )
              img(
                v-bind:class="changePosition ? 'floor-plan-editor-img move' : 'floor-plan-editor-img'"
                :src="image_url"
                v-bind:style="{ width: imageProps.width + 'px', height: imageProps.height + 'px' }"
                @mousedown="!changePosition ? null : startChangePosition()"
                )
              div(
                v-for="item in dots"
                :key="item.id"
              )
                floorPoint(
                  :dotData="{dot: item, editable: false, floor_plan_id: floor_plan_id, types: types, floor_id: floor_id, building_id: building_id}"
                )
          legendComponent(:legendData="{editable: editable, types: types, icons: icons}")
</template>

<script>
  import floorPoint from '../view/floor_map/floor_point.vue'
  import legendComponent from '../view/floor_map/map/legend.vue'

  export default ({
    data() {
      return{
        image_url: '',
        dots: [],
        editable: this.parentData.editable,
        floor_id: this.parentData.floor_id,
        building_id: this.parentData.building_id,
        floor_plan_id: this.parentData.floor_plan_id,
        editor: false,
        fullMap: false,
        loading: false,
        types: {},
        icons: {},
        imageProps: {
          minWidth: 900,
          width: Number,
          height: Number,
          zoomSteepsWidth: {},
          zoomSteepsHeight: {}
        },
        wrapperPosition: {
          minX: 0,
          minY: 0,
          x: Number,
          y: Number
        },
        plusActive: true,
        minusActive: true,
        currentZoomSteep: Number,
        changePosition: false,
        startPositionX: Number,
        startPositionY: Number,
        mapHeight: ''
      }
    },

    props: {
      parentData: Object
    },

    components: {
      floorPoint,
      legendComponent
    },
    
    watch: { 
      parentData: function(newVal, oldVal) { // watch it
        this.getMap(newVal.floor_id)
      }
    },

    methods: {
      openEditor() {
        this.editor = true
      },

      openFullMap() {
        this.fullMap = true
      },

      clearPlan() {
        this.$emit('clear')
      },

      createDot() {
        const e = event
        if (this.dots.find(dot => dot.create === true)){
          return
        }
        const img = e.target
        const imgWidth = img.width
        const imgHeight = img.height
        const round = function(number){
          return Math.round(parseFloat(number) * 1000) / 10
        }
        const x = round(e.offsetX / imgWidth)
        const y = round(e.offsetY / imgHeight)
        this.dots.push({
          x: x,
          y: y,
          create: true
        })
      },

      removeDot(dot){
        this.$backend.destroy(`/api/v3/plan_points`, dot.id)
        .then((res) => {
          this.dots = this.dots.filter((item) => item.id !== dot.id)
        })
      },

      setDot(dot){
        this.hideCreateDot()
        this.dots.push(dot)
      },

      getDots(){
        this.$backend.index(`/api/v3/plan_points?floor_plan_id=${this.floor_plan_id}`)
        .then((resDots) => {
          const dots = []
          resDots.data.forEach(item => {
            const dot = {
              value: item.device_id,
              type: item.type_device,
              x: item.x,
              y: item.y,
              id: item.id,
              short_info: item.short_info,
              icon_url: item.icon_url
            }
            dots.push(dot)
          })
          this.dots = dots
          this.loading = false
        })
      },

      hideCreateDot(){
        this.dots = this.dots.filter((item) => !item.create)
      },

      getMap(floorId) {
        this.loading = true
        this.$backend.index(`/api/v3/floor_plans?floor_id=${floorId}`)
        .then((resPlan) => {
          this.image_url = resPlan.data.plan
          this.types = resPlan.data.device_types
          this.icons = resPlan.data.device_icons
          const img = new Image()
          img.src = this.image_url
          img.onload = (e) => {
            const el = e.path[0]
            this.imageProps['maxWidth'] = el.width
            this.imageProps['maxHeight'] = el.height
            this.imageProps['minHeight'] = this.imageProps.minWidth * (this.imageProps.maxHeight / this.imageProps.maxWidth)
            const steepWidth = (this.imageProps.maxWidth - this.imageProps.minWidth) / 4
            for (let i = 0; i <= 4; i++){
              this.imageProps.zoomSteepsWidth[i] = this.imageProps.minWidth + steepWidth * i
            }
            const steepHeight = (this.imageProps.maxHeight - this.imageProps.minHeight) / 4
            for (let i = 0; i <= 4; i++){
              this.imageProps.zoomSteepsHeight[i] = this.imageProps.minHeight + steepHeight * i
            }
            this.currentZoomSteep = 2
            this.imageProps.width = this.imageProps.zoomSteepsWidth[this.currentZoomSteep]
            this.imageProps.height = this.imageProps.zoomSteepsHeight[this.currentZoomSteep]
            if (el.height < 500){
              this.mapHeight = 'low-height'
            }
            this.getMapCenterPosition()
          }
          this.floor_plan_id = resPlan.data.id
          this.getDots()
        })
        .catch((err) =>{
          this.loading = false
          console.log(err)
        })
      },

      getMapCenterPosition() {
        let x, y
        if (this.imageProps.width >= this.imageProps.minWidth){
          x = (this.imageProps.width - 900) / 2
          this.wrapperPosition.x = x
          this.wrapperPosition['maxX'] = x*2
        }
        if (this.imageProps.height >= this.imageProps.minHeight){
          y = (this.imageProps.height - 500) / 2
          this.wrapperPosition.y = y
          this.wrapperPosition['maxY'] = y*2
        }
      },

      zoomPlus() {
        if(this.currentZoomSteep !== 4){
          this.currentZoomSteep++
          this.imageProps.width = this.imageProps.zoomSteepsWidth[this.currentZoomSteep]
          this.imageProps.height = this.imageProps.zoomSteepsHeight[this.currentZoomSteep]
          this.getMapCenterPosition()
          this.minusActive = true
        }
        if(this.currentZoomSteep === 4){
          this.plusActive = false
        }
      },

      zoomMinus() {
        if(this.currentZoomSteep !== 0){
          this.currentZoomSteep--
          this.imageProps.width = this.imageProps.zoomSteepsWidth[this.currentZoomSteep]
          this.imageProps.height = this.imageProps.zoomSteepsHeight[this.currentZoomSteep]
          this.getMapCenterPosition()
          this.plusActive = true
        }
        if(this.currentZoomSteep === 0){
          this.minusActive = false
        }
      },

      setChangePosition() {
        if (this.changePosition === false){
          this.changePosition = true
        }else{
          this.changePosition = false
        }
      },

      startChangePosition() {
        event.preventDefault()
        this.startPositionX = event.x
        this.startPositionY = event.y
        document.addEventListener('mousemove', this.changeMapPosition)
        document.addEventListener('mouseup', () => {
          document.removeEventListener('mousemove', this.changeMapPosition)
        })
      },

      changeMapPosition(e) {
        const newX = e.x
        const newY = e.y
        if (this.wrapperPosition.maxX >= this.wrapperPosition.x && this.wrapperPosition.minX <= this.wrapperPosition.x){
          this.wrapperPosition.x = this.wrapperPosition.x + this.startPositionX - newX
        }
        if (this.wrapperPosition.maxY >= this.wrapperPosition.y && this.wrapperPosition.minY <= this.wrapperPosition.y){
          this.wrapperPosition.y = this.wrapperPosition.y + this.startPositionY - newY
        }
        if (this.wrapperPosition.maxX <= this.wrapperPosition.x){
          this.wrapperPosition.x = this.wrapperPosition.maxX
        }
        if (this.wrapperPosition.minX >= this.wrapperPosition.x){
          this.wrapperPosition.x = this.wrapperPosition.minX
        }
        if (this.wrapperPosition.maxY <= this.wrapperPosition.y){
          this.wrapperPosition.y = this.wrapperPosition.maxY
        }
        if (this.wrapperPosition.minY >= this.wrapperPosition.y){
          this.wrapperPosition.y = this.wrapperPosition.minY
        }
        this.startPositionX = e.x
        this.startPositionY = e.y
      }
    },

    created() {
      this.getMap(this.floor_id)
      if(this.editable === false){
        this.changePosition = true
      }
    },
  })
</script>
