<template lang='pug'>
  div
    div(v-if="dataHasLoaded" class="work-edit-form justify-center")
      handle-form(@close-form="closeForm" :parentData="{method: 'update', item: item, item_id: row.id, title_icon_path: title_icon_path, form_title: form_data.form_header.title.edit + ' №' + row.id, path: path, grid: grid, data: form_data, table_data: employee_statistics}")

</template>

<script>
  import handleForm from '../../shared/forms/FormWithTable'

  export default {
    data: function () {
      return {
        grid: 'employee_statuses',
        item: 'employee_status',
        path: this.$store.state.paths['employee_status'],
        title_icon_path: 'edit_header.svg',
        row: this.parentData.row,
        callback_params: this.parentData.callback_params,
        form_data: [],
        employee_statistics: [],
        dataHasLoaded: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      handleForm,
    },

    methods: {

      closeForm(data) {
        this.$emit('close-form', data)
      },

        loadFormData() {

            let form_data_path = this.path + '/form_data';
            let params = {};
            if (this.row) {
                params['id'] = this.row.id;
            }

            this.$backend.index(form_data_path, { params: params })
                .then(({ data }) => {
                    this.form_data = data.head
                    this.employee_statistics = data.table
                })
                .catch((error) => {
                    console.log(error);
                  if (error.response) { this.reLogin(error.response.status) }
                })
                .finally(() => {
                    this.dataHasLoaded = true
                })
        },
    },

    created(){
      this.loadFormData()
      this.$emit('row-is-active', true)
    },

    mounted() {
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>
</style>