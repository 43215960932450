<template lang='pug'>

  q-card(id="form" class="modal-form-card")

    q-card-section
      form-header(ref="form-header" @close-form="closeFiltersForm"
                  :parentData="{grid: grid, title: title, title_icon: 'filters_header.svg'}")


    div(v-if="loading" class="spinner-container modal-spinner")
      q-spinner(color="primary" size="3em")

    q-card-section
      q-form(v-show="!loading")
        div(class="row" v-for="row in data.scheme")
          div(v-for="filter in row" :key="filter.name" :class="'col-' + filter.col" style="padding: 5px;")
            component(:ref="filter.name" :is="getFilterComponent(getFilterDataByName(filter.name))"
                      @filters-loaded="filtersLoaded"
                      @load-options="loadOptions"
                      :parentData="{grid: grid, data: [getFilterDataByName(filter.name)], options_data: options_data}")

    q-card-actions(v-show="!loading" class="button-group row justify-center")
      q-btn(flat no-caps
            class="modal-form-cancel"
            :label="buttons['cancel']"
            @click="closeFiltersForm()")
      q-btn(flat no-caps
            class="modal-form-submit"
            :label="buttons['submit']"
            @click="submitFiltersForm()")

</template>

<script>
  import filters from '../filters'
  import formHeader from '../../shared/forms/formHeader'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        path: this.parentData.path,
        data: this.parentData.data,
        title: this.parentData.header.filters_form.form_header,
        buttons: this.parentData.header.filters_form.form_buttons,


        options_data: this.parentData.options_data,

        loading_filters: [],
        loading: true
      }
    },

    props: {
      parentData: Object
    },

    components: {
      filters,
      formHeader
    },

    computed: {
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    methods: {

      setOptionsData(data, filter_name) {
        this.$refs[filter_name][0].setOptionsData(data);
      },

      setLoading(val, filter_name) {
        this.$refs[filter_name][0].setLoading(val);
      },

      loadOptions(attr) {
        this.$emit('load-options', attr)
      },

      getFilterComponent(key) {
        if (key) {
          return filters[key.type]
        }
      },

      getFilterDataByName(filter_name) {
        return this.data.filters.find(f => f.name === filter_name)
      },

      filtersLoaded(filter=undefined) {
        if (this.loading_filters.length === 0) {
          this.loading_filters = this.data.filters.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (filter) {
          this.loading_filters = this.loading_filters.filter(f => f.sort().toString() !== filter.sort().toString())
        }

        if (this.loading_filters.length === 0) {

          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      },

      closeFiltersForm() {
        this.$emit('close-filters-form')
      },

      submitFiltersForm() {
        this.$emit('submit-filters-form')
      }
    },

    created() {
      this.filtersLoaded()
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/modal-form";

</style>