<template lang='pug'>

  div(class="form-field form-multiselect filter")
    multiselect(class="multiple-select-filter-field"
                v-model="currentFilter"
                track-by="value"
                label="label"
                :multiple="multiple"
                :placeholder="label"
                :show-labels="false"
                @search-change="searchFn"
                :options="filterOptions"
                :closeOnSelect="!multiple"
                :hideSelected="true"
                @open="multiselectHandler"
                open-direction="bottom")

      span(slot="noResult")
        i {{notifies.no_search_result}}
      span(slot="noOptions")
        i {{notifies.no_options_list}}

      template(slot="afterList")
        div(v-if="hasNextPage()" style="text-align:center;")
          div(v-observe-visibility="reachedEndOfList")
          span(style="padding:10px;") ...

    q-icon(name="cancel" v-show="currentFilter" @click.stop="resetFilter()" class="cancel-select-filter")

</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        multiple: this.parentData.data[0].multiple || false,
        // label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,

        options_params: this.parentData.data[0].options_params || {},
        options_path: this.parentData.data[0].options_path,
        watch: this.parentData.data[0].watch || {},

        options_data: this.parentData.options_data,

        optObject: {},
        options: [],
        filterOptions: [],

        nextPage: 2,
        pageSize: 20,
        loading: false,
        error: false,

        parent: null
      }
    },

    props: {
      parentData: Object
    },

    components: {
      Multiselect
    },

    computed: {
      label() {
        return this.parentData.data[0].label
      },

      parentFilter() {
        if (this.watch && this.watch['parent']) {
          return this.currentFilters[this.watch['parent']]
        } else {
          return undefined
        }
      },

      currentFilter: {
        get() {
          return this.currentFilters[this.name]
        },
        set(value) {
          this.$store.commit('updateFilter', {grid_name: this.grid, filter: this.name, value: value})
        }
      }
    },

    watch: {
      parentFilter(newValue, oldValue) {

        if (this.watch && this.watch['parent']) {
          if ((newValue && oldValue && (newValue.value !== oldValue.value)) || newValue) {

            this.options_params['infinite_scroll'] = {
              page: 1,
              per_page: this.pageSize
            };

            this.nextPage = 2

            let attr = Object.keys(this.options_params).reduce((result, key) => {
              result[key] = this.options_params[key]
              return result
            }, {})

            this.parent = newValue && (newValue.value || newValue.map(v => v.value))

            attr[this.watch['parent']] = this.parent
            this.options = []

            this.$emit('load-options', {params: attr, filter_name: this.name})

          } else if (!newValue && oldValue) {
            this.resetFilter()
            this.options = []
            this.parent = null
            this.loadDefaultOptions()
          }
        }
      }
    },

    methods: {

      loadDefaultOptions(params={}) {
        if (this.options_params) {
          if (this.options_params['infinite_scroll']) {
            this.options_params['infinite_scroll'] = {
              page: 1,
              per_page: this.pageSize
            };

            this.nextPage = 2
          }
          params = this.options_params
        }
        this.$emit('load-options', {params: params, filter_name: this.name})
      },

      resetFilter() {
        // this.currentFilter = this.multiple ? [] : ''
        // this.currentFilter = undefined
        this.$store.commit('resetFilter', {grid_name: this.grid, filter: this.name})
      },

      setOptionsData(data) {
        this.optObject = data.opt_object
        data.options.forEach(item => {
          let i = this.options.findIndex((opt) => { return opt.value === item.value })
          if (i < 0) { this.options.push(item) }
        })
        this.filterOptions = this.options;

        if (this.currentFilter) {
          if (this.multiple) {

            let val = this.currentFilter.map(el => el.value)

            if (val.length > 0) {
              val.forEach(id => {
                if (!this.options.map(el => el.value).includes(id)) {
                  this.currentFilter = this.currentFilter.filter(el => el.value !== id)
                }
              })
            }
          } else {
            if (!this.options.map(el => el.value).includes(this.currentFilter.value)) {
              this.resetFilter()
            }
          }

        }
      },

      setLoading(val) {
        this.loading = val
      },

      setError(val) {
        this.error = val
      },

      reachedEndOfList(reached) {
        if (reached && !this.error && !this.loading) {
          this.loading = true;

          this.$nextTick(() => {

            this.options_params['infinite_scroll'] = {
              page: this.nextPage,
              per_page: this.pageSize
            }

            let attr = Object.keys(this.options_params).reduce((result, key) => {
              result[key] = this.options_params[key]
              return result
            }, {})

            if (this.parent) {
              attr[this.watch['parent']] = this.parent
            }

            this.nextPage++

            this.$emit('load-options', {params: attr, filter_name: this.name})
          })
        }
      },

      hasNextPage() {
        let lastPage = Math.ceil(this.optObject.count / this.pageSize);
        return this.nextPage <= lastPage
      },

      searchFn (query) {
        let search_query = query.length > 0 ? query : null
        this.options_params['search_query'] = search_query

        this.options_params['infinite_scroll'] = {
          page: 1,
          per_page: this.pageSize
        };

        this.nextPage = 2

        this.options = []
        this.$emit('load-options', {params: this.options_params, filter_name: this.name})
      },
    },

    created() {
    },

    beforeMount() {
      this.loadDefaultOptions()
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/filters/select";

</style>