<template lang='pug'>

  div(class="report-link-area")
    span(class="report-link-area-title") Ваш отчет готов! Скачайте его, нажав на иконку ниже:
    div(class="existing-file")
      div(class="form-image")
        a(v-if="report" class="report-link" :href="report.link" target="_blank" @click="hideReportArea()")
          inline-svg(class="file-document" :src="require('../../../assets/icons/document.svg')")

</template>


<script>

  export default {
    data: function () {
      return {
        report: this.parentData.report
      }
    },
    props: {
      parentData: Object
    },

    computed: {
    },

    components: {
    },

    methods: {

      hideReportArea() {
        this.$root.$emit('show-report-modal', {report: {}, value: false})
      }
    },

    created() {
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>

  .report-link-area {
    position: fixed;
    top: 15px;
    background-color: var(--field-background);
    text-align: center;
    border-radius: 8px !important;
    padding: 20px;

    min-width: 350px;

    .report-link-area-title {
      display: flex;
      color: var(--form-field-label);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .existing-file {
      display: inline-block;
      padding-top: 20px;

      .form-image {
        padding: 10px;

        .report-link {
          color: var(--field-input-color);
          text-decoration: none;

          .file-document {
            width: 4em;
            height: 5em;
            cursor: pointer;
            path {
              fill: var(--existing-file-fill);
            }
          }

          .caption {
            display: block;
          }
        }
      }
    }
  }

</style>