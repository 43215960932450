<template lang="pug">
  div(class="floor-dot-menu")
    div(class="floor-dot-menu-buttons")
      q-btn(v-if="canBack" dense flat @click="backToType")
        inline-svg(fill="red" :src="require(`../../../../assets/icons/arrow-left.svg`)" alt="icon")
      q-btn(dense flat icon="close" @click="canselCreate")
    div( class="form-field form-input sublinks-menu-search" id="nav-link-search-input-dot")
      q-input(filled no-error-icon
              autocomplete="off"
              v-model="searchMsg"
              :placeholder="'Поиск'"
              :label-slot="false" :for="`nav-link-search-input-dot`")
        q-icon(name="cancel" v-if="searchMsg"
                @click.stop="resetSearchMsg()"
                class="cancel-string-field")
    ul(v-if="typeGetting" v-show="!loading" class="floor-dot-menu-select")
      div(
        v-for="(value, key) in types"
        :key="value.id" 
      )
        li(
          v-if="value"
          class="nav-link"
          @click="getType(getTypeText(key))"
          ) 
            |{{ locales['floor_plan'][current_locale][key] }}
    ul(v-else v-show="!loading" class="floor-dot-menu-select")
      li(
        v-for="item in searchDotList" 
        :key="item.id" 
        class="nav-link"
        @click="getCollection(nextDotType, item.id, dot)"
        ) 
        |{{item.title}}
</template>

<script>

  export default {

    data: function(){
      return{
        loading: false,
        currentDotList: [],
        searchDotList: [],
        nextDotType: '',
        prevDotType: '',
        floor_plan_id: this.parentData.floor_plan_id,
        building_id: this.parentData.building_id,
        dot: this.parentData.dot,
        types: this.parentData.types,
        floor_id: this.parentData.floor_id,
        search: '',
        typeGetting: true,
        canBack: false
      }
    },
    
    computed: {

      searchMsg: {
        get() {
          return this.search
        },
        set(value) {
          this.search = value

          if (this.search !== '') {
            this.searchDotList = this.currentDotList.filter(link => {
              return link.title.toLowerCase().includes(this.search.toLowerCase())
            })

          } else {
            this.searchDotList = this.currentDotList
          }
        }
      }
    },

    props: {
      parentData: Object
    },

    methods: {
      resetSearchMsg(item) {
        this.searchMsg = ''
      },

      getTypeText(msg){
        if (msg === 'Оборудование') return 'equipments'
        if (msg === 'Счетчики') return 'measuring_meters'
        if (msg === 'Датчики') return 'sensors'
        return msg
      },

      getCollection(type, id, dot){
        if (type == undefined){
          dot.value = id
          const plan_points = {
            floor_plan_id: this.floor_plan_id,
            device_id: dot.value,
            x: dot.x,
            y: dot.y,
            type_device: dot.type
          }
          this.$backend.collection(`api/v3/plan_points`, {plan_point: plan_points})
          .then((res) => {
            this.dot = {
              value: res.data.device_id,
              type: res.data.type_device,
              x: res.data.x,
              y: res.data.y,
              id: res.data.id,
              short_info: res.data.short_info,
              icon_url: res.data.icon_url
            }
            this.$emit('setDot', this.dot)
          })
          .finally(() => {
            this.currentDotList = []
            this.searchDotList = this.currentDotList
            this.prevDotType = ''
            this.nextDotType = ''
            this.loading = false
          }) 
          return
        }
        if (this.dot.type === 'equipments'){
          this.loading = true
          this.getEquipments(type, id)
          return
        }
        if (this.dot.type === 'meters'){
          this.loading = true
          this.getMeters(type, id)
          return
        }
        if (this.dot.type === 'sensors'){
          this.loading = true
          this.getSensors(type)
          return
        }
      },

      getEquipments(type, id){
        this.$backend.index(`api/v3/ppr/${type}/collection${this.prevDotType ? '?ppr_' + this.prevDotType + '_id=' + id : ''}`, {})
        .then((res) => {
          this.canBack = true
          this.currentDotList = res.data.options
          this.searchDotList = this.currentDotList
          let nextType
          let prevType
          switch (type) {
            case 'group_systems':
              nextType = 'systems'
              prevType = 'group_system'
              break
            
            case 'systems':
              nextType = 'equipments'
              prevType = 'system'
              break
          
            default:
              break
          }
          this.nextDotType = nextType
          this.prevDotType = prevType
          this.loading = false
        })
      },

      getMeters(type, id){
        this.$backend.index(`api/v3/measuring/${type}/collection?measuring_meter_type_id=${id}`)
        .then((res) => {
          this.canBack = true
          this.currentDotList = res.data.options
          this.searchDotList = this.currentDotList
          let nextType
          let prevType
          if(type === 'meter_types'){
            nextType = 'meters'
            prevType = 'meter_types'
          }
          this.nextDotType = nextType
          this.prevDotType = prevType
          this.loading = false
        })
      },

      getSensors(type){
        let nextType
        const params = {}
        params['building_id'] = this.building_id
        this.$backend.collection(`api/v3/${type}/collection`, params)
        .then((res) => {
          this.canBack = true
          this.currentDotList = res.data.options
          this.searchDotList = this.currentDotList
          this.nextDotType = nextType
          this.loading = false
        })
      },

      getType(type){
        this.typeGetting = false
        console.log(type)
        switch (type.toLowerCase()) {
          case 'equipments':
            this.dot['type'] = 'equipments'
            this.getCollection('group_systems')
            break
          case 'measuring_meters':
            this.dot['type'] = 'meters'
            this.getCollection('meter_types')
            break
          case 'sensors':
            this.dot['type'] = 'sensors'
            this.getCollection('sensors')
            break
            
          default:
            break
        }
      },
      
      canselCreate(){
        this.$emit('hide')
      },

      backToType(){
        this.canBack = false
        this.typeGetting = true
        this.currentDotList = []
        this.searchDotList = this.currentDotList
        this.prevDotType = ''
        this.nextDotType = ''
      },
    },

    beforeDestroy(){
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/floor-plan/main";

</style>
