<template lang='pug'>

  div(class="profile")
    img(:src="user && user.avatar ? user.avatar.url : require('../../../assets/images/missing.png')"
        :class="{'missing-avatar': !(user && user.avatar)}"
        @click="openForm()")

    q-dialog(v-model="form['profile']" transition-show="slide-left" transition-hide="slide-right")
      component(:is="getForm(grid)"
                @close-form="closeForm"
                :parentData="{row: user, callback_params: {}}")


</template>


<script>
  import crud_events from '../../shared/crud_events'

  export default {
    data: function () {
      return {
        grid: 'profile',
        user: this.$store.state.account.current_user,
        form: {
          profile: false
        }
      }
    },
    props: {
      parentData: Object
    },

    computed: {
    },

    components: {
      crud_events
    },

    methods: {

      getForm(grid) {
        return crud_events['edit'][grid]
      },

      openForm() {
        this.form['profile'] = true
      },

      closeForm(data) {
        if (data.data) {
          this.user = data.data
          this.$store.commit('updateCurrentUser', this.user)
          this.$emit('reload-data')
        }
        this.form['profile'] = false
      }
    },

    created(attr={}) {
      this.$store.commit('initialGridsState', {grid: this.grid, attr: attr})
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>
</style>