<template lang='pug'>

  div(class="col-12 col-lg-6")
    div(class="form-field-label")
      span {{field.title}}
      span(v-show="field.required" class="form-field-require-tag" style="color:red;" :title="field.title")
        |*

    div(class="form-field form-input")

      q-input(filled no-error-icon
              v-model="value"
              @input="val => editField(val)"
              :placeholder="field.description"
              :label-slot="false"
              autocomplete="off")
        q-icon(name="cancel" v-if="value && !readonly" @click.stop="resetField()" class="cancel-string-field")

</template>

<script>
  export default {
    data() {
        return {
            value: this.$props.initialValue[0].value
        }
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean || undefined,
            required: false,
            default: false
        },
        initialValue: {
            type: Array,
            required: false,
            default: () => ([{value: ''}])
        }
    },
    methods: {
        resetField() {
            this.value = ''
            this.$emit('reset-field', {id: this.field.id})
        },
        editField(val) {
            if (!this.readonly) this.$emit('edit-field', {id: this.field.id, value: [{value: val, title: ''}]})
            if (this.readonly) this.value = this.field._data.value[0].value
        }
    },
    created() {
        if (this.readonly) {
            
            this.value = this.field._data.value && this.field._data.value.length > 0 ? this.field._data.value[0].value : []
        }
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/string";

</style>