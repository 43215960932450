<template lang='pug'>

  div
    form-header(v-if="data" ref="form-header"
                :parentData="{title: data.form_header.title + ' № ' + row.id, title_icon: 'show_header.svg'}")

    div(v-for="block in data['blocks']" :class="['show-card-info ' + block.class]")
      div(v-for="part in block.parts" class="row-info-parts")
        div(v-if="block.spacer" class="spacer")
        div(v-for="field in part" class="row-info-field")
          span(class="row-label") {{field.label}}
          span(class="row-value" v-if="field.type === 'description'" v-html="row[field.value]")
          span(class="row-value" v-else) {{row[field.value] || '-'}}

</template>

<script>
  import formHeader from '../../../shared/forms/formHeader'

  export default {
    data: function () {
      return {
        // row: this.parentData.row,
        // data: this.parentData.data,
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader
    },

    computed: {
      row() {
        return this.parentData.row
      },

      data() {
        return this.parentData.data
      }
    },

    methods: {

      // refreshRow(row) {
      //   this.row = row
      // },
    }
  }
</script>
