<template lang='pug'>

  div(class="camera")
    div(class="card-label")
      span {{data.title}}
    div(class="card-image")
      img(:src="require(`../../../../assets/icons/telemetry/camera.png`)")


</template>

<script>

  export default {
    data: function () {
      return {
        camera: {}
      }
    },

    computed: {
      data() {
        return this.parentData.data
      }
    },

    props: {
      parentData: Object
    }
  }
</script>

<style lang='scss'>

  .camera {
    padding-top: 30px;
    height: 300px;

    .card-label {
      padding-bottom: 10px !important;
    }

    .card-image {
      padding-top: 10px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

</style>