<template lang='pug'>

  div
    div(v-show="locales")
      div(class="login-title row justify-center")
        span {{login_locales['login_form.title']}}

      q-form(@submit="onSubmit")
        div(class="login-form login-field")
          div(class="login-field-label")
            span {{login_locales['login_form.fields.login.label']}}

          div(class="login-field-input")
            q-input(filled
                    :label-slot="false"
                    ref="login"
                    v-model="login"
                    lazy-rules
                    no-error-icon
                    for="login-input"
                    :rules="[val => val && val.length > 0 || login_locales['fields_rules.not_empty'], val => checkLogin(val) || login_locales['fields_rules.wrong_email_format']]")


        div(class="login-form password-field")
          div(class="login-field-label")
            span {{login_locales['login_form.fields.password.label']}}

          div(class="login-field-input")
            q-input(filled
                    :label-slot="false"
                    ref="password"
                    v-model="password"
                    :type="hidden_pass ? 'password' : 'text'"
                    lazy-rules
                    no-error-icon
                    for="password-input"
                    :rules="[val => val && val.length >= 4 || login_locales['fields_rules.not_less_4'], val => !checkOnCyrillic(val) || login_locales['fields_rules.not_cyrillic']]")

              div(class="show-password-icon" @click="hidden_pass = !hidden_pass")
                inline-svg(:src="require(`../../../assets/icons/login/${hidden_pass ? 'closed-eye' : 'opened-eye'}.svg`)")


        div(class="login-form-under-fields-area row")
          q-checkbox(class="col-6" dark v-model="remember_me" :label="login_locales['login_form.fields.remember_me.label']")
          span(class="col-6 remind-password-link" @click="openPasswordRecovery()") {{login_locales['login_form.remind_password']}}


        div(class="login-form button-group row justify-center")
          q-btn(flat no-caps class="login-submit" :label="login_locales['login_form.buttons.submit']" type="submit")


      div(class="login-form-personal-data-agreement row justify-center")
        span {{login_locales['login_form.agreement']}}
        span(class="link") {{login_locales['login_form.agreement_link']}}


      div(class="login-form-download-app-msg row justify-center")
        span {{login_locales['login_form.download_app_msg']}}

      div(class="login-form-download-app-icons row justify-center")
        inline-svg(:src="require(`../../../assets/icons/login/app-store.svg`)" @click="downloadApp('app-store')")
        inline-svg(:src="require(`../../../assets/icons/login/google-play.svg`)" @click="downloadApp('google-play')")

</template>

<script>
  export default {
    data: function () {
      return {
        login: null,
        password: null,
        remember_me: false,
        hidden_pass: true
      }
    },

    methods: {

      openPasswordRecovery() {
        this.$emit('password-recovery', true)
      },

      downloadApp(name) {
        // console.log(name)
        let app_url = {
          'app-store': process.env.VUE_APP_APP_STORE_LINK,
          'google-play': process.env.VUE_APP_GOOGLE_PLAY_LINK
        };
        window.open(app_url[name], '_blank');
      },

      onSubmit () {
        this.$refs.login.validate()
        this.$refs.password.validate()

        if (this.$refs.login.hasError || this.$refs.password.hasError) {
          this.formHasError = true
        } else {
          let params = {
            user: {
              email: this.login.toLowerCase().trim(),
              password: this.password,
              // remember_me: (this.remember_me ? '1' : '0'),
            }
          }

          this.signIn(params)
        }
      },

      checkLogin (email) {
        let regex = /[^@\s]+@[^@\s]+/
        return regex.test(String(email).toLowerCase())
      },

      checkOnCyrillic (password) {
        let regex = /[а-яА-Я]/
        return regex.test(String(password))
      },
    },

    mounted() {
      this.$emit('set-loading', false)
    },
  }
</script>

<style lang='scss'>
</style>
