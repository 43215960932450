<template lang='pug'>

  div
    inline-svg(class="log-card-icon" :src="require('../../../assets/icons/checklist/log_card_icon.svg')" @click="modal = true")

    q-dialog(v-model="modal")
      q-card(class="modal-form-card log-card")

        div(class="log-card-title")
          span {{title}}

        table
          tr(class="log-card-param" v-for="param in data")
            td(class="label")
              span {{param['label']}}
            td(class="value")
              span {{param['value']}}

</template>

<script>

  export default {
    data: function () {
      return {
        modal: false
      }
    },

    computed: {
      title() {
        return this.parentData.title
      },

      data() {
        return this.parentData.data
      }
    },

    props: {
      parentData: Object
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/checklists/log_card";

</style>