<template lang='pug'>

  div
    div(id="project-filter-form-button" class="project-filter-form-button")
      q-btn(flat no-caps @click="openForm()")
        template(slot="default")
          span(class="project-filter-form-button-label") {{label}}


    q-dialog(v-model="modal")
      project-filters-form(ref="project-filters-form"
                           @load-form-data="loadFormData"
                           @close-form="closeForm"
                           @load-options="loadSelectFilterOptions"
                           @filters-form-reset="filtersFormReset"
                           :parentData="{data: filters_form_data, path: path[item], grid: grid, item: item, header_title: label, options_data: options_data}")

</template>

<script>
  import projectFiltersForm from './filters_form'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        item: this.parentData.item,
        path: this.$store.state.paths,
        filters_form_data: {},

        options_data: {},
        options: [],

        modal: false
      }
    },

    components: {
      projectFiltersForm
    },

    computed: {
      data() {
        return this.filters_form_data.filters_data
      },

      label() {
        return this.locales['dashboard'][this.current_locale].projects
      }
    },

    props: {
      parentData: Object
    },

    methods: {

      openForm() {
        this.modal = true
      },

      closeForm() {
        this.modal = false
      },

      loadFormData(params={}) {
        this.$backend.index(`${this.path[[this.item]]}/project_filter_data`, { params: params })
        .then(({ data }) => {
          this.filters_form_data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.$refs['project-filters-form'].setDataHasLoaded(true)
        })
      },

      setOptionsData(data, filter_name) {
      },

      setLoading(val, filter_name) {
      },

      filtersLoaded(filter) {
      },

      filtersFormReset() {
        this.$store.commit('resetAllFilters', {grid_name: this.grid, filters: Object.keys(this.filters)})
      },
    },

    created() {
      this.$store.commit('initialGridsState', {grid: this.grid, attr: {}})
    },

    beforeDestroy() {
      this.filtersFormReset()
    }
  }
</script>

<style lang="scss">
</style>