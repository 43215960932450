<template lang='pug'>

  index-page(@set-loading="setLoading" :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class, transition_key: 'work'}")

</template>

<script>
  import indexPage from '../../shared/indexPage'

  export default {
    data: function () {
      return {
        grid: 'works',
        report_class: 'work',
        path: this.$store.state.paths['work'],
        grid_key: 'data',
      }
    },
    components: {
      indexPage,
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>
