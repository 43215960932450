<template lang='pug'>
div(:class="`entity-view-quantity-card entity-view-quantity-card--bg-${background}-${theme}`")
  div(class="entity-view-quantity-card__title") {{ title }}
  div(class="entity-view-quantity-card__value") {{ value }}
</template>

<script>
  export default {
    props: {
      value: Number,
      title: String,
      background: String,
    },
  }
</script>

<style lang="scss">
.entity-view-quantity-card {
  background: var(--quantity-card-background);
  border-radius: 20px;
  height: 120px;
  width: 240px;

  .entity-view-quantity-card__title {
    margin-top: 20px;
    margin-left: 24px;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: var(--quantity-title-color);
  }

  .entity-view-quantity-card__value {
    margin-left: 24px;

    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: var(--quantity-value-color);
  }


  &--bg-total-light {
    background-image: url('../../../../assets/icons/map/quantity-cards/total_light.svg');
  }
  &--bg-urgent-light {
    background-image: url('../../../../assets/icons/map/quantity-cards/urgent_light.svg');
  }
  &--bg-total-dark {
        background-image: url('../../../../assets/icons/map/quantity-cards/total_dark.svg');
  }
  &--bg-urgent-dark {
        background-image: url('../../../../assets/icons/map/quantity-cards/urgent_dark.svg');
  }
}
</style>
