<template lang='pug'>

  div
    index-page(@set-loading="setLoading" :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class}")

    ui-prompts(:parentData="{areas_key: grid}")

</template>

<script>
  import indexPage from '../../shared/indexPage'
  import uiPrompts from '../../shared/ui_prompts/index.vue'

  export default {
    data: function () {
      return {
        grid: 'room_types',
        report_class: 'room_type',
        path: this.$store.state.paths['room_type'],
        grid_key: 'data',
      }
    },
    components: {
      indexPage,
      uiPrompts
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>
