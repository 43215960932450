<template lang='pug'>

  div(class="new-edit-form-tabs")
    q-tabs(v-model="tab.name" align="left")
      q-tab(v-for="tab in tabs.data" :key="tab.name" @click="addCurrentTab(tab)" :name="tab.name" :label-slot="false")

        template(v-if="tab.icon" slot="default")
          inline-svg(class="tab-icon" :src="require(`../../../../assets/icons/form/tabs/${tab.icon}`)")

        template(v-if="tab.label" slot="default")
          span() {{tab.label}}

    q-tab-panels(v-model="tab.name" class="new-edit-form-tabs-panels")
      q-tab-panel(v-for="tab in tabs.data" :key="tab.name" :name="tab.name")
        component(:is="getForm(tab.name)"
                  @show-field="showField"
                  @fields-loaded="fieldsLoaded"
                  @submit-form="submitForm"
                  :parentData="{method: method, grid: grid, data: data, item_id: item_id, building_id: building_id}")

</template>

<script>
  import forms from './forms'

  export default {
    data: function () {
      return {
        data: this.parentData.data,
        tabs: this.parentData.data.tabs,
        method: this.parentData.method,
        grid: this.parentData.grid,
        item_id: this.parentData.item_id,
        building_id: this.parentData.building_id,
        tab: ''
      }
    },

    props: {
      parentData: Object
    },

    components: {
      forms
    },

    computed: {
    },

    methods: {

      getForm(key) {
        if (key) {
          return forms[key]
        }
      },

      submitForm() {
        this.$emit('submit-form')
      },

      showField(data) {
        this.$nextTick(() => {
          if (this.$refs[data.name]) {
            if (data.val) {
              this.$refs[data.name][0].style.display = 'flex'
            } else {
              this.$refs[data.name][0].style.display = 'none'
            }
          }
        })
      },

      fieldsLoaded(field) {
        this.$emit('fields-loaded', field)
      },

      addCurrentTab(tab) {
        this.tab = tab
      }
    },

    created() {
      if (this.tabs.data && this.tabs.data.length > 0) {
        this.tab = this.tabs.data[0]
      }
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/tabs";

</style>