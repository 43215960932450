<template lang='pug'>
  div
    dialog-drag(:options="{ buttonPin: false, buttonClose: false, top: 400, left: 150 }")
      q-btn(flat no-caps class="remove-checkbox" @click="removeCheckboxes")
        template( slot="default")
          inline-svg( :src="require('../../assets/icons/grid/close.svg')")
      span(class='step') {{actionsByCheckBoxRowsCount}}
      span(class="chosen-elements") {{action_menu_locales['chosen_elements_title']}}
      button(class="action-button"
            v-for="action in actionsByCheckBox"
            :key="action.key"
            @click="clickActionButton(action)")
            span {{action.name}}



    q-dialog(ref="transition_modal" v-model="modal.transition_form")
      transition-form(ref="transition_form"
        @cancel-transition="cancelTransition"
        @send-transition="handleTransition"
        :parentData="{grid: grid, path: path, action: action, row: actionsByCheckBoxRows[0].row, transition_key: transition_key}")

    q-dialog(v-model="confirm_modal")
      confirm-modal(@confirm="confirmResult" :parentData="{message: confirm_message}")

</template>

<script>
  import DialogDrag from 'vue-dialog-drag'
  import transitionForm from './forms/transitionForm'
  import Button from "./forms/selected_items_form/fields/button";

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        grid: this.parentData.grid,
        transition_key: this.parentData.transition_key,
        action: {
            name: '',
            key: ''
        },
        modal: {
            transition_form: false
        },
        confirm_modal: false,
        confirm_message: '',
        destroy_data: undefined
      }
    },

    components: {
      Button,
      DialogDrag,
      transitionForm,
      confirmModal: () => import('./helpers/confirmModal')
    },

    props: {
      parentData: Object
    },

    computed: {
      action_menu_locales() {
        return this.locales.action_menu[this.current_locale]
      }
    },

    methods: {
      clickActionButton(action) {
        if (action.key === 'destroy') {
          this.destroy_data = this.actionsByCheckBoxRows
          this.confirm_message = this.notifies.confirm_destroy_entries
          this.confirm_modal = true
        } else {
          this.action = action
          this.modal.transition_form = true
        }
      },

      cancelTransition() {
        this.$refs.transition_modal.hide()
      },

      handleTransition(params) {
        let row_name = this.transition_key
        let promises = []



        let req_params = {}
        req_params[row_name] = params[row_name]

        this.$refs.transition_form.loadingForm(true)

        this.actionsByCheckBoxRows.forEach(
          (element) => {
            promises.push(this.$backend.update(this.path, element.row.id, req_params))
          }
        )

        Promise.all(promises)
        .then(res => {
            if (Array.isArray(res)) {
                this.$refs.transition_form.loadingForm(false);
                this.$refs.transition_modal.hide()
                this.$nextTick(() => {
                    res.forEach((element) => {
                        this.$emit('refresh-table', {attr: element.data, action: 'update'})
                    })
                  this.removeCheckboxes()
                })
            }
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }
          this.$refs.transition_form.loadingForm(false);
          this.$refs.transition_modal.hide()
          console.log(error)
        })
      },

      confirmResult(val) {
        if (val) {
          let promises = []

          this.destroy_data.forEach(
              (element) => {
                  promises.push(this.$backend.destroy(this.path, element.row.id))
              }
          )
          Promise.all(promises)
            .then(res => {
                if (Array.isArray(res)) {
                    this.confirm_modal = false
                    this.$q.notify(this.notifies.success_deleted_entries)
                    this.$refs.transition_modal.hide()
                    this.$nextTick(() => {
                        this.destroy_data.forEach((element) => {

                            this.$emit('refresh-table', {attr: {id: element.row.id}, action: 'destroy'})

                        })
                      this.removeCheckboxes()
                      this.destroy_data = undefined
                    })
                }
            })
            .catch((error) => {
              if (error.response) { this.reLogin(error.response.status) }
              this.$q.notify(error.response.data.message)
            })

        } else {
          this.destroy_data = undefined
          this.confirm_modal = false
        }
      },

      removeCheckboxes() {
        this.$emit('remove-checkboxes')
      }
    }
  }
</script>


<style src="vue-dialog-drag/dist/vue-dialog-drag.css"></style>
<style lang='scss'>

  @import '../../assets/styles/actions_menu.scss';

</style>


