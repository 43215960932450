<template lang='pug'>

  div(class="rooms")

    div(class="card-label")
      span {{data.title}}

    div(class="rooms-wrapper")

      div(v-if="loading" class="spinner-container grid-spinner")
        q-spinner(color="primary" size="3em")

      ul(v-show="!loading && rooms.length > 0" class="rooms-list")
        div(v-for="(r, i) in rooms" class="room-link")
          li(class="room-badge" :id="`room-badge-${r.id}`" @click="onClickRoom(r)")
            div(class="room-badge-icon")
              inline-svg(:src="require(`../../../../assets/icons/telemetry/plan.svg`)")
            div(class="room-badge-title")
              span {{r.title}}

      grid-hidden-msg(v-show="!loading && !rooms.length > 0" ref="rooms-hidden-msg" :parentData="{hide_table: hide_table}")

</template>

<script>
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'

  export default {
    data: function () {
      return {
        building: this.parentData.building,
        rooms: [],
        loading: true,

        hide_table: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      gridHiddenMsg
    },

    computed: {
      data() {
        return this.parentData.data
      },

      building_id() {
        return this.building && this.building.id || ''
      },

      room() {
        return this.parentData.room
      }
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs['rooms-hidden-msg']) {
          this.$refs['rooms-hidden-msg'].hideTable(val)
        }
      },

      onClickRoom(room) {
        if (room && room.id !== (this.room && this.room.id)) {
          let attr = {
            item: 'room',
            new_item: room
          }
          this.activeBadge(attr)
          this.$emit('set-room', room)
          this.$emit('load-sensors', {room_id: room.id})
        }
      },

      loadRooms(params={}) {
        this.loading = true

        if (params['floor_id']) {
          this.loading = true

          this.$backend.collection(`${this.$store.state.paths.room}/collection`, params)
          .then(({ data }) => {
            this.rooms  = data.options
          })
          .catch((error) => {
            console.log(error);
            if (error.response) { this.reLogin(error.response.status) }
          })
          .finally(() => {
            this.loading = false

            if (this.rooms.length > 0) {
              this.hideTable(false)
            } else {
              this.hideTable(true)
            }
          })

        } else {
          this.loading = false
          this.hideTable(true)
          this.$emit('load-sensors', {building_id: this.building_id})
        }
      },
    },

    created() {
    }
  }
</script>

<style lang='scss'>

  .rooms {
    margin-top: 20px;
    /*height: fit-content;*/

    .card-label {
      padding-bottom: 10px !important;
    }

    .rooms-wrapper {
      height: 250px;
      overflow: auto;

      .hidden-tabs-message {
        height: inherit;

        span {
          padding-top: initial;
        }
      }
    }

    .rooms-list {

      .room-link {
        padding: 20px 10px 10px 5px;

        .active-badge {
          span {
            color: #5B7AFF !important;
          }

          svg {
            path {
              fill: #5B7AFF !important;
            }
          }
        }

        .room-badge-title {
          padding-left: 15px;
        }

        li {
          display: flex;
          cursor: pointer;
          user-select: none;
          list-style-type: none;

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;

            color: var(--dashboard-label-color);
          }

          svg {
            path {
              fill: var(--dashboard-label-color);
            }
          }
        }

        /*li:hover {*/
          /*span {*/
            /*color: #5B7AFF;*/
          /*}*/
          /*svg {*/
            /*path {*/
              /*fill: #5B7AFF;*/
            /*}*/
          /*}*/
        /*}*/
      }
    }
  }

</style>