<template lang='pug'>
    q-dialog(:value="isCreatePageDialogVisible"
            persistent
            @hide="closeForm"
    )
        div(class="col-8 checklist-form__wrapper checklist-add-field__wrapper")
            div(class="checklist-new-form justify-center")
              q-card(class="checklist-form__card")
                  q-toolbar(class="q-px-lg row-bottom-border")
                      q-icon(name="description"
                            size="24px"
                            class="q-pr-md checklist-form__toolbar__icon"
                      )
                      span(class="checklist-form__toolbar__title") {{isEditPageDialogVisible ? checklist_locales.edit_page : checklist_locales.add_page}}
                      q-space
                      q-btn(flat
                            round
                            icon="close"
                            color="grey"
                            @click="closeForm()"
                      )
                  q-card-section
                    div(class="row checklist-form__position-relative")
                        div(class="col-6")
                            label
                                span(class="text-bold") {{checklist_locales.title}}
                                    q-input(class="q-pt-sm"
                                            v-model="page.name"
                                            outlined
                                            :rules="[val => val.length > 0 || checklist_locales.required_field]"
                                            color="indigo-12"
                                            :placeholder="checklist_locales.enter_page_title"
                                    )
                        //- div(class="col-6 checklist-form__position-absolute")
                        //-     label
                        //-         span(class="text-bold") Инструкция
                        //-             q-input(class="q-pt-sm"
                        //-                     v-model="page.description"
                        //-                     outlined
                        //-                     autogrow
                        //-                     color="indigo-12"
                        //-                     rows="1"
                        //-                     placeholder="Что могло бы помочь в выполнении задания?"
                                    )
                    div(class="row")
                        div(class="col-12")
                            div(class="add-checklist-item"
                                @click="createNewField"
                            )
                                div(class="text-center")
                                    q-icon(name="add")
                                    span(class="q-ml-md") {{checklist_locales.to_add_field}}
                    div(class="row" v-if="page.fields && page.fields.length && isSelectFieldsMenuVisible")
                        div(class="checklist-form__add-checklist__btn-group col-12 justify-end")
                            div(class="checklist-form__add-checklist__btn-group justify-end" v-if="isSelectFieldsMenuActive")
                                span(class="text") {{checklist_locales.were_selected_fields}}
                                  span(class="text-inner q-ml-sm") {{selected.length}}
                                    q-btn(class="q-ml-sm clickable-icon"
                                        round
                                        flat
                                        size="xs"
                                        icon="close"
                                        color="grey"
                                        @click="changeSelectedStatus()"
                                    )
                                span(class="text selected" @click="selectAllFields()") {{checklist_locales.select_all}}
                                q-btn(icon="more_horiz"
                                    outline
                                    color="indigo-12"
                                    class="more"
                                )
                                    q-menu(id="select-pages-fields")
                                        q-list(style="min-width: 100px")
                                            q-item(clickable v-close-popup @click="deleteField")
                                                q-item-section(style="color: var(--modal-form-card-color)")  {{checklist_locales.delete}}
                                            q-item(clickable v-close-popup @click="selected.length === 1 ? openEditPopup() : false")
                                                q-item-section(style="color: var(--modal-form-card-color)")  {{checklist_locales.edit}}
                            div(class="checklist-form__add-checklist__btn-group" v-else)
                                span(class="text selected"
                                    @click="isSelectFieldsMenuActive = !isSelectFieldsMenuActive"
                                ) {{checklist_locales.to_select_fields}}
                    div(class="row" v-if="page.fields && page.fields.length" id="drop-items")
                      div(class='col-12 checklist-field' v-for="(field, index) in page.fields.filter(elem => !elem._destroy)" :key="field.id")
                        q-checkbox(color="indigo-12" :value="checkIfSelected(field.id)" v-if="isSelectFieldsMenuActive" @input="selectField(field.id)")
                        span(class="checklist-field__title") {{field.name}}
                        span(class="checklist-field__type") {{field.type.label}}
                        div(class="btn-up-down q-ml-sm")
                          q-btn(icon="arrow_drop_up" flat round size="sm" @click="correctArr(field, 'up')" v-if="index > 0")
                          q-btn(icon="arrow_drop_down" flat round size="sm" @click="correctArr(field, 'down')" v-if="index < page.fields.length - 1")

                    div(class="row justify-center btn-grp btn-position-absolute")
                      q-btn(outline
                            color="indigo-12"
                            @click="closeForm()"
                      ) {{checklist_locales.cancel}}
                      q-btn(color="indigo-12 q-ml-sm"
                            @click="isEditPageDialogVisible ? editChecklistPage() : addChecklistPage()"
                            :disabled="page.fields.length === 0 || !page.fields || !page.name || !isSelectFieldsMenuVisible"
                      ) {{isEditPageDialogVisible ? checklist_locales.edit : checklist_locales.add}}
        addChecklistFieldDialog(
                      :checklist_locales="checklist_locales"
                      :isCreateFieldDialogVisible.sync="isCreateFieldDialogVisible"
                      :isEditFieldDialogVisible.sync="isEditFieldDialogVisible"
                      :editField="editField.id ? editField : undefined"
                      :allowFiles="allowFiles"
                      @on-new-field-created="onNewFieldCreated($event)"
                      @on-field-edit="onFieldEdit($event)"
        )
</template>

<script>
import addChecklistFieldDialog from './addChecklistFieldDialog.vue'

import {v4} from 'uuid'

  export default {
    data: function () {
      return {
        selected: [],
        isSelectFieldsMenuActive: false,
        isCreateFieldDialogVisible: false,
        isEditFieldDialogVisible: false,
        isSelectFieldsMenuVisible: true,
        editField: {},
        page: {
          id: v4(),
          name: '',
          fields: []
        }
      }
    },


    props: {
      isCreatePageDialogVisible: Boolean,
      isEditPageDialogVisible: {
        type: Boolean,
        required: false,
        default: false
      },
      editPage: {
        type: Object,
        required: false,
      },
      allowFiles: {
        type: Boolean,
        required: true
      },
      checklist_locales: {
        type: Object,
        required: true
      }
    },

    components: {
        addChecklistFieldDialog: addChecklistFieldDialog
    },

    methods: {
      closeForm() {
        this.$emit('update:isCreatePageDialogVisible', false)
        this.$emit('update:isEditPageDialogVisible', false)
      },

      editChecklistPage() {
        console.log(this.page)
        this.$emit("on-edit-checklist-page", this.page)
        this.closeForm()
      },

      addChecklistPage() {
        this.$emit("on-new-page-created", this.page)
        this.closeForm()
      },

      createNewField() {
        this.isCreateFieldDialogVisible = true;
      },

      onNewFieldCreated(event) {
        this.isCreateFieldDialogVisible = false;
        event._new = true
        this.page.fields.push(event)
      },

      onFieldEdit(event) {
        this.isCreateFieldDialogVisible = false;
        this.isEditFieldDialogVisible = false;
        this.$set(this.page.fields, this.page.fields.findIndex(elem => elem.id === event.id), event)
        this.editField = {}
      },

      changeSelectedStatus() {
        this.isSelectFieldsMenuActive = false;
        this.selected = []
      },

      checkIfSelected(id) {
        return this.selected.includes(id);
      },

      openEditPopup() {
        this.editField = this.page.fields[this.page.fields.findIndex(elem => this.selected[0] === elem.id)];
        this.isEditFieldDialogVisible = true;
        this.isCreateFieldDialogVisible = true;
      },

      selectAllFields() {
        if (this.selected.length !== this.page.fields.length) {
          this.selected = []
          this.page.fields.forEach(elem => !elem._destroy ? this.selected.push(elem.id) : false)
        } else {
          this.selected = []
        }
      },

      selectField(id) {
        if(this.checkIfSelected(id)) {
          const index = this.selected.findIndex((elem) => elem === id)

          if (index !== -1) {
            this.selected.splice(index, 1)
          }
        } else {
          if (this.page.fields.filter(elem => !elem._destroy).findIndex(elem => elem.id === id) !== -1) this.selected.push(id)
        }
      },

      deleteField() {
        this.page.fields = this.page.fields.map(elem => {
          elem._destroy = this.selected.includes(elem.id);
          return elem
          })
        if (this.page.fields.filter(elem => elem._destroy !== true).length === 0) this.isSelectFieldsMenuActive = false
        this.selected = []
      },

      correctArr(element, direction) {
        let _arr = this.page.fields
        const index = this.page.fields.findIndex(elem => elem.id === element.id)
        let first_el
        let second_el
        if (direction === 'up') {
          first_el = _arr[index - 1]
          second_el = _arr[index]
          this.$set(this.page.fields, index - 1, second_el)
          this.$set(this.page.fields, index, first_el)
        } else {
          first_el = _arr[index + 1]
          second_el = _arr[index]
          this.$set(this.page.fields, index + 1, second_el)
          this.$set(this.page.fields, index, first_el)
        }
      }
    },

    watch: {
      editPage: function() {
        if (this.isEditPageDialogVisible) this.page = this.editPage
      },
      page: {
        deep: true,
        handler() {
          this.isSelectFieldsMenuVisible = this.page.fields.filter(elem => elem._destroy !== true).length !== 0;
        }
      },
      isCreatePageDialogVisible: function() {
        if (!this.isCreatePageDialogVisible) this.page = {
          id: v4(),
          name: '',
          fields: []
        }
        else if (this.isEditPageDialogVisible) this.page = this.editPage
      },
      isEditPageDialogVisible: function() {
        if (this.isEditPageDialogVisible) this.page = this.editPage
        else this.page = {
            id: v4(),
            name: '',
            fields: []
      }
      },
    }
  }
</script>

<style lang='scss'>
.checklist-add-field {
    &__wrapper {
      .q-dialog__inner {
          padding-top: 40px;
      }


      .btn-grp .q-btn {
        padding: 5px 30px;
        text-transform: none;
        border-radius: 8px;
      }

      .q-select--without-chips {
        .q-field__control-container {
          flex-grow: 10
        }
      }

      .add-another-item {
        color: #5B7AFF;
        cursor: pointer;

        &:hover {
          opacity: .75;
        }

        &:active {
          opacity: .5;
        }
      }
      &.checklist-form {
        &__wrapper {
          box-shadow: none !important;
        }
      }
      .checklist-form {
        &__card {
          border-radius: 15px;
          box-shadow: none;
        }

        &__position-relative {
          margin-top: 30px !important;
        }
      }

      .checklist-new-form {
        padding-top: 20px;
      }

      .row-bottom-border {
        border-bottom: 1px solid lightgrey;
      }

    }

}
</style>
