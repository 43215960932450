/*
 * Wrapper around 2GIS marker.
 */
class Marker {
  constructor(api, map, coordinates, otherOptions) {
    const options = Object.assign({}, otherOptions, { coordinates });
    this.marker = new api.Marker(map.getWrapee(), options);
  }

  addClickHandler(f) {
    this.marker.on('click', f);
  }

  addHoverHandler(over, out) {
    this.marker.on('mouseover', over);
    this.marker.on('mouseout', out);
  }

  moveTo(coords) {
    this.marker.setCoordinates(coords);
  }

  destroy() {
    this.marker.destroy();
  }

  hide() {
    this.marker.hide();
  }

  show() {
    this.marker.show();
  }
}

export default Marker;
