export const headers = {
    "Accept": "application/json",
    "Content-type": "multipart/form-data",
}

export function jsonToFormData(params)
{
    let formData = new FormData()
    buildFormData(formData, params)
    return formData
}

export function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob) && !(Array.isArray(data) && (data.length === 0))) {
        Object.keys(data).forEach(key => {
            let newKey = key
            if (Array.isArray(data)) {
                newKey = ''
            }
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${newKey}]` : newKey)
        })
    } else if (Array.isArray(data) && data.length === 0) {
        formData.append(`${parentKey}[]`, [])
    }
     else {
        const value = data == null ? '' : data
        formData.append(parentKey, value)
    }
}

export default {headers, jsonToFormData, buildFormData}
