<template lang='pug'>
    q-dialog(:value="isCreateFieldDialogVisible"
            persistent
            @hide="closeForm"
    )
        div(class="col-8 checklist-form__wrapper checklist-add-field__wrapper")
            div(class="checklist-new-form justify-center")
              q-card(class="checklist-form__card")
                  q-toolbar(class="q-px-lg row-bottom-border")
                      q-icon(name="description"
                            size="24px"
                            class="q-pr-md checklist-form__toolbar__icon"
                      )
                      span(class="checklist-form__toolbar__title") {{isEditFieldDialogVisible ? checklist_locales.edit_field : checklist_locales.add_field}}
                      q-space
                      q-btn(flat
                            round
                            icon="close"
                            color="grey"
                            @click="closeForm()"
                      )
                  q-card-section
                    div(class="row checklist-form__position-relative")
                      div(class="col-6")
                        label
                          span(class="text-bold") {{checklist_locales.title}}
                          q-input(class="q-pt-sm"
                                  v-model="field.name"
                                  outlined
                                  :rules="[val => val.length > 0 || checklist_locales.required_field]"
                                  color="indigo-12"
                                  :placeholder="checklist_locales.enter_field_title"
                          )
                      div(class="col-6 checklist-form__position-absolute")
                        label
                          span(class="text-bold") {{checklist_locales.details}}
                          q-input(class="q-pt-sm"
                                  v-model="field.description"
                                  outlined
                                  autogrow
                                  color="indigo-12"
                                  rows="1"
                                  :placeholder="checklist_locales.what_can_help"
                          )
                    div(class="row")
                      div(class="col-6")
                        q-option-group(class="q-mt-sm"
                                      :options="fieldIsRequiredOptions"
                                      v-model="field.isRequired"
                                      color="indigo-12"
                                      type="toggle"
                        )
                    div(class="row")
                      div(class="col-6")
                        label
                          span(class="text-bold") {{ checklist_locales.field_type }}
                          q-select(class="q-pt-sm"
                                  :options="fieldTypeOptions"
                                  v-model="field.type"
                                  outlined
                                  :rules="[val => val.value != 0 || checklist_locales.required_field]"
                                  color="indigo-12"
                                  :default="checklist_locales.select_field_type"
                                  dropdown-icon="expand_more"
                                  popup-content-style="color: var(--modal-form-card-color)"
                          )
                      div(class="col-6"
                          v-if="checkFieldIsAddItemAllowed()"
                      )
                        label(class="q-pb-sm")
                          span(class="text-bold") {{labelText}}
                          div(style="overflow: auto; max-height: 240px;")
                            div(v-for="(option, index) in field.options" style="display: flex; flex-direction: row")
                              q-input(
                                      class="q-pt-sm special-fields"
                                      :value="getFieldValue(option)"
                                      @input="(val) => changeFieldOption(val, option)"
                                      outlined
                                      autogrow
                                      :rules="[val => val.length > 0 || checklist_locales.required_field]"
                                      color="indigo-12"
                                      rows="1"
                                      :bottom-slots="false"
                                      :placeholder="checklist_locales.subfield_title"
                              )
                              div(class="btn-up-down q-ml-sm q-pt-sm")
                                q-btn(icon="arrow_drop_up" flat round size="sm" @click="correctArr(option, 'up')" v-if="index > 0")
                                q-btn(icon="arrow_drop_down" flat round size="sm" @click="correctArr(option, 'down')" v-if="index < field.options.length - 1")
                        div(class="q-mt-sm")
                          span(class="add-another-item"
                              @click="addAnotherOption"
                              ) {{checklist_locales.another_option}}
                    div(class="row justify-center btn-grp btn-position-absolute")
                      q-btn(outline color="indigo-12" @click="closeForm") {{checklist_locales.cancel}}
                      q-btn(color="indigo-12 q-ml-sm"
                            @click="isEditFieldDialogVisible ? editChecklistField() : addChecklistField()"
                            :disabled="disabled"
                            type="submit"
                      ) {{isEditFieldDialogVisible ? checklist_locales.edit : checklist_locales.add}}
</template>

<script>

import {v4} from 'uuid'

  export default {
    data: function () {
      return {
        fieldIsRequiredOptions: [{label: this.checklist_locales.required_subfield, value: 1}],
        fieldTypeOptions: [],
        fieldTypeLabelsOptions: [],
        allowedToExpand: [],
        field: this.$props.editField && this.$props.editField.id ? this.$props.editField : {
          id: v4(),
          name: '',
          description: '',
          isRequired: [],
          type: {label: this.checklist_locales.select_field_type, value: 0},
          options: []
        },
        disabled: true
      }
    },

    props: {
      isCreateFieldDialogVisible: Boolean,
      isEditFieldDialogVisible: {
        type: Boolean,
        required: false,
        default: false
      },
      editField: {
        type: Object,
        required: false,
      },
      allowFiles: {
        type: Boolean,
        required: true
      },
      checklist_locales: {
        type: Object,
        required: true
      }
    },

    computed: {
      labelText() {
        return this.fieldTypeLabelsOptions.filter((elem) => this.field.type.value === elem.value).length ? this.fieldTypeLabelsOptions.filter((elem) => this.field.type.value === elem.value)[0].label || "" : ""
      }
    },

    methods: {
      async getFieldOptions() {
        const newFieldOptions = await this.$backend.index('api/v3/checklist_element_types/collection')

        newFieldOptions.data.options.forEach((elem) => {
          if (!this.allowFiles && elem.code === 'file') {
            return
          }
          this.fieldTypeOptions.push({label: elem.title, value: elem.id})
          this.fieldTypeLabelsOptions.push({label: elem.add_item_title, value: elem.id})
          if (elem.is_add_item_allowed) {
            this.allowedToExpand.push(elem.id)
            }
        })
      },

      closeForm() {
        this.$emit('update:isCreateFieldDialogVisible', false)
        this.$emit('update:isEditFieldDialogVisible', false)
      },

      changeFieldOption(val, option) {
        const index = this.field.options.findIndex(elem => elem.id === option.id)
        if (index !== -1) {
          this.field.options[index].value = val
        }
      },

      getFieldValue(option) {
        const index = this.field.options.findIndex(elem => elem.id === option.id)
        if (index !== -1) {
          return this.field.options[index].value
        }
      },

      checkFieldIsAddItemAllowed() {
        if (this.editField && this.editField.options.length && this.isEditFieldDialogVisible) {
          return true
        }
        const res = this.field.type && this.allowedToExpand.includes(this.field.type.value)
        if (res && this.field.options.length === 0) {
          this.field.options.push({id: v4(), value: '', _new: true})
        }
        return res
      },

      addAnotherOption() {
        this.field.options.push({id: v4(), value: '', _new: true})
      },

      addChecklistField() {
        this.$emit("on-new-field-created", this.field)
        this.closeForm()
      },

      editChecklistField() {
        this.$emit("on-field-edit", this.field)
        this.closeForm()
      },

      correctArr(element, direction){
        let _arr = this.field.options
        const index = this.field.options.findIndex(elem => elem.id === element.id)
        let first_el
        let second_el

        if (direction === 'up') {
          first_el = _arr[index - 1]
          second_el = _arr[index]
          this.$set(this.field.options, index - 1, second_el)
          this.$set(this.field.options, index, first_el)
        } else {
          first_el = _arr[index + 1]
          second_el = _arr[index]
          this.$set(this.field.options, index + 1, second_el)
          this.$set(this.field.options, index, first_el)
        }
      }
    },

    created() {
      this.getFieldOptions()
      this.checklist_locales = this.locales.checklist[this.current_locale]
    },

    beforeDestroy() {
      this.field = {
          id: v4(),
          name: '',
          description: '',
          isRequired: [],
          type: {label: this.checklist_locales.select_field_type, value: 0},
          options: [{id: v4(), value: ''}]
        }
    },

    watch: {
      editField: function() {
        this.field = this.editField
      },
      field: {
        deep: true,
        handler() {
          this.field.type.label === this.checklist_locales.select_field_type || !this.field.name || (this.field.options.length > 0 && this.field.options.filter(elem => elem.value === '').length !== 0) ? this.disabled = true : this.disabled = false
        }
      },
      isCreateFieldDialogVisible: function() {
        if (!this.isCreateFieldDialogVisible) this.field = {
          id: v4(),
          name: '',
          description: '',
          isRequired: [],
          type: {label: this.checklist_locales.select_field_type, value: 0},
          options: [{id: v4(), value: ''}]
        }
        else if (this.isEditFieldDialogVisible) this.field = this.editField
      },
      isEditFieldDialogVisible: function() {
        if (this.isEditFieldDialogVisible) this.field = this.editField
        else this.field = {
          id: v4(),
          name: '',
          description: '',
          isRequired: [],
          type: {label: this.checklist_locales.select_field_type, value: 0},
          options: [{id: v4(), value: ''}]
        }
    }
    }
  }
</script>

<style lang='scss'>
.checklist-add-field {
    &__wrapper {
      .q-dialog__inner {
          padding-top: 40px;
      }

      .special-fields {
        .q-field__bottom {
          display: none !important;
        }
      }

      .btn-grp .q-btn {
        padding: 5px 30px;
        text-transform: none;
        border-radius: 8px;
      }

      .q-select--without-chips {
        .q-field__control-container {
          flex-grow: 10
        }
      }

      .add-another-item {
        color: #5B7AFF;
        cursor: pointer;

        &:hover {
          opacity: .75;
        }

        &:active {
          opacity: .5;
        }
      }
      &.checklist-form {
        &__wrapper {
          box-shadow: none !important;
        }
      }
      .checklist-form {
        &__card {
          border-radius: 15px;
          box-shadow: none;
        }

        &__position-relative {
          margin-top: 30px !important;
        }
      }

      .checklist-new-form {
        padding-top: 20px;
      }

      .row-bottom-border {
        border-bottom: 1px solid lightgrey;
      }

    }

}
</style>
