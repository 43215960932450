<template lang='pug'>

  div
    div(class="middle-sub-block top")
      div(class="dashboard-card link-card" v-for="link in data[0]")
        div(class="link-card-icon-container")
          div(class="link-card-icon")
            inline-svg(:style="link.style"
                       :src="require(`../../../../assets/icons/dashboard/links/${link.icon}`)")
        div(class="link-card-title")
          span {{link.title}}
        div(class="link-card-button")
          q-btn(flat no-caps @click="onClickFn(link.button.onclick)" :disable="link.button.disable")
            template(slot="default")
              span {{link.button.label}}


    div(class="middle-sub-block")
      div(class="dashboard-card link-card" v-for="link in data[1]")
        div(class="link-card-icon-container")
          div(class="link-card-icon")
            inline-svg(:style="link.style"
                       :src="require(`../../../../assets/icons/dashboard/links/${link.icon}`)")
        div(class="link-card-title")
          span {{link.title}}
        div(class="link-card-button")
          q-btn(flat no-caps @click="onClickFn(link.button.onclick)" :disable="link.button.disable")
            template(slot="default")
              span {{link.button.label}}

</template>

<script>

  export default {

    computed: {
      data() {
        return this.parentData.data;
      },
    },

    props: {
      parentData: Object
    },

    methods: {

      onClickFn(fn) {
        if (fn) {
          this[fn]()
        }
      },

      addIssue(params = {}) {
        params['add_issue'] = true
        params['attr'] = {}
        this.sendQuery(params)
      },

      openIssues(params = {}) {
        this.sendQuery(params)
      },

      sendQuery(params) {
        if (this.current_user.additional_services_ids.length > 0) {
          params['service_id'] = this.current_user.additional_services_ids[0]
        }

        this.$router.push({
          name: 'issues',
          params: params
        })
      }
    }
  }
</script>

<style lang="scss">
</style>