<template lang='pug'>

  q-input(filled v-model="currentFilter" debounce="500" :label="label" class="search-all-filter-field")
    q-icon(name="cancel" v-if="currentFilter" @click.stop="resetFilter()" class="cancel-search-all-filter")

</template>

<script>
  export default {
    data: function () {
      return {
        grid: this.parentData.grid
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      label() {
        return this.parentData.data[0].label
      },

      name() {
        return this.parentData.data[0].name
      },

      currentFilter: {
        get() {
          return this.$store.state.grid[this.grid][this.name]
        },
        set(value) {
          this.$store.commit('updateQuery', { grid_name: this.grid, value: value })
        }
      }
    },

    methods: {
      resetFilter() {
        this.$emit('search-all-filter-reset')
      },
    },

    beforeMount() {
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/filters/search-all";

</style>