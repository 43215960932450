import editIssue from '../../view/issues/edit'
import editBuilding from '../../view/buildings/edit'
import editFacility from '../../view/facilities/edit'
import editPost from '../../view/posts/edit'
import editFloor from '../../view/floors/edit'
import editRoom from '../../view/rooms/edit'
import editRoomType from '../../view/room_types/edit'
import editUser from '../../view/users/edit'
import editWorkGroup from '../../view/work_groups/edit'
import editWork from '../../view/works/edit'
import editMeasureUnit from '../../view/measure_units/edit'
import editCompany from '../../view/companies/edit'
import editDocument from '../../view/documents/edit'
import editService from '../../view/services/edit'
import editWorkCategory from '../../view/work_categories/edit'
import editMaterial from '../../view/materials/edit'
import editProfile from '../../view/profile/edit'
import editContract from '../../view/contracts/edit'
import editPprEquipment from '../../view/ppr/equipments/edit'
import editPprWork from '../../view/ppr/works/edit'
import editPprFrequencyWork from '../../view/ppr/frequency_works/edit'
import editPprGroupSystem from '../../view/ppr/group_systems/edit'
import editPprSystem from '../../view/ppr/systems/edit'
import editPprWorkGroup from '../../view/ppr/work_groups/edit'
import editEmployee from '../../view/employees/edit'
import editIllnessStatus from '../../view/illness_statuses/edit'
import editEmployeeStatus from '../../view/employee_statuses/edit'
import editCovidTest from '../../view/covid_tests/edit'
import editMeasuringMeter from '../../view/measuring/meters/edit'
import editMeasuringMeterType from '../../view/measuring/meter_types/edit'
import editMeasuringMeterReading from '../../view/measuring/meter_readings/edit'
import editChecklist from '../../view/checklists/edit'


export default {
  issues:               editIssue,
  archive:              editIssue,
  buildings:            editBuilding,
  facilities:           editFacility,
  posts:                editPost,
  floors:               editFloor,
  rooms:                editRoom,
  room_types:           editRoomType,
  users:                editUser,
  work_groups:          editWorkGroup,
  works:                editWork,
  measure_units:        editMeasureUnit,
  companies:            editCompany,
  documents:            editDocument,
  services:             editService,
  work_categories:      editWorkCategory,
  materials:            editMaterial,
  profile:              editProfile,
  contracts:            editContract,
  ppr_equipments:       editPprEquipment,
  ppr_works:            editPprWork,
  ppr_frequency_works:  editPprFrequencyWork,
  ppr_group_systems:    editPprGroupSystem,
  ppr_systems:          editPprSystem,
  ppr_work_groups:      editPprWorkGroup,
  employees:            editEmployee,
  illness_statuses:     editIllnessStatus,
  employee_statuses:    editEmployeeStatus,
  covid_tests:          editCovidTest,
  measuring_meters:     editMeasuringMeter,
  measuring_meter_types: editMeasuringMeterType,
  measuring_meter_readings: editMeasuringMeterReading,  
  checklists:           editChecklist
}