<template lang='pug'>

  div(class="show-form")
    div(v-if="loading" class="spinner-container form-spinner show-form")
      q-spinner(color="primary" size="3em")

    q-form(v-show="!loading" @submit="submitForm")
      div(class="row" v-for="row in data.scheme")
        div(v-for="field in row" :class="'col-' + field.col" style="padding: 5px; margin: auto;")

          component(:ref="field.name" v-if="field.name !== 'add_button'"
                    :is="getFieldComponent(getFieldDataByName(field.name).type)"
                    @fields-loaded="fieldsLoaded"
                    :parentData="{grid: grid, data: [getFieldDataByName(field.name)]}")

          q-btn(v-if="field.name === 'add_button' && data"
                flat no-caps
                class="show-form-submit"
                :label="data.buttons.submit"
                type="submit")

</template>

<script>
  import fields from "../../shared/forms/fields"

  export default {
    data: function () {
      return {
        // row: this.parentData.row,
        item: this.parentData.item,
        // data: this.parentData.data,
        path: this.parentData.path,
        grid: this.parentData.grid,

        loading: true,
        loading_fields: [],
        form_valid: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      fields
    },

    computed: {
      data() {
        return this.parentData.data
      },

      row() {
        return this.parentData.row
      }
    },

    watch: {
      data(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          newVal.fields.forEach(f => {
            this.$nextTick(() => {
              if (f.value) {
                this.$refs[f.name][0].setField(f.value)
              }
            })
          })
        }
      }
    },

    methods: {

      // refreshRow(row) {
      //   this.row = row
      // },

      submitForm() {
        let params = {};
        let form = this.currentForm;

        this.checkFormValidation(form)

        if (this.form_valid) {

          params[this.item] = Object.keys(form).reduce((result, e) => {
            if (Array.isArray(form[e]['field'])) {
              result[e] = form[e]['field'].map(e => e['value'])
            } else if (typeof form[e]['field'] === 'object') {
              result[e] = form[e]['field']['value']
            } else {
              result[e] = form[e]['field']
            }
            return result;
          }, {})

          let keys = Object.keys(params[this.item]).filter(key => {
            let field = this.data.fields.find(f => f.name === key)
            return params[this.item][key] && (field && params[this.item][key] !== field.value)
          })

          if (keys.length > 0) {

            this.loading = true

            this.$backend.update(this.path, this.row.id, params)
            .then(res => {
              // console.log(res.data)

              if (res.data) {
                this.$nextTick(() => {
                  let res_params = {}
                  res_params['attr'] = res.data
                  res_params['action'] = 'update'
                  this.$emit('refresh-table', res_params)
                  this.loading = false

                  this.resetForm()

                  this.$q.notify(this.notifies.success_updated_entry)
                })
              }
            })
            .catch((error) => {
              if (error.response) { this.reLogin(error.response.status) }

              this.$nextTick(() => {
                this.loading = false

                // this.resetForm()

                let base_errors = error.response.data.errors.base
                if (base_errors) {
                  base_errors.forEach(e => {
                    this.$q.notify(e.message)
                  })
                } else {
                  this.$q.notify(this.notifies.error_ask_admin)
                }
              })
              console.log(error)
            })
            .finally(() => {
              this.$nextTick(() => {
                this.$emit('load-show-data')
              })
            })
          }

        } else {
          this.$q.notify(this.notifies.fix_invalid_fields)
        }
      },

      // checkFormValidation(form) {
      //   let invalid_form = Object.keys(form).reduce((result, e) => {
      //     if (form[e]['invalid'] === true) result[e] = form[e]
      //     return result;
      //   }, {})
      //
      //   let invalid_fields = Object.keys(invalid_form)
      //
      //   if (invalid_fields.length > 0) {
      //     this.form_valid = false
      //
      //     this.$store.commit('createFormField', {grid_name: this.grid, field: 'invalid_fields'})
      //
      //     let val = {}
      //     invalid_fields.forEach(f => val[f] = true)
      //     this.$store.commit('updateFormField', {grid_name: this.grid, field: 'invalid_fields', value: val})
      //
      //   } else {
      //     this.form_valid = true
      //     this.$store.commit('resetFormField', {grid_name: this.grid, field: 'invalid_fields'})
      //   }
      // },

      getFieldComponent(key) {
        return fields[key]
      },

      getFieldDataByName(field_name) {
        return this.data.fields.find(f => f.name == field_name)
      },

      fieldsLoaded(field=undefined) {
        if (this.loading_fields.length === 0) {
          this.loading_fields = this.data.fields.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (field) {
          this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString())
        }

        if (this.loading_fields.length === 0) {
          this.loading = false
        }
      },

      // resetForm() {
      //   let fields = Object.keys(this.currentForm);
      //   this.$store.commit('resetFormAllFields', {grid_name: this.grid, fields: fields})
      // },
    },

    created() {
      this.fieldsLoaded()

      this.$root.$on('reset-show-form', () => {
        this.resetForm()
      })
    }

  }
</script>

<style lang="scss">

  @import "../../../assets/styles/show/form";

</style>