<template lang="pug">
  div(class="floor-plan-legend")
    div(v-if="editable" class="floor-plan-legend-text") {{locales['floor_plan'][current_locale]['create_object']}}
    div(v-if="icons" class="floor-plan-legend-icons")
      div(v-for="(value, key) in icons" class="floor-plan-legend-icons-item")
        inline-svg(class="floor-plan-legend-icon" :src="value")
        span(class="floor-plan-legend-icons-item-sep") —
        span(class="floor-plan-legend-icons-item-text") {{getName(key)}}
</template>

<script>

  export default {

    data: function(){
      return{
        editable: this.legendData.editable,
        types: this.legendData.types,
        icons: this.legendData.icons
      }
    },

    props: {
      legendData: Object
    },

    methods: {
      getName(type){
        if (type === 'equipments'){
          return this.locales['floor_plan'][this.current_locale]['equipment']
        }
        if (type === 'measuring_meters'){
          return this.locales['floor_plan'][this.current_locale]['meter']
        }
        if (type === 'sensors'){
          return this.locales['floor_plan'][this.current_locale]['sensor']
        }
      }
    },
    
    beforeMount(){
      console.log(this.types)
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/floor-plan/main";

</style>
