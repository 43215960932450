<template lang='pug'>

  img(class="quantity-bg" :src="require(`../../../../assets/icons/dashboard/quantities/${code}_${theme}.svg`)")
  //inline-svg(class="quantity-bg" :src="require(`../../../../assets/icons/dashboard/quantities/${code}_${theme}.svg`)")

</template>

<script>

  export default {
    props: {
      code: String
    }
  }
</script>

<style lang="scss">
</style>
