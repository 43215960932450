<template lang='pug'>

  div(v-if="swiperOption['spaceBetween']" class="quantity-swiper-area" @mouseenter="autoplayStop()" @mouseleave="autoplayStart()")
    swiper(ref="QuantitySwiper" :options="swiperOption")
      IssueQuantity(class="swiper-slide"
                    :quantity="issues_count"
                    :disable="true")
      IssueQuantity(class="swiper-slide" v-for="count in states_counts"
                    :key="count.code"
                    :quantity="count"
                    :disable="false")

    //div(class="swiper-button-prev" slot="button-prev")
    //div(class="swiper-button-next" slot="button-next")

</template>

<script>
  import IssueQuantity from './IssueQuantity'
  import { Swiper } from 'vue-awesome-swiper';

  export default {
    data() {
      return {
        windowWidth: '',
        options: {
          spaceBetween: null,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          grabCursor: true,
          loop: true,
          freeMode: true,
          speed: 10000,
          mousewheel: true,
          autoplay: {
            delay: 0,
            disableOnInteraction: false
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        }
        // swiperOption: {
        //   spaceBetween: 30,
        //   slidesPerView: 'auto',
        //   slidesPerGroup: 1,
        //   grabCursor: true,
        //   loop: true,
        //   freeMode: true,
        //   speed: 10000,
        //   mousewheel: true,
        //   autoplay: {
        //     delay: 0,
        //     disableOnInteraction: false
        //   },
        //   navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        //   }
        // }
      }
    },

    props: {
      parentData: Object
    },

    components: {
      Swiper,
      IssueQuantity
    },

    computed: {
      swiperOption: {
        get() {
          return this.options
        },

        set(attr) {
          this.options[attr['key']] = attr['value']
        }
      },

      issues_count() {
        return this.parentData.issues_total_count
      },

      states_counts() {
        return this.parentData.issues_count_by_status
      }
    },

    watch: {
      windowWidth(newWidth, oldWidth) {

        let space = newWidth < 568 ? 20 : 30

        if (this.swiperOption['spaceBetween'] !== space) {
          // setTimeout(() => {
          this.swiperOption = {key: 'spaceBetween', value: space}
          // },200)

          setTimeout(() => {
            this.autoplayStop()
            this.autoplayStart()
          },1000)
        }
      }
    },

    methods: {
      autoplayStop() {
        if (this.$refs.QuantitySwiper) {
          this.$refs.QuantitySwiper.$swiper.autoplay.stop()
        }
      },

      autoplayStart() {
        if (this.$refs.QuantitySwiper) {
          this.$refs.QuantitySwiper.$swiper.autoplay.start()
        }
      }
    },

    created() {
      this.$nextTick(() => {
        this.addWindowWidth()

        window.addEventListener('resize', () => {
          this.addWindowWidth()
        });
      })
    },

    beforeDestroy() {
      window.removeEventListener("resize", () => {
        this.addWindowWidth()
      });
    }
  }
</script>

<style lang="scss">
</style>