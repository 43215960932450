<template lang='pug'>

  index-page(@set-loading="setLoading"
              :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class, report_namespace: report_namespace, transition_key: transition_key, sti_type: sti_type, except_filters: except_filters}")

</template>

<script>
  import indexPage from '../../../shared/indexPage'

  export default {
    data: function () {
      return {
        grid: 'ppr_issues',
        path: this.$store.state.paths['ppr_issue'],
        transition_key: 'issue',
        sti_type: 'Ppr::Issue',
        grid_key: 'data',
        report_class: 'issue',
        report_namespace: 'ppr',
        except_filters: {
          state: this.$store.state.issues_archive_states
        },
      }
    },
    components: {
      indexPage,
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>