<template lang='pug'>

  div(v-if="state !== 'none'")
    div(v-if="state")
      div(v-if="!dataHasLoaded" class="spinner-container modal-spinner")
        q-spinner(color="primary" size="3em")

      div(v-if="dataHasLoaded")
        div(class="checklist-form-button")
          q-btn(flat no-caps @click="openForm()" :class="btn_class")

            template(slot="default")
              inline-svg(class="checklist-form-button-icon" :src="require(`../../../assets/icons/checklist/btn_icon.svg`)")

            template(slot="default")
              span(class="checklist-form-button-label") {{label}}


        q-dialog(v-model="modal.form" class="checklist-form__wrapper")
          checklist-form(ref="checklist-form"
                         @close-form="closeForm($event)"
                         @reload-form-data="loadFormData"
                         :parentData="{row: row, data: form_data, path: path, grid: grid, header_title: header_title}")

        q-dialog(v-model="modal.show" class="checklist-form__wrapper")
          checklist-show(ref="checklist-show"
                        @closeForm="closeForm"
                        @reload-form-data="loadFormData"
                        :parentData="{row: row, data: show_data, path: path, grid: grid, header_title: header_title}"
          )
        //- q-dialog(v-model="modal.logs")
          //- checklist-logs(ref="checklist-logs"
          //-                @close-form="closeForm"
          //-                :parentData="{row: row, path: path, grid: grid, header_title: header_title}")
</template>

<script>
  import checklistForm from './form'
  import checklistLogs from './logs'
  import checklistShow from './show'

  export default {
    data: function () {
      return {
        grid: 'checklists',
        path: this.$store.state.paths['checklist'],
        form_data: [],
        show_data: [],
        state: '',

        header_title: 'Чеклист',

        dataHasLoaded: false,

        modal: {
          form: false,
          logs: false,
          show: false
        },
      }
    },

    components: {
      checklistForm,
      checklistLogs,
      checklistShow
    },

    computed: {
      row() {
        return this.parentData.row
      },

      parentGrid() {
        return this.parentData.grid
      },

      btn_class() {
        return this.params_by_state[this.state].btn_class
      },

      label() {
        return this.params_by_state[this.state].label
      },
      checklist_locales() {
        return this.locales.checklist[this.current_locale]
      },
      params_by_state() {
        return  {
          begin: {
            modal: 'form',
            label: this.checklist_locales.start_checklist,
            btn_class: ''
          },
          continue: {
            modal: 'form',
            label: 'Продолжить чеклист',
            btn_class: 'continue-btn'
          },
          show: {
            modal: 'show',
            label: this.checklist_locales.show_checklist,
            btn_class: 'show-btn'
          },
          none: {
            modal: 'none',
            label: 'none',
            btn_class: 'none'
          }
        }
      }
    },

    props: {
      parentData: Object
    },

    methods: {

      openForm() {
        this.resetForm()
        this.modal[this.params_by_state[this.state]['modal']] = true
      },

      closeForm(event) {
        this.modal[this.params_by_state[this.state]['modal']] = false
        if (event.complete && event.data) {
          this.show_data = event.data
          this.state = 'show'
        }
      },

      loadFormData(next_el=undefined) {
        let path = this.path + '/' + this.row.checklist_id
        this.$backend.index(path)
        .then(({ data }) => {
          this.form_data = data

          if (next_el) {
            data.fields.forEach(field => {
              this.$store.commit('createFormField', {grid_name: this.grid, field: field.name})
              this.$refs['checklist-form'].setFieldVal({field: field.name, val: field.value})
            })
            this.$refs['checklist-form'].setLoading(false)
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      }
    },

    created() {

      this.$store.commit('initialGridsState', {grid: this.grid, attr: {}})
      this.loadFormData()
      const appendix = this.parentGrid === 'issues' || this.parentGrid === 'archive' ? '' : '/ppr'
      this.$backend.index('/api/v3' + appendix + '/issues/' + this.parentData.row.id + '/checklist_data').then(res => {
        console.log(res)
        this.show_data = res.data
        this.state = 'show'
      }).catch(
        () => {
          if (this.parentGrid === 'archive' || this.parentGrid === 'ppr_archive') this.state = 'none'
          else this.state = 'begin'
        }
      )
    },

    beforeDestroy() {
    },

  }
</script>

<style lang="scss">

  @import "../../../assets/styles/checklists/main";

</style>
