<template lang='pug'>

  div(class="donut-chart-wrapper")
    div(class="donut-chart-title")
      span {{data.title}}

    div(class="donut-chart-area")
      svg(width="100%" height="100%" viewBox="0 0 42 42" class="donut")
        defs
          linearGradient(id="gradient" x1="50%" y1="100%" x2="100%" y2="50%")
            stop(offset="0%" stop-color="#FA7167")
            stop(offset="50%" stop-color="#7283F4")
            stop(offset="100%" stop-color="#5B7AFF")


        circle(class="donut-ring"
                cx="21" cy="21" r="15.91549430918954")
        circle(class="donut-bottom"
                cx="21" cy="21" r="15.91549430918954")
        circle(class="donut-chart"
                v-show="showDonut()" :style="cssVars"
                cx="21" cy="21" r="15.91549430918954")

        g(class="chart-text")
          text(x="50%" y="50%" class="chart-number" id="chart-number") {{data.value}}
          text(x="50%" y="50%" class="chart-label" id="chart-label") {{label}}

</template>

<script>

  export default {
    data: function () {
      return {
        // data: this.parentData.data,
        key: this.parentData.key,
        stroke_value: '0 100', // 0% chart value css is (stroke-dasharray: '0 100';), 100% chart value css is (stroke-dasharray: '70 30';)
        endings: ['ов', '', 'а', 'а', 'а', 'ов', 'ов', 'ов', 'ов', 'ов', 'ов']
      }
    },

    props: {
      parentData: Object
    },

    components: {},

    computed: {
      data() {
        return this.parentData.data
      },

      label() {
        if (this.data && this.data.value !== null) {
          let label = this.data.chart_label

          if (label === 'процент') {
            let num = parseInt(this.data.value.toString().slice(-1))
            label = label + this.endings[num]
          }
          return label
        } else {
          return undefined
        }
      },

      cssVars() {
        return {
          '--stroke_value': this.stroke_value
        }
      }
    },

    watch: {
      data(newVal, oldVal) {
        if (newVal.value !== oldVal.value) {
          setTimeout(() => {
            this.getChartValue()
          },500)
        }
      }
    },

    methods: {

      showDonut() {
        if (['rejected_percentage', 'reworked_percentage'].includes(this.key)) {
          return this.data.value <= 99.5
        } else {
          return this.data.value >= 0.5
        }
      },

      getChartValue() {
        let first_val = 70 / 100 * parseInt(this.data.value)

        if (['rejected_percentage', 'reworked_percentage'].includes(this.key)) {
          first_val = 70 - first_val     //reverse chart
        }

        let second_val = 100 - first_val

        this.stroke_value = first_val + ' ' + second_val
      }
    },

    created() {
      setTimeout(() => {
        this.getChartValue()
      },500)
    }
  }
</script>

<style>

  /*@import '../../../../assets/styles/dashboard/charts/donut_chart.scss';*/

</style>