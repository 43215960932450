<template lang='pug'>
  div
    div(v-if="dataHasLoaded" class="issue-new-form justify-center" id="issue-new-form")
      handle-form(@close-form="closeForm" :parentData="{method: 'create', item: 'issue', callback_params: callback_params, form_title: form_data.form_header.title.add, title_icon_path: title_icon_path, path: path['issue'], grid: 'issues', data: form_data}")

    div(id="modal-background-form")
    ui-prompts(v-if="dataHasLoaded" :parentData="{areas_key: 'add_issue'}")

</template>

<script>
  import handleForm from '../../shared/forms/Form'
  import uiPrompts from '../../shared/ui_prompts/index.vue'

  export default {
    data: function () {
      return {
        path: this.$store.state.paths,
        title_icon_path: 'add_header.svg',
        form_data: [],
        callback_params: this.parentData.callback_params,

        dataHasLoaded: false,
      }
    },
    components: {
      handleForm,
      uiPrompts
    },

    props: {
      parentData: Object
    },

    methods: {

      closeForm(data) {
        this.$emit('close-form', data)
      },

      loadFormData() {

        let form_data_path = this.path['issue'] + '/form_data';

        this.$backend.index(form_data_path, { params: {} })
        .then(({ data }) => {

          this.form_data = data

          let callback_keys = Object.keys(this.callback_params)

          if (callback_keys.length > 0) {
            callback_keys.forEach(key => {
              this.form_data.fields.forEach(field => {
                if (field['name'] === key) {
                  field['value'] = this.callback_params[key]
                }
              })
            })
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      },
    },

    created(){
      this.loadFormData()
    },

    mounted() {
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>
</style>