<template lang='pug'>

  div
    index-page(@set-loading="setLoading"
                 :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class, report_namespace: report_namespace}")

    ui-prompts(:parentData="{areas_key: grid}")

</template>

<script>
  import indexPage from '../../../shared/indexPage'
  import uiPrompts from '../../../shared/ui_prompts/index.vue'

  export default {
    data: function () {
      return {
        grid: 'ppr_works',
        report_class: 'work',
        report_namespace: 'ppr',
        path: this.$store.state.paths['ppr_work'],
        grid_key: 'data',
      }
    },
    components: {
      indexPage,
      uiPrompts
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      // this.$emit('set-loading', false)
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>