import Vue from 'vue';
import strftime from "strftime";
import './grid/actions_menu'

Vue.mixin({

  computed: {

    currentGrid() {
      return this.$store.state.grid[this.grid][this.grid_key]
    },

    searchAllFilter() {
      let grid = this.$store.state.grid[this.grid]
      if (grid) {
        return grid['query']
      } else {
        return undefined
      }
    },

    filters() {
      let grid = this.$store.state.grid[this.grid]
      if (grid) {
        return grid['filters']
      } else {
        return undefined
      }
    }
  },

  methods: {

    loadRow(id, path) {
      this.$backend.show(path, id, { params: {} })
      .then(({ data }) => {
        this.row = data.common['data']
      })
      .catch((error) => {
        console.log(error);
        if (error.response) { this.reLogin(error.response.status) }
      })
    },

    gridCollection(path, grid_name, grid_key, table_params, additional_attr = {}) {
      let attr = {table: table_params};

      return this.$backend.index(path, {
        params: {json: Object.assign(attr, additional_attr)}
      })
      .then(({data}) => {
        this.$store.commit('updateGridData', {grid_data: data, grid_name: grid_name, grid_key: grid_key})
      })
    },

    handleDescriptionTd(text) {
      if (text) {
        return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&quot;/g, "").replace(/&nbsp;|&emsp;|&ensp;/g, " ")
      }
    },

    mapOrder(array, order, key) {
      array.sort((a, b) => {
        let A = a[key], B = b[key]

        if (order.indexOf(A) > order.indexOf(B)) {
          return 1
        } else {
          return -1
        }
      });
      return array
    },

    handleDateRow(obj, key, format_str=undefined) {
      let date, format, result

      if (obj[key] == null) return ''

      date = new Date(obj[key])
      format = "%d.%m.%Y, %H:%M:%S"

      if (format_str) {
        format = format_str
      }

      result = strftime(format, date)
      return result
    },

    generateFiltersParams(params = {}) {
      Object.keys(this.filters).map (key => {
        let filter = this.filters[key]

        if (filter) {
          if (Array.isArray(filter) && filter.length > 0) {
            params[key] = filter.map(el => el.value)

          } else if (Object.keys(filter).length !== 0 && filter.constructor === Object) {
            params[key] = filter.value

          } else if ((typeof filter === 'string' && filter !== '') || [true, false].includes(filter)) {
            params[key] = filter
          }
        } else {
          delete params[key]
        }
      })
      return params
    },
  }
})