<template lang='pug'>

  div(class="grid-button-group")

    span(v-for="button in buttons")

      q-btn(v-if="button.dropdown" flat no-caps :style="button.style" :id="`${button.name || ''}-${grid.split('_').join('-')}-button`")
        template(v-if="button.icon" slot="default")
          inline-svg(class="grid-btn-icon-left" :src="require(`../../../assets/icons/header/${button.icon}`)")
        template(v-if="button.label" slot="default")
          span {{button.label}}
        template(slot="default")
          inline-svg(class="arrow-down-actions-icon" :src="require(`../../../assets/icons/grid/dropdown.svg`)")

        grid-button-group-menu(ref="grid-actions-menu"
                                @get-handler="getHandler"
                                :parentData="{grid: grid, grid_key: grid_key, links: button.dropdown}")

      q-btn(v-else flat no-caps :style="button.style" @click="getHandler(button)" :id="`${button.name || ''}-${grid.split('_').join('-')}-button`")
        template(v-if="button.icon" slot="default")
          inline-svg(class="grid-btn-icon-left" :src="require(`../../../assets/icons/header/${button.icon}`)")
        template(v-if="button.label" slot="default")
          span {{button.label}}

</template>


<script>
  import gridButtonGroupMenu from './gridButtonGroupMenu'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key,
      }
    },
    props: {
      parentData: Object
    },

    computed: {
      buttons() {
        return this.parentData.buttons
      }
    },

    components: {
      gridButtonGroupMenu
    },

    methods: {

      getHandler(item) {

        return this[item.onclick](item)
      },

      addNewItem() {
        this.$root.$emit('add-new-item')
      },

      openExportForm(item) {
        this.$root.$emit('open-export-form', item)
      },

      openImportForm(item) {
        this.$root.$emit('open-import-form', item)
      }
    },

    created() {
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>
</style>