<template lang='pug'>
  table(class='entity-view-table')
    thead
      tr
        th {{map_locales['id']}}
        th {{map_locales['author']}}
        th {{map_locales['type']}}
        th {{map_locales['created_at']}}
        th {{map_locales['state']}}
    tbody
      tr(v-for="issue in issues")
        td {{ issue.id }}
        td {{ issue.author.full_name }}
        td {{ issue.service_title }}
        td {{ formatDatetime(issue.created_at) }}
        td
          IssueStateBadge(:state='issue.state' :translation='issue.state_translation')

</template>

<script>
import IssueStateBadge from '../EntityView/IssueStateBadge'
import strftime from "strftime";

export default {
  props: {
    issues: Array,
  },
  components: {
    IssueStateBadge,
  },

  methods: {
    formatDatetime(str) {
      const format = "%d.%m.%Y, %H:%M:%S";
      return strftime(format, new Date(str));
    },
  },
}
</script>

<style lang="scss">

.entity-view-table {
  width: 100%;
  border-spacing: 0px 7px;

  thead {
    th {
      font-weight: 600;
      font-size: 11.5px;
      line-height: 18px;
      text-transform: uppercase;
      background-color: var(--simple-table-th-background);
      color: var(--simple-table-th-color);
    }
  }

  tbody {
    tr {
      min-height: 69px;
      background-color: var(--simple-table-tr-background);
      color: var(--simple-table-tr-color);

      .grey {
        color: #6D6D6D;
      }

      td {
        text-align: center;
      }

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      td:last-child {
        border-top-right-radius: 15px;
        border-bottom-rightt-radius: 15px;
      }
    }
    tr:hover {
          background-color: var(--simple-table-tr-background-hover);
      }
  }
}
</style>
