<template lang='pug'>
  div
    div(class="login-title login-reset-password-title row justify-center")
      span {{login_locales['password_recovery_form.title']}}

    q-form(@submit="onSubmit" @reset="onCancel")
      div(class="recovery-form login-field")
        div(class="login-field-label")
          span {{login_locales['password_recovery_form.fields.login.label']}}

        div(class="login-field-input")
          q-input(filled
          :label-slot="false"
          ref="login"
          v-model="login"
          lazy-rules
          no-error-icon
          for="login-input"
          :rules="[val => val && val.length > 0 || login_locales['fields_rules.not_empty'], val => checkLogin(val) || login_locales['fields_rules.wrong_email_format']]")


      div(class="recovery-form button-group row justify-center")
        q-btn(flat no-caps class="login-submit" :label="login_locales['password_recovery_form.buttons.submit']" type="submit")
        q-btn(flat no-caps class="login-cancel" :label="login_locales['password_recovery_form.buttons.cancel']" type="reset")


</template>

<script>
  export default {
    data: function () {
      return {
        login: null,
      }
    },

    methods: {

      onSubmit () {
        this.$refs.login.validate()

        if (this.$refs.login.hasError) {
          this.formHasError = true
        } else {
          let params = {
            user: {
              email: this.login,
            }
          }

          this.sendOnRecovery(params)
        }
      },

      onCancel() {
        this.$emit('password-recovery', false)
      },

      checkLogin (email) {
        let regex = /[^@\s]+@[^@\s]+/
        return regex.test(String(email).toLowerCase())
      },

      sendOnRecovery (params) {
        this.$backend.recovery_password(this.$store.state.paths['reset_password'], params)
        .then((response) => {
          this.$emit('password-recovery', false)
          this.$q.notify(this.login_locales['password_recovery_form.notifies.sent'])
        })
        .catch((error) => {
          console.log(error)
          // this.$emit('set-loading', false)
          this.$q.notify(this.login_locales['password_recovery_form.notifies.error'])
        })
      }
    },

    mounted() {
      setTimeout(() => { this.$emit('set-loading', false) },2000)
    },
  }
</script>

<style lang='scss'>
</style>
