<template lang='pug'>
  div
    div(v-if="dataHasLoaded" class="work-new-form justify-center")
      handle-form(@close-form="closeForm" :parentData="{method: 'create', item: item, form_title: form_data.form_header.title.add, title_icon_path: title_icon_path, path: path, grid: grid, data: form_data, table_data: employee_covid_tests}")

</template>

<script>
  import handleForm from '../../shared/forms/FormWithTable'

  export default {
    data: function () {
      return {
        grid: 'covid_tests',
        item: 'covid_test',
        path: this.$store.state.paths['covid_test'],
        title_icon_path: 'add_header.svg',
        callback_params: this.parentData.callback_params,
        form_data: [],
        employee_covid_tests: [],
        dataHasLoaded: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      handleForm,
    },

    methods: {

      closeForm(data) {
        this.$emit('close-form', data)
      },

      loadFormData() {

        let form_data_path = this.path + '/form_data';

        this.$backend.index(form_data_path, { params: {} })
        .then(({ data }) => {
            this.form_data = data.head
            this.employee_covid_tests = data.table
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      },
    },

    created(){
      this.loadFormData()
    },

    mounted() {
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>
</style>