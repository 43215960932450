<template lang='pug'>

  q-field(filled :label="label" :stack-label="stackLabelCheck()" class="date-filter-field")
    template(slot="prepend")
      inline-svg(class="datetime-icon" :src="require(`../../../assets/icons/calendar.svg`)")

    datetime(:type="type"
              v-model="currentFilter"
              :phrases="phrases")
    q-icon(name="cancel" v-if="currentFilter" @click.stop="resetFilter()" class="cancel-date-filter")

</template>

<script>
  import { Datetime } from 'vue-datetime';

  export default {
    data: function () {
      return {
        type: this.parentData.data[0].type,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        grid: this.parentData.grid,
        path: this.parentData.path,
        phrases: {}
      }
    },

    components: {
      Datetime
    },

    props: {
      parentData: Object
    },

    computed: {
      currentFilter: {
        get() {
          return this.currentFilters[this.name]
        },
        set(value) {
          this.$store.commit('updateFilter', {grid_name: this.grid, filter: this.name, value: value})
        }
      }
    },

    methods: {

      resetFilter() {
        // this.currentFilter = undefined
        this.$store.commit('resetFilter', {grid_name: this.grid, filter: this.name})
      },

      stackLabelCheck() {
        return this.currentFilter !== null && this.currentFilter !== undefined && this.currentFilter !== ''
      },
    },

    beforeMount() {
      this.setDateFieldLocale()
      this.setDateFieldPhrases()
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/filters/date";

</style>