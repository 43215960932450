<template lang='pug'>

  index-page(@set-loading="setLoading"
               :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class, report_namespace: report_namespace, transition_key: transition_key}")

</template>

<script>
  import indexPage from '../../../shared/indexPage'

  export default {
    data: function () {
      return {
        grid: 'measuring_meters',
        report_class: 'meter',
        report_namespace: 'measuring',
        path: this.$store.state.paths['measuring_meter'],
        grid_key: 'data',
        transition_key: 'measuring_meter'
      }
    },
    components: {
      indexPage,
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      // this.$emit('set-loading', false)
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang='scss'>
</style>