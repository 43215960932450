<template lang='pug'>

  div(id="form" class="new-edit-form")
    div(class="form-card")
        q-card-section
          form-header(ref="form-header" @close-form="closeForm"
                      :parentData="{grid: grid, title: form_title, title_icon: title_icon_path}")

        div(v-if="loading" class="spinner-container form-spinner")
          q-spinner(color="primary" size="3em")

        q-card-section(v-show="!loading")
          tabs(v-if="data.tabs && data.tabs.data.length > 0 && (!data.tabs.method_limit || data.tabs.method_limit !== method)"
                    @fields-loaded="fieldsLoaded"
                    @submit-form="submitForm"
                    :parentData="{method: method, grid: grid, data: data, item_id: item_id, building_id: building_id}")

          component(v-else
                    :is="getForm('default_form')"
                    @fields-loaded="fieldsLoaded"
                    @submit-form="submitForm"
                    :parentData="{method: method, grid: grid, data: data, item_id: item_id, building_id: building_id}")


</template>


<script>
  import formHeader from '../../shared/forms/formHeader'
  import tabs from './new_edit_form/tabs'
  import forms from './new_edit_form/forms'

  export default {
    data: function () {
      return {
        method: this.parentData.method,
        item: this.parentData.item,
        item_id: this.parentData.item_id || '',
        building_id: this.parentData.building_id || '',
        callback_params: this.parentData.callback_params,
        path: this.parentData.path,
        // data: this.parentData.data,
        grid: this.parentData.grid,
        form_title: this.parentData.form_title,
        title_icon_path: this.parentData.title_icon_path,

        loading_fields: [],
        loading: true,

        form_valid: false
      }
    },
    props: {
      parentData: Object
    },

    computed: {
      data() {
        return this.parentData.data
      }
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    components: {
      formHeader,
      forms,
      tabs
    },

    methods: {

      getForm(key) {
        if (key) {
          return forms[key]
        }
      },

      closeForm(params={}) {
        params['method'] = this.method
        this.$emit('close-form', params)
      },

      submitForm() {
        let params = {};
        let form = this.currentForm;

        this.checkFormValidation(form)

        if (this.form_valid) {

          this.loading = true

          let result = Object.keys(form).reduce((result, e) => {
            if (Array.isArray(form[e]['field']) && form[e]['selected_items']) {
              result[e] = form[e]['selected_items']
            } else if (Array.isArray(form[e]['field'])) {
              result[e] = form[e]['field'].map(e => e['value'])
            } else if (typeof form[e]['field'] === 'object') {
              result[e] = form[e]['field']['value']
            } else {
              result[e] = form[e]['field']
            }
            return result;
          }, {})

          params[this.item] = result;

          this.$backend[this.method](this.path, this.item_id, params)
          .then(res => {
            // console.log(res.data)

            if (res.data) {
              this.$nextTick(() => {
                let res_params = {}
                res_params['data'] = res.data
                res_params['method'] = this.method
                this.$emit('close-form', res_params)
                this.loading = false

                let row = res.data['data'] || res.data
                let event = (this.method === 'create' ? this.notifies.created : this.notifies.updated)
                this.$q.notify(this.notifies.entry_number + row['id'] + this.notifies.is_success + event)
              })
            }
          })
          .catch((error) => {
            if (error.response) { this.reLogin(error.response.status) }
            this.$nextTick(() => {
              // let res_params = {}
              // res_params['method'] = this.method
              // this.$emit('close-form', res_params)
              this.loading = false

              let base_errors = error.response.data.errors.base
              let password_errors = error.response.data.errors.password

              if (base_errors) {
                base_errors.forEach(e => {
                  this.$q.notify(e.message)
                })
              } else if (password_errors) {
                password_errors.forEach(e => {
                  this.$q.notify(e)
                })
              } else {
                this.$q.notify(this.notifies.error_ask_admin)
              }
            })
            console.log(error)
          })
          .finally(() => {
          })
        } else {
          this.$q.notify(this.notifies.fix_invalid_fields)
        }
      },

      checkFormValidation(form) {
        let invalid_form = Object.keys(form).reduce((result, e) => {
          if (form[e]['invalid'] === true) result[e] = form[e]
          return result;
        }, {})
      
        let invalid_fields = Object.keys(invalid_form)
      
        if (invalid_fields.length > 0) {
          this.form_valid = false
      
          this.$store.commit('createFormField', {grid_name: this.grid, field: 'invalid_fields'})
      
          let val = {}
          invalid_fields.forEach(f => val[f] = true)
          this.$store.commit('updateFormField', {grid_name: this.grid, field: 'invalid_fields', value: val})
      
        } else {
          this.form_valid = true
          this.$store.commit('resetFormField', {grid_name: this.grid, field: 'invalid_fields'})
        }
      },

      fieldsLoaded(field=undefined) {

        if (this.loading_fields.length === 0) {
          this.loading_fields = this.data.fields.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (field) {
          this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString())
        }

        if (this.loading_fields.length === 0) {
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      },
    },

    channels: {
    },

    created() {
      this.resetForm()
      this.fieldsLoaded()
    },

    mounted() {
    }
  }
</script>

<style lang='scss'>

  @import "../../../assets/styles/forms/new-edit-form";

</style>