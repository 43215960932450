<template lang='pug'>
    div
        div(class="row"  v-for="(row, index) in data.fields")
              template(v-for="row in data.scheme")
                    div(v-for="field in row" :class="getFieldClass(field)"
                        style="padding: 5px;")
                        component(v-show="getFieldDataByName(field, index)"
                            :is="getFieldComponent(getFieldDataByName(field, index))"
                            :parentData="{method: 'create', grid: grid, data: [getFieldDataByName(field, index)]}")
</template>

<script>
    import fields from '../fields'

    export default {
        data: function () {
            return {
                data: this.parentData.data,
                grid: this.parentData.grid
            }
        },

        components: {
            fields
        },

        props: {
            parentData: Object
        },

        methods: {

            getFieldComponent(key) {
                if (key) {
                    return fields[key.type]
                }
            },
            getFieldDataByName(field, row) {
                let field_data = Object.assign({}, this.data.fields[row].find(f => f.name  === (field.name + row)));
                if (row !== 0) {
                    delete field_data.label
                    }
                return field_data
            },
            getFieldClass(field) {
                let field_class = 'col-' + field.col
                if (field.name === 'id') {
                    field_class += ' hidden'
                }
                return field_class
            },
        },

        beforeMount() {
        }
    }
</script>

<style lang="scss">



</style>