<template lang='pug'>

  div(v-if="isActive && component" class="sub-menu")
    component(:is="getComponent()" @update-field="updateField" :parentData="{equipment: item, attr: attr}")

</template>

<script>
  import subMenuComponents from './sub_menu_components/index'

  export default {
    data: function () {
      return {
        isActive: false,
        attr: {},
        component: null
      }
    },

    components: {
      subMenuComponents
    },

    props: {
      item: Object
    },

    computed: {
    },

    watch: {
      attr(newVal, oldVal) {

        if (newVal) {
          // console.log(this.item, newVal)
          this.component = newVal.component
        }


      }
    },

    methods: {
      toggle(attr) {
        this.attr = attr
        this.isActive = !this.isActive;
      },

      getComponent() {
        return subMenuComponents[this.component]
      },

      updateField(data) {
        this.$emit('update-field', data)
      }
    },

    created() {
    },

    beforeMount() {
    }
  }
</script>

<style lang="scss">
</style>