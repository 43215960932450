<template lang='pug'>

  div
    index-page(@set-loading="setLoading"
               :parentData="{grid: grid, path: path, grid_key: grid_key, report_class: report_class, report_namespace: report_namespace}")

    ui-prompts(v-if="!child_route_component" :parentData="{areas_key: grid}")

</template>

<script>
  import indexPage from '../../../shared/indexPage'
  import uiPrompts from '../../../shared/ui_prompts/index.vue'

  export default {
    data: function () {
      return {
        grid: 'ppr_equipments',
        report_class: 'equipment',
        report_namespace: 'ppr',
        path: this.$store.state.paths['ppr_equipment'],
        grid_key: 'data',
        child_route_component: false,
      }
    },
    components: {
      indexPage,
      uiPrompts
    },

    methods: {

      setLoading(val) {
        this.$emit('set-loading', val)
      },
    },

    beforeMount() {
      this.$emit('expand-class', {val: false, class: ''})
    },

    created() {
      this.$root.$on('set-child-route-component', (val) => {
        this.child_route_component = val
      })
    }
  }
</script>

<style lang='scss'>
</style>