<template>
    <v-chart :style="sizeProps" :options="chartObj" autoresize />
</template>

<script>
    import ECharts from 'vue-echarts'
    import themeDark from './dark'
    import lightTheme from './light'


    ECharts.registerTheme('dark', themeDark)
    ECharts.registerTheme('light', lightTheme)

    export default {
        name: 'ChartContainer',
        components: {
            'v-chart': ECharts
        },
        props: {
            chartObj: Object,
            sizeProps: {
                width: String,
                height: String
            }
        }
    }
</script>

<style lang="scss">

</style>
