<template lang='pug'>
  div
    div(class="login-title login-reset-password-title row justify-center")
      span {{login_locales['password_reset_form.title']}}

    q-form(@submit="onSubmit")
      div(class="login-form password-field reset-password-field")
        div(class="login-field-label")
          span {{login_locales['password_reset_form.fields.password.label']}}

        div(class="login-field-input")
          q-input(filled
                  :label-slot="false"
                  ref="password"
                  v-model="password"
                  :type="hidden_pass ? 'password' : 'text'"
                  lazy-rules
                  no-error-icon
                  for="password-input"
                  :rules="[val => val && val.length >= 4 || login_locales['fields_rules.not_less_4'], val => !checkOnCyrillic(val) || login_locales['fields_rules.not_cyrillic']]")

            div(class="show-password-icon" @click="hidden_pass = !hidden_pass")
              inline-svg(:src="require(`../../../assets/icons/login/${hidden_pass ? 'closed-eye' : 'opened-eye'}.svg`)")

      div(class="login-form password-field reset-password-confirm-field")
        div(class="login-field-label")
          span {{login_locales['password_reset_form.fields.confirm_password.label']}}

        div(class="login-field-input")
          q-input(filled
                  :label-slot="false"
                  ref="password"
                  v-model="password_confirm"
                  :type="hidden_pass_confirm ? 'password' : 'text'"
                  lazy-rules
                  no-error-icon
                  for="password-confirm-input"
                  :rules="[val => val && val.length >= 4 || login_locales['fields_rules.not_less_4'], val => !checkOnCyrillic(val) || login_locales['fields_rules.not_cyrillic']]")

            div(class="show-password-icon" @click="hidden_pass_confirm = !hidden_pass_confirm")
              inline-svg(:src="require(`../../../assets/icons/login/${hidden_pass_confirm ? 'closed-eye' : 'opened-eye'}.svg`)")

      div(class="recovery-form button-group reset-password-form-button-group row justify-center")
        q-btn(flat no-caps class="login-submit" :label="login_locales['password_reset_form.buttons.submit']" type="submit")


</template>

<script>
  export default {
    data: function () {
      return {
        password: null,
        password_confirm: null,
        hidden_pass: true,
        hidden_pass_confirm: true
      }
    },

    props: {
      reset_password_token: String,
    },

    methods: {

      onSubmit () {
        this.$refs.password.validate()

        if (this.$refs.password.hasError) {
          this.formHasError = true
        } else {
          let params = {
            user: {
              reset_password_token: this.reset_password_token,
              password: this.password,
              password_confirmation: this.password_confirm
            }
          }

          this.sendNewPassword(params)
        }
      },

      checkOnCyrillic(password) {
        let regex = /[а-яА-Я]/
        return regex.test(String(password))
      },

      sendNewPassword(params) {
        this.$backend.reset_password(this.$store.state.paths['reset_password'], params)
        .then((response) => {
          this.$emit('password-reset', false)
          this.$q.notify(this.login_locales['password_reset_form.notifies.updated'])
        })
        .catch((error) => {
          console.log(error)
          this.$q.notify(this.login_locales['password_reset_form.notifies.error'])
        })
      }
    },

    mounted() {
      setTimeout(() => { this.$emit('set-loading', false) },2000)
    },
  }
</script>

<style lang='scss'>
</style>
