<template lang='pug'>

  div(id="calendar-ppr" class="calendar-ppr")
    div(v-if="loading" class="spinner-container grid-spinner")
      q-spinner(color="primary" size="3em")

    grid-hidden-msg(v-show="hide_table && !loading" :ref="`grid-hidden-msg-${grid}`" :parentData="{hide_table: hide_table}")

    div(v-show="!hide_table && !loading" class="calendar-ppr-table" id="calendar-ppr-table")
      div(id="first-col-overlapping")
      div(id="another-one-col-overlapping")
      table
        div(v-infinite-scroll="loadMore"
            infinite-scroll-disabled="loading"
            infinite-scroll-distance="limit"
            infinite-scroll-immediate-check="false")
          thead
            tr
              th(class="first-col")
                span {{currentGrid.entity_label}}
              th(v-for="week in weeks")
                span {{week}}

          tbody
            tr(v-if="currentGrid.common_data")
              td(class="first-col common-data")
                span {{currentGrid.common_data.title}}

              td(v-for="(week, week_i) in currentGrid.common_data.weeks"
                  :class="currentGrid.current_week === (week_i + 1) ? 'another-one-col current-week-col' : 'another-one-col'")
                div(v-for="week_data in week.data")
                  div(class="week-data")
                    span(class="week-data-hours") {{week_data.hours}}


            tr(v-for="(entity, entity_i) in currentGrid.data")
              td(class="first-col")
                span(@click="onClickTitle(entity)") {{entity.title}}

              td(v-for="(week, week_i) in entity.weeks"
                 :class="currentGrid.current_week === (week_i + 1) ? 'another-one-col current-week-col' : 'another-one-col'")

                div(v-for="(week_data, week_data_i) in week.data")

                  div(v-if="week.data && week.data.length > 0 && week_data_i !== 0"
                      class="spacer")

                  div(:class="week_data.description ? 'week-data with-icon' : 'week-data'")

                    span(class="week-data-hours") {{week_data.hours}}

                    div(v-if="checkDescription(week_data.description, entity_i, week_i, week_data_i)"
                        :id="'description-' + entity_i + '-' + week_i + '-' + week_data_i"
                        class="week-data-description")
                      inline-svg(:src="require(`../../../../assets/icons/ppr/calendar/description.svg`)")

                      div(:id="'td-description-' + entity_i + '-' + week_i + '-' + week_data_i" class="td-title")
                        div(class="title-msg")
                        div(class="title-arrow")

</template>

<script>
  import gridHiddenMsg from '../../../shared/grid/gridHiddenMsg'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key,
        path: this.parentData.path,
        weeks: Array.from({length: 52}, (_, i) => i + 1),

        loading: true,
        loadingMore: false,
        limit: 1500,
        error: false,
        pageSize: 20,
        nextPage: 2,

        next_entity: false,

        filters_form_accepted: false,

        hide_table: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      gridHiddenMsg
    },

    computed: {
      filters_data() {
        return this.parentData.filters_data
      },
    },

    watch: {
      filters(newValue, oldValue) {
        if (newValue && this.next_entity) {
          this.next_entity = false
          this.filters_form_accepted = true
        }
      },

      filters_form_accepted(newValue, oldValue) {
        if (newValue) {
          this.currentGrid.pagination.page = 1
          this.nextPage = 2

          this.loading = true

          setTimeout(() => {
            this.onRequest({ pagination: this.currentGrid.pagination })
          }, 300)

          this.filters_form_accepted = false
        }
      },
    },

    methods: {

      hideTable(val) {
        this.hide_table = val
        if (this.$refs[`grid-hidden-msg-${this.grid}`]) {
          this.$refs[`grid-hidden-msg-${this.grid}`].hideTable(val)
        }
      },

      setColOverlapping() {
        setTimeout(() => {
          let grid_height = document.getElementById('grid-data').getBoundingClientRect().height
          let grid_width = document.getElementById('grid-data').getBoundingClientRect().width
          let table_height = document.getElementById('calendar-ppr-table').getBoundingClientRect().height
          let first_col_overlapping = document.getElementById('first-col-overlapping')
          let another_col_overlapping = document.getElementById('another-one-col-overlapping')

          // console.log(grid_height, table_height)

          if (table_height < grid_height) {
            first_col_overlapping.style.height = table_height + 20 + 'px'
            another_col_overlapping.style.width = grid_width - 350 + 'px'
          } else {
            first_col_overlapping.style.height = grid_height + 17 + 'px'
            another_col_overlapping.style.width = grid_width - 353 + 'px'
          }
        },5)
      },

      loadMore () {
        let lastPage = this.currentGrid.count / this.pageSize

        if (!this.error && !this.loadingMore && (this.nextPage - 1) < lastPage) {
          this.loadingMore = true;

          this.$nextTick(() => {
            let pagination = {
              sortBy: this.currentGrid.pagination.sortBy,
              descending: this.currentGrid.pagination.descending,
              page: this.nextPage,
              rowsPerPage: this.currentGrid.pagination.rowsPerPage,
              rowsNumber: this.currentGrid.pagination.rowsNumber
            };

            this.nextPage++

            this.onRequest({
              pagination: pagination
            })
          })
        }
      },

      onClickTitle(entity) {
        if (entity.onclick) {
          if (entity.onclick.fn) {
            return this[entity.onclick.fn](entity.onclick)

          } else {
            let routeData = this.$router.resolve({
              name: entity.onclick.name,
              query: entity.onclick.query
            });
            window.open(routeData.href, '_blank');
          }
        }
      },

      nextEntity(attr, filters={}) {
        filters[attr['filter']] = attr['id']
        this.$root.$emit('add-filters-from-params', filters)
        this.loading = true
        this.next_entity = true
      },

      checkDescription(description, entity_i, week_i, week_data_i) {
        if (description) {
          this.addTitleEvent(description, entity_i, week_i, week_data_i)
          return true
        } else {
          return false
        }
      },

      addTitleEvent(description, entity_i, week_i, week_data_i) {
        this.$nextTick(() => {
          let td_el = document.getElementById('description-' + entity_i + '-' + week_i + '-' + week_data_i)
          let title_el = document.getElementById('td-description-' + entity_i + '-' + week_i + '-' + week_data_i)
          let grid_el = document.getElementById('grid-' + this.grid_key.toString())

          td_el.addEventListener('mouseover', () => { this.showTitle(td_el, title_el, week_i, description) })
          td_el.addEventListener('mouseleave', () => { this.hideTitle(title_el) })

          if (grid_el) {
            grid_el.addEventListener('scroll', () => { this.hideTitle(title_el) })
          }
        })
      },

      showTitle(td_el, title_el, week_i, description) {
        let msg = title_el.querySelector('.title-msg')

        if (msg.children.length === 0) {
          let span = document.createElement("span")
          span.textContent = description
          msg.appendChild(span);
        }

        if (week_i >= Number(49)) {
          let calendar_el = document.getElementById('calendar-ppr')
          calendar_el.style['padding-right'] = '150px'
        }

        title_el.style.visibility = 'visible'
        title_el.style.opacity = '1'
        title_el.style.top = (td_el.getBoundingClientRect().top - (title_el.clientHeight + 6)) + 'px'
        title_el.style.left = (td_el.getBoundingClientRect().left - 170) + 'px'
      },

      hideTitle(title_el) {
        title_el.style.opacity = '0'
        title_el.style.visibility = 'hidden'
        let msg = title_el.querySelector('.title-msg')
        while (msg.hasChildNodes()) {
          msg.removeChild(msg.firstChild);
        }
      },

      setLoading(val) {
        this.loading = val
        this.loadingMore = val

        if (this.loading === false) {
          this.setColOverlapping()
        }

        if (this.currentGrid.count === 0) {
          this.hideTable(true)
          // this.$emit('hide-table', true)
        } else {
          this.hideTable(false)
          // this.$emit('hide-table', false)
        }
      },

      setError(val) {
        this.error = val
      },

      filtersFormAccepted(val) {
        this.filters_form_accepted = val
      },

      reloadData() {
        this.onRequest({
          pagination: this.currentGrid.pagination
        })
      },

      onRequest(props) {
        let params = props.pagination;

        params.query = ""
        params.filters = this.generateFiltersParams()

        this.$emit('get-collection', params)
      },
    },

    created() {
      this.$store.commit('initialGrid', {grid_name: this.grid, grid_key: this.grid_key})

      window.addEventListener('resize', () => {
        if (document.getElementById('calendar-ppr-table')) {
          this.setColOverlapping()
        }
      });

      setTimeout(() => { this.onRequest({
        pagination: this.currentGrid.pagination
      }) }, 1000)
    },

    beforeMount() {
      setTimeout(() => {
        this.setColOverlapping()
      }, 1500)
    }
  }
</script>

<style lang='scss'>

  @import "../../../../assets/styles/ppr/calendar/main";

</style>