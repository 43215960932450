<template lang='pug'>
  div(class="col-8 checklist-form__wrapper")
    div(class="checklist-new-form justify-center") 
      q-card(class="checklist-form__card")
        q-toolbar(class="q-px-lg")
          q-icon(class="q-pr-md checklist-form__toolbar__icon"
                name="description" 
                size="24px" 
          )
          span(class="checklist-form__toolbar__title") {{checklist_locales.edit_checklist}}
          q-space
          q-btn(flat 
                round 
                icon="close"  
                color="grey" 
                @click="closeForm()"
          )
        div
        q-stepper(header-class="checklist-form__stepper-header" 
                  :animated="false"
                  v-model="checklistStep" 
                  ref="stepper" 
                  color="primary" 
                  done-color="indigo-12" 
                  active-color="indigo-12" 
                  active-icon="circle"
        )
          q-step(:name="1" 
                :title="checklist_locales.step_1"
                icon="edit"
                :done="checklistStep > 1" 
          )
            q-card-section
              div(class="row checklist-form__position-relative")
                div(class="col-6")
                  label 
                    span(class="text-bold") {{checklist_locales.checklist_title}}
                    q-input(class="q-pt-sm" 
                            outlined 
                            :rules="[val => val.length > 0 || checklist_locales.required_field]"
                            v-model="checklistName" 
                            color="indigo-12"
                    )
                div(class="col-6 checklist-form__position-absolute")
                  label
                    span(class="text-bold") {{checklist_locales.checklist_details}}
                    q-input(class="q-pt-sm" 
                            outlined 
                            autogrow
                            v-model="checklistDescription" 
                            color="indigo-12" 
                            rows="1"
                    )
              div(class="row")
                div(class="col-6")
                  label
                    span(class="text-bold") {{checklist_locales.primary_organization}}
                  div(class="form-field form-multiselect")
                    multiselect(
                      v-model="facilityId"
                      :options="facilityIdOptions"
                      track-by="value"
                      label="label"
                      :closeOnSelect="true"
                      :show-labels="false"
                      :placeholder="checklist_locales.select_value"
                      @search-change="searchFn"
                      open-direction="bottom"
                      :allow-empty="true"
                      ref="mySelect"
                    )
                      span(slot="noResult")
                        i {{notifies.no_search_result}}
                      span(slot="noOptions")
                        i {{notifies.no_options_list}}
              //- div(class="row")
              //-   div(class="col-6")
              //-     label
              //-       span(class="text-bold") Роль исполнителя
              //-       q-option-group(class="q-mt-sm" 
              //-                     :options="checklistRadioOptions" 
              //-                     v-model="userRoleRadioGroupValue"
              //-                     color="indigo-12" 
              //-                     type="radio" 
              //-       )
              //- div(class="row")
              //-   div(class="col-6")
              //-     label
              //-       span(class="text-bold") ГОСБ
              //-       q-select(class="q-pt-sm" 
              //-               outlined 
              //-               :options="checklistGOSBOptions" 
              //-               v-model="GOSBValue" 
              //-               color="indigo-12" 
              //-               dropdown-icon="expand_more"
              //-       )
              //-   div(class="col-6")
              //-     label
              //-       span(class="text-bold") ТБ
              //-       q-select(class="q-pt-sm" 
              //-               outlined 
              //-               :options="checklistTBOptions" 
              //-               v-model="TBValue" 
              //-               color="indigo-12" 
              //-               dropdown-icon="expand_more"
              //-       )
              div(class="row")
                div(class="col-9")
                  label
                    span(class="text-bold") {{checklist_locales.extended_options}}
                    q-option-group(class="q-mt-sm" 
                      :options="checklistToggleOptions" 
                      v-model="toggleGroupValue"
                      color="indigo-12" 
                      type="toggle" 
                    )
              div(class="row justify-center btn-position-absolute")
                q-stepper-navigation
                  q-btn(outline color="indigo-12"
                        :disabled="!checklistName"
                        @click="activateChecklist(true)"
                  ) {{checklist_locales.save_as_draft}}
                  q-btn(color="indigo-12 q-ml-sm" 
                        @click="checklistStep++"
                        :disabled="!checklistName || !facilityId"
                  ) {{checklist_locales.continue}}
          q-step(:name="2" :title="checklist_locales.step_2"
                :done="checklistStep > 2" 
                icon="edit"
          )
            q-card-section
              div(class="row")
                div(class="col-12")
                  div(class="add-checklist-item" 
                      @click="createNewPage"
                  )
                    div(class="text-center")
                      q-icon(name="add")
                      span(class="q-ml-md") {{checklist_locales.to_add_page}}
              div(class="row" v-if="pages && pages.length && isSelectPagesMenuVisible")
                div(class="checklist-form__add-checklist__btn-group col-12 justify-end")
                  div(class="checklist-form__add-checklist__btn-group justify-end" v-if="isSelectPagesMenuActive")
                    span(class="text") {{checklist_locales.were_selected_pages}}
                      span(class="text-inner q-ml-sm") {{selected.length}}
                        q-btn(class="q-ml-sm clickable-icon"
                              round
                              flat
                              size="xs"
                              icon="close"
                              color="grey"
                              @click="changeSelectedStatus()"
                        )
                    span(class="text selected" @click="selectAllPages()") {{checklist_locales.select_all}}
                    q-btn(icon="more_horiz"
                          outline
                          color="indigo-12"
                          class="more"
                    )
                      q-menu(id="select-pages-fields")
                        q-list(style="min-width: 100px")
                          q-item(clickable v-close-popup @click="deletePage")
                            q-item-section(style="color: var(--modal-form-card-color)")  {{checklist_locales.delete}}
                          q-item(clickable v-close-popup @click="selected.length === 1 ? openEditPopup() : false")
                            q-item-section(style="color: var(--modal-form-card-color)")  {{checklist_locales.edit}}
                  div(class="checklist-form__add-checklist__btn-group" v-else)
                    span(class="text selected"
                        @click="isSelectPagesMenuActive = !isSelectPagesMenuActive"
                    ) {{checklist_locales.to_select_pages}}
              div(class="rows")
                div(class="row" v-if="pages && pages.length")
                  div(class="col-12 checklist-field" v-for="(page, index) in pages.filter(elem => !elem._destroy)" :key="page.id")
                    q-checkbox(color="indigo-12" :value="checkIfSelected(page.id)" v-if="isSelectPagesMenuActive" @input="selectPage(page.id)")
                    span(class="checklist-field__title") {{page.name}}
                    div(class="btn-up-down q-ml-sm")
                      q-btn(icon="arrow_drop_up" flat round size="sm" @click="correctArr(page, 'up')" v-if="index > 0")
                      q-btn(icon="arrow_drop_down" flat round size="sm" @click="correctArr(page, 'down')" v-if="index < pages.length - 1")

              div(class="row justify-center btn-position-absolute")
                q-stepper-navigation
                  q-btn(outline color="indigo-12"
                        :disabled="!checklistName"
                        @click="activateChecklist(true)"
                  ) {{checklist_locales.save_as_draft}}
                  q-btn(color="indigo-12" 
                        class=" q-ml-sm"
                        :disabled="pages.length === 0 || !isSelectPagesMenuVisible"
                        @click="activateChecklist(false)"
                  ) {{checklist_locales.activate}}
    
    addChecklistPageDialog(
                      :checklist_locales="checklist_locales"
                      :isCreatePageDialogVisible.sync="isCreatePageDialogVisible"
                      :isEditPageDialogVisible.sync="isEditPageDialogVisible"
                      :editPage="editPage.id ? editPage : undefined"
                      :allowFiles="this.toggleGroupValue.length > 0"
                      @on-new-page-created="onNewPageCreated($event)"
                      @on-edit-checklist-page="onEditPage($event)"
    )   

</template>

<script>
  import handleForm from '../../shared/forms/Form'
  import addChecklistFieldDialog from './addChecklistFieldDialog.vue'
  import addChecklistPageDialog from './addChecklistPageDialog.vue'
  import {v4} from 'uuid'
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    data: function () {
      return {
        path: this.$store.state.paths,
        title_icon_path: 'add_header.svg',
        callback_params: this.parentData.callback_params,
        form_data: [],
        dataHasLoaded: false,

        checklistId: this.parentData.row.id,

        checklistName: '',
        checklistDescription: '',
        checklistStep: 1,
        userRoleRadioGroupValue: -1,
        GOSBValue: '',
        TBValue: '',
        toggleGroupValue: [],
        fields: [],
        pages: [],
        selected: [],
        editPage: {},
        facilityId: null,
        facilityIdOptions: [],

        isCreatePageDialogVisible: false,
        isSelectPagesMenuVisible: true,
        isSelectPagesMenuActive: false,
        isEditPageDialogVisible: false,

        // checklistToggleOptions: [{
        //   label: this.current_locale === 'en' ? 'Add attachments' : 'Добавлять вложения',
        //   value: 1
        // },
        // {
        //   label: "Отправлять обращения",
        //   value: 2
        // },
        // {
        //   label: "Дублировать чеклист",
        //   value: 3
        // },
        // {
        //   label: "Писать заметки к странице шаблонов",
        //   value: 4
        // }
        // ],

        // checklistRadioOptions: [{
        //   label: "Администратор ЦКП",
        //   value: 1,
        // },
        // {
        //   label: "ССМ",
        //   value: 2,
        // },
        // {
        //   label: "Территориальный менеджер",
        //   value: 3,
        // },
        // {
        //   label: "СМ",
        //   value: 4
        // }],
        //
        // checklistGOSBOptions: [{
        //   label: "Администратор ЦКП",
        //   value: 1,
        // },
        // {
        //   label: "ССМ",
        //   value: 2,
        // },
        // {
        //   label: "Территориальный менеджер",
        //   value: 3,
        // },
        // {
        //   label: "СМ",
        //   value: 4
        // }],
        //
        // checklistTBOptions: [{
        //   label: "Администратор ЦКП",
        //   value: 1,
        // },
        // {
        //   label: "ССМ",
        //   value: 2,
        // },
        // {
        //   label: "Территориальный менеджер",
        //   value: 3,
        // },
        // {
        //   label: "СМ",
        //   value: 4
        // }],
      }
    },

    computed: {
      checklistToggleOptions() {
        return [{
          label: this.current_locale === 'en' ? 'Add attachments' : 'Добавлять вложения',
          value: 1
        }]
      },
      checklist_locales() {
        return this.locales.checklist[this.current_locale]
      }
    },

    props: {
      parentData: Object
    },

    components: {
      handleForm,
      addChecklistFieldDialog,
      addChecklistPageDialog,
      Multiselect
    },

    methods: {
      async getFacilityIds() {
        const newFacilityIdOptions = await this.$backend.index("api/v3/facilities/collection")
        this.facilityIdOptions = newFacilityIdOptions.data.options.map((elem) => ({
          label: elem.title,
          value: elem.id
        })) 
      },
      closeForm(data) {
        this.$emit('close-form', data ? data : {data: null})
      },

      searchFn (query) {
        let search_query = query.length > 0 ? query : null
        this.nextFacilitiesPage = 1
        this.$backend.collection('api/v3/facilities/collection', {
          search_query: search_query
        })
            .then(({ data }) => {
              this.facilityIdOptions = data.options.map((elem) => ({
                label: elem.title,
                value: elem.id
              }))
            })
      },

      createNewPage() {
        this.isEditPageDialogVisible = false;
        this.isCreatePageDialogVisible = true;
      },

      onNewPageCreated(event) {
        this.isCreatePageDialogVisible = false;
        event._new = true;
        this.pages.push(event)
      },

      openEditPopup() {
        this.editPage = this.pages[this.pages.findIndex(elem => this.selected[0] === elem.id)]; 
        this.isEditPageDialogVisible = true;
        this.isCreatePageDialogVisible = true;
      },

      onEditPage(event) {
        this.isCreatePageDialogVisible = false;
        this.isEditPageDialogVisible = false;
        this.$set(this.pages, this.pages.findIndex(elem => elem.id === event.id), event)
      },

      changeSelectedStatus() {
        this.isSelectPagesMenuActive = false;
        this.selected = []
      },

      checkIfSelected(id) {
        return this.selected.includes(id);
      },
      
      selectPage(id) {
        if(this.checkIfSelected(id)) {
          const index = this.selected.findIndex((elem) => elem === id)
          if (index !== -1) {
            this.selected.splice(index, 1)
          }
        } else {
          if (this.pages.filter(elem => !elem._destroy).findIndex(elem => elem.id === id) !== -1) this.selected.push(id)
        }
      },

      selectAllPages() {
        if (this.selected.length !== this.pages.length) {
          this.selected = []
          this.pages.forEach(elem => !elem._destroy ? this.selected.push(elem.id) : false)
        } else {
          this.selected = []
        }
      },

      deletePage() {
        this.pages = this.pages.map((elem) => {
          elem._destroy = this.selected.includes(elem.id);
          return elem
        })
        this.selected = []
      },

      correctArr(element, direction){ 
        let _arr = this.pages
        const index = this.pages.findIndex(elem => elem.id === element.id)
        let first_el
        let second_el
        if (direction === 'up') {
          first_el = _arr[index - 1]
          second_el = _arr[index]
          this.$set(this.pages, index - 1, second_el)
          this.$set(this.pages, index, first_el)
        } else {
          first_el = _arr[index + 1]
          second_el = _arr[index]
          this.$set(this.pages, index + 1, second_el)
          this.$set(this.pages, index, first_el)
        }
      },

      activateChecklist(draft) {
        const new_uuid = v4()
        if (!this.checklistName) {
          this.$q.notify(this.checklist_locales.error_data_saved)
          return
        }
        const checklist = {
          checklist: {
            title: this.checklistName,
            description: this.checklistDescription,
            start_page_uuid: this.pages.filter(elem => !elem._destroy)[0].id,
            allow_files: !!this.toggleGroupValue.length,
            facility_ids: this.facilityId.value ? [ this.facilityId.value] : [],
            checklist_pages_attributes: this.pages.map((page) => {
              const res = {
                id: page.id,
                title: page.name,
                parent_uuid: new_uuid,
                submit_title: "Готово",
                order: this.pages.filter(elem => !elem._destroy).findIndex(element => element.id === page.id),
                checklist_elements_attributes: page.fields.map((field) => {
                  const res = {
                    id: field.id,
                    title: field.name,
                    description: field.description,
                    checklist_element_type_id: field.type.value,
                    required: !!field.isRequired.length,
                    order: page.fields.filter(elem => !elem._destroy).findIndex((fieldElem) => fieldElem.id === field.id),
                    checklist_element_options_attributes: field.options.map(option => {
                      const res = {
                        id: option.id,
                        title: option.value,
                        order: field.options.filter(elem => !elem._destroy).findIndex((optionElem) => optionElem.id === option.id)
                      }
                      if (option._new) delete res.id
                      if (option._destroy) res._destroy = true
                      return res
                    })
                  }
                  if (field._new) delete res.id
                  if (field._destroy) res._destroy = true
                  return res
                })
                }
                if (page._new) delete res.id
                if (page._destroy) res._destroy = true
                return res
            })
          }
        }

        checklist.checklist.draft = !!draft;

        console.log(checklist)

        this.$backend.update('/api/v3/checklists', this.checklistId, checklist).then((res) => {
            this.$q.notify(this.checklist_locales.all_data_saved)
            this.closeForm({method: 'update', item: 'checklist', form_title: '', title_icon_path: this.title_icon_path, path: this.path['checklist'], grid: 'checklists', data: res.data.checklist})
          }
        ).catch(() => {
          this.$q.notify(this.checklist_locales.error_data_saved)
        })
      }
    },
    async created() {

            console.log(this.checklistId)

        await this.getFacilityIds()

        this.$backend.index('api/v3/checklist_element_types/collection').then(
            (newFieldOptions) => {
                if (this.checklistId) {
                    this.$backend.index('/api/v3/checklists/' + this.checklistId).then(res => {
                        console.log(res.data)
                        console.log(newFieldOptions)


                        this.checklistName = res.data.checklist.title
                        this.checklistDescription = res.data.checklist.description
                        this.toggleGroupValue = res.data.checklist.allow_files ? [1] : []
                        this.facilityId = this.facilityIdOptions[this.facilityIdOptions.findIndex((elem) => elem.value === res.data.checklist.facility_ids[0])]
                        this.pages = res.data.checklist.pages.map((page) => 
                            ({
                                id: page.id, 
                                name: page.title, 
                                fields: page.elements.map((field) => 
                                    ({
                                        id: field.id,
                                        name: field.title, 
                                        description: field.description,
                                        isRequired: field.required ? [1] : [],
                                        type: {label: newFieldOptions.data.options[newFieldOptions.data.options.findIndex((optionsElem) => optionsElem.id === field.checklist_element_type_id)].title, value: newFieldOptions.data.options[newFieldOptions.data.options.findIndex((optionsElem) => optionsElem.id === field.checklist_element_type_id)].id},
                                        options: field.options.map((option) => ({
                                            id: option.id,
                                            value: option.title
                                        }))
                                    })
                                )
                            })
                        )
                    })
                }
            }
        )

        
    },
    mounted() {
    },
    watch: {
      pages: {
        deep: true,
        handler() {
          if (this.pages.filter(elem => elem._destroy !== true).length === 0) {
            console.log(this.pages)
            this.isSelectPagesMenuVisible = false;
          } else {
            this.isSelectPagesMenuVisible = true;
          }
        }
      }
    }
  }
</script>

<style lang='scss'>
.checklist-form {
  &__position-relative {
    position: relative;
  }

  &__position-absolute {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__add-checklist__btn-group {
    display: flex; 
    gap: 20px;
    height: 35px;

    .text {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: var(--modal-form-card-color);

      .text-inner {
        color: grey;
        font-weight: normal;
        .clickable-icon {
          cursor: pointer;

          &:hover {
            opacity: .75;
          }

          &:active {
            opacity: .5;
          }
        }
      }

      &.selected {
        font-size: 15px;
        color: #5B7AFF;
        cursor: pointer;

        &:hover {
          opacity: .75;
        }

        &:active {
          opacity: .5;
        }
      }
    }

    .q-btn.more {
      width: 34px;
      height: 34px;
      padding: 0;
      border: 1px solid lightgrey;
      border-radius: 8px;
      .q-btn__wrapper.col.row.q-anchor--skip::before {
        border: none
      }
    }
  }

  &__wrapper {
    padding: 0 !important;
    border-radius: 15px !important;
    min-height: 600px !important;
    height: 100% !important;
    width: 66.6666666667% !important;
    max-width: 66.6666666667% !important;

    .btn-up-down {
      justify-content: center;
      display: flex; 
      flex-direction: column;
      .q-btn__content {
        width: 20px !important;
        height: 20px !important;
        
      }
      .q-btn {
        width: 20px !important;
        height: 20px !important;
      }
      .q-btn__wrapper:before {
        width: 20px !important;
        height: 20px !important;
      }
      .q-btn__wrapper {
        width: 20px !important;
        height: 20px !important;
        min-width: unset;
        min-height: unset;
      }
    }

    .col-8 {
      max-width: 66.6666666667% !important;
    }

    .checklist-field {
      border-bottom: 1px solid lightgrey;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      min-height: 60px;
      max-height: 200px;
      height: min-content;
      word-break: break-all;
      max-width: 100%;

      :last-child {
        border: none;
      }

      &__title {
        font-weight: 600;
        max-width: 90%;
        flex-grow: 1
      }
      &__type {
        margin-left: 10%;
        font-weight: 100;
      }
      .q-checkbox {
        flex-grow: 0;
      }
      .q-checkbox__bg {
        border-radius: 20px;
      }
    }

    .q-card__section, .q-panel-parent {
      position: static
    }

    .btn-position-absolute {
      position: absolute;
      width: calc(100% - 32px);
      bottom: 16px;
    }

    #drop-items {
      max-height: 230px;
      overflow-y: auto;
    }

    .checklist-new-form {
      height: 100%;
    }

    .add-checklist-item {
      display: flex;
      height: 100px;
      border: 1px dashed lightgrey;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s;

      div {
        margin: auto;
        display: flex;
        width: 200px;
        color: grey;
        align-items: center;
        justify-content: center;
      }
    }

    .q-toggle__label {
      margin-top: -5px;
    }

    .q-select--without-chips {
        .q-select__dropdown-icon {
          color: var(--modal-form-card-color);
        }

        .q-field__control-container {
          flex-grow: 10
        }
      }

    .q-stepper__nav .q-btn {
      padding: 5px 30px;
      text-transform: none;
      border-radius: 8px;
    }

    .q-toggle__track {
      height: 18px;
      margin-top: -5px;
      border-radius: 25px;
      width: 32px;
      margin-left: -5px;
    }
    .q-toggle__thumb, .q-toggle__thumb::after {
      height: 14px;
      width: 14px;
    }

    .q-toggle__inner--truthy .q-toggle__thumb {
      margin-left: -4px
    }

    .q-toggle {
      min-width: 320px
    }

    .q-toggle__thumb::after {
      background: white;
    }

    .q-stepper__title {
      margin-left: 10px
    }

    .q-stepper__tab {
      flex: unset !important;
    }

    .q-stepper__tab--active {
        color: #5B7AFF !important;
    }

    .q-stepper__step-inner {
      padding: 0 !important;
    }

    .q-radio {
      min-width: 72px;
    }

    .q-option-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -18px;
    }
  }

  &__card {
    background-color: var(--simple-table-background);
    height: 100%;
    color: var(--modal-form-card-color);

    .q-field--outlined .q-field__control {
      border-radius: 8px;
      height: unset;
      font-size: 12px;
      &::before {
        border: 1px solid;
        border-color: var(--modal-form-card-color);
      }

      .relative-position.row.no-wrap.q-anchor--skip {
        width: 100%;
        flex-grow: 3;
      }
    }

    .q-field__native {
      min-height: unset;
      height: 40px;
    }

    .q-field__append {
      min-height: unset;
      height: 40px;
      flex-direction: row-reverse;
      flex: unset;
    }

    .q-field--auto-height .q-field__control {
      min-height: unset;
    }

    .q-textarea {
      .q-field__native {
        padding-top: 10px;
        min-height: 40px;
      }

      .q-field__control {
        min-height: 40px;
      }

      .q-field__inner {
        height: 40px;
        overflow: hidden;
      }

      &.q-field--focused {
        .q-field__inner {
          height: unset;
          overflow: unset;
          background: white;
          z-index: 1;
        }
      }
    }
  }
  &__toolbar{
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #0D1F3C;
    }
    &__icon {
      color: #5B7AFF
    }
  }
  &__stepper-header {
    justify-content: center;
    border-top: 1px solid lightgrey;
    border-bottom: none;
  } 
  
}

</style>
