<template lang='pug'>

  div
    q-card(class="selected-items-form")

      div(class="selected-items-form-thead")

        div(v-for="el in data.thead"
            :class="'selected-item ' + el.class"
            :style="el.style")

          span {{el.label}}

      div(v-for="(item, i) in selected_items"
            class="selected-items-form-tbody")

        div(class="wrapper")
          div(v-for="el in data.tbody"
              :class="'selected-item ' + el.class"
              :style="el.style")


            component(v-if="el.input"
                      :ref="'input-' + el.input.type + '-' + el.key"
                      :is="getField(el.input.type)"
                      @set-selected-items="updateSelectedItems"
                      :parentData="{item: item, value_key: el.key, value: getInputValue(el, item)}")

            button-component(v-else-if="el.button" @on-click="onClickFn" :parentData="{item: item, el: el}")

            span(v-else :ref="'field-' + item.id + '-' + el.key") {{item[el.key]}}

        sub-menu(:ref="'sub-menu-' + item.id" @update-field="updateField" :item="item")


      div(v-if="data.footer" class="selected-items-form-footer")

        div(v-for="el in data.footer"
            :class="'selected-item ' + el.class"
            :style="el.style")

          span(v-if="el.calculate") {{calculateCommonCount(el.calculate.calculated_keys)}}
          span(v-else) {{el.label}}

    //sub-menu(ref="sub-menu")


</template>

<script>
  import sectedItemsFormFields from './fields/index'
  import subMenu from './subMenu'
  import buttonComponent from './fields/button'

  export default {
    data: function () {
      return {
        data: this.parentData.data,
        grid: this.parentData.grid,
        parent_name: this.parentData.parent_name,
        selected_items: this.parentData.selected_items,

        sub_menu: false
      }
    },

    components: {
      sectedItemsFormFields,
      buttonComponent,
      subMenu
    },

    props: {
      parentData: Object
    },

    computed: {
      selectedItemsForm: {
        get() {
          return this.currentForm[this.parent_name]['selected_items']
        },
        set(value) {
          this.$store.commit('updateSelectedItemsFormField', {grid_name: this.grid, field: this.parent_name, value: value})
        }
      },
    },

    methods: {

      updateField(data) {
        this.$refs['field-' + data.item.id + '-' + data.field_key][0].innerHTML = data.value
      },

      onClickFn(data) {
        if (data.el.onclick) {
          this[data.el.onclick.fn_name](data.el.onclick.attr, data.item)
        }
      },

      openSubMenu(attr, item) {
        this.$refs['sub-menu-' + item.id][0].toggle(attr)
      },

      getInputValue(el, item) {
        let val = this.selected_items.find(el => el.id === item.id)[el.key]
        return val
      },

      getField(key) {
        return sectedItemsFormFields[key]
      },

      setSelectedItems(data) {
        this.selected_items = data
        this.selectedItemsForm = this.selected_items
        this.$emit('set-selected-items', this.selected_items)
      },

      updateSelectedItems(data) {
        this.selected_items.find(el => el.id === data.id)[data.value_key] = data.value
        this.selectedItemsForm = this.selected_items
        this.$emit('set-selected-items', this.selected_items)
      },

      calculateCommonCount(keys) {
        return this.selected_items.map((item) => {
          return keys.map(key => {
            return item[key]
          })
        }).map(el => {
          return el.reduce((a, b) => parseFloat(a) * parseFloat(b))
        }).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
      }
    },

    beforeMount() {
    },

    created() {
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/selected-items-form";

</style>