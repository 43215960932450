<template lang='pug'>

  div(class="modal-form-card")

    q-card-section
      form-header(ref="form-header" @close-form="closeModal"
                  :parentData="{title: post.created_at}")

    div(class="post-modal-area")
      q-card-section(class="post-modal-img")
        img(:src="post && post.image_url ? post.image_url : require('../../../../assets/icons/dashboard/posts/missing_post.svg')")

      q-card-section(class="post-modal-title")
        span {{post.title}}

      q-card-section(class="post-modal-content")
        span(class="post-title" v-html="post.content")

</template>

<script>
  import formHeader from '../../../shared/forms/formHeader'

  export default {

    props: {
      post: Object
    },

    components: {
      formHeader
    },

    methods: {

      closeModal() {
        this.$emit('close-modal')
      }
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/modal-form";

  .post-modal-area {
    padding-left: 15px;
    padding-right: 15px;

    .post-modal-img {
      text-align: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 350px;
      }
    }

    .post-modal-title {
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 21px;
        color: var(--modal-form-card-color);
      }
    }

    .post-modal-content {
      padding-bottom: 50px;

      .post-title {

        h1, h2, h3, h4, h5, p, i, em {

          strong {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--modal-form-card-color) !important;
          }

          big, span, em {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--modal-form-card-color) !important;
          }

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--modal-form-card-color) !important;
        }

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--modal-form-card-color) !important;
      }
    }
  }
</style>