import showIssue from '../../view/issues/show'
import showContract from '../../view/contracts/show'
import showPprIssue from '../../view/ppr/issues/show'
import showPprEquipment from '../../view/ppr/equipments/show.vue'
import showMeasuringMeter from '../../view/measuring/meters/show'

export default {
  issues:           showIssue,
  archive:          showIssue,
  ppr_issues:       showPprIssue,
  ppr_archive:      showPprIssue,
  ppr_equipments:   showPprEquipment,
  contracts:        showContract,
  measuring_meters: showMeasuringMeter,
}