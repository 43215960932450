<template lang='pug'>
  div
    div(v-if="dataHasLoaded" class="user-edit-form justify-center")
      handle-form(@close-form="closeForm" :parentData="{method: 'update', item: 'user', item_id: row.id, title_icon_path: title_icon_path, form_title: form_data.form_header.title.edit + ' №' + row.id, path: path['user'], grid: 'users', data: form_data}")

    div(id="modal-background-form")
    ui-prompts(v-if="dataHasLoaded" :parentData="{areas_key: 'edit_user'}")

</template>

<script>
  import handleForm from '../../shared/forms/Form'
  import uiPrompts from '../../shared/ui_prompts/index.vue'

  export default {
    data: function () {
      return {
        path: this.$store.state.paths,
        title_icon_path: 'edit_header.svg',
        row: this.parentData.row,
        callback_params: this.parentData.callback_params,
        form_data: [],
        dataHasLoaded: false
      }
    },

    props: {
      parentData: Object
    },

    components: {
      handleForm,
      uiPrompts
    },

    methods: {

      closeForm(data) {
        this.$emit('close-form', data)
      },

      loadFormData() {
        let form_data_path = this.path['user'] + '/form_data';

        let params = {};
        params['class_name'] = 'user'

        if (this.row) {
          params['id'] = this.row.id;
        }

        this.$backend.index(form_data_path, { params: params })
        .then(({ data }) => {
          this.form_data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      },
    },

    created(){
      this.loadFormData()
      this.$emit('row-is-active', true)
    },

    mounted() {
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>
</style>