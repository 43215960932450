import fuzzysearch from 'fuzzysearch'

function searchFilter(txt, entities) {
  if(!txt) {
    return entities;
  }

  txt = txt.toLocaleLowerCase();

  return entities.filter(entity => {
    switch(entity.getType()) {
      case 'worker': return matchesWorker(txt, entity);
      case 'building': return matchesBuilding(txt, entity);
      default:
        console.error("searchFilter: type error: ${entity.getType()}");
        return false;
    }
  });
}

function matchesBuilding(query, building) {
  return searchStrings(query, [
    building.getTitle(),
    building.getAddress(),
  ])
}

function matchesWorker(query, worker) {
  return searchStrings(query, [
    worker.getFullName(),
  ]);
}

function searchStrings(query, strings) {
  return strings.flatMap(s => s.split(/\s/))
                .some(s => fuzzysearch(query, s.toLocaleLowerCase()));
}

export default searchFilter;
