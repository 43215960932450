<template lang='pug'>

  div
    div(class="form-field-label")
      span {{label}}
      span(v-show="required" class="form-field-require-tag" style="color:red;" :title="notifies.required_field")
        |*

    div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
      q-input(filled no-error-icon
              v-model="currentField"
              :label-slot="false"
              autocomplete="off"
              :for="name")
        q-icon(name="cancel" v-if="currentField" @click.stop="resetField()" class="cancel-string-field")

    span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}

</template>

<script>
  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        depend_from: this.parentData.data[0].depend_from || {},
        watch: this.parentData.data[0].watch || {},
        required: this.parentData.data[0].require,
        rules: this.parentData.data[0].rules,
        uniqueness: this.parentData.data[0].uniqueness,
        valid_error_message: null,
        main_class: 'form-field form-input'
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            return form_field['field']
          } else {
            return undefined
          }
        },
        set(value) {
          let result = {}
          if (value && value.length > 0) {
            result['field'] = value
            result['invalid'] = this.invalid(value)
          } else {
            result['invalid'] = this.invalid()
          }
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})

          if (this.uniqueness) { this.checkFieldUniqueness(value) }

          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {grid_name: this.grid, field: 'invalid_fields', value: this.name})
          }
        }
      },

      fieldDependency() {
        return this.checkDependency()
      }
    },

    watch: {
      fieldDependency(newVal, oldVal) {},
    },

    methods: {

      setField(val) {
        this.currentField = val
      },

      resetField() {
        this.currentField = ''
      },

      invalidLength(val) {
        if (val && val.length > 0) {
          this.valid_error_message = null
          return false
        } else {
          this.valid_error_message = this.notifies.not_empty
          return true
        }
      },

      // invalidCyrillic(val) {
      //   let regex = /[а-яА-Я]/;
      //   if (!regex.test(String(val))) {
      //     this.valid_error_message = null
      //     return false
      //   } else {
      //     this.valid_error_message = this.notifies.not_cyrillic
      //     return true
      //   }
      // },

      invalid(val=undefined) {
        let result

        if (this.required) {
          result = this.invalidLength(val)
        } else {
          this.valid_error_message = null
          result = false
        }

        if (this.rules && this.rules.length > 0) {
          if (val && val.length > 0) {
            let messages = this.rules.map(rule => {
              let regex = new RegExp(rule['regex'])
              if (!regex.test(val)) {
                return rule['error']
              }
            }).filter(msg => ![undefined, null, ""].includes(msg))
            if (messages.length > 0) {
              this.valid_error_message = messages[messages.length - 1]
              result = true
            }
          }
        }

        return result
      },
    },

    created() {
      this.watchSimpleFieldParents()
    },

    beforeMount() {
      this.$store.commit('createFormField', {grid_name: this.grid, field: this.name})
      this.setField(this.value)
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/string";

</style>