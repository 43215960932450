<template lang='pug'>

  div(class="editable-buttons-group")
    div(v-for="button in buttons" class="button-wrapper" :style="button.style")
      q-btn(flat no-caps :class="button.name + '-button'" @click="onClickFn(button)" :disabled="button.checkable && !checkable")
        template(slot="default")
          inline-svg(:src="require(`../../../../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`)")
        template(slot="default")
          span(class="action-label") {{button.label}}

</template>

<script>
  export default {
    data: function () {
      return {
        buttons: this.parentData.buttons
      }
    },

    props: {
      parentData: Object
    },

    computed: {
      checkable() {
        return this.parentData.checkable
      }
    },

    watch: {
    },

    methods: {
      onClickFn(button) {
        this.$emit('on-click-fn', button)
      }
    },

    created() {
    },

    beforeMount() {
    }
  }
</script>

<style lang="scss">

</style>