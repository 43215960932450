<template lang="pug">
  div
    div(v-if="loading" class="spinner-container")
      q-spinner(color="primary" size="3em")
    div(v-else class="")
      div(class="floor-plan-dot-prompt-content")
        div(class="floor-plan-dot-prompt-content-item" v-for="(value, key) in shortInfo")
          |{{key}}
          p {{value}}
      
    
</template>

<script>

export default {

  data: function(){
    return{
      shortInfo: this.promptData.dot.short_info,
      dot: this.promptData.dot,
      floor_plan_id: this.promptData.floor_plan_id,
      loading: false
    }
  },

  props: {
    promptData: Object
  },

  watch: {
    promptData: function(newVal, oldVal) {
      this.dot = newVal.dot
      this.shortInfo = newVal.dot.short_info
    }
  },
}
</script>

<style lang="scss">

  @import "../../../../assets/styles/floor-plan/main";

</style>
