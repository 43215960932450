<template lang='pug'>

  div(id="show-form" class="issue-show")
    div(class="show-card")

      q-card-section
        form-header(v-if="show_data.common" ref="form-header" @close-form="closeShow"
                   :parentData="{grid: grid, title: show_data.common.form_header.title + ' № ' + row_id, title_icon: 'show_header.svg'}")

      div(v-if="loading" class="spinner-container form-spinner")
        q-spinner(color="primary" size="3em")

      div(v-if="!loading")
        q-card-section
          component(ref="actions"
                    v-if="show_data.common['actions']"
                    :is="getShowComponent('actions')"
                     @refresh-table="refreshTable"
                     @open-form="openForm"
                     :parentData="{row: row, grid: grid, grid_key: grid_key, path: path, transition_key: transition_key, data: show_data.common['actions']}")

        q-card-section(v-if="row && row.checklist_id")
          checklist(ref="checklist" :parentData="{row: row}")

        q-card-section
          component(ref="blocks"
                    v-if="show_data.common['blocks']"
                    :is="getShowComponent('blocks')"
                    :parentData="{row: row, show_details: false, data: show_data.common['blocks']}")


        q-card-section
          component(ref="tabs"
                    v-if="show_data.common['tabs']"
                    :is="getShowComponent('tabs')"
                    :parentData="{data: show_data.common['tabs']}")


        q-card-section
          component(ref="form_data"
                    v-if="show_data.common['form_data']"
                    :is="getShowComponent('form_data')"
                    @load-show-data="loadShowData"
                    @refresh-table="refreshTable"
                    @set-oading="setLoading"
                    :parentData="{item: item, row: row, grid: grid, path: path, data: show_data.common['form_data']}")


    div(v-if="!loading" v-show="false" id="print-issue-card")
      component(ref="print_card"
                :is="getShowComponent('print_card')"
                :parentData="{row: row, data: show_data.common}")

</template>

<script>
  import formHeader from '../../../shared/forms/formHeader'
  import showComponents from '../../../shared/show_card_components/index'
  import checklist from '../../checklists/item'

  export default {
    data: function () {
      return {
        path: this.parentData.path,
        row_id: this.parentData.row.id,
        row: {},
        item: 'issue',
        transition_key: this.parentData.transition_key,
        grid: this.parentData.grid,
        grid_key: this.parentData.grid_key,
        show_data: [],
        loading: true
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      showComponents,
      checklist
    },

    computed: {
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    methods: {

      setLoading(val) {
        console.log(val)
        this.loading = val
      },

      refreshTable(data) {
        this.refreshRow(data)
        this.$emit('refresh-table', data)
        this.$refs.tabs.refreshGrid('comments')

        if (data.action === 'destroy') {
          this.$emit('close-show', this.row)
        }
      },

      refreshRow(data) {
        this.row = data.attr.data || data.attr
        // Object.keys(showComponents).forEach(component => {
        //   if (this.$refs[component]) {
        //     try {
        //       this.$refs[component].refreshRow(this.row)
        //     } catch(e) {}
        //   }
        // })
      },

      openForm(data) {
        this.$emit('open-form', data)
      },

      closeShow(row=this.row) {
        this.$emit('close-show', row)
      },

      loadShowData(params={}) {
        this.$backend.show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.show_data = data
          this.row = this.show_data.common['data']
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
        .finally(() => {
          this.loading = false
        })
      },
    },

    created(){
      this.$emit('row-is-active', true)
      setTimeout(() => {
        this.loadShowData()
      }, 300)

      this.$root.$on('load-show-data', (row) => {
        if (this.row_id && row && this.row_id === row.id) {
          this.loadShowData()
        }
      })
    },

    mounted() {
    },

    beforeMount() {
    },

    beforeDestroy() {
      this.row_id = ''
    }
  }
</script>

<style lang='scss'>

 @import "../../../../assets/styles/show/issue/main";

</style>

