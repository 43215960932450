<template lang='pug'>

  div
    div(id="ui-prompt")
      div(id="ui-prompt-arrow" class="ui-prompt-arrow")
      div(id="ui-prompt-card" :class="`modal-form-card ui-prompt-card ${prompt.class}`")

        q-card-section
          form-header(ref="form-header" @close-form="closeModal"
                      :parentData="{title: prompt.title}")

        div(class="prompt-modal-area")
          q-card-section(class="prompt-modal-img")
            img(id="ui-prompt-img" :src="require(`../../../assets/icons/ui_prompts/${prompt.image_url}.svg`)")

          q-card-section(class="prompt-modal-content")
            span(class="post-title" v-html="prompt.content")

          q-card-actions(class="button-group prompt-modal-actions row justify-center")
            q-btn(flat no-caps
                  class="modal-form-cancel prompt-back"
                  :label="ui_prompts_locales.back"
                  @click="onClick('back')")
            q-btn(flat no-caps
                  class="modal-form-submit prompt-next"
                  :label="ui_prompts_locales.next"
                  @click="onClick('next')")

    q-dialog(v-model="confirm_modal")
      confirm-modal(@confirm="confirmResult" class="ui-prompt-close-confirm" :parentData="{message: confirm_message}")

</template>

<script>
  import formHeader from '../../shared/forms/formHeader'

  export default {

    data: function () {
      return {
        key: '',
        confirm_modal: false,
        confirm_message: '',
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      confirmModal: () => import('../helpers/confirmModal')
    },

    computed: {
      prompt() {
        return this.parentData.prompt
      },

      ui_prompts_locales() {
        return this.locales.ui_prompts[this.current_locale]
      },
    },

    methods: {

      setPrompt() {
        let parent_el = document.getElementById(this.prompt.parent_id) || document.getElementsByClassName(this.prompt.parent_id) && document.getElementsByClassName(this.prompt.parent_id)[0]
        let el = document.getElementById('ui-prompt')
        let arrow = document.getElementById('ui-prompt-arrow')
        let card = document.getElementById('ui-prompt-card')

        if (parent_el) {
          let modal_bg = document.getElementById('modal-background')
          modal_bg.classList.add('disable-background')
          modal_bg.style.zIndex = '5000'

          modal_bg.removeEventListener('click', (e) => {
            modal_bg.classList.add('disable-background')
          })

          modal_bg.addEventListener('click', (e) => {
            modal_bg.classList.add('disable-background')
          })

          this.$emit('customize-prompt', parent_el, arrow, card)
        }
      },

      showPrompt() {
        setTimeout(() => {
          let el = document.getElementById('ui-prompt')
          el.style.visibility = 'visible'
        },200)
      },

      onClick(direction) {
        this.$root.$emit('change-ui-prompt', {direction: direction, key: this.prompt.areas_key})
      },

      confirmResult(val) {
        if (val) {
          this.confirm_modal = false
          this.promptHasShowed()
          this.$emit('close-modal')
        } else {
          this.confirm_modal = false
          this.$emit('close-modal')
        }
      },

      closeModal() {
        this.confirm_message = this.notifies.confirm_close_prompt
        this.confirm_modal = true
      },

      promptHasShowed(params={}) {
        params['ui_prompt'] = {user_id: this.current_user.id}
        this.$backend.create(`${this.$store.state.paths['ui_prompt']}/ui_prompts_showed`, null, params)
        .then(({data}) => {
          // console.log(data)
          this.$store.commit('updateCurrentUser', data)
        })
        .catch((error) => {
          console.log(error)
          if (error.response) { this.reLogin(error.response.status) }
        })
      }
    },

    created(){
      window.addEventListener('resize', () => {
        this.setPrompt()
      });
    },

    beforeDestroy() {
      window.removeEventListener("resize", () => {
        this.setPrompt()
      });
    },

    mounted() {
      if (this.prompt) {
        this.$refs['form-header'].setLoading(false);
        this.setPrompt()
      }
    }
  }
</script>

<style lang='scss'>

  @import "../../../assets/styles/forms/modal-form";

  .form-card-title-spacer {
    display: none;
  }

  .modal-form-card.ui-prompt-card.buttons-add-issue {
    width: 240px !important;
  }

  #ui-prompt {
    visibility: hidden;
    /*transition: visibility 0s, opacity 3.5s linear;*/
  }

  .ui-prompt-arrow {
    position: absolute;
    //cursor: default;
    content: "";
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
    background: var(--ui-prompt-card-background) !important;

    left: -10px;
    top: 15px;

    z-index: 5000;
  }

  .ui-prompt-card {
    z-index: 6000;
  }

  .modal-form-card.ui-prompt-card {
    border-radius: 4px !important;
    position: absolute;
    background: var(--ui-prompt-card-background) !important;

    .form-card-title {
      margin-bottom: initial;
      padding: 0 !important;

      span {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.44px;
      }
    }

    .form-card-close-icon {
      top: 15px;
      right: 15px;
    }

    .prompt-modal-img {
      text-align: center;
      /*padding-bottom: 0 !important;*/
    }

    .button-group.prompt-modal-actions {
      margin-bottom: 25px !important;

      .prompt-back {
        left: 0px;
        position: absolute;
        margin: 15px;
        /*margin-right: 15px;*/
        box-shadow: inset 0px 0px 0px 1px #ADADAD;
        background-color: var(--ui-prompt-card-background);
        color: var(--ui-prompt-cancel-color);
        border-radius: 4px;
      }

      .prompt-next {
        right: 0px;
        position: absolute;
        margin: 15px;
        /*margin-left: 15px;*/
        background-color: #FF8283;
        border-radius: 4px;
      }
    }

    .prompt-modal-content {
      margin-bottom: 10px;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.38px;
      }
    }
  }


</style>