<template lang='pug'>

  div(class="col-12 col-lg-6")

    div(class="form-field-label")
        span {{field.title}}
        span(v-show="field.required" class="form-field-require-tag" style="color:red;" :title="field.title")
            |*

    div(class="d-flex checklist-checkbox")
        q-option-group(class="q-mt-sm"
                        style="display: block; margin-left: 0"
                        :options="options" 
                        v-model="value"
                        color="indigo-12" 
                        type="checkbox" 
                        :disable="readonly"
                        )

</template>

<script>
  export default {
    data() {
        return {
            options: this.field.options.map(elem => ({label: elem.title, value: elem.id})),
            value: this.$props.initialValue ? this.$props.initialValue.map(elem => elem.value) : [],
        }
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean || undefined,
            required: false,
            default: false
        },
        initialValue: {
            type: Array,
            required: false,
            default: () => ([{value: []}])
        }
    },
    methods: {
        resetField() {
            this.value = []
            this.$emit('reset-field', {id: this.field.id})
        },
        editField(val) {
            if (!this.readonly) this.$emit('edit-field', {id: this.field.id, value: val.map(el => ({value: el, title: ''}))})
        },
    },
    watch: {
        value: {
            deep: true,
            handler() {
                if (!this.readonly) this.editField(this.value)
                document.getElementsByClassName('q-checkbox__truthy').forEach(elem => elem.style = 'x:10px')
            }
        }
    },
    created() {
      if (this.initialValue) {
        // this.value = this.initialValue.map(elem => elem.value)
      }
        if (this.readonly) {
            this.options = this.field.options.map(elem => ({label: elem.values[0].title, value: elem.values[0]._data.value}))
            this.value = this.field._data.value && this.field._data.value.length > 0 ? this.field._data.value.map(elem => elem.value) : []
        }
    },
    mounted() {
        setTimeout(() => document.getElementsByClassName('q-checkbox__truthy').forEach(elem => elem.style = 'x:10px'), 100)
    }
  }
</script>

<style lang="scss">

  @import "../../../assets/styles/forms/fields/checkbox";
  .checklist-checkbox {
      .q-checkbox__truthy {
          x: 10px
      }
  }

</style>