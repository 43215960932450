<template lang="pug">
  table(class="tech-map-tab-table issues-table")
    thead
      tr
        th
          span(class="column-header-title") Исполнитель
        th
          span(class="column-header-title") Начало (план)
        th
          span(class="column-header-title") № недели
        th
          span(class="column-header-title") Статус
        th
          span(class="column-header-title") Подпись
    tbody
      tr(v-for="tab in tabs")
        td(v-for="row in tab.rows") 
          span {{row.value || "–"}}
</template>

<script>

export default({
  data(){
    return{
      tabs: this.parentData.tabs
    }
  },

  props: {
    parentData: Object
  },

  mounted(){
  }
})
</script>
