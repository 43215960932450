<template lang='pug'>

  div

</template>

<script>

  export default {

    methods: {
    },

    beforeMount() {
      this.$emit('set-loading', false)
      this.$emit('expand-class', {val: false, class: ''})
    }
  }
</script>

<style lang="scss">
</style>