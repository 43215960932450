<template lang='pug'>

  div
    q-card-section(class="checklist-logs")

      div(class="checklist-logs-title")
        span {{data['checklist_title']}}

      div(class="checklist-logs-title")
        span {{data['building_title']}}

      div(class="checklist-logs-table")
        table(cellspacing="0" cellpadding="0")

          tr(v-for="row in default_rows")
            td(class="title-row")
              span {{row['title']}}
            td(class="user-row")
              span
            td(class="date-row")
              span {{data[row['key']]}}
            td(class="value-row")
              span

          tr(v-for="row in data['form_params']")
            td(class="title-row")
              span {{row['title']}}
            td(class="user-row")
              span {{row['user']}}
            td(class="date-row")
              span {{row['created_at']}}
            td(class="value-row")
              table
                tr(class="log-card-param" v-for="param in row['values']")
                  td(class="label")
                    span {{param['label']}}
                  td(class="value")
                    span {{param['value']}}

      div(class="checklist-logs-title")
        span {{data['result_time']}}


</template>

<script>

  export default {
    data: function () {
      return {
        data: this.parentData.data,
        default_rows: [
          {
            title: 'Открыт',
            key: 'started_at'
          },
          {
            title: 'Завершен',
            key: 'finished_at'
          }
        ],
      }
    },

    props: {
      parentData: Object
    }
  }
</script>
