import actions from './actions'
import blocks from './blocks'
import tabs from './tabs'
import form_data from './form'
import percentage from './percentage'
import print_card from './print/print_card'
import completion_certificate from './print/print_completion_certificate'
import print_qr from '../../view/ppr/equipments/show/tech_map/print/print_qr'
import print_equipment_tech_map from '../../view/ppr/equipments/show/tech_map/print/print_equipment_tech_map'

export default {
  actions:                      actions,
  blocks:                       blocks,
  tabs:                         tabs,
  form_data:                    form_data,
  percentage:                   percentage,
  print_card:                   print_card,
  completion_certificate:       completion_certificate,
  print_qr:                     print_qr,
  print_equipment_tech_map:     print_equipment_tech_map
}