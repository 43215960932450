<template lang='pug'>

  div(v-if="prompt_obj && tips_modal")
    component(ref="prompt-component" :is="getPrompt()" @close-modal="closePrompt" :parentData="{prompt: prompt_data}")



</template>

<script>
  import ui_prompts from '../../shared/ui_prompts/index.js'

  export default {
    data: function () {
      return {
        areas_key: this.parentData.areas_key,

        tips_modal: false,
        prompt_obj: undefined,
        prompt_data: {},
        beginning_indexes: [-1, 0],
        tip_indexes: [],
        parent: ''
      }
    },

    props: {
      parentData: Object
    },

    components: {
      ui_prompts
    },

    computed: {
      tips_areas() {
        return this.$store.state.ui_prompts[this.areas_key]
      },



      areas() {
        if (this.tips_areas) {
          return Object.keys(this.tips_areas)
        } else {
          return []
        }
      },

      prompt: {
        get() {
          return this.prompt_obj
        },
        set(value) {
          this.prompt_obj = value
        }
      },

      indexes: {
        get() {
          return this.tip_indexes.length > 0 ? this.tip_indexes : this.beginning_indexes
        },
        set(value) {
          this.tip_indexes = value
        }
      }
    },

    methods: {

      setUiPrompt(direction='next') {
        if (!this.ui_prompts_showed) {
          this[direction](direction)
        }
      },

      back(direction) {
        if (this.parent === undefined) {
          this.closePrompt()

        } else if (this.indexes[1] > 0) {
          this.indexes[1] -= 1
          this.loadPromptData({direction: direction})

        } else if (this.indexes[1] === 0) {

          if (this.indexes[0] > 0) {
            this.indexes[0] -= 1
            this.loadPromptData({direction: direction})
          }
        }
      },

      next(direction) {
        if (this.indexes[0] < 0) {
          this.indexes[0] += 1
          this.loadPromptData({direction: direction})

        } else if (this.areas && [this.areas.length - 1, (this.tips_areas[this.areas[this.areas.length - 1]].length - 1)].join() === this.indexes.join()) {
          this.closePrompt()

        } else if (this.prompt && this.prompt.area && (this.indexes[1] === (this.tips_areas[this.prompt.area].length - 1)
                                && this.indexes[0] < (this.areas.length - 1))) {
          this.indexes[0] += 1
          this.indexes[1] = 0
          this.loadPromptData({direction: direction})

        } else if (this.prompt && this.prompt.area && (this.indexes[1] < (this.tips_areas[this.prompt.area].length - 1))) {
          this.indexes[1] += 1
          this.loadPromptData({direction: direction})

        }
      },

      getPrompt() {
        return ui_prompts[this.areas_key][this.prompt.area][this.prompt.sub_area]
      },

      loadPromptData(params={}) {
        params['ui_prompt'] = {}
        params['ui_prompt']['areas_key'] = this.areas_key
        params['ui_prompt']['indexes'] = this.indexes

        this.$backend.create(`${this.$store.state.paths['ui_prompt']}/ui_prompts_data`, null, params)
        .then(({ data }) => {

          if (data && data.prompt_data) {
            this.parent = document.getElementById(data.prompt_data.parent_id) || document.getElementsByClassName(data.prompt_data.parent_id) && document.getElementsByClassName(data.prompt_data.parent_id)[0]
            this.prompt = data.prompt_obj
            this.indexes = data.indexes
            this.prompt_data = data.prompt_data
            this.tips_modal = true

          } else {
            this.closePrompt()
          }
        })
        .catch((error) => {
          if (error.response) { this.reLogin(error.response.status) }
          console.log(error)
        })
        .finally(() => {
          if (this.parent === undefined) { this[params.direction](params.direction) }
        })
      },

      closePrompt() {
        this.indexes = []
        this.prompt = {}
        this.tips_modal = false
        document.getElementById('ui-prompt').style.visibility = 'hidden'
        let modal_bg = document.getElementById('modal-background')
        let modal_bg_form = document.getElementById('modal-background-form')
        if (modal_bg) {
          modal_bg.classList.remove('disable-background')
        }
        if (modal_bg_form) {
          modal_bg_form.classList.remove('disable-background')
        }
      }
    },

    created(){
      setTimeout(() => {
        this.setUiPrompt()
      },2000)

      this.$root.$on('change-ui-prompt', (attr) => {
        this.setUiPrompt(attr.direction)
      })
    }
  }
</script>

<style lang='scss'>
</style>