<template lang='pug'>

  div
    table(class="tech-map-tab-table replaced-components-table" id="replaced-components-table")
      thead
        tr
          th(v-for='column in tab_data.columns' :key="column.name")
            span(class="column-header-title") {{ column.label }}
      tbody
        tr(v-if="editable" v-for="(item, i) in editable_data" :style="{'cursor': 'pointer'}"
        :class="{'active-replaced-components-row': checkable && checkable.index === i}" @click="setCheckable(item, i)")
          td(v-for="key in Object.keys(item)" :class="{'properties-td': key === 'properties'}" :style="{'padding': (key === 'properties' ? '0' : '10px')}")
            table(v-if="key === 'properties'" class="properties-table")
              tr(v-for="(sub_item, sub_index) in item[key]")
                td(v-for="sub_key in Object.keys(sub_item)")
                  tech-map-text-area(:parentData="{tech_map_key: tech_map_key, grid: grid, index: i, sub_index: sub_index, value: sub_item[sub_key], value_key: sub_key, value_key_parent: key}")
                  //tech-map-input(:parentData="{tech_map_key: tech_map_key, grid: grid, index: i, sub_index: sub_index, value: sub_item[sub_key], value_key: sub_key, value_key_parent: key}")

            span(v-else)
              tech-map-text-area(:parentData="{tech_map_key: tech_map_key, grid: grid, index: i, value: item[key], value_key: key}")
              //tech-map-input(v-else :parentData="{tech_map_key: tech_map_key, grid: grid, index: i, value: item[key], value_key: key}")


        tr(v-if="!editable" v-for="item in data")
          td(v-for="key in Object.keys(item)" :class="key === 'properties' ? 'properties-td td-' + key : 'td-' + key" :style="{'padding': (key === 'properties' ? '0' : '10px')}")
            table(v-if="key === 'properties'" class="properties-table")
              tr(v-for="sub_item in item[key]")
                td(v-for="sub_key in Object.keys(sub_item)")
                  span {{sub_item[sub_key].length > 0 ? sub_item[sub_key] : '-'}}

            span(v-else) {{item[key].length > 0 ? item[key] : '-'}}

    handle-buttons(v-if="editable" @on-click-fn="onClickFn" :parentData="{buttons: buttons['rows_handle'], checkable: checkable}")
    handle-buttons(v-if="editable" @on-click-fn="onClickFn" :parentData="{buttons: buttons['properties_handle'], checkable: checkable}")


</template>

<script>
  import techMapInput from './helpers/input'
  import techMapTextArea from './helpers/textarea'
  import handleButtons from './helpers/buttons.vue'

  export default {
    data: function () {
      return {
        grid: this.parentData.grid,
        row_id: this.parentData.row_id,
        tab_data: this.parentData.tab_data,
        checkable: undefined,
        tech_map_key: 'replaced_components'
      }
    },

    props: {
      parentData: Object
    },

    components: {
      techMapInput,
      techMapTextArea,
      handleButtons
    },

    computed: {

      editable() {
        return this.parentData.editable
      },

      data() {
        let data = this.parentData.data
        return data ? data : []
      },

      buttons() {
        return this.parentData.buttons
      }
    },

    watch: {
      editable(newVal, oldVal) {
        if (!newVal) {
          this.checkable = undefined
        }
      },

      data(newVal, oldVal) {
        this.editable_data = newVal
      }
    },

    methods: {

      onClickFn(button) {
        this[button.onclick]()
      },

      addItem() {
        let empty_replaced_component = {
          code: '',
          name: '',
          properties: [
            {
              key: '',
              value: ''
            },
            {
              key: '',
              value: ''
            }
          ],
          replacement_date: '',
          replacement_reason: ''
        }
        this.editable_data.push(empty_replaced_component)
        let index = this.editable_data.length - 1
        this.setCheckable(this.editable_data[index], index)
      },

      duplicateItem() {
        let new_replaced_component = {
          code: this.checkable.row.code,
          name: this.checkable.row.name,
          properties: [],
          replacement_date: this.checkable.row.replacement_date,
          replacement_reason: this.checkable.row.replacement_reason
        }

        this.checkable.row.properties.forEach(p => {
          let new_prop = {
            key: p.key,
            value: p.value
          }
          new_replaced_component.properties.push(new_prop)
        })

        this.editable_data.push(new_replaced_component)
        let index = this.editable_data.length - 1
        this.setCheckable(this.editable_data[index], index)
      },

      deleteItem() {
        if (this.editable_data.length > 1) {
          this.editable_data = this.editable_data.filter((el, i) => i !== this.checkable.index)
        } else {
          let empty_replaced_component = {
            code: '',
            name: '',
            properties: [
              {
                key: '',
                value: ''
              },
              {
                key: '',
                value: ''
              }
            ],
            replacement_date: '',
            replacement_reason: ''
          }
          this.editable_data = [empty_replaced_component]
        }
        this.checkable = undefined
      },

      addProperties(properties=[]) {
        this.editable_data[this.checkable.index]['properties'].forEach(p => { properties.push(p) })
        let empty_property = {key: '', value: ''}
        properties.push(empty_property)
        this.editable_data[this.checkable.index]['properties'] = properties
      },

      deleteProperties() {
        let row = this.editable_data[this.checkable.index]['properties']
        if (row.length > 2) {
          this.editable_data[this.checkable.index]['properties'] = this.editable_data[this.checkable.index]['properties'].filter((el, i) => i !== row.length - 1)
        } else {
          this.editable_data[this.checkable.index]['properties'] = [
            {
              key: '',
              value: ''
            },
            {
              key: '',
              value: ''
            }
          ]
        }
      }
    },

    mounted(){
      this.setPropertiesTableHeight('replaced-components-table')
    }
  }
</script>

<style lang='scss'>

</style>