export const options = (data, locale, theme) => ({
  grid: {
    borderColor: theme.grid_border_color,
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: ['#5B7AFF'],
    width: 2,
    dashArray: 0,
  },
  fill: {
    colors : ['#5B7AFF'],
    opacity: 0.25,
    type: 'solid'
  },
  chart: {
    zoom: {
      enabled: true,
      zoomedArea: {
        fill: {
          color: '#5B7AFF',
          opacity: 0.10
        },
        stroke: {
          color: '#5B7AFF',
          opacity: 0.4,
          width: 1
        }
      }
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: []
      },
      autoSelected: 'zoom'
    },
    foreColor: theme.fore_color,
    defaultLocale: locale || 'ru',
    locales: [{
      name: 'ru',
      options: {
        months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        shortMonths: ['янв.', 'февр.', 'марта', 'апр.', 'мая', 'июня', 'июля', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
        days: ['воскресение', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        shortDays: ['вскр', 'пнд', 'вт', 'ср', 'чт', 'пт', 'суб'],
        toolbar: {
          zoomIn: 'Увеличить масштаб',
          zoomOut: 'Уменьшить масштаб',
          reset: 'Сбросить',
          selectionZoom: 'Управлять масштабом',
          pan: 'Переместить'
        }
      }
    },
    {
      name: "en",
      options: {
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        toolbar: {
          selectionZoom: "Selection Zoom",
          zoomIn: "Zoom In",
          zoomOut: "Zoom Out",
          pan: "Panning",
          reset: "Reset Zoom"
        }
      }
    }],
    id: 'vuechart-example',
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: 'datetime',
    categories: data.map(val => val.finished_at)
  },
})