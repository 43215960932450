<template lang='pug'>

  div
    div(:class="[currentFieldIsInvalid ? main_class + ' valid-error' : main_class]")
      q-option-group(:options="fieldOptions" :label="label" type="radio" v-model="currentField" :id="name")

    //span(v-if="currentFieldIsInvalid" class="valid-error-message") {{valid_error_message}}

</template>

<script>
  export default {
    data: function () {
      return {
        method: this.parentData.method,
        method_limit: this.parentData.method_limit,
        grid: this.parentData.grid,
        value: this.parentData.data[0].value,
        label: this.parentData.data[0].label,
        name: this.parentData.data[0].name,
        required: this.parentData.data[0].require,
        depend_from: this.parentData.data[0].depend_from || {},

        // options_params: this.parentData.data[0].options_params || {},
        options_path: this.parentData.data[0].options_path,

        optObject: {},
        options: [],
        fieldOptions: [],
        valid_error_message: null,
        main_class: 'form-radio'
      }
    },

    props: {
      parentData: Object
    },

    computed: {

      options_params() {
        return this.parentData.data[0].options_params || {}
      },

      currentField: {
        get() {
          let form_field = this.currentForm[this.name]
          if (form_field) {
            return form_field['field']
          } else {
            return undefined
          }
        },
        set(value) {
          let result = {}
          result['field'] = value
          result['invalid'] = this.invalid(value)
          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
          if (!result['invalid']) {
            this.$store.commit('resetFormFieldValue', {grid_name: this.grid, field: 'invalid_fields', value: this.name})
          }
        }
      },

      fieldDependency() {
        return this.checkDependency()
      }
    },

    watch: {
      fieldDependency(newVal, oldVal) {},

      options_params(newVal, oldVal) {
        if (newVal['options_type'] && newVal['options_type'] !== oldVal['options_type']) {
          this.options = []
          this.loadOptions()
        }
      }
    },

    methods: {

      setField(val) {
        this.currentField = val
      },

      resetField() {
        this.currentField = ''
      },

      invalid(val=undefined) {

        if (this.required) {
          if (typeof val === 'number') {
            this.valid_error_message = null
            return false
          } else {
            this.valid_error_message = this.notifies.not_empty
            return true
          }
        } else {
          this.valid_error_message = null
          return false
        }
      },

      loadOptions() {
        this.$backend.collection(this.$store.state.paths[this.options_path] + '/collection', this.options_params)
        .then(({ data }) => {

          this.optObject = data
          let for_select = this.optObject.options.map(el => {

            return Object.keys(el).reduce((result, key) => {
              if (key === 'id') {
                result['value'] = el[key];
              } else if (key === 'title') {
                result['label'] = el[key];
              }
              return result
            }, {})
          });

          this.options = this.options.concat(for_select)
          this.fieldOptions = this.options;

          let result = {}

          // if (typeof this.value === 'number') {
          if (![undefined, null, ''].includes(this.value)) {
            let value = this.fieldOptions.find(e => e['value'] === this.value);
            result['field'] = value['value']
            result['invalid'] = this.invalid(value['value'])

          } else {
            result['invalid'] = this.invalid()
          }

          this.$store.commit('updateFormField', {grid_name: this.grid, field: this.name, value: result})
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
          this.error = true
        })
        .finally(() => {
          // this.optionsLoaded = true;
          this.$emit('fields-loaded', ['radio_collection', this.name])
        })
      },
    },

    created() {
    },

    beforeMount() {
      this.loadOptions()
    }
  }
</script>

<style lang="scss">

  @import "../../../../assets/styles/forms/fields/radio";

</style>