<template lang='pug'>

  div(v-if="dataHasLoaded" class="add-work-equipment-form justify-center")
    div(id="form" class="new-edit-form")
      div(class="form-card")
        q-card-section
          form-header(ref="form-header" @close-form="closeForm"
                      :parentData="{grid: grid, title: data.custom_form_header.title + row.id, title_icon: title_icon_path}")

        div(v-if="loading" class="spinner-container form-spinner")
          q-spinner(color="primary" size="3em")

        q-card-section(v-show="!loading")
          q-form

            div(class="row" v-for="(row, i) in data.scheme" :ref="row[0]['name']")
              div(v-for="field in row" :key="field.name" :class="'col-' + field.col" style="padding: 5px;")

                component(v-show="getFieldDataByName(field)"
                          :is="getFieldComponent(getFieldDataByName(field))"
                          @fields-loaded="fieldsLoaded"
                          :parentData="{grid: grid, data: [getFieldDataByName(field)]}")


</template>

<script>
  import formHeader from '../../../shared/forms/formHeader'
  import fields from '../../../shared/forms/fields'

  export default {
    data: function () {
      return {
        grid: 'ppr_systems',
        item: 'ppr_system',
        path: this.$store.state.paths['ppr_system'],
        title_icon_path: 'add_header.svg',
        row: this.parentData.row,
        callback_params: this.parentData.callback_params,
        data: [],

        loading_fields: [],
        dataHasLoaded: false,
        loading: true
      }
    },

    props: {
      parentData: Object
    },

    components: {
      formHeader,
      fields
    },

    watch: {
      loading(newVal, oldVal) {
        if ([true, false].includes(newVal)) {
          this.$refs['form-header'].setLoading(newVal);
        }
      }
    },

    methods: {

      closeForm(data) {
        this.$emit('close-form', data)
      },

      loadFormData(params={}) {

        let form_data_path = this.path + '/form_data';

        if (this.row) {
          params['id'] = this.row.id;
        }

        params['custom_form'] = 'add_work_equipment'

        this.$backend.index(form_data_path, { params: params })
        .then(({ data }) => {
          this.data = data
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response) { this.reLogin(error.response.status) }
          }
        })
        .finally(() => {
          this.dataHasLoaded = true
        })
      },

      getFieldComponent(key) {
        if (key) {
          return fields[key.type]
        }
      },

      getFieldDataByName(field) {
        return this.data.fields.find(f => f.name === field.name)
      },

      fieldsLoaded(field=undefined) {

        if (this.loading_fields.length === 0) {
          this.loading_fields = this.data.fields.filter(f => this.$store.state.fields_with_options.includes(f.type)).map(f => [f.type, f.name])
        }

        if (field) {
          this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString())
        }

        if (this.loading_fields.length === 0) {
          this.loading = false
        }
      }
    },

    created(){
      this.$emit('row-is-active', true)
      this.loadFormData()
    },

    mounted() {
    },

    beforeMount() {
    }
  }
</script>

<style lang='scss'>

  @import "../../../../assets/styles/forms/new-edit-form";

</style>