<template lang='pug'>

  div(class="map-building-telemetry")

    div(v-if="indications.length > 0" class="common-indications-data")
      div(class="indication-badge" v-for="(indication, i) in indications" :key="i")
        div(class="title")
          inline-svg(:src="require(`../../../../../assets/icons/map/telemetry/temperature.svg`)")
          div(class="value-data")
            span(class="value") {{indication.value || '***'}}
            span(class="unit") {{indication.measure_unit || ''}}
        div(class="sub-title")
          span {{indication.title}}

    div(v-if="floors_loading" class="spinner-container form-spinner")
      q-spinner(color="primary" size="3em")

    grid-hidden-msg(v-show="!floors_loading && floors.length === 0" ref="hidden-msg" :parentData="{hide_table: hide_table}")

    div(v-if="rooms.length > 0" class="telemetry-rooms-swiper-area")
      swiper(ref="roomsSwiper" :options="options" class="main-swiper" id="telemetry-rooms-swiper")
        div(class="room-badge swiper-slide" :id="`room-badge-${r.id}`" v-for="r in rooms" @click="onClickRoom(r)")
          span {{r.short_title}}
        div(class="swiper-pagination" id="swiper-pagination" slot="pagination")

    div(v-show="!floors_loading && floors.length > 0")
      div(class="entity-view__label") {{`${map_locales['plan']} (${floor && floor.short_title || ''})`}}:
      div(class="floor-plan")
        floorMap(v-if="!floors_loading && floor" :parentData="{floor_id: floor.id, editable: false}")

        div(class="floors")
          ul(class="floors-list")
            div(v-for="(f, i) in floors" class="floor-link")
              li(class="floor-plan-badge" :id="`floor-plan-badge-${f.id}`" @click="onClickFloor(f)")
                span {{f.short_title}}

    div(class="button-group row justify-center")
      q-btn(flat no-caps class="object-pass-btn" :label="map_locales['object_pass']" @click="onClickObjectPass()")

</template>

<script>
  import { Swiper } from 'vue-awesome-swiper';
  import gridHiddenMsg from '../../../../shared/grid/gridHiddenMsg'
  import floorMap from '../../../../shared/floorMap'

  export default {
    data: function () {
      return {
        path: this.$store.state.paths,
        floors: [],
        floor: {},
        rooms: [],
        room: {},
        indications: [],
        indication: {},
        floors_loading: true,
        options: {
          spaceBetween: 10,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          grabCursor: true,
          loop: false,
          freeMode: true,
          mousewheel: true,
          autoplay: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },

        hide_table: false
      }
    },

    props: {
      building: Object
    },

    components: {
      Swiper,
      gridHiddenMsg,
      floorMap
    },

    watch: {
      floor(newVal, oldVal) {
        if (newVal) {
          this.loadRooms()
          let attr = {
            item: 'room',
            new_item: this.room
          }
          this.activeBadge(attr, false)
        }
      }
    },

    methods: {

      hideTab(val) {
        this.hide_table = val
        if (this.$refs['hidden-msg']) {
          this.$refs['hidden-msg'].hideTable(val)
        }
      },

      onClickObjectPass(params={}) {
        params['row_id'] = this.building.getId()
        this.toObjectPass(params)
      },

      loadFloors(params={}) {
        params['building_id'] = this.building.getId()

        let attr = {
          item: 'floor',
          path: 'floor',
          route: 'collection',
          params: params,
          response_key: 'options'
        }

        this.loadCollection(attr)
        .finally(() => {
          this.floors_loading = false

          if (this.floors.length > 0) {
            let attr = {
              item: 'floor',
              new_item: this.floors[0],
              id: 'floor-plan'
            }
            this.activeBadge(attr)
            this.hideTab(false)

          } else {
            this.hideTab(true)
          }
        })
      },

      loadRooms(params={}) {
        params['floor_id'] = this.floor.id

        let attr = {
          item: 'room',
          path: 'room',
          route: 'collection',
          params: params,
          response_key: 'options'
        }

        this.loadCollection(attr)
        .finally(() => {
          this.setRoomsSwiperHeight()
        })
      },

      loadIndications(params={}) {
        if (!params['room_id']) {
          params['building_id'] = this.building.getId()
        }

        let attr = {
          item: 'indication',
          path: 'telemetry',
          route: 'indications',
          params: params
        }

        this.loadCollection(attr)
      },

      loadCollection(attr) {
        return this.$backend.collection(`${this.path[attr.path]}/${attr.route}`, attr.params)
        .then(({ data }) => {
          this[`${attr.item}s`]  = data[attr.response_key] || data
          this[attr.item] = this[`${attr.item}s`][0]
        })
        .catch((error) => {
          console.log(error);
          if (error.response) { this.reLogin(error.response.status) }
        })
      },

      onClickFloor(floor) {
        if (floor) {
          let attr = {
            item: 'floor',
            new_item: floor,
            id: 'floor-plan'
          }
          this.activeBadge(attr)
          this.floor = floor

          setTimeout(() => {
            if (this.$refs.roomsSwiper) { this.$refs.roomsSwiper.$swiper.slideTo(0, 600, false) }
          },300)
        }
      },

      onClickRoom(room) {
        if (room) {
          let attr = {
            item: 'room',
            new_item: room
          }
          this.activeBadge(attr)
          this.room = room
          // this.loadIndications({room_id: this.room.id})
        }
      },

      setRoomsSwiperHeight() {
        let el = document.getElementById('swiper-pagination')
        let rooms_swiper = document.getElementById('telemetry-rooms-swiper')

        if (el && rooms_swiper) {
          if (el.offsetHeight > 20) {
            rooms_swiper.style.height = `${rooms_swiper.offsetHeight - 20 + el.offsetHeight}px`
          } else {
            rooms_swiper.style.height = '70px'
          }
        }
      }
    },

    created() {
      this.loadIndications()
      this.loadFloors()
    }
  }
</script>

<style lang="scss">

  .map-building-telemetry {
    margin-bottom: 40px;

    .spinner-container {
      position: initial;
      height: 100px;
    }

    .hidden-tabs-message {
      height: 100px;

      span {
        padding-top: initial;
      }
    }

    .button-group {
      width: 100%;

      .object-pass-btn {
        margin-top: 25px;
        border-radius: 13px;
        background-color: #5B7AFF;
        color: #fff;
        height: 34px;
        width: 200px;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 22px;
        }
      }
    }

    .main-swiper {
      height: 70px;

      .swiper-pagination-bullet {
        background: #007AFF;
      }
    }

    .swiper-slide {
      width: fit-content !important;
    }

    .room-badge {
      height: fit-content;
      padding: 5px;
      border-radius: 15px;
      background: var(--telemetry-floor-badge-background);
      padding-left: 30px;
      padding-right: 30px;
      cursor: pointer;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;

        white-space: nowrap;

        color: var(--show-card-info-block-color);
      }
    }

    .active-badge {
      background: #627FFF !important;

      span {
        color: #FFFFFF !important;
      }
    }

    .telemetry-rooms-swiper-area {
      width: 100%;
    }

    .common-indications-data {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 25px 25px 25px;
      width: 100%;
      margin: auto;
      min-height: 100px;
      max-height: 300px;
      overflow-y: auto;
      margin-bottom: 20px;

      .indication-badge {
        margin: auto;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 138px;
        height: 67px;
        background: var(--map-building-indication-badge);
        border: var(--map-building-indication-border);
        box-sizing: border-box;
        border-radius: 20px;

        text-align: center;

        .title {
          height: 25px;
          margin: 7px;

          display: flex;
          justify-content: center;

          svg {
            path {
              fill: var(--show-card-actions-button-color);
            }
          }

          .value-data {
            .value {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              padding-left: 7px;
            }

            .unit {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 27px;
            }
          }

          span {
            color: var(--show-card-actions-button-color)
          }
        }

        .sub-title {
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            color: var(--map-building-indication-sub-title);
          }
        }
      }
    }

    .floor-plan {
      margin-top: 10px;
      position: relative;

      img {
        object-fit: cover;
        height: 174px;
        width: 100%;
        border-radius: 20px;
      }

      .floors::-webkit-scrollbar-track {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .floors {
        overflow-y: auto;
        position: absolute;
        top: 10px;
        width: fit-content;
        min-width: 50px;
        max-width: 150px;
        height: fit-content;
        max-height: 150px;
        background: #fff;
        border-radius: 15px;
        right: 15px;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 16%);

        .floors-list {
          text-align: center !important;

          .floor-link {
            padding: 10px;

            .active-badge {
              background: #5B7AFF;

              span {
                color: #FFFFFF !important;
              }
            }

            li {
              padding: 10px;
              cursor: pointer;
              align-items: center;
              user-select: none;
              list-style-type: none;
              border-radius: 10px;

              span {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.2px;

                white-space: normal;

                color: #000000;
              }
            }

            li:hover {
              background: #F1F1F1;
              border-radius: 10px;
              span {
                color: #000000;
              }
            }
          }
        }
      }
    }
  }

</style>